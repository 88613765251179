import React from "react";
import { Helmet } from "react-helmet";
import MainLayout from "../components/Layout/MainLayout";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import { retry } from "../utils/helpers/retry";

const LazyCraftsmenList = React.lazy(() => retry(() => import("../container/Craftsman/CraftsmenList")));

const CraftsmenPage = ({ title, match, t, network, ...rest }) => (
    <MainLayout network={network} match={match}>
        <Helmet>
            <title>
                {match && match.params && match.params.state ? `${title}: ${t("states." + match.params.state)}` : title}
            </title>
        </Helmet>
        <React.Suspense fallback={<LoadingIndicator />}>
            <LazyCraftsmenList match={match} {...rest} />
        </React.Suspense>
    </MainLayout>
);

export default CraftsmenPage;
