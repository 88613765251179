import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { renderVariantClasses } from "../../utils/helpers/render";
import Icon from "../Icon/Icon";
import styles from "./LinkIcon.css";

const Button = props => <button {...props}>{props.children}</button>;

// register linktypes
const linkTypes = {
    NavLink,
    Link,
    Button
};

export default class LinkIcon extends Component {
    static defaultProps = {
        component: "Button",
        variant: "bright",
        justify: "flexStart"
    };

    static propTypes = {
        component: PropTypes.string,
        variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        iconName: PropTypes.string,
        size: PropTypes.string,
        icon: PropTypes.object,
        customClass: PropTypes.string,
        justify: PropTypes.string,
        optionalText: PropTypes.any
    };

    render() {
        const {
            component,
            children,
            iconName,
            className,
            justify,
            variant,
            icon,
            customClass,
            iconClass,
            textClass,
            optionalText,
            inheritColor,
            ...rest
        } = this.props;

        const Component = !!component && linkTypes[component] ? linkTypes[component] : Button;
        const variantClasses = renderVariantClasses(variant, styles);

        return Component ? (
            <Component
                {...rest}
                className={classNames(className, styles.link, variantClasses, {
                    [styles[customClass]]: customClass,
                    [styles.noIcon]: !(icon || iconName),
                    [styles[justify]]: justify,
                    [styles.disabled]: this.props.disabled
                })}
            >
                {icon || iconName ? (
                    <Icon className={classNames(styles.linkIcon, iconClass)} name={iconName} {...icon} />
                ) : null}
                {children ? (
                    <span className={classNames(styles.linkText, textClass)}>
                        {children}
                        {optionalText ? <span className={styles.optionalText}>{optionalText}</span> : null}
                    </span>
                ) : null}
            </Component>
        ) : null;
    }
}
