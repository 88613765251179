import { LOGOUT } from "../actions/auth.actions";
import {
    GET_CRAFTSMEN_STATES_FULFILLED,
    GET_CRAFTSMEN_STATES_PENDING,
    GET_CRAFTSMEN_STATES_REJECTED,
    GET_PROJECTS_STATES_FULFILLED,
    GET_PROJECTS_STATES_PENDING,
    GET_PROJECTS_STATES_REJECTED
} from "../actions/states.actions";

const initialState = {
    craftsmen: {
        data: [],
        failed: false,
        isFetching: false
    },
    projects: {
        data: [],
        failed: false,
        isFetching: false
    }
};

export function states(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;
        case GET_CRAFTSMEN_STATES_PENDING:
            return {
                ...state,
                craftsmen: {
                    ...state.craftsmen,
                    failed: false,
                    isFetching: true
                }
            };
        case GET_CRAFTSMEN_STATES_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmen: {
                        ...state.craftsmen,
                        isFetching: false,
                        failed: true
                    }
                };
            }
        case GET_CRAFTSMEN_STATES_FULFILLED:
            return {
                ...state,
                craftsmen: {
                    ...state.craftsmen,
                    isFetching: false,
                    failed: false,
                    data: action.payload.data
                }
            };
        case GET_PROJECTS_STATES_PENDING:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    failed: false,
                    isFetching: true
                }
            };
        case GET_PROJECTS_STATES_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    projects: {
                        ...state.projects,
                        isFetching: false,
                        failed: true
                    }
                };
            }
        case GET_PROJECTS_STATES_FULFILLED:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    isFetching: false,
                    failed: false,
                    data: action.payload.data
                }
            };
        default:
            return state;
    }
}
