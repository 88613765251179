import React from "react";
import { classNames } from "@lucify/utils";
import { Initials, renderInitials } from "../../utils/helpers/render";
import styles from "./Avatar.css";

interface AvatarProps {
    imageSrc?: string;
    imageAlt?: string;
    imageSize?: AvatarImagesSizes;
}

export type AvatarImagesSizes = "defaultSize" | "smallSize";

export const Avatar: React.FC<AvatarProps & Initials> = ({
    firstName,
    lastName,
    imageSrc,
    imageAlt,
    imageSize = "defaultSize"
}) => {
    return (
        <div className={classNames(styles.imageBox, styles[imageSize])}>
            {imageSrc ? (
                <img className={styles.image} src={imageSrc} alt={imageAlt} />
            ) : (
                renderInitials({ firstName, lastName })
            )}
        </div>
    );
};

Avatar.displayName = "Avatar";
