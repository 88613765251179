import React from "react";
import { Helmet } from "react-helmet";
import SimpleLayout from "../components/Layout/SimpleLayout";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import { retry } from "../utils/helpers/retry";

const LazyProjectRevoke = React.lazy(() => retry(() => import("../container/Project/Entziehen")));

const ProjectRevokePage = ({ title, match, network, ...rest }) => (
    <SimpleLayout network={network} match={match}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <React.Suspense fallback={<LoadingIndicator />}>
            <LazyProjectRevoke
                match={match}
                macherProjektId={match.params.macherProjektId}
                id={match.params.id}
                {...rest}
            />
        </React.Suspense>
    </SimpleLayout>
);

export default ProjectRevokePage;
