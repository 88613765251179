import React, { Component } from "react";
import { uuid } from "@lucify/utils";

// Pub/Sub System
const subscriptions = new Map();

const subscribe = callback => {
    const id = uuid();
    subscriptions.set(id, callback);
    return id;
};

const unsubscribe = id => {
    subscriptions.delete(id);
};

export const triggerTick = () => {
    subscriptions.forEach(callback => callback());
};

// Interval Handling
let interval = null;

export const startTick = timer => {
    interval = setInterval(triggerTick, timer);
};

export const endTick = () => {
    clearInterval(interval);
};

// HOC
export const withTick = WrappedComponent => {
    class WithTick extends Component {
        render() {
            return (
                <WrappedComponent onTick={subscribe} offTick={unsubscribe} triggerTick={triggerTick} {...this.props} />
            );
        }
    }

    WithTick.displayName = `WithTick(${WrappedComponent.displayName || WrappedComponent.name})`;

    return WithTick;
};

// DECORATOR
export function tickAfter(target, name, descriptor) {
    const wrappedMethod = descriptor.value;

    descriptor.value = function (...args) {
        const result = wrappedMethod.call(this, ...args);

        if (result instanceof Promise) {
            result.then(response => {
                triggerTick();
                return response;
            });
        } else {
            triggerTick();
        }

        return result;
    };

    return descriptor;
}
