import React, { InputHTMLAttributes, PureComponent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import { FieldProps, getIn } from "formik";
import { $TSFixMe } from "@lucify/types";
import { FormFieldHint, FormGroup, FormLabel } from "../../FormElements/FormElements";
import Icon from "../../Icon/Icon";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import styles from "../SingleInput.css";
import FormMessageValidation from "./FormMessageValidation";

interface SingleInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    labelHint: string;
    hint: $TSFixMe; // string oder node
    placeholder: string;
    required: boolean;
    layout: string;
    disableBrowserSpinners: boolean;
    dataCy: string;
    showInlineError: boolean;
    forwardRef: $TSFixMe;
    autoComplete: string;
    spellCheck: boolean;
    autoCorrect: string;
    autoCapitalize: string;
    loading: boolean;
    currency: string;
    touchOnChange: boolean;
    classNameWrapper: string;
    icon: string;
}

export default class SingleInput extends PureComponent<SingleInputProps & FieldProps> {
    static defaultProps = {
        type: "text",
        label: "",
        placeholder: "",
        required: false,
        layout: "",
        dataCy: null,
        disableBrowserSpinners: true,
        showInlineError: false,
        autoComplete: "off",
        spellCheck: "false",
        autoCorrect: "off",
        autoCapitalize: "off",
        touchOnChange: false
    };

    @autobind
    handleChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event);
        }
        this.props.field.onChange(event);

        if (this.props.touchOnChange) {
            this.props.form.setFieldTouched(this.props.field.name, true, false);
        }
    }

    render() {
        const {
            field,
            form,
            showInlineError,
            className,
            classNameWrapper,
            loading,
            icon,
            layout,
            dataCy,
            disableBrowserSpinners,
            children,
            labelHint,
            touchOnChange,
            onFocus,
            ...props
        } = this.props;
        const error = form.errors ? getIn(form.errors, field.name) : false;
        const hasError = !!error;
        // fallback to '' in case of missing value to prevent un/controlled issues
        const value = field.value !== null && field.value !== undefined ? field.value : "";

        function handleOnWheel(event) {
            event.preventDefault();
        }

        function handleFocus(event) {
            if (props.type === "number") {
                event.currentTarget.addEventListener("wheel", handleOnWheel, { passive: false });
            }

            if (onFocus) {
                onFocus(event);
            }
        }

        function handleBlur(event) {
            event.currentTarget.removeEventListener("wheel", handleOnWheel);
            if (field.onBlur) {
                field.onBlur(event);
            }
        }

        return (
            <FormGroup
                hasLabel={!!props.label}
                className={classNames(classNameWrapper, {
                    [styles[layout]]: layout
                })}
            >
                <FormMessageValidation field={field} form={form} disabled={!(hasError && showInlineError)} />
                <FormLabel labelText={props.label || ""} labelHint={labelHint} />

                <div
                    className={classNames(styles.wrapper, {
                        [styles.currency]: this.props.currency
                    })}
                    data-currency={this.props.currency}
                >
                    <input
                        {...props}
                        name={field.name}
                        onChange={this.handleChange}
                        onFocus={handleFocus}
                        value={value}
                        onBlur={handleBlur}
                        data-cy={this.props.dataCy || field.name}
                        className={classNames(styles.input, className, {
                            [styles.hasError]: hasError,
                            [styles.disableBrowserSpinners]: disableBrowserSpinners
                        })}
                    />

                    {loading ? (
                        <LoadingIndicator variant={"onLight"} className={styles.indicator} />
                    ) : icon ? (
                        <Icon name={icon} className={styles.icon} />
                    ) : null}
                </div>
                {this.props.hint ? <FormFieldHint hint={this.props.hint} /> : null}
            </FormGroup>
        );
    }
}
