import uriTemplates from "uri-templates";
import { Feedback, FeedbackType } from "@lucify/internals";
import { $TSFixMe, IDocumentLink, MacherProjektForPatchDTO } from "@lucify/types";
import { Methods } from "./Fetch";
import { Service } from "./Service";
import {
    ActionPayload,
    CommentForPostDTO,
    CraftsmanFacilityMetricsDTO,
    CraftsmanFacilityStatesDTO,
    CreatedObject,
    DocumentPatchRequest,
    GMVAndFeedbackResponseDTO,
    HandwerkerAccessPayload,
    HandwerkerAnfrageDTO,
    MacherProjektDTO,
    MacherProjektDocumentLink,
    MacherProjektListDTO,
    MacherProjektStatusMetaDTO,
    MacherProjektTaskCreateDTO,
    MacherProjektTaskDTO,
    MacherProjektTaskPatchDTO,
    Market,
    MatchingCraftsmen,
    OfferAcceptedValueDTO,
    OfferAndGMVDTO,
    OfferSentValueDTO,
    PartnerRequest,
    Project,
    ProjektKoordinatorCreateDTO,
    ProjektKoordinatorDTO,
    ProjektKoordinatorPatchDTO,
    Unit,
    VermittlungDTO,
    VermittlungForPatchDTO,
    VermittlungStatesDTO,
    VorOrtTerminAbstimmenDTO,
    VorgangComment,
    VorgangDTO,
    VorgangForPatchDTO,
    VorgangStatesDTO,
    VorgangStatusMetaDTO,
    WorkTask
} from "./generated/macher-service.types";

const endpoints = {
    craftsmenStates: "/vermittlungs-service/api/{country}/states/craftsman-facilities",
    vermittlungenStates: "/vermittlungs-service/api/{country}/states/vermittlungen",
    vorgangStates: "/vermittlungs-service/api/{country}/states/vorgaenge",

    stats: "/vermittlungs-service/api/{country}/craftsman-facilities/{id}/stats",
    request: "/vermittlungs-service/api/{country}/vermittlungen/{vermittlungId}/requests",
    response: "/vermittlungs-service/api/{country}/vermittlungen/{vermittlungId}/requests/{requestId}/{action}",
    matching: "/vermittlungs-service/api/{country}/craftsman-facilities/matches/{vermittlungId}",
    publicRequest: "/vermittlungs-service/api/handwerkeranfragen",

    vermittlungen: "/vermittlungs-service/api/{country}/vermittlungen",
    profile: "/vermittlungs-service/api/{country}/vermittlungen/{id}",
    macherprojekte: "/vermittlungs-service/api/{country}/macherprojekte",
    macherprojektProfile: "/vermittlungs-service/api/{country}/macherprojekte/{id}",
    vorgangProfile: "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}",
    assignOperator: "/vermittlungs-service/api/{country}/vermittlungen/{id}/assign",
    complete: "/vermittlungs-service/api/{country}/vermittlungen/{id}/complete",
    reject: "/vermittlungs-service/api/{country}/vermittlungen/{id}/reject",
    revoke: "/vermittlungs-service/api/{country}/vermittlungen/{id}/revoke",
    cancel: "/vermittlungs-service/api/{country}/vermittlungen/{id}/cancel",
    reopen: "/vermittlungs-service/api/{country}/vermittlungen/{id}/reopen",
    create: "/vermittlungs-service/internal/{country}/projects",
    kommentare: "/vermittlungs-service/api/{country}/vorgaenge/{vorgangId}/comments",
    kommentar: "/vermittlungs-service/api/{country}/vorgaenge/{vorgangId}/comments/{commentId}",
    markets: "/vermittlungs-service/api/{country}/markets",

    // first iteration of history, history endpoint not available, use requests until history is implemented for API
    history: "/vermittlungs-service/api/{country}/vorgaenge/{id}/requests",

    // Projektkoordinatoren (PKOs)
    projektkoordinatoren: "/vermittlungs-service/api/{country}/projektkoordinatoren",
    projektkoordinator: "/vermittlungs-service/api/{country}/projektkoordinatoren/{id}",

    vorgangTriggers:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger",

    // Vor Ort Termin
    vorOrtTerminAbstimmen:
        "/vermittlungs-service/api/{country}/vermittlungen/{vermittlungId}/trigger/vorOrtTerminAbstimmen",
    vorOrtTerminLoeschen:
        "/vermittlungs-service/api/{country}/vermittlungen/{vermittlungId}/trigger/vorOrtTerminLoeschen",
    vorOrtTerminBestaetigen:
        "/vermittlungs-service/api/{country}/vermittlungen/{vermittlungId}/trigger/vorOrtTerminBestaetigen",

    // MacherProjekt status trigger
    macherProjektTriggers: "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/trigger",
    starteVorgangTrigger: "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/trigger/starteVorgang",

    // FSM Trigger
    genericTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/{trigger}",
    angebotAnnehmenMitAngebotsWertTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/angebotAnnehmenMitAngebotsWert",
    angebotVersendenMitAngebotsWertTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/angebotVersendenMitAngebotsWert",
    abbrechenTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/abbrechen",
    umsetzenTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/umsetzen",
    umsetzenMitGMVTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/umsetzenMitGMV",
    zusagenTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/zusagen",
    ablehnenTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/ablehnen",
    absagenTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/absagen",
    zurueckziehenTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/zurueckziehen",
    setzeVorOrtTerminTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/setzeVorOrtTermin",
    setzeAngebotsWerteTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/setzeAngebotsWerte",
    anfragenTrigger:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/anfragen",

    // Worktasks
    worktasks: "/vermittlungs-service/api/{country}/worktasks",

    // Craftsman Request Documents
    craftsmanRequestDocumentDraftUri:
        "/vermittlungs-service/api/{country}/vermittlungen/{id}/documents/current-craftsman-request-draft.pdf?access_token={token}",
    craftsmanRequestDocumentDraft:
        "/vermittlungs-service/api/{country}/vermittlungen/{id}/documents/current-craftsman-request-draft.pdf",
    craftsmanRequestDocument: "/vermittlungs-service/api/{country}/vermittlungen/{id}/documents/",
    craftsmanRequestDocumentUri:
        "/vermittlungs-service/api/{country}/vermittlungen/{id}/documents/{documentId}?download={download}&gaeb={gaeb}&access_token={token}",
    craftsmanRequestDocumentDownload:
        "/vermittlungs-service/api/{country}/vermittlungen/{id}/documents/{documentId}?gaeb={gaeb}",

    // Craftsman Request Documents (Coordination)
    craftsmanRequestCoordinationDocumentDraftUri:
        "/vermittlungs-service/api/{country}/vermittlungen/{id}/dirty-documents/{craftsmanFacilityId}?access_token={token}",
    craftsmanRequestCoordinationDocument:
        "/vermittlungs-service/api/{country}/vermittlungen/{id}/dirty-documents/{craftsmanFacilityId}",

    // Dokumente
    projektDokumente: "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/dokumente",
    projektDokumentUri:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/dokumente/{documentId}?download={download}&access_token={token}",
    projektDokumentDownload:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/dokumente/{documentId}?download=false",
    updateProjektDokument:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/dokumente/{documentId}",
    handwerkerDokumente: "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/dokumente/craftsman",
    handwerkerDokument:
        "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/dokumente/{documentId}/fetch?download={download}",

    // Tätigkeiten
    task: "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/tasks/{macherProjektTaskId}",
    createTask: "/vermittlungs-service/api/{country}/macherprojekte/{macherProjektId}/tasks"
};

export class MacherService extends Service<typeof endpoints> {
    endpoints = endpoints;

    getCraftsmenStates() {
        return this.fetch.request<CraftsmanFacilityStatesDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("craftsmenStates"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    getProjectsStates() {
        return this.fetch.request<VermittlungStatesDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("vermittlungenStates"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    getVorgangStates() {
        return this.fetch.request<VorgangStatesDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("vorgangStates"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    getCraftsmanFacilityMetrics(id: string) {
        return this.fetch.request<CraftsmanFacilityMetricsDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("stats"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    request(vermittlungId: string, craftsmanFacilityId: string, manuallyMatched = false) {
        return this.fetch.request<PartnerRequest>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("request"),
                data: {
                    ...this.defaults,
                    vermittlungId
                }
            },
            data: {
                craftsmanFacilityId,
                manuallyMatched
            }
        });
    }

    response(requestId: string, vermittlungId: string, action: "accept" | "decline", data: $TSFixMe) {
        return this.fetch.request<never>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("response"),
                data: {
                    ...this.defaults,
                    requestId,
                    vermittlungId,
                    action
                }
            },
            data
        });
    }

    getMatches(vermittlungId: string, params?: { notes: string }) {
        return this.fetch.request<MatchingCraftsmen[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("matching"),
                data: {
                    ...this.defaults,
                    vermittlungId
                }
            },
            params
        });
    }

    publicRequest(data: ActionPayload) {
        return this.fetch.request<string>({
            method: Methods.POST,
            url: this.endpoint("publicRequest"),
            data
        });
    }

    getProfile(id: string) {
        return this.fetch.request<VermittlungDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("profile"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    updateProfile(id: string, data: VermittlungForPatchDTO) {
        return this.fetch.request<VermittlungDTO>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("profile"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    getMacherProjekte(searchParams: $TSFixMe) {
        return this.fetch.request<MacherProjektListDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("macherprojekte"),
                data: {
                    ...this.defaults
                }
            },
            params: {
                ...searchParams
            }
        });
    }

    getMacherProjekteKPIs(searchParams: $TSFixMe) {
        return this.fetch.request<never>({
            method: Methods.HEAD,
            rest: {
                url: this.endpoint("macherprojekte"),
                data: {
                    ...this.defaults
                }
            },
            params: {
                ...searchParams
            }
        });
    }

    getMacherProjektProfile(id: string) {
        return this.fetch.request<MacherProjektDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("macherprojektProfile"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    updateMacherProjektProfile(id: string, data: MacherProjektForPatchDTO) {
        return this.fetch.request<MacherProjektDTO>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("macherprojektProfile"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    getVorgangProfile(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("vorgangProfile"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    updateVorgangProfile(macherProjektId: string, vorgangId: string, data: VorgangForPatchDTO) {
        return this.fetch.request<VorgangDTO>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("vorgangProfile"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    assignOperator(id: string) {
        return this.fetch.request<VermittlungDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("assignOperator"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    complete(id: string, data: $TSFixMe) {
        return this.fetch.request<VermittlungDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("complete"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    reject(id: string, data: $TSFixMe) {
        return this.fetch.request<VermittlungDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("reject"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    revoke(id: string, data: $TSFixMe) {
        return this.fetch.request<VermittlungDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("revoke"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    cancel(id: string, data: $TSFixMe) {
        return this.fetch.request<VermittlungDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("cancel"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    reopen(id: string) {
        return this.fetch.request<VermittlungDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("reopen"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    starteVorgangTrigger(macherProjektId: string) {
        return this.fetch.request<CreatedObject>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("starteVorgangTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId
                }
            }
        });
    }

    createProject(data: Project) {
        return this.fetch.request<Project>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("create"),
                data: {
                    ...this.defaults
                }
            },
            data: {
                ...data,
                country: this.country,
                siteCountry: this.country
            }
        });
    }

    getVorgangKommentare(vorgangId: string) {
        return this.fetch.request<VorgangComment[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("kommentare"),
                data: {
                    ...this.defaults,
                    vorgangId
                }
            }
        });
    }

    addVorgangKommentar(vorgangId: string, data: CommentForPostDTO) {
        return this.fetch.request<VorgangComment>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("kommentare"),
                data: {
                    ...this.defaults,
                    vorgangId
                }
            },
            data
        });
    }

    deleteVorgangKommentar(vorgangId: string, commentId: string) {
        return this.fetch.request<never>({
            method: Methods.DELETE,
            rest: {
                url: this.endpoint("kommentar"),
                data: {
                    ...this.defaults,
                    vorgangId,
                    commentId
                }
            }
        });
    }

    /**
     * @deprecated
     * Use method provided in WorktaskService
     */
    getMarkets() {
        return this.fetch.request<Market[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("markets"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    getKPIs(state: string, stateChangedAtFrom: string, stateChangedAtTo: string) {
        return this.fetch.request({
            method: Methods.HEAD,
            rest: {
                url: this.endpoint("vermittlungen"),
                data: {
                    ...this.defaults
                }
            },
            params: {
                state,
                stateChangedAtFrom,
                stateChangedAtTo
            },
            cancelable: false
        });
    }

    getProjectsList(searchParams: $TSFixMe) {
        return this.fetch.request<VermittlungDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("vermittlungen"),
                data: {
                    ...this.defaults
                }
            },
            params: {
                ...searchParams
            }
        });
    }

    getHistory(id: string) {
        return this.fetch.request<PartnerRequest[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("history"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    // Projektkoordinatoren (PKOs)
    getPKOList() {
        return this.fetch.request<ProjektKoordinatorDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("projektkoordinatoren"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    getPKO(id: string) {
        return this.fetch.request<ProjektKoordinatorDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("projektkoordinator"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    updatePKO(id: string, data: ProjektKoordinatorPatchDTO) {
        return this.fetch.request<ProjektKoordinatorDTO>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("projektkoordinator"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    addPKO(data: ProjektKoordinatorCreateDTO) {
        return this.fetch.request<ProjektKoordinatorDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("projektkoordinatoren"),
                data: {
                    ...this.defaults
                }
            },
            data
        });
    }

    getMacherProjektTrigger(macherProjektId: string) {
        return this.fetch.request<MacherProjektStatusMetaDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("macherProjektTriggers"),
                data: {
                    ...this.defaults,
                    macherProjektId
                }
            }
        });
    }

    getTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangStatusMetaDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("vorgangTriggers"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    vorOrtTerminAbstimmen(vermittlungId: string, data: VorOrtTerminAbstimmenDTO) {
        return this.fetch.request<never>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("vorOrtTerminAbstimmen"),
                data: {
                    ...this.defaults,
                    vermittlungId
                }
            },
            data: {
                ...data
            }
        });
    }

    vorOrtTerminLoeschen(vermittlungId: string) {
        return this.fetch.request<Unit>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("vorOrtTerminLoeschen"),
                data: {
                    ...this.defaults,
                    vermittlungId
                }
            }
        });
    }

    vorOrtTerminBestaetigen(vermittlungId: string) {
        return this.fetch.request<Unit>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("vorOrtTerminBestaetigen"),
                data: {
                    ...this.defaults,
                    vermittlungId
                }
            }
        });
    }

    genericTrigger(macherProjektId: string, vorgangId: string, trigger: string) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("genericTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    trigger
                }
            }
        });
    }

    abbrechenTrigger(
        macherProjektId: string,
        vorgangId: string,
        data: Feedback<FeedbackType.cancelVorgang>,
        params?: {
            WithoutNotification: boolean;
        }
    ) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("abbrechenTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data,
            params
        });
    }

    umsetzenTrigger(macherProjektId: string, vorgangId: string, data: Feedback<FeedbackType.completeVorgang>) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("umsetzenTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    angebotAnnehmenMitAngebotsWert(macherProjektId: string, vorgangId: string, data: OfferAcceptedValueDTO) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("angebotAnnehmenMitAngebotsWertTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    angebotVersendenMitAngebotsWert(macherProjektId: string, vorgangId: string, data: OfferSentValueDTO) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("angebotVersendenMitAngebotsWertTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    umsetzenMitGMVTrigger(macherProjektId: string, vorgangId: string, data: GMVAndFeedbackResponseDTO) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("umsetzenMitGMVTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    zusagenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("zusagenTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    absagenTrigger(macherProjektId: string, vorgangId: string, data: Feedback<FeedbackType.declineRequest>) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("absagenTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    ablehnenTrigger(macherProjektId: string, vorgangId: string, data: Feedback<FeedbackType.rejectVorgang>) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("ablehnenTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    zurueckziehenTrigger(macherProjektId: string, vorgangId: string, data: Feedback<FeedbackType.revokeVorgang>) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("zurueckziehenTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    setzeVorOrtTerminTrigger(macherProjektId: string, vorgangId: string, data: VorOrtTerminAbstimmenDTO) {
        return this.fetch.request<never>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("setzeVorOrtTerminTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    setzeAngebotsWerteTrigger(macherProjektId: string, vorgangId: string, data: OfferAndGMVDTO) {
        return this.fetch.request<never>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("setzeAngebotsWerteTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    anfragenTrigger(macherProjektId: string, vorgangId: string, data: HandwerkerAnfrageDTO) {
        return this.fetch.request<never>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("anfragenTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    /**
     * @deprecated
     * Use method provided in WorktaskService
     */
    getWorktasks() {
        return this.fetch.request<WorkTask[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("worktasks"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    getCraftsmanRequestDocumentDraftUri(id: string) {
        return uriTemplates(this.endpoints.craftsmanRequestDocumentDraftUri).fill({
            id,
            country: this.country as string,
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    getCraftsmanRequestDocumentDraft(id: string) {
        return this.fetch.request<Blob>({
            method: Methods.GET,
            rest: {
                url: this.endpoints.craftsmanRequestDocumentDraft,
                data: {
                    ...this.defaults,
                    id,
                    country: this.country as string
                }
            },
            params: {
                timestamp: new Date().getTime()
            },
            blob: true
        });
    }

    getCraftsmanRequestDocumentUri(id: string, documentId: string, download = false, gaeb = false) {
        return uriTemplates(this.endpoints.craftsmanRequestDocumentUri).fill({
            id,
            documentId,
            country: this.country as string,
            download: download.toString(),
            gaeb: gaeb.toString(),
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    downloadCraftsmanRequestDocument(id: string, documentId: string, gaeb = false) {
        return this.fetch.request<Blob>({
            method: Methods.GET,
            rest: {
                url: this.endpoints.craftsmanRequestDocumentDownload,
                data: {
                    ...this.defaults,
                    id,
                    documentId,
                    gaeb: gaeb.toString()
                }
            },
            blob: true
        });
    }

    getCraftsmanRequestCoordinationDocumentDraftUri(id: string, craftsmanFacilityId: string) {
        return uriTemplates(this.endpoints.craftsmanRequestCoordinationDocumentDraftUri).fill({
            id,
            craftsmanFacilityId,
            country: this.country as string,
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    // CraftsmanRequests
    createCraftsmanRequestDocument(id: string) {
        return this.fetch.request<IDocumentLink>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("craftsmanRequestDocument"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    createCraftsmanRequestCoordinationDocument(id: string, craftsmanFacilityId: string) {
        return this.fetch.request<IDocumentLink>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("craftsmanRequestCoordinationDocument"),
                data: {
                    ...this.defaults,
                    id,
                    craftsmanFacilityId
                }
            }
        });
    }

    uploadProjektDokument(macherProjektId: string, formData: FormData) {
        return this.fetch.request<MacherProjektDocumentLink>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("projektDokumente"),
                data: {
                    ...this.defaults,
                    macherProjektId
                }
            },
            formData
        });
    }

    getProjektDokumentUri(macherProjektId: string, documentId: string, download = false) {
        return uriTemplates(this.endpoints.projektDokumentUri).fill({
            macherProjektId,
            documentId,
            country: this.country as string,
            download: download.toString(),
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    downloadProjektDokument(macherProjektId: string, documentId: string) {
        return this.fetch.request<Blob>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("projektDokumentDownload"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    documentId
                }
            },
            blob: true
        });
    }

    updateProjektDokument(macherProjektId: string, documentId: string, data: DocumentPatchRequest) {
        return this.fetch.request<MacherProjektDocumentLink>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("updateProjektDokument"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    documentId
                }
            },
            data
        });
    }

    handwerkerDokumente(macherProjektId: string, data: HandwerkerAccessPayload) {
        return this.fetch.request<MacherProjektDocumentLink[]>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("handwerkerDokumente"),
                data: {
                    ...this.defaults,
                    macherProjektId
                }
            },
            data
        });
    }

    getHandwerkerDokumentUri(macherProjektId: string, documentId: string, download = true) {
        return uriTemplates(this.endpoints.handwerkerDokument).fill({
            macherProjektId,
            documentId,
            country: this.country as string,
            download: download.toString()
        });
    }

    getTask(macherProjektId: string, macherProjektTaskId: string) {
        return this.fetch.request<MacherProjektTaskDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("task"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    macherProjektTaskId
                }
            }
        });
    }

    deleteTask(macherProjektId: string, macherProjektTaskId: string) {
        return this.fetch.request<MacherProjektTaskDTO>({
            method: Methods.DELETE,
            rest: {
                url: this.endpoint("task"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    macherProjektTaskId
                }
            }
        });
    }

    updateTask(macherProjektId: string, macherProjektTaskId: string, data: MacherProjektTaskPatchDTO) {
        return this.fetch.request<MacherProjektTaskPatchDTO>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("task"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    macherProjektTaskId
                }
            },
            data
        });
    }

    createTask(macherProjektId: string, data: MacherProjektTaskCreateDTO) {
        return this.fetch.request<MacherProjektTaskCreateDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("createTask"),
                data: {
                    ...this.defaults,
                    macherProjektId
                }
            },
            data
        });
    }

    projects(state: string, stateChangedAtFrom: string, stateChangedAtTo: string) {
        return this.fetch.request<VermittlungDTO>({
            method: Methods.HEAD,
            rest: {
                url: this.endpoint("vermittlungen"),
                data: {
                    ...this.defaults
                }
            },
            params: {
                state,
                stateChangedAtFrom,
                stateChangedAtTo
            },
            cancelable: false
        });
    }
}
