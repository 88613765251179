const keymap = {
    "lightbox.close": ["Escape"],
    "lightbox.up": ["ArrowUp"],
    "lightbox.down": ["ArrowDown"],
    "pagination.nextPage": ["ArrowRight"],
    "pagination.prevPage": ["ArrowLeft"],
    "app.goto.dashboard": ["ShiftLeft", "KeyD"],
    "app.goto.projects": ["ShiftLeft", "KeyV"],
    "app.goto.craftsmen": ["ShiftLeft", "KeyH"]
};

export default keymap;
