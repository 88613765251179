import { macherService } from "../../services/macher/macher";

export const GET_PROJECTS_KPIS = "GET_PROJECTS_KPIS";
export const GET_PROJECTS_KPIS_PENDING = "GET_PROJECTS_KPIS_PENDING";
export const GET_PROJECTS_KPIS_FULFILLED = "GET_PROJECTS_KPIS_FULFILLED";
export const GET_PROJECTS_KPIS_REJECTED = "GET_PROJECTS_KPIS_REJECTED";

export const getProjectsKpis = (name, state, stateChangedAtFrom, stateChangedAtTo) => {
    return {
        type: GET_PROJECTS_KPIS,
        payload: macherService.projects(state, stateChangedAtFrom, stateChangedAtTo),
        meta: {
            name
        }
    };
};
