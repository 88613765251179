import { craftsmenService } from "../../services/craftsmen";

export const GET_QUALIFICATIONS = "GET_QUALIFICATIONS";
export const GET_QUALIFICATIONS_PENDING = "GET_QUALIFICATIONS_PENDING";
export const GET_QUALIFICATIONS_FULFILLED = "GET_QUALIFICATIONS_FULFILLED";
export const GET_QUALIFICATIONS_REJECTED = "GET_QUALIFICATIONS_REJECTED";

export const getQualifications = country => {
    return {
        type: GET_QUALIFICATIONS,
        payload: craftsmenService.getQualifications(country)
    };
};
