import React from "react";
import { bindActionCreators } from "redux";
import { INotification } from "../interfaces/Notification";
import { deleteNotification, dismissNotification, pushNotification } from "../store/actions/notifications.actions";
import { connect } from "./typedConnect";

// type PushNotificationParameters = Parameters<typeof pushNotification>;
type PushNotificationReturnType = ReturnType<typeof pushNotification>;

// type DismissNotificationParameters = Parameters<typeof dismissNotification>;
type DismissNotificationReturnType = ReturnType<typeof dismissNotification>;

// type DeleteNotificationParameters = Parameters<typeof deleteNotification>;
type DeleteNotificationReturnType = ReturnType<typeof deleteNotification>;

interface WithNotificationsStateProps {}
interface WithNotificationsDispatchProps {
    pushNotification: (PushNotificationParameters) => PushNotificationReturnType;
    dismissNotification: (DismissNotificationParameters) => DismissNotificationReturnType;
    deleteNotification: (DeleteNotificationParameters) => DeleteNotificationReturnType;
}

export interface WithNotifications {
    createNotification: (notification: Partial<INotification>) => INotification["id"];
    dismissNotification: (DismissNotificationParameters) => DismissNotificationReturnType;
    deleteNotification: (DeleteNotificationParameters) => DeleteNotificationReturnType;
}

const mapStateToProps = null;
const mapDispatchToProps = dispatch =>
    bindActionCreators({ pushNotification, dismissNotification, deleteNotification }, dispatch);

export const withNotifications = <P extends WithNotifications>(
    WrappedComponent: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof WithNotifications>> => {
    const WithNotifications = props => {
        const { pushNotification, dismissNotification, deleteNotification, ...restProps } = props;

        function createNotification(notification: Partial<INotification>) {
            const action = pushNotification(notification);
            if (!notification.persist) {
                setTimeout(() => dismissNotification(action.meta.id), 5000);
            }

            return action.meta.id;
        }

        return (
            <WrappedComponent
                {...(restProps as P)}
                createNotification={createNotification}
                dismissNotification={dismissNotification}
                deleteNotification={deleteNotification}
            />
        );
    };

    return connect<WithNotificationsStateProps, WithNotificationsDispatchProps, Omit<P, keyof WithNotifications>>(
        mapStateToProps,
        mapDispatchToProps
    )(WithNotifications);
};
