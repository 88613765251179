import { macherService } from "../../services/macher/macher";

export const GET_CRAFTSMEN_STATES = "GET_CRAFTSMEN_STATES";
export const GET_CRAFTSMEN_STATES_PENDING = "GET_CRAFTSMEN_STATES_PENDING";
export const GET_CRAFTSMEN_STATES_FULFILLED = "GET_CRAFTSMEN_STATES_FULFILLED";
export const GET_CRAFTSMEN_STATES_REJECTED = "GET_CRAFTSMEN_STATES_REJECTED";

export const getCraftsmenStates = country => {
    return {
        type: GET_CRAFTSMEN_STATES,
        payload: macherService.getCraftsmenStates(country)
    };
};

export const GET_PROJECTS_STATES = "GET_PROJECTS_STATES";
export const GET_PROJECTS_STATES_PENDING = "GET_PROJECTS_STATES_PENDING";
export const GET_PROJECTS_STATES_FULFILLED = "GET_PROJECTS_STATES_FULFILLED";
export const GET_PROJECTS_STATES_REJECTED = "GET_PROJECTS_STATES_REJECTED";

export const getProjectsStates = country => {
    return {
        type: GET_PROJECTS_STATES,
        payload: macherService.getProjectsStates(country)
    };
};
