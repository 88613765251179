import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Sidebar from "../../container/Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import styles from "./MainLayout.css";

@connect(({ ui }) => ({ ui }))
export default class MainLayoutExpanded extends Component {
    render() {
        const { ui, network, match, children } = this.props;
        const { header } = ui;

        return (
            <div
                className={classNames(styles.container, {
                    [styles.displayNav]: ui.sidebar.isOpen
                })}
            >
                <div className={styles.canvas}>
                    <Header showSidebarToggle showSidebar={false} network={network} />

                    <div
                        className={classNames(styles.layoutMain, styles.layoutExtended)}
                        style={{ paddingTop: header.extendedBaseHeight }}
                    >
                        <Sidebar position={"layoutMobileOnly"} fetchData={true} />
                        <div className={styles.content}>{children}</div>
                    </div>

                    <Footer match={match} />
                </div>
            </div>
        );
    }
}
