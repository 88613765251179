import React from "react";
import styles from "./Icon.css";

export const IconPowerOff = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z" />
        </svg>
    );
});

export const IconBurgerMenu = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
        </svg>
    );
});

export const IconClose = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconPerson = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconDashboard = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
        </svg>
    );
});

export const IconAddCircleOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        </svg>
    );
});

export const IconCompareArrows = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <clipPath id="b">
                <path d="M0 0h24v24H0V0z" />
            </clipPath>
            <path clipPath="url(#b)" d="M9.01 14H2v2h7.01v3L13 15l-3.99-4v3zm5.98-1v-3H22V8h-7.01V5L11 9l3.99 4z" />
        </svg>
    );
});

export const IconArrowDown = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
            <path d="M0-.75h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconArrowRight = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
            <path fill="none" d="M0 0h24v24H0V0z" />
        </svg>
    );
});

export const IconArrowLeft = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
            <path fill="none" d="M0 0h24v24H0V0z" />
        </svg>
    );
});

export const IconArrowDropDown = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M7 10l5 5 5-5z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconArrowDropUp = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M7 14l5-5 5 5z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconFileDownload = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconFace = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconGroup = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
        </svg>
    );
});

export const IconMoreHorizontal = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
    );
});

export const IconBrokenImage = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 0h24v24H0zm0 0h24v24H0zm21 19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2"
                fill="none"
            />
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M21 5v6.59l-3-3.01-4 4.01-4-4-4 4-3-3.01V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2zm-3 6.42l3 3.01V19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-6.58l3 2.99 4-4 4 4 4-3.99z" />
        </svg>
    );
});

export const IconEject = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 24V0h24v24H0z" fill="none" />
            <path d="M5 17h14v2H5zm7-12L5.33 15h13.34z" />
        </svg>
    );
});

export const IconErrorOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        </svg>
    );
});

export const IconError = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
        </svg>
    );
});

export const IconReportErrored = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <rect fill="none" height="24" width="24" />
            </g>
            <g>
                <path d="M15.73,3H8.27L3,8.27v7.46L8.27,21h7.46L21,15.73V8.27L15.73,3z M19,14.9L14.9,19H9.1L5,14.9V9.1L9.1,5h5.8L19,9.1V14.9z" />
                <rect height="6" width="2" x="11" y="7" />
                <rect height="2" width="2" x="11" y="15" />
            </g>
        </svg>
    );
});

export const IconModeEdit = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconTimer = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 4.74 14.12 4 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
        </svg>
    );
});

export const IconCheck = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
        </svg>
    );
});

export const IconRepeat = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z" />
        </svg>
    );
});

export const IconTimelapse = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        </svg>
    );
});

export const IconLocationOn = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconWhatsHot = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconVerfiedUser = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
        </svg>
    );
});

export const IconDelete = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z" />
        </svg>
    );
});

export const IconDateRange = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
            <path fill="none" d="M0 0h24v24H0z" />
        </svg>
    );
});

export const IconMailOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" />
        </svg>
    );
});

export const IconInfo = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
        </svg>
    );
});

export const IconPhone = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
        </svg>
    );
});

export const IconPolymer = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 4h-4L7.11 16.63 4.5 12 9 4H5L.5 12 5 20h4l7.89-12.63L19.5 12 15 20h4l4.5-8z" />
        </svg>
    );
});

export const IconFileCopyOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zM8 21V7h6v5h5v9H8z" />
        </svg>
    );
});

export const IconWarningRound = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3zM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z" />
        </svg>
    );
});

export const IconWarningFilled = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
        </svg>
    );
});

export const IconOpenInNew = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox={`0 0 24 24`}
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
        </svg>
    );
});

export const IconHelpOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z" />
        </svg>
    );
});

export const IconKeyboardOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0V0zm0 0h24v24H0V0z" />
            <path d="M20 7v10H4V7h16m0-2H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-9 3h2v2h-2zm0 3h2v2h-2zM8 8h2v2H8zm0 3h2v2H8zm-3 0h2v2H5zm0-3h2v2H5zm3 6h8v2H8zm6-3h2v2h-2zm0-3h2v2h-2zm3 3h2v2h-2zm0-3h2v2h-2z" />
        </svg>
    );
});

export const IconTextsmsOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12zM7 9h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2z" />
        </svg>
    );
});

export const IconSearch = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconArchive = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM6.24 5h11.52l.81.97H5.44l.8-.97zM5 19V8h14v11H5zm8.45-9h-2.9v3H8l4 4 4-4h-2.55z" />
        </svg>
    );
});

export const IconUndo = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z" />
        </svg>
    );
});

export const IconSave = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z" />
        </svg>
    );
});

export const IconSaveAlt = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />
            <path fill="none" d="M0 0h24v24H0z" />
        </svg>
    );
});

export const IconRemoveRedEye = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M12 6.5c3.79 0 7.17 2.13 8.82 5.5-1.65 3.37-5.02 5.5-8.82 5.5S4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5m0-2C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5m0-2c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5z" />
        </svg>
    );
});

export const IconCreateNewFolderOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M20 6h-8l-2-2H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm0 12H4V6h5.17l2 2H20v10zm-8-4h2v2h2v-2h2v-2h-2v-2h-2v2h-2z" />
        </svg>
    );
});

export const IconFolderOpenOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z" />
        </svg>
    );
});

export const IconDescription = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
        </svg>
    );
});

export const IconNoteAddOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M13 11h-2v3H8v2h3v3h2v-3h3v-2h-3zm1-9H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
        </svg>
    );
});

export const IconPDF = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M14,2H6A2.006,2.006,0,0,0,4,4V20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8Zm4,18H6V4h7V9h5Zm-5.294-3.8a1.665,1.665,0,0,1,.042.39,1.166,1.166,0,0,1-.06.4.746.746,0,0,1-.16.266.582.582,0,0,1-.228.146.821.821,0,0,1-.264.044h-.56v-1.8h.448a1.015,1.015,0,0,1,.39.066.611.611,0,0,1,.254.19A.78.78,0,0,1,12.706,16.2Zm-2.9-.386a.549.549,0,0,1,0,.44.348.348,0,0,1-.122.13.491.491,0,0,1-.176.062,1.28,1.28,0,0,1-.208.016H8.808V15.6H9.3a1.28,1.28,0,0,1,.208.016.472.472,0,0,1,.176.062A.341.341,0,0,1,9.8,15.811Zm6.69-1.622H7.508a.583.583,0,0,0-.584.584V18.31a.584.584,0,0,0,.584.584h8.984a.584.584,0,0,0,.584-.584V14.773A.583.583,0,0,0,16.492,14.189Zm-6.09,2.18a.779.779,0,0,1-.172.294.85.85,0,0,1-.306.206,1.194,1.194,0,0,1-.456.078h-.66v1.024H8.18V15.115H9.468a1.194,1.194,0,0,1,.456.078.863.863,0,0,1,.306.206.787.787,0,0,1,.172.292,1.091,1.091,0,0,1,0,.678ZM13.3,17.1a1.278,1.278,0,0,1-.242.456,1.124,1.124,0,0,1-.4.3,1.377,1.377,0,0,1-.57.11H10.848V15.115H12.08a1.464,1.464,0,0,1,.514.088,1.117,1.117,0,0,1,.412.264,1.218,1.218,0,0,1,.271.44,1.742,1.742,0,0,1,.1.62A1.956,1.956,0,0,1,13.3,17.1Zm2.524-1.46H14.44v.66h1.2v.488h-1.2v1.18h-.628V15.115H15.82Z" />
        </svg>
    );
});

export const IconXLS = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M14 2H6a2.006 2.006 0 00-2 2v16a2 2 0 001.99 2H18a2.006 2.006 0 002-2V8zm4 18H6V4h7v5h5z" />
            <path d="M6.924 14.773v3.537a.584.584 0 00.584.584h8.984a.584.584 0 00.584-.584v-3.537a.583.583 0 00-.584-.584H7.508a.583.583 0 00-.584.584zm8.139 2.2a1.4 1.4 0 00-.4-.134l-.31-.067a1.95 1.95 0 01-.626-.219.7.7 0 01-.289-.618.82.82 0 01.284-.638 1.205 1.205 0 01.829-.253 1.28 1.28 0 01.78.239.85.85 0 01.338.694H15.1a.409.409 0 00-.231-.366.788.788 0 00-.355-.071.684.684 0 00-.378.092.3.3 0 00-.141.26.244.244 0 00.139.229 1.682 1.682 0 00.38.118l.5.118a1.28 1.28 0 01.494.208.7.7 0 01.255.583.82.82 0 01-.3.65 1.281 1.281 0 01-.856.258 1.392 1.392 0 01-.886-.255.84.84 0 01-.324-.7h.57a.582.582 0 00.108.293.638.638 0 00.509.176 1.131 1.131 0 00.351-.046.332.332 0 00.254-.332.246.246 0 00-.126-.222zm-3.951-1.846h.6v2.342h1.426v.514h-2.026zm-2.84 0h.714l.509.93.524-.93h.689L9.834 16.5l.929 1.478h-.725l-.543-.974-.573.974h-.694l.918-1.455z" />
        </svg>
    );
});

export const IconAdd = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconNotInterested = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z" />
        </svg>
    );
});

export const IconRemoveCircleOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        </svg>
    );
});

export const IconDownload = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
        </svg>
    );
});

export const IconUpload = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
        </svg>
    );
});

export const IconNotifications = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z" />
        </svg>
    );
});

export const IconPlaylistAdd = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z" />
        </svg>
    );
});

export const IconTagOutline = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z" />
            <circle cx="6.5" cy="6.5" r="1.5" />
        </svg>
    );
});

export const IconAlarmOn = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm-1.46-5.47L8.41 12.4l-1.06 1.06 3.18 3.18 6-6-1.06-1.06-4.93 4.95z" />
        </svg>
    );
});

export const IconAlarmAdd = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm1-11h-2v3H8v2h3v3h2v-3h3v-2h-3V9z" />
        </svg>
    );
});

export const IconRadioUnchecked = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconRadioChecked = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    );
});

export const IconAdminPanel = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <rect fill="none" height="24" width="24" />
            </g>
            <g>
                <g>
                    <path d="M17,11c0.34,0,0.67,0.04,1,0.09V6.27L10.5,3L3,6.27v4.91c0,4.54,3.2,8.79,7.5,9.82c0.55-0.13,1.08-0.32,1.6-0.55 C11.41,19.47,11,18.28,11,17C11,13.69,13.69,11,17,11z" />
                    <path d="M17,13c-2.21,0-4,1.79-4,4c0,2.21,1.79,4,4,4s4-1.79,4-4C21,14.79,19.21,13,17,13z M17,14.38c0.62,0,1.12,0.51,1.12,1.12 s-0.51,1.12-1.12,1.12s-1.12-0.51-1.12-1.12S16.38,14.38,17,14.38z M17,19.75c-0.93,0-1.74-0.46-2.24-1.17 c0.05-0.72,1.51-1.08,2.24-1.08s2.19,0.36,2.24,1.08C18.74,19.29,17.93,19.75,17,19.75z" />
                </g>
            </g>
        </svg>
    );
});

export const IconPublic = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <rect fill="none" height="24" width="24" />
            </g>
            <g>
                <g>
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
                </g>
            </g>
        </svg>
    );
});

export const IconStar = React.memo(props => {
    return (
        <svg
            className={styles.svg}
            fill={props.fill}
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="M0,0h24v24H0V0z" fill="none" />
                <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
            <g>
                <path d="M12,17.27l4.15,2.51c0.76,0.46,1.69-0.22,1.49-1.08l-1.1-4.72l3.67-3.18c0.67-0.58,0.31-1.68-0.57-1.75l-4.83-0.41 l-1.89-4.46c-0.34-0.81-1.5-0.81-1.84,0L9.19,8.63L4.36,9.04c-0.88,0.07-1.24,1.17-0.57,1.75l3.67,3.18l-1.1,4.72 c-0.2,0.86,0.73,1.54,1.49,1.08L12,17.27z" />
            </g>
        </svg>
    );
});
