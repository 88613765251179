import React from "react";
import classNames from "classnames";
import styles from "./LoadingIndicator.css";

interface LoadingIndicatorProps {
    className?: string;
    loading?: boolean;
    variant: "onLight" | "onDark" | "onLightDark";
    size: "default" | "large";
}

const LoadingIndicator = (props: LoadingIndicatorProps) => (
    <div className={classNames(styles.wrapper, props.className)}>
        <div
            className={classNames(styles.loading, {
                [styles[props.variant]]: props.variant,
                [styles[props.size]]: props.size
            })}
        />
    </div>
);

LoadingIndicator.defaultProps = {
    variant: "onLight",
    size: "default"
};

export default React.memo(LoadingIndicator);
