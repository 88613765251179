import { LOGOUT } from "../actions/auth.actions";
import {
    CREATE_CUSTOMEROFFER_DOCUMENT_FULFILLED,
    CREATE_CUSTOMEROFFER_DOCUMENT_PENDING,
    CREATE_CUSTOMEROFFER_DOCUMENT_REJECTED,
    CREATE_REQUEST_DOCUMENT_FULFILLED,
    CREATE_REQUEST_DOCUMENT_PENDING,
    CREATE_REQUEST_DOCUMENT_REJECTED
} from "../actions/projects.actions";

const initialState = {
    customerOffers: {
        isFetching: false,
        failed: false
    },
    craftsmanRequests: {
        isFetching: false,
        failed: false
    }
};

export function projectsDocuments(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        case CREATE_REQUEST_DOCUMENT_PENDING:
            return {
                ...state,
                craftsmanRequests: {
                    isFetching: true,
                    failed: false,
                    [action.meta.projectId]: {
                        isFetching: true,
                        failed: false,
                        data: null
                    }
                }
            };

        case CREATE_REQUEST_DOCUMENT_REJECTED:
            return {
                ...state,
                craftsmanRequests: {
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true,
                    [action.meta.projectId]: {
                        status: action.payload.response.status,
                        isFetching: false,
                        failed: true,
                        data: null
                    }
                }
            };

        case CREATE_REQUEST_DOCUMENT_FULFILLED:
            return {
                ...state,
                craftsmanRequests: {
                    status: action.payload.status,
                    isFetching: false,
                    failed: false,
                    [action.meta.projectId]: {
                        status: action.payload.status,
                        isFetching: false,
                        failed: false,
                        data: action.payload.data
                    }
                }
            };

        case CREATE_CUSTOMEROFFER_DOCUMENT_PENDING:
            return {
                ...state,
                customerOffers: {
                    isFetching: true,
                    failed: false,
                    [action.meta.customerOfferId]: {
                        isFetching: true,
                        failed: false,
                        data: null
                    }
                }
            };

        case CREATE_CUSTOMEROFFER_DOCUMENT_FULFILLED:
            return {
                ...state,
                customerOffers: {
                    status: action.payload.status,
                    isFetching: false,
                    failed: false,
                    [action.meta.customerOfferId]: {
                        status: action.payload.status,
                        isFetching: false,
                        failed: false,
                        data: action.payload.data
                    }
                }
            };

        case CREATE_CUSTOMEROFFER_DOCUMENT_REJECTED:
            return {
                ...state,
                customerOffers: {
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true,
                    [action.meta.customerOfferId]: {
                        status: action.payload.response.status,
                        isFetching: false,
                        failed: true,
                        data: null
                    }
                }
            };

        default:
            return state;
    }
}
