import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import LinkIcon from "../LinkIcon/LinkIcon";
import Popover from "../Tooltip/Popover";
import styles from "./FormMessage.css";

export const scrollToFirstFormMessageError = () => {
    setTimeout(() => {
        const errorMessage = document.querySelector(`.${styles.error}`);

        if (errorMessage) {
            const hook = document.createElement("div");
            hook.style.marginTop = "-120px";

            try {
                errorMessage.prepend(hook);
            } catch (e) {
                // TODO: at this point we only jump to block/flex elements
                errorMessage.insertBefore(hook, errorMessage.firstChild);
            }

            hook.scrollIntoView({
                block: "start",
                behavior: "smooth"
            });

            hook.parentNode && hook.parentNode.removeChild(hook);
        }
    });
};

const FormMessageAction = props => {
    const { component: Component, className, ...rest } = props;
    const classes = classNames(styles.action, className);

    return props.component ? (
        <Component {...rest} className={classes}>
            {props.label}
        </Component>
    ) : null;
};

export default class FormMessage extends PureComponent {
    static defaultProps = {
        type: "info"
    };

    static propTypes = {
        title: PropTypes.string,
        message: PropTypes.string,
        correlationId: PropTypes.string,
        type: PropTypes.oneOf(["info", "error", "success", "warning"]),
        hide: PropTypes.bool,
        name: PropTypes.string,
        relatesTo: PropTypes.string,
        action: PropTypes.object,
        iconName: PropTypes.string,
        dataCy: PropTypes.string
    };

    render() {
        const type = this.props.type || "info";
        const { action = {} } = this.props;
        const dataCyAttribute = this.props.dataCy ? { "data-cy": this.props.dataCy } : {};

        return (this.props.title || this.props.message) && !this.props.hide ? (
            <div
                className={classNames(
                    styles.formMessage,
                    styles[type],
                    { [styles[this.props.relatesTo]]: this.props.relatesTo },
                    this.props.className
                )}
                {...dataCyAttribute}
            >
                {this.props.iconName ? <Icon className={styles.icon} name={this.props.iconName} /> : null}

                <div className={styles.message}>
                    {/* This is a workaround to work with the new prop naming of the FormMessage object */}
                    {this.props.title || this.props.message}&nbsp;
                    <FormMessageAction {...action} />
                    {this.props.correlationId ? (
                        <Popover
                            headline={"Fehlerdetails"}
                            text={
                                <div className={styles.correlationMessage}>
                                    <p>
                                        Es liegt folgender Fehlercode vor:
                                        <br />
                                        <strong>{this.props.correlationId}</strong>
                                    </p>

                                    <LinkIcon
                                        customClass={"smallText"}
                                        icon={{
                                            name: "IconFileCopyOutline",
                                            width: 18,
                                            height: 18
                                        }}
                                        onClick={() => navigator.clipboard?.writeText(this.props.correlationId)}
                                        type={"button"}
                                    >
                                        Fehlercode kopieren
                                    </LinkIcon>

                                    <p>
                                        Bitte gib den oben stehenden Fehlercode beim Melden des Fehlers an. Damit kann
                                        das Entwicklungsteam den Fehler leichter identifizieren.
                                    </p>
                                </div>
                            }
                        >
                            <strong>Fehlerdetails</strong>
                        </Popover>
                    ) : null}
                </div>
            </div>
        ) : null;
    }
}

FormMessageAction.propTypes = {
    component: PropTypes.func,
    label: PropTypes.string
};
