import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkPermission } from "@lucify/auth";
import SidebarNav from "../../components/Sidebar/SidebarNav";
import { withTick } from "../../hoc/withTick";
import { lucifyOIDCProvider } from "../../services/lucifyOIDCProvider";
import { getCraftsmenStates, getProjectsStates } from "../../store/actions/states.actions";

const mapStateToProps = ({ states, ui, breakpoint }) => ({
    states,
    ui,
    breakpoint
});
const mapDispatchToProps = dispatch => bindActionCreators({ getCraftsmenStates, getProjectsStates }, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
@withTick
export default class Sidebar extends Component {
    static defaultProps = {
        fetchData: false
    };

    tickId = null;

    componentDidMount() {
        if (this.props.fetchData) {
            this.fetchData();

            this.tickId = this.props.onTick(() => {
                this.fetchData();
            });
        }
    }

    componentWillUnmount() {
        if (this.tickId) {
            this.props.offTick(this.tickId);
        }
    }

    fetchData() {
        if (checkPermission(lucifyOIDCProvider, "handwerker:ansehen")) {
            this.props.getCraftsmenStates().catch(e => console.error(e));
        }

        if (checkPermission(lucifyOIDCProvider, "projekt:ansehen")) {
            this.props.getProjectsStates().catch(e => console.error(e));
        }
    }

    render() {
        const absoluteSidebarWouldFit =
            this.props.breakpoint.device !== "mobile" && this.props.breakpoint.height >= 700;
        const renderSidebar =
            (this.props.position === "header" && absoluteSidebarWouldFit) ||
            (this.props.position !== "header" && !absoluteSidebarWouldFit);

        return renderSidebar ? (
            <SidebarNav
                craftsmenStates={this.props.states.craftsmen}
                projectsStates={this.props.states.projects}
                open={this.props.ui.sidebar.isOpen}
                position={this.props.position}
            />
        ) : null;
    }
}
