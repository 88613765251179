import React, { PureComponent } from "react";
import { autobind } from "core-decorators";
import { $TSFixMe } from "@lucify/types";
import { Notification, NotificationProps, NotificationVariant } from "../../components/NotificationCenter/Notification";
import NotificationArea from "../../components/NotificationCenter/NotificationArea";
import { connect } from "../../hoc/typedConnect";
import { INotification, NotificationTargetArea } from "../../interfaces/Notification";
import { dismissNotification } from "../../store/actions/notifications.actions";
import { sortBy } from "../../utils/helpers/render";

interface NotificationListStateProps {
    notifications: INotification[];
}
type NotificationListDispatchProps = any;

interface NotificationListProps {
    component?: NotificationArea | $TSFixMe; // any render component
    targetArea: NotificationProps[] | NotificationTargetArea;
    showDismissed?: boolean;
    dismiss: (notificationId) => void;
    maxEntries?: number;
}

@connect<NotificationListStateProps, NotificationListDispatchProps, NotificationListProps>(
    ({ notifications }) => ({
        notifications: notifications.list
    }),
    { dismissNotification }
)
export default class NotificationList extends PureComponent<
    NotificationListStateProps & NotificationListDispatchProps & NotificationListProps
> {
    static defaultProps = {
        maxEntries: Infinity,
        showDismissed: false,
        component: NotificationArea
    };

    getVariant(targetArea) {
        switch (targetArea) {
            case NotificationTargetArea.bottom:
            case NotificationTargetArea.top:
                return NotificationVariant.banner;

            case NotificationTargetArea.center:
                return NotificationVariant.modal;

            case NotificationTargetArea.topRight:
            default:
                return NotificationVariant.hint;
        }
    }

    getNotifications() {
        return sortBy(
            this.props.notifications.filter(
                n => n.targetArea === this.props.targetArea && (this.props.showDismissed || !n.dismissed)
            ),
            "createdAt",
            "desc"
        ).splice(0, this.props.maxEntries);
    }

    @autobind
    dismiss(id) {
        this.props.dismissNotification(id);
    }

    render() {
        const { component: Component } = this.props;
        const filteredNotifications = this.getNotifications();

        return (
            <Component variant={this.props.targetArea}>
                {filteredNotifications.map(n => (
                    <Notification
                        key={n.id}
                        {...n}
                        variant={this.getVariant(this.props.targetArea)}
                        inList={filteredNotifications.length > 1}
                        onClose={this.dismiss}
                    />
                ))}
                {this.props.children}
            </Component>
        );
    }
}
