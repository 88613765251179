import { CountryCode } from "@lucify/types/src";
import { craftsmenFilters, projectsFilters } from "../../mappings/filters";
import CraftsmanDeclineRequestPage from "../../pages/CraftsmanDeclineRequest";
import CraftsmanDetailPage from "../../pages/CraftsmanDetail";
import CraftsmanRequestPage from "../../pages/CraftsmanRequest";
import CraftsmenPage from "../../pages/Craftsmen";
import DashboardPage from "../../pages/Dashboard";
import ShortcutsPage from "../../pages/Docs/ShortcutsPage";
import NotFoundPage from "../../pages/Handler/NotFound";
import HandwerkerBewertenPage from "../../pages/HandwerkerBewerten";
import HandwerkerDokumentePage from "../../pages/HandwerkerDokumente";
import HomePage from "../../pages/Home";
import LandingPage from "../../pages/LandingPage";
import LegalAGBPage from "../../pages/Legal/AGB";
import LegalCustomerAGBPage from "../../pages/Legal/CustomerAGB";
import LegalDatenschutzPage from "../../pages/Legal/Datenschutz";
import LegalEWEPage from "../../pages/Legal/EWE";
import LegalImpressumPage from "../../pages/Legal/Impressum";
import MatchingPage from "../../pages/Matching";
import NotificationCenter from "../../pages/NotificationCenter";
import ProjectBilling from "../../pages/ProjectBilling";
import ProjectCancelPage from "../../pages/ProjectCancel";
import ProjectCreatePage from "../../pages/ProjectCreate";
import ProjectDetailPage from "../../pages/ProjectDetail";
import ProjectEMailVorlagen from "../../pages/ProjectEMailVorlagen";
import ProjectRejectPage from "../../pages/ProjectReject";
import ProjectRevokePage from "../../pages/ProjectRevoke";
import ProjectsPage from "../../pages/Projects";
import ReportsPage from "../../pages/Reports/Reports";

const title = "MachbarMacher";
const uuidRegExp = "[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}";
const availableCountryCodes = Object.values(CountryCode).join("|");

const routes = [
    // Root
    {
        path: "/",
        title: `Willkommen | ${title}`,
        page: LandingPage,
        exact: true
    },
    {
        path: `/:country(${availableCountryCodes})`,
        title: `Willkommen | ${title}`,
        page: LandingPage,
        exact: true
    },

    // Dashboard
    {
        path: "/dashboard",
        title: `Dashboard | ${title}`,
        page: DashboardPage,
        exact: true,
        auth: true,
        needs: "plattform:access"
    },

    // Handwerker
    {
        path: "/handwerker",
        title: `Handwerker | ${title}`,
        page: CraftsmenPage,
        exact: true,
        auth: true,
        needs: "handwerker:ansehen"
    },
    {
        path: `/handwerker/:filter(${Object.keys(craftsmenFilters).join("|")})`,
        title: `Handwerker | ${title}`,
        page: CraftsmenPage,
        exact: true,
        auth: true,
        needs: "handwerker:ansehen"
    },
    {
        path: `/handwerker/:craftsmanId(${uuidRegExp})`,
        title: `Detailansicht Handwerker | ${title}`,
        page: CraftsmanDetailPage,
        exact: true,
        auth: true,
        needs: "handwerker:ansehen"
    },
    {
        path: `/handwerker/:craftsmanId(${uuidRegExp})/bewerten`,
        title: `Handwerker bewerten | ${title}`,
        page: HandwerkerBewertenPage,
        exact: true,
        auth: true,
        needs: "handwerker:bewertungen:erstellen"
    },
    // Projekte
    {
        path: "/projekte",
        title: `Vermittlungen | ${title}`,
        page: ProjectsPage,
        exact: true,
        auth: true,
        needs: "projekt:ansehen"
    },
    {
        path: `/projekte/:filter(${Object.keys(projectsFilters).join("|")})`,
        title: `Vermittlungen | ${title}`,
        page: ProjectsPage,
        exact: true,
        auth: true,
        needs: "projekt:ansehen"
    },
    {
        path: `/projekt/:macherProjektId/vorgang/:id/abbrechen`,
        title: `Vorgang abbrechen | ${title}`,
        page: ProjectCancelPage,
        exact: true,
        auth: true,
        needs: "projekt:bearbeiten"
    },
    {
        path: "/projekt/anlegen",
        title: `Neues Projekt anlegen | ${title}`,
        page: ProjectCreatePage,
        exact: true,
        auth: true,
        needs: "projekt:erstellen"
    },
    {
        path: `/projekt/:macherProjektId/vorgang/:id`,
        title: `Detailansicht Vorgang | ${title}`,
        page: ProjectDetailPage,
        exact: true,
        auth: true,
        needs: "projekt:ansehen"
    },
    {
        path: `/handwerker/absage/:macherProjektId/:vermittlungId/:requestId(${uuidRegExp})`,
        title: `Absage vom Handwerker | ${title}`,
        page: CraftsmanDeclineRequestPage,
        exact: true,
        auth: true,
        needs: "projekt:bearbeiten"
    },
    {
        path: `/projekt/:macherProjektId/vorgang/:id/ablehnen`,
        title: `Vorgang ablehnen | ${title}`,
        page: ProjectRejectPage,
        exact: true,
        auth: true,
        needs: "projekt:bearbeiten"
    },
    {
        path: `/projekt/:macherProjektId/vorgang/:id/entziehen`,
        title: `Vorgang entziehen | ${title}`,
        page: ProjectRevokePage,
        exact: true,
        auth: true,
        needs: "projekt:bearbeiten"
    },
    {
        path: `/projekt/:macherProjektId/vorgang/:id/abrechnen/pauschale`,
        title: `Angebotspauschale abrechnen | ${title}`,
        page: ProjectBilling,
        exact: true,
        auth: true,
        needs: "projekt:bearbeiten"
    },
    {
        path: `/projekt/:macherProjektId/vorgang/:id/vorlagen`,
        title: `E-Mail Vorlagen | ${title}`,
        page: ProjectEMailVorlagen,
        exact: true,
        auth: true,
        needs: "projekt:emailvorlagen"
    },

    // Matching
    {
        path: `/matching/:macherProjektId/vorgang/:projectId`, // projectId = Vermittlungs-Id
        title: `Matching | ${title}`,
        page: MatchingPage,
        exact: true,
        auth: true,
        needs: "projekt:bearbeiten"
    },

    // Handwerkeranfrageantwortseite
    {
        path: `/:country(${availableCountryCodes})/handwerkeranfrage`,
        title: `Anfrage für Vermittlungsprojekt | ${title}`,
        page: CraftsmanRequestPage,
        exact: true
    },

    // Handwerker Zugang Projektdokumente
    {
        path: `/:country(${availableCountryCodes})/handwerkerdokumente`,
        title: `Dokumente | ${title}`,
        page: HandwerkerDokumentePage,
        exact: true
    },

    // Login
    {
        path: "/login",
        title: `Login | ${title}`,
        page: HomePage,
        exact: true
    },

    // Dokumentation
    {
        path: "/docs/shortcuts",
        title: `Dokumentation: Shortcuts | ${title}`,
        page: ShortcutsPage,
        exact: true,
        auth: true,
        needs: "plattform:access"
    },

    // Mitteilungszentrale
    {
        path: "/demo/notifikationen",
        title: `DEMO Notifikationen: ${title}`,
        page: NotificationCenter,
        exact: true,
        auth: true,
        needs: "dev:access"
    },

    // Reports
    {
        path: "/reports",
        title: "Reports",
        page: ReportsPage,
        exact: true,
        auth: true,
        needs: "reports:ansehen"
    },

    // Legal
    {
        path: `/:country(${availableCountryCodes})/agb`,
        title: "Allgemeine Geschäftsbedingungen",
        page: LegalAGBPage,
        exact: true
    },
    {
        path: `/:country(${availableCountryCodes})/impressum`,
        title: "Impressum",
        page: LegalImpressumPage,
        exact: true
    },
    {
        path: `/:country(${availableCountryCodes})/datenschutz`,
        title: "Datenschutzbestimmungen",
        page: LegalDatenschutzPage,
        exact: true
    },

    {
        path: `/:country(${availableCountryCodes})/kundenagb`,
        title: "Kunden Allgemeine Geschäftsbedingungen",
        page: LegalCustomerAGBPage,
        exact: true
    },
    {
        path: `/:country(${availableCountryCodes})/ewe`,
        title: "EWE",
        page: LegalEWEPage,
        exact: true
    },

    // 404
    {
        title: `404 | ${title}`,
        page: NotFoundPage
    }
];

export default routes;
