import uriTemplates from "uri-templates";
import { $TSFixMe, IDocumentLink } from "@lucify/types";
import { Methods } from "./Fetch";
import { Service } from "./Service";
import {
    Angebot,
    AngebotBearbeitenDTO,
    AngebotDocumentLink,
    AngebotErstellenDTO,
    AngebotForCreationDTO,
    AngebotPatchDTO,
    AngebotPauschaleDTO,
    BaustellenStartTerminDTO,
    CraftsmanAssignment,
    CraftsmanPerfomanceRecordPatchDTO,
    CraftsmanPerformanceRecord,
    ExtendedVorgang,
    LeistungsBuchungDTO,
    SdForm,
    VorgangStatusMetaDTO,
    VorgangTransitionResult,
    WorkTask
} from "./generated/realization-service.types";

const endpoints = {
    getExtendedVorgang: "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}",

    trigger:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger?legacy={legacy}",

    // Baustellenstart Termin
    baustellenStartTerminFestlegen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/baustellenStartTerminFestlegen",
    baustellenStartTerminLoeschen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/baustellenStartTerminLoeschen",
    baustellenStartBestaetigen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/baustellenStartBestaetigen",

    // Trigger
    fuehrendesAngebotErstellen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/fuehrendesAngebotErstellen",
    fuehrendesAngebotBearbeiten:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/fuehrendesAngebotBearbeiten",
    fuehrendesAngebotLoeschen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/fuehrendesAngebotLoeschen",
    fuehrendesAngebotVersenden:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/fuehrendesAngebotVersenden",
    fuehrendesAngebotAblehnen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/fuehrendesAngebotAblehnen",
    fuehrendesAngebotAnpassen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/fuehrendesAngebotAnpassen",
    fuehrendesAngebotReaktivieren:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/fuehrendesAngebotReaktivieren/{angebotId}",
    dienstleistungsVertragSchliessen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/dienstleistungsVertragSchliessen",
    beauftragungVersenden:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/beauftragungVersenden",
    beauftragungBestaetigen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/beauftragungBestaetigen",
    nachtragErstellen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragErstellen",
    nachtragBearbeiten:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragBearbeiten/{nachtragsId}",
    nachtragLoeschen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragLoeschen/{nachtragsId}",
    nachtragVersenden:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragVersenden/{nachtragsId}",
    nachtragAblehnen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragAblehnen/{nachtragsId}",
    nachtragAnpassen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragAnpassen/{nachtragsId}",
    nachtragReaktivieren:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragReaktivieren/{nachtragsId}",
    nachtragBeauftragungErstellen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragBeauftragungErstellen/{nachtragsId}",
    nachtragBeauftragungVersenden:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragBeauftragungVersenden/{beauftragungsId}",
    nachtragBeauftragungStornieren:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/nachtragBeauftragungStornieren/{nachtragsId}",
    leistungenBuchen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/leistungenBuchen/{beauftragungsId}",
    abrechnen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/abrechnen",
    abrechnungStornieren:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/abrechnungStornieren/{sdFormId}",
    pauschaleAbrechnen:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/pauschaleAbrechnen",
    pauschaleStornieren:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/pauschaleStornieren",

    // Angebote
    customerOffers: "/realization-service/api/{country}/customer-offers/",
    createCustomerOffer: "/realization-service/api/{country}/customer-offers/",
    customerOffer: "/realization-service/api/{country}/customer-offers/{customerOfferId}",

    // Beauftragungen
    craftsmanAssignment: "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments",
    cancelCraftsmanAssignment:
        "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments/cancel",

    // Leistungsnachweise
    createPerformanceRecord:
        "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments/{craftsmanAssignmentId}/performance-record",

    deletePerformanceRecord:
        "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments/{craftsmanAssignmentId}/performance-record",

    updatePerformanceRecord:
        "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments/{craftsmanAssignmentId}/performance-record",

    // Abrechnungen
    getSDForms: "/realization-service/api/{country}/projekte/{projectId}/abrechnungen",

    createSDForm: "/realization-service/api/{country}/customer-offers/{customerOfferId}/sd-form",
    cancelSDForm: "/realization-service/api/{country}/customer-offers/{customerOfferId}/sd-form/{sdFormId}/cancel",

    dismiss: "/realization-service/api/{country}/customer-offers/{customerOfferId}/ablehnen",
    reactivate: "/realization-service/api/{country}/customer-offers/{customerOfferId}/reaktivieren",

    craftsmanAssignmentDraftUri:
        "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments/{craftsmanAssignmentId}/documents/draft.pdf?access_token={token}",
    craftsmanAssignmentDraft:
        "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments/{craftsmanAssignmentId}/documents/draft.pdf",
    craftsmanAssignmentDocument:
        "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments/{craftsmanAssignmentId}/documents/",
    craftsmanAssignmentDocumentUri:
        "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments/{craftsmanAssignmentId}/documents?download={download}&gaeb={gaeb}&access_token={token}",
    craftsmanAssignmentDocumentDownload:
        "/realization-service/api/{country}/customer-offers/{customerOfferId}/craftsman-assignments/{craftsmanAssignmentId}/documents?download={download}&gaeb={gaeb}",
    customerOfferDocumentDraftUri:
        "/realization-service/api/{country}/customer-offers/{id}/documents/draft.pdf?access_token={token}",
    customerOfferDocumentDraft:
        "/realization-service/api/{country}/customer-offers/{id}/documents/draft.pdf?access_token={token}",
    customerOfferDocument: "/realization-service/api/{country}/customer-offers/{id}/documents/",
    customerOfferDocumentUri:
        "/realization-service/api/{country}/customer-offers/{id}/documents/{documentId}?download={download}&access_token={token}",
    customerOfferDocumentDownload:
        "/realization-service/api/{country}/customer-offers/{id}/documents/{documentId}?download={download}",
    getPauschalen: "/realization-service/api/{country}/projekte/{projectId}/abrechnungen/pauschalen",
    createPauschaleSDForm: "/realization-service/api/{country}/projekte/{projectId}/abrechnungen/pauschalen",

    // Handwerkerrückmeldung erfassen
    handwerkerRueckmeldungErfassenTrigger:
        "/realization-service/api/{country}/macherprojekte/{macherProjektId}/vorgaenge/{vorgangId}/trigger/handwerkerRueckmeldungErfassen"
};

export class RealizationService extends Service<typeof endpoints> {
    endpoints = endpoints;

    getExtendedVorgang(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<ExtendedVorgang>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("getExtendedVorgang"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    getTrigger(macherProjektId: string, vorgangId: string, legacy = false) {
        return this.fetch.request<VorgangStatusMetaDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("trigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    legacy: legacy.toString()
                }
            }
        });
    }

    baustellenStartTerminFestlegenTrigger(macherProjektId: string, vorgangId: string, data: BaustellenStartTerminDTO) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("baustellenStartTerminFestlegen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data: {
                ...data
            }
        });
    }

    baustellenStartTerminLoeschenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("baustellenStartTerminLoeschen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    baustellenStartBestaetigenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("baustellenStartBestaetigen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    getPauschalen(projectId: string) {
        return this.fetch.request<WorkTask[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("getPauschalen"),
                data: {
                    ...this.defaults,
                    projectId
                }
            }
        });
    }

    createPauschaleSDForm(projectId: string, vermittlungId: string, data: $TSFixMe) {
        return this.fetch.request({
            method: Methods.POST,
            rest: {
                url: this.endpoint("createPauschaleSDForm"),
                data: {
                    ...this.defaults,
                    projectId
                }
            },
            params: {
                vermittlungId
            },
            data: {
                ...data
            }
        });
    }

    getCustomerOffers(params: $TSFixMe) {
        return this.fetch.request<Angebot[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("customerOffers"),
                data: {
                    ...this.defaults
                }
            },
            params
        });
    }

    createCustomerOffer(data: Omit<AngebotForCreationDTO, "countryCode">) {
        return this.fetch.request<Angebot>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("createCustomerOffer"),
                data: {
                    ...this.defaults
                }
            },
            data: {
                ...data,
                countryCode: this.country
            }
        });
    }

    readCustomerOffer(customerOfferId: string) {
        return this.fetch.request<Angebot>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("customerOffer"),
                data: {
                    ...this.defaults,
                    customerOfferId
                }
            }
        });
    }

    updateCustomerOffer(customerOfferId: string, data: AngebotPatchDTO) {
        return this.fetch.request<Angebot>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("customerOffer"),
                data: {
                    ...this.defaults,
                    customerOfferId
                }
            },
            data
        });
    }

    deleteCustomerOffer = (customerOfferId: string) => {
        return this.fetch.request<Angebot>({
            method: Methods.DELETE,
            rest: {
                url: this.endpoint("customerOffer"),
                data: {
                    ...this.defaults,
                    customerOfferId
                }
            }
        });
    };

    dismissCustomerOffer(customerOfferId: string) {
        return this.fetch.request<Angebot>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("dismiss"),
                data: {
                    ...this.defaults,
                    customerOfferId
                }
            }
        });
    }

    reactivateCustomerOffer(customerOfferId: string) {
        return this.fetch.request<Angebot>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("reactivate"),
                data: {
                    ...this.defaults,
                    customerOfferId
                }
            }
        });
    }

    createCraftsmanAssignment(customerOfferId: string, craftsmanFacilityId: string) {
        return this.fetch.request<CraftsmanAssignment[]>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("craftsmanAssignment"),
                data: {
                    ...this.defaults,
                    customerOfferId
                }
            },
            data: {
                craftsmanFacilityId
            }
        });
    }

    deleteCraftsmanAssignment(customerOfferId: string) {
        return this.fetch.request<{}>({
            method: Methods.DELETE,
            rest: {
                url: this.endpoint("craftsmanAssignment"),
                data: {
                    ...this.defaults,
                    customerOfferId
                }
            }
        });
    }

    cancelCraftsmanAssignments(customerOfferId: string) {
        return this.fetch.request<CraftsmanAssignment[]>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("cancelCraftsmanAssignment"),
                data: {
                    ...this.defaults,
                    customerOfferId
                }
            }
        });
    }

    updatePerformanceRecord(
        customerOfferId: string,
        craftsmanAssignmentId: string,
        data: CraftsmanPerfomanceRecordPatchDTO
    ) {
        return this.fetch.request<CraftsmanPerformanceRecord>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("updatePerformanceRecord"),
                data: {
                    ...this.defaults,
                    customerOfferId,
                    craftsmanAssignmentId
                }
            },
            data
        });
    }

    getSDForms(projectId: string) {
        return this.fetch.request<SdForm[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("getSDForms"),
                data: {
                    ...this.defaults,
                    projectId
                }
            }
        });
    }

    createSDForm(customerOfferId: string) {
        return this.fetch.request<SdForm>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("createSDForm"),
                data: {
                    ...this.defaults,
                    customerOfferId
                }
            }
        });
    }

    cancelSDForm(customerOfferId: string, sdFormId: string) {
        return this.fetch.request<SdForm>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("cancelSDForm"),
                data: {
                    ...this.defaults,
                    customerOfferId,
                    sdFormId
                }
            }
        });
    }

    getCustomerOfferDocumentDraftUri(id: string) {
        return uriTemplates(this.endpoint("customerOfferDocumentDraftUri")).fill({
            id,
            country: this.country as string,
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    getCustomerOfferDocumentUri(id: string, documentId: string, download = false) {
        return uriTemplates(this.endpoint("customerOfferDocumentUri")).fill({
            id,
            documentId,
            country: this.country as string,
            download: download.toString(),
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    getCraftsmanAssignmentDocumentDraftUri(customerOfferId: string, craftsmanAssignmentId: string) {
        return uriTemplates(this.endpoint("craftsmanAssignmentDraftUri")).fill({
            customerOfferId,
            craftsmanAssignmentId,
            country: this.country as string,
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    getCraftsmanAssignmentDocumentUri(
        customerOfferId: string,
        craftsmanAssignmentId: string,
        download = false,
        gaeb = false
    ) {
        return uriTemplates(this.endpoint("craftsmanAssignmentDocumentUri")).fill({
            customerOfferId,
            craftsmanAssignmentId,
            country: this.country as string,
            download: download.toString(),
            gaeb: gaeb.toString(),
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    getCraftsmanAssignmentDocument(customerOfferId: string, craftsmanAssignmentId: string, gaeb = false) {
        return this.fetch.request<Blob>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("craftsmanAssignmentDocumentDownload"),
                data: {
                    ...this.defaults,
                    customerOfferId,
                    craftsmanAssignmentId,
                    gaeb: gaeb.toString(),
                    download: "false"
                }
            },
            blob: true
        });
    }

    // CustomerOffers Documents
    createCustomerOfferDocument(id: string) {
        return this.fetch.request<AngebotDocumentLink>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("customerOfferDocument"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    getCustomerOfferDocument(id: string, documentId: string) {
        return this.fetch.request<Blob>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("customerOfferDocumentDownload"),
                data: {
                    ...this.defaults,
                    id,
                    documentId,
                    download: "false"
                }
            },
            blob: true
        });
    }

    getCustomerOfferDocumentDraft(id: string) {
        return this.fetch.request<Blob>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("customerOfferDocumentDraft"),
                data: {
                    ...this.defaults,
                    id,
                    download: "true",
                    country: this.country as string
                }
            },
            blob: true
        });
    }

    // FIXME: Response type nicht in Swagger definiert
    createCraftsmanAssignmentDocument(customerOfferId: string, craftsmanAssignmentId: string) {
        return this.fetch.request<IDocumentLink>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("craftsmanAssignmentDocument"),
                data: {
                    ...this.defaults,
                    customerOfferId,
                    craftsmanAssignmentId
                }
            }
        });
    }

    getCraftsmanAssignmentDocumentDraft(customerOfferId: string, craftsmanAssignmentId: string) {
        return this.fetch.request<Blob>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("craftsmanAssignmentDraft"),
                data: {
                    ...this.defaults,
                    customerOfferId,
                    craftsmanAssignmentId,
                    download: "true",
                    country: this.country as string
                }
            },
            blob: true
        });
    }

    fuehrendesAngebotErstellenTrigger(macherProjektId: string, vorgangId: string, data?: AngebotErstellenDTO) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("fuehrendesAngebotErstellen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    fuehrendesAngebotBearbeitenTrigger(macherProjektId: string, vorgangId: string, data?: AngebotBearbeitenDTO) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("fuehrendesAngebotBearbeiten"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    fuehrendesAngebotLoeschenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("fuehrendesAngebotLoeschen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    fuehrendesAngebotVersendenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("fuehrendesAngebotVersenden"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    fuehrendesAngebotAblehnenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("fuehrendesAngebotAblehnen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    fuehrendesAngebotAnpassenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("fuehrendesAngebotAnpassen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    fuehrendesAngebotReaktivierenTrigger(macherProjektId: string, vorgangId: string, angebotId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("fuehrendesAngebotReaktivieren"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    angebotId
                }
            }
        });
    }

    dienstleistungsVertragSchliessenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("dienstleistungsVertragSchliessen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    beauftragungVersendenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("beauftragungVersenden"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    beauftragungBestaetigenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("beauftragungBestaetigen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    nachtragErstellenTrigger(macherProjektId: string, vorgangId: string, data?: AngebotErstellenDTO) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragErstellen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    nachtragBearbeitenTrigger(
        macherProjektId: string,
        vorgangId: string,
        nachtragsId: string,
        data?: AngebotBearbeitenDTO
    ) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragBearbeiten"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    nachtragsId
                }
            },
            data
        });
    }

    nachtragLoeschenTrigger(
        macherProjektId: string,
        vorgangId: string,
        nachtragsId: string,
        data?: AngebotBearbeitenDTO
    ) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragLoeschen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    nachtragsId
                }
            },
            data
        });
    }

    nachtragVersendenTrigger(macherProjektId: string, vorgangId: string, nachtragsId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragVersenden"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    nachtragsId
                }
            }
        });
    }

    nachtragAblehnenTrigger(macherProjektId: string, vorgangId: string, nachtragsId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragAblehnen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    nachtragsId
                }
            }
        });
    }

    nachtragAnpassenTrigger(macherProjektId: string, vorgangId: string, nachtragsId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragAnpassen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    nachtragsId
                }
            }
        });
    }

    nachtragReaktivierenTrigger(macherProjektId: string, vorgangId: string, nachtragsId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragReaktivieren"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    nachtragsId
                }
            }
        });
    }

    nachtragBeauftragungErstellenTrigger(macherProjektId: string, vorgangId: string, nachtragsId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragBeauftragungErstellen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    nachtragsId
                }
            }
        });
    }

    nachtragBeauftragungVersendenTrigger(macherProjektId: string, vorgangId: string, beauftragungsId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragBeauftragungVersenden"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    beauftragungsId
                }
            }
        });
    }

    nachtragBeauftragungStornierenTrigger(macherProjektId: string, vorgangId: string, nachtragsId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("nachtragBeauftragungStornieren"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    nachtragsId
                }
            }
        });
    }

    abrechnenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("abrechnen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    abrechnungStornierenTrigger(macherProjektId: string, vorgangId: string, sdFormId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("abrechnungStornieren"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    sdFormId
                }
            }
        });
    }

    handwerkerRueckmeldungErfassenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("handwerkerRueckmeldungErfassenTrigger"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }

    leistungenBuchenTrigger(
        macherProjektId: string,
        vorgangId: string,
        beauftragungsId: string,
        data: LeistungsBuchungDTO
    ) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("leistungenBuchen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId,
                    beauftragungsId
                }
            },
            data
        });
    }

    pauschaleAbrechnenTrigger(macherProjektId: string, vorgangId: string, data: AngebotPauschaleDTO) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("pauschaleAbrechnen"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            },
            data
        });
    }

    pauschaleStornierenTrigger(macherProjektId: string, vorgangId: string) {
        return this.fetch.request<VorgangTransitionResult>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("pauschaleStornieren"),
                data: {
                    ...this.defaults,
                    macherProjektId,
                    vorgangId
                }
            }
        });
    }
}
