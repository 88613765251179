import { LucifyOIDCProvider } from "./LucifyOIDCProvider";

export const checkPermission = (
    lucifyOIDCProvider: LucifyOIDCProvider,
    action: string,
    prependCountry: boolean = true
) => {
    try {
        const user = lucifyOIDCProvider.getUser();
        const country = lucifyOIDCProvider.legacyMode ? user.country : lucifyOIDCProvider.getCountry();

        if (lucifyOIDCProvider.legacyMode && (!country || !user.countrySupport)) {
            return false;
        }

        if (!lucifyOIDCProvider.legacyMode && !country && prependCountry) {
            return false;
        }

        const permissionRealmRole = prependCountry ? `${country?.toLocaleLowerCase()}:${action}` : action;
        return lucifyOIDCProvider.hasRealmRole(permissionRealmRole);
    } catch (e) {
        console.warn(e);
        return false;
    }
};
