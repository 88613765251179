// todo move to internals when implementing in new client?
export enum HandwerkerBewertungskriterium {
    freundlichkeit = "freundlichkeit",
    erreichbarkeit = "erreichbarkeit",
    puenktlichkeit = "puenktlichkeit",
    loyalitaetProzesstreue = "loyalitaetProzesstreue",
    arbeitsqualitaet = "arbeitsqualitaet",
    professionellesAuftreten = "professionellesAuftreten",
    lvTreuePreisakzeptanz = "lvTreuePreisakzeptanz"
}

// todo move to internals when implementing in new client?
export enum HandwerkerBewertungskategorie {
    freundlichkeitUndVerlaesslichkeit = "freundlichkeitUndVerlaesslichkeit",
    geleisteteArbeitProfessionalitaet = "geleisteteArbeitProfessionalitaet",
    preisgestaltung = "preisgestaltung"
}
