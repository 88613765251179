import React, { PureComponent } from "react";
import ReactSelect from "react-select";
import classNames from "classnames";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "../../FormElements/FormElements";
import styles from "../Select.css";
import FormMessageValidation from "./FormMessageValidation";
import {
    ClearIndicator,
    DropdownIndicator,
    MultiValueRemove,
    Option,
    OptionCheckbox,
    SelectContainer,
    ValueContainerSummarize
} from "./SelectComponents";

export default class Select extends PureComponent {
    selectRef = React.createRef();

    static defaultProps = {
        component: ReactSelect,
        placeholder: "Bitte auswählen",
        noOptionsMessage: () => null,
        classNamePrefix: "select",
        closeMenuOnScroll: true,
        label: "",
        showInlineError: false,
        summarizeValues: false,
        hideDropdownIndicator: false
    };

    static propTypes = {
        // custom
        classNameWrapper: PropTypes.string,
        className: PropTypes.string,
        label: PropTypes.string,
        split: PropTypes.string,
        showInlineError: PropTypes.bool,
        summarizeValues: PropTypes.bool,
        hideDropdownIndicator: PropTypes.bool,
        onEnter: PropTypes.func,

        // use value from custom key
        valueIsObjectKey: PropTypes.string,

        // react select
        component: PropTypes.any,
        options: PropTypes.array.isRequired,
        isDisabled: PropTypes.bool,
        placeholder: PropTypes.string,
        noOptionsMessage: PropTypes.func,
        filterOption: PropTypes.func,
        loadOptions: PropTypes.func,
        loadingMessage: PropTypes.func,
        defaultOptions: PropTypes.bool,
        classNamePrefix: PropTypes.string,
        autoFocus: PropTypes.bool,
        openMenuOnFocus: PropTypes.bool
    };

    @autobind
    handleChange(value) {
        const newValue = this.props.isMulti
            ? value.map(option =>
                  this.props.valueIsObjectKey ? { [this.props.valueIsObjectKey]: option.value } : option.value
              )
            : value && value.value !== undefined && value.value !== null
            ? value.value
            : value;

        if (this.props.onChange) {
            this.props.onChange(value);
        }

        this.props.form.setFieldValue(this.props.field.name, newValue);
    }

    @autobind
    onBlur(event) {
        this.props.field.onBlur(event);
    }

    @autobind
    onKeyDown(event) {
        if (
            this.props.onEnter &&
            this.selectRef.current &&
            !this.selectRef.current.select.state.inputValue &&
            this.selectRef.current.select.state.value.length &&
            event.keyCode === 13
        ) {
            this.props.onEnter();
        }
    }

    @autobind
    getValue() {
        const { field, valueIsObjectKey, defaultValue } = this.props;
        let value =
            field.value !== null && field.value !== undefined
                ? field.value
                : field.value === null && defaultValue
                ? defaultValue.value
                : "";

        if (this.props.isMulti) {
            value =
                field.value && Array.isArray(field.value)
                    ? field.value.map(option => {
                          return this.props.options.find(
                              ({ value }) => (valueIsObjectKey ? option[valueIsObjectKey] : option) === value
                          );
                      })
                    : [];
        } else {
            value =
                this.props.options.find(option => {
                    return valueIsObjectKey ? option[valueIsObjectKey] === value : option.value === value;
                }) || null;
        }

        return value;
    }

    render() {
        const ReactSelectComponent = this.props.component;
        const { form, field, showInlineError, layout } = this.props;
        const hasError = !!form.errors[field.name];

        const reactSelectProps = {
            options: this.props.options,
            isDisabled: this.props.isDisabled,
            placeholder: this.props.placeholder,
            noOptionsMessage: this.props.noOptionsMessage,
            isMulti: this.props.isMulti,
            isClearable: this.props.isClearable,
            closeMenuOnSelect: this.props.closeMenuOnSelect || !this.props.isMulti,
            isSearchable: this.props.isSearchable || this.props.options.length >= 10,
            classNamePrefix: this.props.classNamePrefix,
            closeMenuOnScroll: this.props.closeMenuOnScroll,
            filterOption: this.props.filterOption,
            loadOptions: this.props.loadOptions,
            loadingMessage: this.props.loadingMessage,
            defaultOptions: this.props.defaultOptions,
            autoFocus: this.props.autoFocus,
            menuIsOpen: this.props.menuIsOpen,
            components: {
                DropdownIndicator: this.props.hideDropdownIndicator ? () => null : DropdownIndicator,
                Option,
                MultiValueRemove,
                ClearIndicator,
                SelectContainer,
                ...this.props.components
            },
            menuPosition: this.props.menuPosition,
            openMenuOnFocus: this.props.openMenuOnFocus,
            isLoading: this.props.isLoading
        };

        if (this.props.summarizeValues) {
            reactSelectProps.hideSelectedOptions = false;
            reactSelectProps.isClearable = false;
            reactSelectProps.closeMenuOnSelect = false;
            reactSelectProps.components = {
                ...reactSelectProps.components,
                ValueContainer: ValueContainerSummarize,
                Option: OptionCheckbox,
                ...this.props.components
            };
        }

        return (
            <FormGroup
                hasLabel={!!this.props.label}
                className={classNames({
                    [styles[layout]]: layout
                })}
            >
                <FormMessageValidation
                    field={this.props.field}
                    form={this.props.form}
                    disabled={!(hasError && showInlineError)}
                />

                <FormLabel
                    labelText={this.props.label}
                    disabled={this.props.isDisabled}
                    labelHint={this.props.labelHint}
                />

                <div
                    className={classNames(styles.wrapper, this.props.classNameWrapper, {
                        [styles.disabled]: this.props.isDisabled
                    })}
                >
                    <ReactSelectComponent
                        {...reactSelectProps}
                        {...field}
                        ref={this.selectRef}
                        inputId={field.name}
                        onChange={this.handleChange}
                        onBlur={this.onBlur}
                        onKeyDown={this.onKeyDown}
                        value={this.getValue()}
                        data-cy={this.props.dataCy || field.name}
                        className={classNames(styles.select, this.props.className, {
                            [styles[this.props.split]]: this.props.split,
                            [styles.hasError]: hasError
                        })}
                    />
                </div>
            </FormGroup>
        );
    }
}
