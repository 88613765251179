import React from "react";
import PropTypes from "prop-types";
import styles from "./Container.css";
import ContainerHeadline from "./ContainerHeadline";

const Container = props => {
    return (
        <div className={styles.container}>
            {props.headline ? (
                <div className={styles.header}>
                    <ContainerHeadline headline={props.headline} standalone={false} {...props.custom} />
                </div>
            ) : null}
            <div className={props.boxed ? styles.boxed : ""}>{props.children}</div>
        </div>
    );
};

Container.defaultProps = {
    boxed: true
};

Container.propTypes = {
    headline: PropTypes.string,
    custom: PropTypes.object,
    children: PropTypes.any,
    boxed: PropTypes.bool
};

export default Container;
