import { isArray, isObject } from "./typeGuards";

export type EscapeFn = (value: string) => string;

export const escapeQuotes: EscapeFn = (value: string) => value.replace(/\\/g, "\\\\").replace(/"/g, "\\x22");

export function escapeRecursive<T>(arrayOrObject: T, escapeFn: EscapeFn = escapeQuotes): T {
    if (isArray(arrayOrObject)) {
        return arrayOrObject.map(value =>
            typeof value === "string" ? escapeFn(value) : escapeRecursive(value, escapeFn)
        ) as unknown as T;
    }

    if (isObject(arrayOrObject)) {
        return Object.entries(arrayOrObject).reduce(
            (acc: T, [key, value]) => ({
                [key]: typeof value === "string" ? escapeFn(value) : escapeRecursive(value, escapeFn),
                ...acc
            }),
            {} as T
        );
    }

    return arrayOrObject;
}
