import React, { PureComponent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import { getIn } from "formik";
import PropTypes from "prop-types";
import Icon from "../../Icon/Icon";
import styles from "../Checkbox.css";

export default class Checkbox extends PureComponent {
    static defaultProps = {
        label: "Checkbox Label",
        disabled: false
    };

    static propTypes = {
        disabled: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        onChange: PropTypes.func,
        field: PropTypes.shape({
            name: PropTypes.string.isRequired,
            onBlur: PropTypes.func,
            onChange: PropTypes.func,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
        })
    };

    @autobind
    handleChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event);
        }

        this.props.field.onChange(event);
    }

    render() {
        const { field, form } = this.props;

        const error = form.errors ? getIn(form.errors, field.name) : false;
        const isInvalid = !!error;

        return (
            <label
                className={classNames(styles.wrapper, {
                    [styles.disabled]: this.props.disabled,
                    [styles.isInvalid]: isInvalid
                })}
            >
                <input
                    type="checkbox"
                    className={styles.checkbox}
                    disabled={this.props.disabled}
                    value={field.value}
                    name={field.name}
                    onChange={this.handleChange}
                    onBlur={field.onBlur}
                />
                <div className={styles.fakeCheckbox} />
                <Icon className={styles.iconCheck} name={"IconCheck"} />
                <div className={styles.labelText}>
                    {typeof this.props.label === "function" ? this.props.label() : this.props.label}
                </div>
            </label>
        );
    }
}
