import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";
import SimpleLayout from "../../components/Layout/SimpleLayout";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { INetworkStatus } from "../../interfaces/Globals";
import { retry } from "../../utils/helpers/retry";

interface AuthHandlerProps extends RouteComponentProps {
    network: INetworkStatus;
    title: string;
    loading?: boolean;
    accessGranted?: boolean;
}

const LazyAuthHandler = React.lazy(() => retry(() => import("../../components/RoutingErrorHandler/AuthHandler")));

const AuthHandlerPage: FC<AuthHandlerProps> = ({ network, title, ...rest }) => (
    <SimpleLayout network={network} match={rest.match}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <React.Suspense fallback={<LoadingIndicator />}>
            <LazyAuthHandler {...rest} />
        </React.Suspense>
    </SimpleLayout>
);

export default AuthHandlerPage;
