import { UseMutationResult } from "react-query";
import { UseQueryResult } from "react-query/types/react/types";
import { EnhancedWorkTask } from "@lucify/internals";
import { RestErrorResponse } from "@lucify/services";
import { FieldErrorDTO as CraftsmenServiceFieldErrorDTO } from "@lucify/services/src/generated/craftsmen-service.types";
import { FieldErrorDTO as MacherServiceFieldErrorDTO } from "@lucify/services/src/generated/macher-service.types";
import { RecordedWorkTask } from "@lucify/services/src/generated/realization-service.types";

type FieldErrorDTO = MacherServiceFieldErrorDTO | CraftsmenServiceFieldErrorDTO;

export function isArray<T = any>(array?: any): array is Array<T> {
    return Array.isArray(array);
}

export function isObject(object?: any): object is Object {
    const type = typeof object;
    return !isArray(object) && (type === "function" || (type === "object" && !!object));
}

export function isQueryResult(query?: any): query is UseQueryResult {
    return (
        isObject(query) &&
        query.hasOwnProperty("data") &&
        query.hasOwnProperty("isStale") &&
        query.hasOwnProperty("isSuccess") &&
        query.hasOwnProperty("isError") &&
        query.hasOwnProperty("status") &&
        query.hasOwnProperty("refetch")
    );
}

export function isMutationResult(mutation?: any): mutation is UseMutationResult {
    return (
        isObject(mutation) &&
        mutation.hasOwnProperty("data") &&
        mutation.hasOwnProperty("isSuccess") &&
        mutation.hasOwnProperty("isError") &&
        mutation.hasOwnProperty("status") &&
        mutation.hasOwnProperty("mutate")
    );
}

export function isNumber(possibleNumber: any): possibleNumber is number {
    return typeof possibleNumber === "number";
}

export function isString(possibleString: any): possibleString is string {
    return typeof possibleString === "string";
}

export function isRestErrorResponse(error: any): error is RestErrorResponse {
    return isObject(error) && error.hasOwnProperty("response") && error.hasOwnProperty("config");
}

export function isFieldError(data: object): data is FieldErrorDTO {
    return data.hasOwnProperty("errors");
}

export function isFieldErrorResponse(error?: RestErrorResponse): error is RestErrorResponse<FieldErrorDTO> {
    return isRestErrorResponse(error) && isFieldError(error.response.data);
}

export function isRecordedWorkTask(workTask: any): workTask is RecordedWorkTask {
    return workTask.hasOwnProperty("actualAmount");
}

export function isEnhancedRecordedWorkTask(workTask: any): workTask is EnhancedWorkTask<RecordedWorkTask> {
    return isRecordedWorkTask(workTask);
}
