/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface Address {
    country: string;
    state?: string;
    zip?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addition?: string;
}

export interface Angebot {
    id: string;
    state?: AngebotState;
    title?: string;
    projectId: string;
    vermittlungId: string;
    countryCode: string;
    /** @format date-time */
    createdAt: string;
    offerWorkTasks: AssignedWorkTask[];
    projectDetail?: ProjectDetail;
    /** @format date-time */
    setDownAt?: string;
    /** @format int32 */
    version: number;
    customerOfferDocumentLink?: AngebotDocumentLink;
    customerServiceContractDocumentLink?: AngebotDocumentLink;
    craftsmanAssignments: CraftsmanAssignment[];
    sdForms: SdForm[];
    setDown: boolean;
    transitions: Record<string, object>[];
    type: string;
}

export interface AngebotBearbeitenDTO {
    title?: string;
    offerWorkTasks?: AssignedWorkTask[];
}

export interface AngebotDocumentLink {
    documentId: string;
    documentNumber?: string;
    /** @format date-time */
    createdAt: string;
}

export interface AngebotErstellenDTO {
    title?: string;
    offerWorkTasks?: AssignedWorkTask[];
}

export interface AngebotForCreationDTO {
    title?: string;
    projectId: string;
    vermittlungId: string;
    countryCode: string;
    offerWorkTasks: AssignedWorkTask[];
}

export interface AngebotPatchDTO {
    title?: string;
    offerWorkTasks?: AssignedWorkTask[];
}

export interface AngebotPauschaleDTO {
    articleNumber: string;
    /** @format date-time */
    periodStart: string;
    /** @format date-time */
    periodEnd: string;
}

export enum AngebotState {
    Entwurf = "Entwurf",
    Festgeschrieben = "Festgeschrieben",
    Abgelehnt = "Abgelehnt",
    PauschalAbgerechnet = "PauschalAbgerechnet",
    BeauftragungErstellt = "BeauftragungErstellt",
    Teilbeauftragt = "Teilbeauftragt",
    Beauftragt = "Beauftragt",
    Abrechenbar = "Abrechenbar",
    Abgerechnet = "Abgerechnet",
    Geloescht = "Geloescht"
}

export interface AssignedWorkTask {
    workTask: WorkTask;
    clusterName: string;
    /** @format int32 */
    priority: number;
    /** @format double */
    amount: number;
    /** @format double */
    netPurchasePrice?: number;
    /** @format double */
    netSellingPrice: number;
    purchasePriceCluster?: string;
    optional: boolean;
    additionalDescription?: string;
    craftsmanFacilityId?: string;
}

export interface BaustellenAdresse {
    country: string;
    zip: string;
    city: string;
    street: string;
    addressAddition?: string;
}

export interface BaustellenStartTermin {
    typ: BaustellenStartTerminType;
    /** @format date */
    datum?: string;
}

export interface BaustellenStartTerminDTO {
    /** @format date */
    datum?: string;
    typ: BaustellenStartTerminType;
}

export enum BaustellenStartTerminType {
    UNBEKANNT = "UNBEKANNT",
    ERFORDERLICH = "ERFORDERLICH"
}

export interface Contact {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    mobileNumber?: string;
    faxNumber?: string;
}

export interface CraftsmanAssignment {
    id: string;
    customerOffer: FuehrendesAngebot | NachtragsAngebot;
    /** @format date-time */
    createdAt: string;
    assignedWorkTasks: AssignedWorkTask[];
    craftsmanFacility: CraftsmanFacility;
    documentLink?: CraftsmanAssignmentDocumentLink;
    /** @format date-time */
    setDownAt?: string;
    performanceRecord?: CraftsmanPerformanceRecord;
    /** @format date-time */
    cancelledAt?: string;
}

export interface CraftsmanAssignmentDocumentLink {
    documentId: string;
    documentNumber?: string;
    /** @format date-time */
    createdAt: string;
    gaebAvailable: boolean;
}

export interface CraftsmanFacility {
    id: string;
    shortId: string;
    facilityName?: string;
    email?: string;
    contact?: Contact;
    address?: Address;
}

export interface CraftsmanPerfomanceRecordPatchDTO {
    recordedWorkTasks?: RecordedWorkTask[];
    /** @format date-time */
    periodStart?: string;
    /** @format date-time */
    periodEnd?: string;
}

export interface CraftsmanPerformanceRecord {
    id: string;
    craftsmanAssignment: CraftsmanAssignment;
    recordedWorkTasks: RecordedWorkTask[];
    /** @format date-time */
    periodStart?: string;
    /** @format date-time */
    periodEnd?: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    lastModified: string;
    /** @format date-time */
    cancelledAt?: string;
}

export interface CreateCraftsmanAssignmentPayload {
    craftsmanFacilityId?: string;
}

export interface Customer {
    salutation: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    country: string;
    zip: string;
    city: string;
    street: string;
    addressAddition?: string;
    debitorId: string;
}

export interface ExtendedVorgang {
    id: string;
    /** @format int32 */
    version: number;
    localSubstate?: string;
    baustellenStartTermin?: BaustellenStartTermin;
}

export type FuehrendesAngebot = UtilRequiredKeys<
    Angebot,
    | "id"
    | "projectId"
    | "vermittlungId"
    | "countryCode"
    | "createdAt"
    | "offerWorkTasks"
    | "version"
    | "sdForms"
    | "setDown"
    | "transitions"
> & {
    nachtragsAngebote: NachtragsAngebot[];
};

export interface LeistungsBuchungDTO {
    recordedWorkTasks?: RecordedWorkTask[];
    /** @format date-time */
    periodStart?: string;
    /** @format date-time */
    periodEnd?: string;
}

export interface MacherProjekt {
    id: string;
    projektId: string;
    countryCode: string;
    /** @format int32 */
    version: number;
    state: string;
    categoryKey: string;
    marktId: string;
    projectDescription?: string;
    /** @format date-time */
    createdAt: string;
    customer: Customer;
    baustelle: BaustellenAdresse;
}

export type NachtragsAngebot = UtilRequiredKeys<
    Angebot,
    | "id"
    | "projectId"
    | "vermittlungId"
    | "countryCode"
    | "createdAt"
    | "offerWorkTasks"
    | "version"
    | "sdForms"
    | "setDown"
    | "transitions"
> & {
    fuehrendesAngebot: FuehrendesAngebot;
};

export interface PhaseStateEventLog {
    countryCode: string;
    projektId: string;
    vorgangId: string;
    phaseName: string;
    /** @format int64 */
    sequenceNumber: number;
    /** @format int32 */
    vorgangsNummer: number;
    originState: string;
    trigger: string;
    destinationState: string;
    endState: boolean;
    /** @format date-time */
    createdAt: string;
    sentToKafka: boolean;
    user: string;
    key: string;
}

export interface PriceCluster {
    clusterNumber: string;
    /** @format double */
    netSellingPrice: number;
    /** @format double */
    netPurchasePrice: number;
}

export interface ProjectDetail {
    id: string;
    categoryKey?: string;
    marktId?: string;
    projectDescription?: string;
    street?: string;
    zip?: string;
    country: string;
    city?: string;
    addressAddition?: string;
    customer?: ProjectDetailCustomer;
}

export interface ProjectDetailCustomer {
    id: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    country?: string;
    zip?: string;
    city?: string;
    street?: string;
    addressAddition?: string;
    debitorId: string;
}

export enum Rechnungsart {
    ANZAHLUNGSRECHNUNG = "ANZAHLUNGSRECHNUNG",
    SCHLUSSRECHNUNG = "SCHLUSSRECHNUNG",
    TEILRECHNUNG = "TEILRECHNUNG",
    PAUSCHALE = "PAUSCHALE",
    EINZELRECHNUNG = "EINZELRECHNUNG"
}

export interface RecordedWorkTask {
    id: string;
    workTask: WorkTask;
    /** @format double */
    actualAmount?: number;
    /** @format double */
    targetAmount?: number;
    assigned: boolean;
    /** @format int32 */
    priority: number;
    additionalDescription?: string;
    /** @format double */
    netPurchasePrice?: number;
    /** @format double */
    netSellingPrice: number;
    purchasePriceCluster?: string;
    optional: boolean;
    clusterName: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    lastModified: string;
}

export interface SdEntry {
    recordedWorkTaskId?: string;
    debitorId: string;
    articleNumber: string;
    /** @format double */
    actualAmount: number;
    /** @format double */
    netSellingPrice: number;
    description: string;
}

export interface SdForm {
    id: string;
    /** @format int32 */
    revision: number;
    projectId: string;
    /** @format int32 */
    vorgangsNummer?: number;
    projectDescription?: string;
    rechnungsnummer: string;
    rechnungsart: Rechnungsart;
    /** @format date-time */
    firstPeriodStart: string;
    /** @format date-time */
    lastPeriodEnd: string;
    customerOffer?: FuehrendesAngebot | NachtragsAngebot;
    sdEntries: SdEntry[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    cancelledAt?: string;
    /** @format date-time */
    lastModified: string;
}

export type StreamingResponseBody = object;

export interface TransitionDTO {
    trigger: string;
}

export type Unit = object;

export interface Vorgang {
    id: string;
    /** @format int32 */
    version: number;
    countryCode: string;
    projektId: string;
    state: string;
    /** @format int32 */
    vorgangsNummer: number;
    substate?: string;
    currentCraftsmanFacilityId?: string;
}

export interface VorgangStatusMetaDTO {
    /** @format int32 */
    projektVersion: number;
    /** @format int32 */
    vorgangVersion: number;
    phase: string;
    status?: string;
    aktiv: boolean;
    transitions: TransitionDTO[];
    fullState: string;
}

export type VorgangTransitionPayload = object;

export interface VorgangTransitionResult {
    countryCode: string;
    projektId: string;
    vorgangId: string;
    createdEntityId?: string;
    createdEntityType?: string;
}

export interface WorkTask {
    id: string;
    /** @format int32 */
    version: number;
    articleNumber?: string;
    trade?: string;
    category: string;
    name?: string;
    description?: string;
    unit?: string;
    /** @format double */
    tax?: number;
    active?: boolean;
    netPurchasePriceEditable: boolean;
    netSellingPriceEditable: boolean;
    /** @format float */
    timeFactor?: number;
    priceClusters?: PriceCluster[];
}
