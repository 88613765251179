import React, { PureComponent, ReactNode } from "react";
import classNames from "classnames";
import { NotificationVariant } from "./Notification";
import styles from "./NotificationArea.css";

interface NotificationAreaProps {
    children?: ReactNode;
    variant: NotificationVariant;
}

export default class NotificationArea extends PureComponent<NotificationAreaProps> {
    render() {
        return <div className={classNames(styles.area, styles[this.props.variant])}>{this.props.children}</div>;
    }
}
