import { macherService } from "../../services/macher/macher";
import { realizationService } from "../../services/realization";

export const GET_MACHERPROJEKT_PROFILE = "GET_MACHERPROJEKT_PROFILE";
export const GET_MACHERPROJEKT_PROFILE_PENDING = "GET_MACHERPROJEKT_PROFILE_PENDING";
export const GET_MACHERPROJEKT_PROFILE_FULFILLED = "GET_MACHERPROJEKT_PROFILE_FULFILLED";
export const GET_MACHERPROJEKT_PROFILE_REJECTED = "GET_MACHERPROJEKT_PROFILE_REJECTED";

export const getMacherProjektProfile = (macherProjektId, vorgangId?) => ({
    type: GET_MACHERPROJEKT_PROFILE,
    meta: {
        macherProjektId,
        vorgangId
    },
    payload: macherService.getMacherProjektProfile(macherProjektId)
});

export const UPDATE_MACHERPROJEKT_PROFILE = "UPDATE_MACHERPROJEKT_PROFILE";
export const UPDATE_MACHERPROJEKT_PROFILE_PENDING = "UPDATE_MACHERPROJEKT_PROFILE_PENDING";
export const UPDATE_MACHERPROJEKT_PROFILE_FULFILLED = "UPDATE_MACHERPROJEKT_PROFILE_FULFILLED";
export const UPDATE_MACHERPROJEKT_PROFILE_REJECTED = "UPDATE_MACHERPROJEKT_PROFILE_REJECTED";

export const updateMacherProjektProfile = (macherProjektId, data) => ({
    type: UPDATE_MACHERPROJEKT_PROFILE,
    meta: {
        macherProjektId
    },
    payload: macherService.updateMacherProjektProfile(macherProjektId, data)
});

export const GET_VORGANG_PROFILE = "GET_VORGANG_PROFILE";
export const GET_VORGANG_PROFILE_PENDING = "GET_VORGANG_PROFILE_PENDING";
export const GET_VORGANG_PROFILE_FULFILLED = "GET_VORGANG_PROFILE_FULFILLED";
export const GET_VORGANG_PROFILE_REJECTED = "GET_VORGANG_PROFILE_REJECTED";

export const getVorgangProfile = (macherProjektId, vorgangId) => {
    return {
        type: GET_VORGANG_PROFILE,
        meta: {
            macherProjektId,
            vorgangId
        },
        payload: macherService.getVorgangProfile(macherProjektId, vorgangId)
    };
};

export const GET_EXTENDED_VORGANG_PROFILE = "GET_EXTENDED_VORGANG_PROFILE";
export const GET_EXTENDED_VORGANG_PROFILE_PENDING = "GET_EXTENDED_VORGANG_PROFILE_PENDING";
export const GET_EXTENDED_VORGANG_PROFILE_FULFILLED = "GET_EXTENDED_VORGANG_PROFILE_FULFILLED";
export const GET_EXTENDED_VORGANG_PROFILE_REJECTED = "GET_EXTENDED_VORGANG_PROFILE_REJECTED";

export const getExtendedVorgangProfile = (macherProjektId, vorgangId) => {
    return {
        type: GET_EXTENDED_VORGANG_PROFILE,
        meta: {
            macherProjektId,
            vorgangId
        },
        payload: realizationService.getExtendedVorgang(macherProjektId, vorgangId)
    };
};

export const UPDATE_VORGANG_PROFILE = "UPDATE_VORGANG_PROFILE";
export const UPDATE_VORGANG_PROFILE_PENDING = "UPDATE_VORGANG_PROFILE_PENDING";
export const UPDATE_VORGANG_PROFILE_FULFILLED = "UPDATE_VORGANG_PROFILE_FULFILLED";
export const UPDATE_VORGANG_PROFILE_REJECTED = "UPDATE_VORGANG_PROFILE_REJECTED";

export const updateVorgangProfile = (macherProjektId, vorgangId, data) => ({
    type: UPDATE_VORGANG_PROFILE,
    meta: {
        macherProjektId,
        vorgangId
    },
    payload: macherService.updateVorgangProfile(macherProjektId, vorgangId, data)
});
