import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./FormGroupRow.css";

export class FormGroupRow extends Component {
    static propTypes = {
        children: PropTypes.any,
        columns: PropTypes.array,
        unstack: PropTypes.bool,
        className: PropTypes.string,
        layout: PropTypes.string,
        stackedColumnsSpace: PropTypes.bool
    };

    static defaultProps = {
        columns: [],
        unstack: true,
        className: "",
        layout: "",
        stackedColumnsSpace: false
    };

    render() {
        return (
            <div
                className={classNames(styles.row, this.props.className, styles[this.props.layout], {
                    [styles.unstack]: this.props.unstack
                })}
            >
                {React.Children.map(this.props.children, (child, index) => {
                    const columnWidth = this.props.columns[index];
                    const columnClass = "column" + (this.props.unstack ? columnWidth || "" : "");

                    return (
                        !child || (
                            <div
                                key={index}
                                className={classNames(styles[columnClass], {
                                    [styles.columnStackedSpaced]: this.props.stackedColumnsSpace
                                })}
                            >
                                {child}
                            </div>
                        )
                    );
                })}
            </div>
        );
    }
}
