import React, { Component } from "react";
import { Media } from "react-breakpoints";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { autobind } from "core-decorators";
import { craftsmenFilters, projectsFilters } from "../../mappings/filters";
import Can from "../Feature/Can";
import { isFlagActive } from "../Feature/Feature";
import LinkIcon from "../LinkIcon/LinkIcon";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import styles from "./Sidebar.css";

@withTranslation("internals")
export default class SidebarNav extends Component {
    state = {
        open: false
    };

    static defaultProps = {
        position: ""
    };

    componentDidMount() {
        const { open } = this.props;

        // initial state to props
        this.setState({ open: !!open });
    }

    @autobind
    createStatesList({ states = [], statesToShow, path, highlight }) {
        const { t } = this.props;

        return states && states.length
            ? statesToShow.map(stateName => {
                  const state = states.find(s => s.state === stateName);
                  return state ? (
                      <NavLink
                          key={state.state}
                          to={{
                              pathname: `${path}${state.state}`
                          }}
                          className={styles.link}
                      >
                          {t(`internals:states.${state.state}`)}
                          <span
                              className={classNames(styles.count, {
                                  [styles.countHighlight]: state.count && highlight.includes(state.state)
                              })}
                          >
                              {state.count || 0}
                          </span>
                      </NavLink>
                  ) : null;
              })
            : null;
    }

    @autobind
    createStatesListByFilter({ states = [], filters, filtersToShow, filtersToSeparate = [], path, highlight }) {
        const { t } = this.props;

        return states && states.length
            ? filtersToShow.map(filterName => {
                  const count = filters[filterName] && filters[filterName].count(states);
                  return (
                      <NavLink
                          key={filterName}
                          to={{
                              pathname: `${path}${filterName}`
                          }}
                          className={classNames(styles.link, {
                              [styles.linkSeparated]: filtersToSeparate.includes(filterName)
                          })}
                      >
                          {t(`internals:filters.${filterName}`)}
                          <span
                              className={classNames(styles.count, {
                                  [styles.countHighlight]: count && highlight.includes(filterName)
                              })}
                          >
                              {count || 0}
                          </span>
                      </NavLink>
                  );
              })
            : null;
    }

    static getStateCountForCategory(states) {
        return states && states.length ? states.reduce((accumulator, state) => accumulator + state.count, 0) : 0;
    }

    render() {
        return (
            <aside className={classNames(styles.sidebar, styles[this.props.position])}>
                <nav className={styles.sidebarInner}>
                    <Media>
                        {({ currentBreakpoint, breakpoints }) =>
                            breakpoints[currentBreakpoint] < breakpoints.desktop ? (
                                <LinkIcon
                                    component={"NavLink"}
                                    to={"/"}
                                    iconName={"IconDashboard"}
                                    iconClass={styles.primaryOnActive}
                                    className={styles.sidebarMainItem}
                                >
                                    Dashboard
                                </LinkIcon>
                            ) : null
                        }
                    </Media>

                    <Can perform={"projekt:ansehen"}>
                        <h3 className={styles.title}>Vermittlungen</h3>
                        {this.props.projectsStates.isFetching && !this.props.projectsStates.data.length ? (
                            <LoadingIndicator />
                        ) : (
                            <React.Fragment>
                                <NavLink to={"/projekte"} exact={true} className={styles.link}>
                                    Alle
                                    <span className={styles.count}>
                                        {SidebarNav.getStateCountForCategory(this.props.projectsStates.data)}
                                    </span>
                                </NavLink>

                                {this.createStatesListByFilter({
                                    states: this.props.projectsStates.data,
                                    filters: projectsFilters,
                                    filtersToShow: [
                                        "created",
                                        "operator_assigned",
                                        "craftsman_requested",
                                        "in_execution",
                                        "completed",
                                        "rejected",
                                        "cancelled",
                                        ...(isFlagActive("vermittlungsType") ? ["coordination"] : [])
                                    ],
                                    filtersToSeparate: ["coordination"],
                                    path: "/projekte/",
                                    highlight: ["created", "possibleDuplicate", "serviceLevelCritical"]
                                })}
                            </React.Fragment>
                        )}
                    </Can>

                    <Can perform={"handwerker:ansehen"}>
                        <h3 className={styles.title}>Handwerker</h3>
                        {this.props.craftsmenStates.isFetching && !this.props.craftsmenStates.data.length ? (
                            <LoadingIndicator />
                        ) : (
                            <React.Fragment>
                                <NavLink to={"/handwerker"} exact={true} className={styles.link}>
                                    Alle
                                    <span className={styles.count}>
                                        {SidebarNav.getStateCountForCategory(this.props.craftsmenStates.data)}
                                    </span>
                                </NavLink>

                                {this.createStatesListByFilter({
                                    states: this.props.craftsmenStates.data,
                                    filters: craftsmenFilters,
                                    filtersToShow: [
                                        "akquise",
                                        "toBeChecked",
                                        "active",
                                        "temporarilyDisabled",
                                        "disabled",
                                        "abgebrochen"
                                    ],
                                    path: "/handwerker/",
                                    highlight: ["toBeChecked"]
                                })}
                            </React.Fragment>
                        )}
                    </Can>
                </nav>
            </aside>
        );
    }
}
