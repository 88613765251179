import qs from "qs";
import uriTemplates from "uri-templates";
import { LucifyOIDCProvider } from "@lucify/auth";
import { $TSFixMe } from "@lucify/types";
import { uuid } from "@lucify/utils";

export enum Methods {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
    HEAD = "HEAD"
}

interface RestRequestConfig extends Omit<Partial<Request>, keyof Body> {
    method: Methods;
    rest?: RestUrlObject;
    blob?: boolean;
    /**
     * When `false` prevents requests for beeing automatic cancelled when a new request with the same pathname is beeing exexuted
     * @default true
     */
    cancelable?: boolean;
    signal?: AbortSignal;
    data?: $TSFixMe;
    // data?: {
    //     [key: string]: string | number | boolean;
    // };
    formData?: FormData;
    params?: object;
    header?: {
        [key: string]: string;
    };
}

export interface RestSuccessResponse<ResponseDataType = any> {
    data: ResponseDataType;
    headers: Headers;
    ok: boolean;
    redirected: boolean;
    status: number;
    statusText: string;
    type: ResponseType;
    url: string;
}

export interface RestErrorResponse<ResponseDataType = any> {
    response: RestSuccessResponse<ResponseDataType>;
    config: Request;
    name?: string;
}

export type RestResponse = RestSuccessResponse | RestErrorResponse;

type AuthHeader = {
    Authorization: string;
};

type RestUrlObject = {
    url: string;
    data: {
        [key: string]: string;
    };
};

export class Fetch {
    private abortControllers = new Map();

    readonly lucifyOIDCProvider?: LucifyOIDCProvider;
    readonly customCancellation: boolean = false;

    constructor(lucifyOIDCProvider?: LucifyOIDCProvider, customCancellation?: boolean) {
        this.customCancellation = customCancellation ?? this.customCancellation;
        this.lucifyOIDCProvider = lucifyOIDCProvider;
    }

    private static paramsSerializer(url: string, params: object) {
        const paramQueryString = qs.stringify(params, { arrayFormat: "repeat" });
        return `${url}${paramQueryString ? (url.includes("?") ? "&" : "?") : ""}${paramQueryString}`;
    }

    private static formatUrl({ url, data }: RestUrlObject) {
        const template = uriTemplates(url);
        return template.fill(data);
    }

    private getAuthHeader(): AuthHeader {
        return { Authorization: this.lucifyOIDCProvider?.token ? `Bearer ${this.lucifyOIDCProvider.token}` : "" };
    }

    private cancelExisting(url: string) {
        // Abbrechen von pending fetches an die selbe URI
        if (this.abortControllers.has(url)) {
            this.abortControllers.get(url)(`Request "${url}" cancelled`);
        }

        const controller = new AbortController();
        const { signal } = controller;

        this.abortControllers.set(url, (msg: string) => {
            controller.abort();
            if (process.env.NODE_ENV === "development") {
                console.info(msg);
            }
            this.abortControllers.delete(url);
        });

        return signal;
    }

    public request<ResponseDataType>(config: RestRequestConfig) {
        if (this.customCancellation) {
            const controller = new AbortController();
            const { signal } = controller;
            const promise = this.requestInternal<ResponseDataType>({ ...config, signal });
            promise.cancel = () => controller.abort();
            return promise;
        } else {
            return this.requestInternal<ResponseDataType>(config);
        }
    }

    private async requestInternal<ResponseDataType>({
        rest,
        cancelable = true,
        blob = false,
        method,
        url,
        header = {},
        params = {},
        data,
        signal,
        formData,
        ...args
    }: RestRequestConfig): Promise<RestSuccessResponse<ResponseDataType>> {
        if (!rest && !url) {
            throw TypeError("No URL defined");
        }

        const newUrl = rest ? Fetch.formatUrl(rest) : url!;
        const parameterizedUrl = Fetch.paramsSerializer(newUrl, params);

        const config = () => {
            // prevents rest util to send Authorization header if token is null
            const authHeader = this.lucifyOIDCProvider?.token ? this.getAuthHeader() : {};
            const headers = new Headers({
                ...authHeader,
                "X-CORRELATION-ID": uuid(),
                ...(data ? { "Content-Type": "application/json" } : {}),
                ...header
            });

            return new Request(parameterizedUrl, {
                method,
                headers,
                signal: cancelable ? signal || this.cancelExisting(newUrl) : undefined,
                body: data ? JSON.stringify(data) : formData ? formData : undefined,
                ...args
                // Assertion zu Request weil properties im Interface nicht als optional hinterlegt sind (?!)
            } as Partial<Request>);
        };

        let response = await fetch(config());

        // Authorisierung
        // Wenn das Keycloak Token abgelaufen ist, wird einmal versucht das Token zu refreshen
        // und den Request mit dem neuem Token zu wiederholen
        if (this.lucifyOIDCProvider?.authenticated && response && response.status === 401) {
            if (
                response.headers.get("www-authenticate")?.includes("invalid_token") &&
                this.lucifyOIDCProvider.isTokenExpired()
            ) {
                await this.lucifyOIDCProvider.updateToken(0);
            }

            response = await fetch(config());
        }

        // Lösche diesen fetch aus den pending fetches
        if (cancelable && !this.customCancellation) {
            this.abortControllers.delete(newUrl);
        }

        // FIXME: Sicherstellen, dass Content-Type verlässlich gesetzt ist um auf dessen Grundlage
        //  den Response-Body korrekt weiterzuverarbeiten (leer/Text/JSON/Blob/Markdown)

        let returnData;

        if (blob) {
            returnData = await response.blob();
        } else {
            returnData = await response.text();

            if (returnData && response.headers.get("content-type")?.includes("application/json")) {
                try {
                    returnData = JSON.parse(returnData);
                } catch (e) {
                    console.warn('Content-Type includes "application/json" but can\'t be parsed\n', e, response);
                }
            }
        }

        const returnValue: RestSuccessResponse<ResponseDataType> = {
            headers: response.headers,
            ok: response.ok,
            redirected: response.redirected,
            status: response.status,
            statusText: response.statusText,
            type: response.type,
            url: response.url,
            data: returnData as ResponseDataType
        };

        // Wenn Response nicht valide ist (Fehler oder HTTP StatusCode) wird der Response rejected returned
        return response.ok
            ? returnValue
            : Promise.reject({ response: returnValue, config: config() } as RestErrorResponse);
    }
}
