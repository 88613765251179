import React, { FC, useContext } from "react";
import { Helmet } from "react-helmet";
import { WithTranslation } from "react-i18next";
import { Redirect, RouteComponentProps } from "react-router";
import { authContext } from "@lucify/auth";
import { CountryCode } from "@lucify/types";
import SimpleLayout from "../components/Layout/SimpleLayout";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import ContactFormAT from "../container/LandingPage/ContactFormAT";
import ContactFormDE from "../container/LandingPage/ContactFormDE";
import { default as CraftsmanLandingPage } from "../container/LandingPage/LandingPage";
import { WithNotifications } from "../hoc/withNotifications";
import { INetworkStatus } from "../interfaces/Globals";
import PlaceholderImage from "../static/images/startseite_background.jpg";
import { getContentCountry } from "../utils/helpers/dev";

interface LandingPageProps extends WithNotifications, WithTranslation, RouteComponentProps<{ country: CountryCode }> {
    network: INetworkStatus;
    title: string;
}

const LandingPage: FC<LandingPageProps> = ({ match, title, network, ...rest }) => {
    const auth = useContext(authContext);
    const country = getContentCountry(match.params.country).toUpperCase();

    return (
        <SimpleLayout network={network} match={match} backgroundImage={PlaceholderImage}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <React.Suspense fallback={<LoadingIndicator />}>
                {auth.authenticated ? (
                    <Redirect to={"/dashboard"} />
                ) : (
                    <CraftsmanLandingPage
                        match={match}
                        {...rest}
                        contactForm={country === CountryCode.de ? ContactFormDE : ContactFormAT}
                        country={country}
                    />
                )}
            </React.Suspense>
        </SimpleLayout>
    );
};

export default LandingPage;
