import { deepmerge } from "@lucify/utils";

// Facades für Craftsman relevante Datenstrukturen

// Craftman Profile
const craftsmanProfileFacade = {
    id: null,
    jacandoId: null,
    orsId: null,
    companyName: null,
    operationRoles: null,
    contact: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        mobileNumber: null,
        faxNumber: null
    },
    email: null,
    radius: null,
    address: {
        country: null,
        zip: null,
        city: null,
        street: null,
        houseNumber: null,
        geoLocation: {
            latitude: null,
            longitude: null,
            confidence: null
        },
        state: null,
        addition: null
    },
    status: null,
    marketBlacklist: [],
    preferredCategories: [],
    notes: null,
    qualifications: [],
    version: null,
    lastModified: null,
    newsletterChoice: null,
    documentLinks: [],
    crefoNummer: null,
    erinnerungen: [],
    handwerkerKarteAusgestelltAm: null,
    gewerbeAnmeldungAusgestelltAm: null,
    betriebsHaftpflichtGueltigBis: null,
    freistellungsBescheinigungGueltigBis: null,
    handwerkerKoordinator: null,
    meisterBetrieb: null,
    akquiseQuellen: [],
    zusammenarbeitSeit: null,
    maerkte: [],
    website: null,
    sapKreditorNr: null,
    vertragsinfos: null,
    craftsmanCompany: {
        email: null
    }
};

export const createCraftsmanProfileFromFacade = craftsmanProfile => deepmerge(craftsmanProfileFacade, craftsmanProfile);

// Craftsman Stats
const craftsmanStatsFacade = {
    acceptedRequestsCount: null,
    cancelledVorgangCount: null,
    completedVorgangCount: null,
    declinedRequestsCount: null,
    expiredRequestCount: null,
    lastRequestedDate: null,
    pendingRequestsCount: null,
    ratingCustomer: null,
    revokedVermittlungCount: null
};

export const createCraftsmanStatsFromFacade = craftsmanStats => deepmerge(craftsmanStatsFacade, craftsmanStats);
