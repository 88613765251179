// todo discuss handling  fallback string and different usages
export function displayName(firstName?: string, lastName?: string, salutation?: string): string {
    return [salutation, firstName, lastName].join(" ").trim();
}

export function displayAddress(
    street?: string | null,
    zip?: string | null,
    city?: string | null,
    houseNumber?: string | null,
    additional?: string | null
) {
    const arr = [
        street,
        houseNumber,
        additional && additional[0] !== "/" && (street || houseNumber) && (zip || city) ? "," : null,
        additional,
        additional || street || houseNumber ? "," : null,
        zip,
        city
    ];
    return arr.join(" ").trim().replace(/\s+,/g, ",").replace(/\s+\//g, "/").replace(/\s+/g, " ") || "";
}

export function displayValueOrPlaceholder(value: any, overridePlaceholder?: string): string {
    return value || overridePlaceholder || "-";
}

export interface Initials {
    firstName?: string;
    lastName?: string;
}

export function displayInitials({ firstName, lastName }: Initials) {
    return firstName && lastName ? (firstName[0] + lastName[0]).toUpperCase() : "N/A";
}
