import { TFunction } from "i18next";
import { VorOrtTermin } from "@lucify/services/src/generated/macher-service.types";
import { BaustellenStartTermin } from "@lucify/services/src/generated/realization-service.types";
import { displayDateTime } from "./date";

export function noop() {}

export function renderIf(condition: boolean, renderFn: () => void) {
    return condition ? renderFn() : null;
}

export const isScrollable = (element: HTMLElement) => {
    return element ? element.scrollWidth > element.clientWidth : null;
};

export function displayName(firstName?: string, lastName?: string, salutation?: string) {
    return [salutation, firstName, lastName].join(" ").trim() || "-";
}

export function displayAddress(
    street?: string | null,
    zip?: string | null,
    city?: string | null,
    houseNumber?: string | null,
    additional?: string | null
) {
    const arr = [
        street,
        houseNumber,
        additional && additional[0] !== "/" && (street || houseNumber) && (zip || city) ? "," : null,
        additional,
        additional || street || houseNumber ? "," : null,
        zip,
        city
    ];
    return arr.join(" ").trim().replace(/\s+,/g, ",").replace(/\s+\//g, "/").replace(/\s+/g, " ") || "-";
}

export function renderVariantClasses(variant: string | string[] | undefined, styles: object) {
    return Array.isArray(variant)
        ? variant
              .map(variant => styles[variant])
              .filter(style => !!style)
              .join(" ")
        : variant
        ? styles[variant]
        : null;
}

export function displayPhoneNumbers(phoneNumbers?: Array<{ number: string }> | null) {
    return phoneNumbers && phoneNumbers.length ? phoneNumbers[0].number : "-";
}

export function displayCategories(categories: Array<string>, t: TFunction) {
    return categories.length ? categories.map(category => t("internals:categoryKeys." + category)).join(", ") : "-";
}

export function displayTermin(termin: VorOrtTermin | BaustellenStartTermin, t: TFunction) {
    const { datum, typ } = termin || {};
    return datum ? displayDateTime(datum, false) : typ ? t(`internals:terminTypes.${typ}`) : "-";
}

export const formatNumber = (number, fractionDigits = 2) =>
    new Intl.NumberFormat("de-DE", {
        style: "decimal",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }).format(number);

export function sortBy<T extends Array<{ [key: string]: any }>>(array: T, key: string, type = "asc"): T {
    return array.sort((a, b) => (type === "asc" ? (a[key] > b[key] ? 1 : -1) : a[key] > b[key] ? -1 : 1));
}

export function getChunkedArrayByCount(array: Array<any>, chunkCount: number) {
    const chunkSize = Math.ceil(array.length / chunkCount);
    return getChunkedArrayBySize(array, chunkSize);
}

export function getChunkedArrayBySize(array: Array<any>, chunkSize: number) {
    return array.reduce((allOptions, one, i) => {
        const chunkIndex = Math.floor(i / chunkSize);
        allOptions[chunkIndex] = [].concat(allOptions[chunkIndex] || [], one);
        return allOptions;
    }, []);
}

export function calcContainerWidth(actionsCount = 0, columnSize = 24, gutterSize = 12) {
    return actionsCount * columnSize + actionsCount * gutterSize;
}

export interface Initials {
    firstName?: string;
    lastName?: string;
}

export function renderInitials({ firstName, lastName }: Initials) {
    return firstName && lastName ? (firstName[0] + lastName[0]).toUpperCase() : "N/A";
}
