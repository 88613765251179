import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import foundation from "./Foundation.css";
import styles from "./Grid.css";

export default class Grid extends Component {
    static propTypes = {
        axis: PropTypes.oneOf(["x", "y"]),
        paddingX: PropTypes.bool,
        paddingY: PropTypes.bool,
        marginX: PropTypes.bool,
        marginY: PropTypes.bool,
        customClasses: PropTypes.arrayOf(PropTypes.string),
        className: PropTypes.string,
        layout: PropTypes.string
    };

    static defaultProps = {
        axis: "x",
        paddingX: false,
        paddingY: false,
        marginX: false,
        marginY: false,
        customClasses: [],
        layout: "",
        className: ""
    };

    render() {
        const baseClass = this.props.axis === "y" ? foundation["gridY"] : foundation["gridX"];
        const customClasses = this.props.customClasses.map(string => foundation[string]);

        return (
            <div
                className={classNames(baseClass, customClasses, this.props.className, {
                    [foundation.gridPaddingX]: this.props.paddingX,
                    [foundation.gridPaddingY]: this.props.paddingY,
                    [foundation.gridMarginX]: this.props.marginX,
                    [foundation.gridMarginY]: this.props.marginY,
                    [styles[this.props.layout]]: this.props.layout
                })}
            >
                {this.props.children}
            </div>
        );
    }
}
