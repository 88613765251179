import { lucifyOIDCProvider } from "../../services/lucifyOIDCProvider";

export function getUserCountry() {
    if (!lucifyOIDCProvider.tokenParsed) {
        throw Error("User is not authenticated");
    }

    if (!lucifyOIDCProvider.tokenParsed?.country) {
        // console.warn("User country mapping failed. Please check if user has needed authorities");
        throw Error("User country mapping failed. Please check if user has needed authorities");
    }

    return lucifyOIDCProvider.tokenParsed.country.toUpperCase();
}
