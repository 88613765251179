/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum ClientVisibilityId {
    DIRECTLEAD = "DIRECTLEAD"
}

export enum Confidence {
    Unknown = "unknown",
    Pending = "pending",
    Invalid = "invalid",
    High = "high",
    Medium = "medium",
    Low = "low"
}

export enum DataType {
    SELECT = "SELECT",
    MULTI_SELECT = "MULTI_SELECT",
    INTEGER = "INTEGER",
    DECIMAL = "DECIMAL",
    STRING = "STRING",
    BOOLEAN = "BOOLEAN"
}

export interface ErrorDescription {
    error: string;
}

export interface FieldErrorDTO {
    errors: LucifyFieldError[];
}

export interface GeneralQuestionGroupParams {
    taskId?: string[];
}

export interface GeoLocation {
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    confidence: Confidence;
}

export interface LucifyFieldError {
    objectName: string;
    field: string;
    rejectedValue?: object;
    defaultMessage?: string;
}

export interface MarketCreateDTO {
    id: string;
    type: MarketType;
    vertriebsRegion: VertriebsRegion;
    description: string;
    street: string;
    zip: string;
    city: string;
    state: string;
    phoneNumber?: string;
    active: boolean;
    /** @uniqueItems true */
    modelTypes?: ModelType[];
}

export interface MarketDTO {
    id: string;
    /** @format int32 */
    version: number;
    country: string;
    type: MarketType;
    vertriebsRegion: VertriebsRegion;
    description: string;
    street: string;
    zip: string;
    city: string;
    state: string;
    geoLocation: GeoLocation;
    phoneNumber?: string;
    active: boolean;
    /** @uniqueItems true */
    modelTypes: ModelType[];
}

export interface MarketListDTO {
    id: string;
    /** @format int32 */
    version: number;
    country: string;
    type: MarketType;
    vertriebsRegion: VertriebsRegion;
    description: string;
    street: string;
    zip: string;
    city: string;
    state: string;
    /** @format double */
    latitude?: number;
    /** @format double */
    longitude?: number;
    confidence?: Confidence;
    phoneNumber?: string;
    active: boolean;
    /** @uniqueItems true */
    modelTypes: ModelType[];
    geoLocation: GeoLocation;
}

export interface MarketPatchDTO {
    /** @format int32 */
    version: number;
    type?: MarketType;
    vertriebsRegion?: VertriebsRegion;
    description?: string;
    street?: string;
    zip?: string;
    city?: string;
    state?: string;
    phoneNumber?: string;
    active?: boolean;
    /** @uniqueItems true */
    modelTypes?: ModelType[];
}

export interface MarketQueryParams {
    all?: string[];
    id?: string[];
    type?: MarketType[];
    modelTypes?: ModelType[];
    vertriebsRegion?: VertriebsRegion[];
    zip?: string[];
    city?: string[];
    active?: boolean[];
    page?: string;
    pageSize?: string;
    sortOrder?: string;
    sortBy?: string;
}

export enum MarketType {
    Franchisepartner = "Franchisepartner",
    Filialmarkt = "Filialmarkt",
    Intern = "Intern"
}

export enum ModelType {
    MM = "MM",
    SP = "SP"
}

export interface PriceCluster {
    clusterNumber: string;
    /** @format double */
    netSellingPrice: number;
    /** @format double */
    netPurchasePrice: number;
}

export interface QualificationCreateDTO {
    businessId: string;
    name: string;
    active?: boolean;
}

export interface QualificationDTO {
    id: string;
    businessId: string;
    country?: string;
    name?: string;
    active?: boolean;
    /** @format int32 */
    version: number;
}

export interface QualificationPatchDTO {
    name?: string;
    active?: boolean;
    /** @format int32 */
    version: number;
}

export interface QuestionChoiceDTO {
    label: string;
    group?: string;
    value: string;
}

export interface SurveyDTO {
    surveyQuestionRoot: SurveyQuestionDTO;
    generalQuestionGroups?: SurveyGeneralQuestionGroupDTO[];
}

export interface SurveyGeneralQuestionDTO {
    parameterName: string;
    question: string;
    display: string;
    type: DataType;
    choices?: QuestionChoiceDTO[];
}

export interface SurveyGeneralQuestionGroupDTO {
    id: string;
    generalQuestions: SurveyGeneralQuestionDTO[];
}

export interface SurveyQuestionChoiceDTO {
    label: string;
    description?: string;
    /** @uniqueItems true */
    generalQuestionGroupRefs?: string[];
    task?: SurveyTaskDTO;
    survey?: SurveyQuestionDTO;
    /** @uniqueItems true */
    visibility?: VertriebsRegion[];
    modelType?: ModelType;
}

export interface SurveyQuestionChoiceUploadDTO {
    /**
     * @minLength 0
     * @maxLength 128
     */
    label: string;
    description?: string;
    taskId?: string;
    survey?: SurveyQuestionUploadDTO;
    /** @uniqueItems true */
    visibility?: VertriebsRegion[];
    modelType?: ModelType;
}

export interface SurveyQuestionDTO {
    label: string;
    description?: string;
    choices: SurveyQuestionChoiceDTO[];
}

export interface SurveyQuestionUploadDTO {
    /**
     * @minLength 0
     * @maxLength 128
     */
    label: string;
    description?: string;
    choices: SurveyQuestionChoiceUploadDTO[];
}

export interface SurveyTaskDTO {
    taskId: string;
    additionalQuestions?: SurveyTaskQuestionDTO[];
}

export interface SurveyTaskQuestionDTO {
    parameterName: string;
    question: string;
    display: string;
    type: DataType;
    choices?: QuestionChoiceDTO[];
}

export interface SurveyUploadDTO {
    surveyQuestionRoot: SurveyQuestionUploadDTO;
}

export interface TaskBulkCreateDTO {
    taskCreateDTOs: TaskCreateDTO[];
}

export interface TaskCreateDTO {
    id: string;
    displayName: string;
    category: string;
    subCategory: string;
    /** @uniqueItems true */
    qualificationMappingIds?: string[];
    /** @uniqueItems true */
    clientVisibility: ClientVisibilityId[];
    active: boolean;
    /** @uniqueItems true */
    generalQuestionGroups?: string[];
    questions: TaskQuestionDTO[];
}

export interface TaskDTO {
    countryCode: string;
    id: string;
    /** @format int32 */
    version: number;
    /** @format date-time */
    lastModified: string;
    displayName: string;
    category: string;
    subCategory: string;
    /** @uniqueItems true */
    qualificationMappingIds: string[];
    /** @uniqueItems true */
    generalQuestionGroups: string[];
    questions: TaskQuestionDTO[];
    /** @uniqueItems true */
    clientVisibility: ClientVisibilityId[];
    active: boolean;
}

export interface TaskGeneralQuestionCreateDTO {
    group: string;
    label: string;
    questions: TaskGeneralQuestionDTO[];
}

export interface TaskGeneralQuestionDTO {
    parameterName: string;
    active: boolean;
    question: string;
    display: string;
    type: DataType;
    choices: TaskGeneralQuestionsChoiceDTO[];
}

export interface TaskGeneralQuestionGroupDTO {
    countryCode: string;
    group: string;
    /** @format int32 */
    version: number;
    label: string;
    /** @format date-time */
    lastModified: string;
    questions: TaskGeneralQuestionDTO[];
}

export interface TaskGeneralQuestionPatchDTO {
    /** @format int32 */
    version: number;
    label?: string;
    questions?: TaskGeneralQuestionDTO[];
}

export interface TaskGeneralQuestionsChoiceDTO {
    label: string;
    group?: string;
    value: string;
}

export interface TaskPatchDTO {
    /** @format int32 */
    version: number;
    displayName?: string;
    category?: string;
    subCategory?: string;
    /** @uniqueItems true */
    qualificationMappingIds?: string[];
    /** @uniqueItems true */
    clientVisibility?: ClientVisibilityId[];
    active?: boolean;
    /** @uniqueItems true */
    generalQuestionGroups?: string[];
    questions?: TaskQuestionDTO[];
}

export interface TaskQuestionChoiceDTO {
    label: string;
    group?: string;
    value: string;
}

export interface TaskQuestionDTO {
    parameterName: string;
    active: boolean;
    question: string;
    display: string;
    type: DataType;
    choices: TaskQuestionChoiceDTO[];
}

export type Unit = object;

export enum VertriebsRegion {
    UNBEKANNT = "UNBEKANNT",
    DE_NORDOST = "DE_NORDOST",
    DE_NORDWEST = "DE_NORDWEST",
    DE_SUED = "DE_SUED",
    DE_SUEDWEST = "DE_SUEDWEST",
    DE_INTERN = "DE_INTERN",
    DE_FRANCHISEPARTNER = "DE_FRANCHISEPARTNER",
    AT = "AT",
    ATFM1 = "AT_FM_1",
    ATFM2 = "AT_FM_2",
    ATFM3 = "AT_FM_3",
    ATFM4 = "AT_FM_4",
    ATFM5 = "AT_FM_5",
    ATFM6 = "AT_FM_6",
    AT_FRANCHISEPARTNER = "AT_FRANCHISEPARTNER",
    AT_INTERN = "AT_INTERN"
}

export interface WorkTask {
    id: string;
    articleNumber?: string;
    trade?: string;
    category: string;
    name?: string;
    description?: string;
    unit?: string;
    /** @format double */
    tax?: number;
    active?: boolean;
    netPurchasePriceEditable: boolean;
    netSellingPriceEditable: boolean;
    /** @format float */
    timeFactor?: number;
    priceClusters?: PriceCluster[];
    /** @format int32 */
    version: number;
}
