import keymapManager from "../../utils/keymapManager";

export const registerAppShortcuts = push => {
    keymapManager.registerKeyMapping({
        name: "app.goto.dashboard",
        fn: () => push("/")
    });

    keymapManager.registerKeyMapping({
        name: "app.goto.craftsmen",
        fn: () => push("/handwerker")
    });

    keymapManager.registerKeyMapping({
        name: "app.goto.projects",
        fn: () => push("/projekte")
    });
};

export const unregisterAppShortcuts = () => {
    keymapManager.unregisterKeyMapping({
        name: "app.goto.dashboard"
    });

    keymapManager.unregisterKeyMapping({
        name: "app.goto.craftsmen"
    });

    keymapManager.unregisterKeyMapping({
        name: "app.goto.projects"
    });
};
