import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { getIn } from "formik";
import PropTypes from "prop-types";
import FormMessage from "../../FormElements/FormMessage";

@withTranslation("messages")
export default class FormMessageValidation extends PureComponent {
    static propTypes = {
        field: PropTypes.object,
        form: PropTypes.object,
        disabled: PropTypes.bool,
        className: PropTypes.string
    };

    render() {
        const error = getIn(this.props.form.errors, this.props.field.name);
        const message = error && this.props.t(["messages:validation.syncError." + error, error]);

        return this.props.field && this.props.form && !this.props.disabled ? (
            <FormMessage
                dataCy={"FormMessageValidation_" + this.props.field.name}
                message={message}
                type={"error"}
                className={this.props.className}
                hide={this.props.form.isValid}
            />
        ) : null;
    }
}
