import { HolidayDate } from "@lucify/internals";

const DEFAULT_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
};

export const formatDate = (isoDateString: string | Date | number, options?: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat("de-DE", {
        ...DEFAULT_DATE_OPTIONS,
        ...options
    }).format(new Date(isoDateString));
};

export const formatDateRange = (isoStartDateString?: string | Date | null, isoEndDateString?: string | Date | null) => {
    if (isoStartDateString && isoEndDateString) {
        return `${formatDate(isoStartDateString)} - ${formatDate(isoEndDateString)}`;
    } else if (isoStartDateString && !isoEndDateString) {
        return `ab dem ${formatDate(isoStartDateString)}`;
    } else if (!isoStartDateString && isoEndDateString) {
        return `bis zum ${formatDate(isoEndDateString)}`;
    } else {
        return "-";
    }
};

const DEFAULT_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric"
};

export const formatTime = (isoDateString: string | Date, options?: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat("de-DE", {
        ...DEFAULT_TIME_OPTIONS,
        ...options
    }).format(new Date(isoDateString));
};
export function getDayDistance(date: Date) {
    const today = new Date();
    const newDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    newDate.setHours(0, 0, 0, 0);

    return Math.floor((newDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
}

export const getDistance = (date: string | Date | number, workingDays = false, holidays?: HolidayDate[]) => {
    let inWords;
    const targetDate = new Date(date);

    // Check if the targetDate is a working days and if not show the actual distance in days
    const showWorkingDays = workingDays && isWorkingDay(targetDate, holidays);

    const inDays = getDayDistance(targetDate);
    const inWorkingDays = workingDays ? getWorkingDayDistance(targetDate, holidays) : null;

    switch (true) {
        case inDays === -1 && (inWorkingDays === -1 || inWorkingDays === null):
            inWords = "Gestern";
            break;
        case inDays === 0 && (inWorkingDays === 0 || inWorkingDays === null):
            inWords = "Heute";
            break;
        case inDays === 1 && (inWorkingDays === 1 || inWorkingDays === null):
            inWords = "Morgen";
            break;
        case inDays === 2 && (inWorkingDays === 2 || inWorkingDays === null):
            inWords = "Übermorgen";
            break;
        case inDays === null && inWorkingDays === null:
            inWords = null;
            break;
        default:
            const days = showWorkingDays ? inWorkingDays! : inDays;
            const normalizedDays = Math.abs(Math.ceil(days));
            const preposition = Math.sign(days) === 1 ? "in" : "vor";
            const label = `${showWorkingDays ? "Arbeitstag" : "Tag"}${normalizedDays > 1 ? "en" : ""}`;

            inWords = `${preposition} ${normalizedDays} ${label}`;
    }

    return {
        inDays,
        inWorkingDays,
        inWords
    };
};

export const getTimePeriodInWords = (startDate: string | Date, endDate?: string | Date): string => {
    const startDateObject = new Date(startDate);
    startDateObject.setHours(0);
    startDateObject.setMinutes(0);
    startDateObject.setSeconds(0);
    const endDateObject = endDate ? new Date(endDate) : new Date();
    endDateObject.setHours(0);
    endDateObject.setMinutes(0);
    endDateObject.setSeconds(0);

    if (endDateObject < startDateObject) {
        return "";
    }

    const daysDistance = Math.floor((endDateObject.getTime() - startDateObject.getTime()) / (1000 * 60 * 60 * 24));

    const years = Math.floor(daysDistance / 365);
    const months = Math.floor((daysDistance - years * 365) / 30);
    const days = daysDistance - years * 365 - months * 30;

    const parts: string[] = [];
    if (years > 0) {
        parts.push(`${years} Jahr${years > 1 ? "e" : ""}`);
    }
    if (months > 0) {
        parts.push(`${months} Monat${months > 1 ? "e" : ""}`);
    }
    if (days > 0) {
        parts.push(`${days} Tag${days > 1 ? "e" : ""}`);
    }

    return parts.join(", ");
};

export const displayDateTime = (date: string, useWords: boolean) => {
    const distance = getDistance(date);
    let display;
    const timeSet = date.includes("T");

    if (useWords) {
        display = `${distance.inWords}${timeSet ? `, ${formatTime(date)} Uhr` : ""}`;
    } else if (timeSet) {
        display =
            formatDate(date, {
                hour: "numeric",
                minute: "numeric"
            }) + " Uhr";
    } else {
        display = formatDate(date);
    }

    return display;
};

// Why "sv"? Bescause Sveden is useing ISO 8601 as date standard and so
// this considers the actual timezone
export const toISO = (date: Date) => date.toLocaleDateString("sv");

export function isToday(date: Date | string) {
    const today = new Date();
    const compareDate = new Date(date);
    return (
        compareDate.getDate() === today.getDate() &&
        compareDate.getMonth() === today.getMonth() &&
        compareDate.getFullYear() === today.getFullYear()
    );
}

export const isPast = (date: Date | string) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return new Date(date) < now;
};

export function dateFromString(date: string): Date {
    if (!date || date.trim() === "") {
        return new Date("Invalid Date");
    }

    let day,
        month,
        year: string | undefined = undefined;

    const dbDateFormat = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/; // 2022-02-01
    const regularDateFormat = /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/; // 01.02.2022
    const shortYearDateFormat = /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{2}$/; // 01.02.22
    const regularDateFormatNoDots = /^[0-9]{8}$/; // 01022022
    const shortYearDateFormatNoDots = /^[0-9]{6}$/; // 010222

    if (date.match(dbDateFormat)) {
        [year, month, day] = date.substring(0, 10).split(/-/);
    } else if (date.match(regularDateFormat)) {
        [day, month, year] = date.split(/\./);
    } else if (date.match(shortYearDateFormat)) {
        [day, month, year] = date.split(/\./);
        year = `20${year}`;
    } else if (date.match(regularDateFormatNoDots)) {
        [day, month, year] = [date.substring(0, 2), date.substring(2, 4), date.substring(4, 8)];
    } else if (date.match(shortYearDateFormatNoDots)) {
        [day, month, year] = [date.substring(0, 2), date.substring(2, 4), `20${date.substring(4, 6)}`];
    }

    if (day && month && year) {
        return new Date(`${year}-${month}-${day}`);
    }

    return new Date("Invalid Date");
}

export enum Workdays {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday
}

export function getDateWithOffset(offset: number, type: "day" | "month" | "year", date: Date = new Date()): Date {
    const newDate = new Date(date);

    if (type === "day") {
        newDate.setUTCDate(newDate.getUTCDate() + offset);
    }

    if (type === "month") {
        newDate.setUTCMonth(newDate.getUTCMonth() + offset);
    }

    if (type === "year") {
        newDate.setUTCFullYear(newDate.getUTCFullYear() + offset);
    }

    return newDate;
}

export function isWorkingDay(date: Date, holidays?: HolidayDate[]): boolean {
    const dateIsWorkingDay = Object.values(Workdays).includes(date.getDay());
    const dateIsHoliday = Boolean(holidays?.find(holiday => holiday.startDate === toISO(date)));

    return dateIsWorkingDay && !dateIsHoliday;
}

export function getDateWithWorkingDayOffset(offset: number, holidays?: HolidayDate[], date: Date = new Date()): Date {
    const newDate = getDateWithOffset(offset > 0 ? 1 : -1, "day", date);

    const dateIsWorkingDay = isWorkingDay(newDate, holidays);
    const nextOffset = dateIsWorkingDay ? (offset > 0 ? offset - 1 : offset + 1) : offset;

    if (nextOffset === 0 && dateIsWorkingDay) {
        return newDate;
    }

    return getDateWithWorkingDayOffset(nextOffset, holidays, newDate);
}

export function getDates(from: Date, to: Date) {
    const dates: Date[] = [];

    let currentDate = new Date(from < to ? from : to);
    const endDate = new Date(from >= to ? from : to);

    currentDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(0, 0, 0, 0);
    }

    return dates;
}

export function getWorkingDayDistance(date: Date, holidays?: HolidayDate[]) {
    const now = new Date();
    const target = date;
    now.setHours(0, 0, 0, 0);
    target.setHours(0, 0, 0, 0);

    const negativeSigned = now > target;

    const dates = getDates(now, target);
    const workingDates = dates.filter(date => date.getTime() !== now.getTime() && isWorkingDay(date, holidays));

    if (negativeSigned) {
        return workingDates.length * -1;
    }

    return workingDates.length;
}

export function getLatestMonday(date: Date = new Date()): Date {
    if (date.getDay() === Workdays.Monday) {
        return date;
    }

    const diff = date.getDay() - 1;
    return getDateWithOffset(diff === -1 ? -6 : -diff, "day", date);
}

export enum WeekDay {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
}

export function getDayLastWeek(day: WeekDay, date: Date = new Date()): Date {
    const dateLastWeek = getDateWithOffset(-7, "day", date);

    if (dateLastWeek.getDay() === day) {
        return dateLastWeek;
    }

    const diff = dateLastWeek.getDay() - day;
    return getDateWithOffset(-diff, "day", dateLastWeek);
}

type DateNumber =
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26
    | 27
    | 28
    | 29
    | 30
    | 31;

export function getDateLastMonth(dateLastMonth: DateNumber, date: Date = new Date()): Date {
    const newDate = new Date(date.setDate(dateLastMonth));
    return getDateWithOffset(-1, "month", newDate);
}

export function getLastDateOfLastMonth(date: Date = new Date()): Date {
    return new Date(date.setDate(0)); // 0 sets last day of previous month
}
