function getCount(states) {
    const count = states
        .filter(stateObj => this.query.state.includes(stateObj.state))
        .reduce((accumulator, stateObj) => accumulator + stateObj.count, 0);
    return count || 0;
}

export const projectsFilters = {
    default: {
        count: () => null,
        query: {
            sortBy: "projectCreatedAt",
            sortOrder: "desc"
        }
    },
    created: {
        count: getCount,
        query: {
            state: ["created"],
            sortBy: "projectCreatedAt",
            sortOrder: "asc"
        }
    },
    operator_assigned: {
        count: getCount,
        query: {
            state: ["operator_assigned"],
            sortBy: "projectCreatedAt",
            sortOrder: "asc"
        }
    },
    craftsman_requested: {
        count: getCount,
        query: {
            state: ["craftsman_requested"],
            sortBy: "projectCreatedAt",
            sortOrder: "asc"
        }
    },
    in_execution: {
        count: getCount,
        query: {
            state: ["in_execution"],
            sortBy: "projectCreatedAt",
            sortOrder: "asc"
        }
    },
    completed: {
        count: getCount,
        query: {
            state: ["completed"],
            sortBy: "projectCreatedAt",
            sortOrder: "desc"
        }
    },
    rejected: {
        count: getCount,
        query: {
            state: ["rejected"],
            sortBy: "projectCreatedAt",
            sortOrder: "desc"
        }
    },
    cancelled: {
        count: getCount,
        query: {
            state: ["cancelled"],
            sortBy: "projectCreatedAt",
            sortOrder: "desc"
        }
    },
    coordination: {
        count: getCount,
        query: {
            state: ["coordination"],
            sortBy: "projectCreatedAt",
            sortOrder: "desc"
        }
    }
};

export const craftsmenFilters = {
    default: {
        count: () => null,
        query: {
            sortBy: "facilityName",
            sortOrder: "asc"
        }
    },
    toBeChecked: {
        count: getCount,
        query: {
            state: ["toBeChecked"],
            sortBy: "facilityName",
            sortOrder: "asc"
        }
    },
    akquise: {
        count: getCount,
        query: {
            state: ["akquise"],
            sortBy: "facilityName",
            sortOrder: "asc"
        }
    },
    active: {
        count: getCount,
        query: {
            state: ["active"],
            sortBy: "facilityName",
            sortOrder: "asc"
        }
    },
    abgebrochen: {
        count: getCount,
        query: {
            state: ["abgebrochen"],
            sortBy: "facilityName",
            sortOrder: "asc"
        }
    },
    temporarilyDisabled: {
        count: getCount,
        query: {
            state: ["temporarilyDisabled"],
            sortBy: "facilityName",
            sortOrder: "asc"
        }
    },
    disabled: {
        count: getCount,
        query: {
            state: ["disabled"],
            sortBy: "facilityName",
            sortOrder: "asc"
        }
    }
};
