export enum ProjectState {
    created = "created",
    operator_assigned = "operator_assigned",
    craftsman_requested = "craftsman_requested",
    in_execution = "in_execution",
    completed = "completed",
    rejected = "rejected",
    cancelled = "cancelled",
    coordination = "coordination"
}

export enum VorgangFullState {
    HandwerkerAnfrage_VorgangAngelegt = "HandwerkerAnfrage.VorgangAngelegt",
    HandwerkerAnfrage_Geprueft = "HandwerkerAnfrage.Geprueft",
    HandwerkerAnfrage_MachbarMacherAngefragt = "HandwerkerAnfrage.MachbarMacherAngefragt",
    HandwerkerAnfrage_SolutionPartnerAngefragt = "HandwerkerAnfrage.SolutionPartnerAngefragt",
    HandwerkerAnfrage_PartnerAPIAnfrageAusstehend = "HandwerkerAnfrage.PartnerAPIAnfrageAusstehend",

    MachbarMacher_MachbarMacherAnfrageBestaetigt = "MachbarMacher.MachbarMacherAnfrageBestaetigt",
    MachbarMacher_KundeKontaktiert = "MachbarMacher.KundeKontaktiert",
    MachbarMacher_AngebotVersendet = "MachbarMacher.AngebotVersendet",
    MachbarMacher_AngebotAngenommen = "MachbarMacher.AngebotAngenommen",
    MachbarMacher_Umgesetzt = "MachbarMacher.Umgesetzt",

    SolutionPartnering_SolutionPartneringAnfrageBestaetigt = "SolutionPartnering.SolutionPartneringAnfrageBestaetigt",
    SolutionPartnering_KundeKontaktiert = "SolutionPartnering.KundeKontaktiert",
    SolutionPartnering_AngebotVersendet = "SolutionPartnering.AngebotVersendet",
    SolutionPartnering_AngebotAngenommen = "SolutionPartnering.AngebotAngenommen",
    SolutionPartnering_Umgesetzt = "SolutionPartnering.Umgesetzt",

    VertragsManagement_VorOrtTerminBestaetigt = "VertragsManagement.VorOrtTerminBestaetigt",
    VertragsManagement_HandwerkerRueckmeldungErfasst = "VertragsManagement.HandwerkerRueckmeldungErfasst",
    VertragsManagement_DienstleistungsVertragVersendet = "VertragsManagement.DienstleistungsVertragVersendet",
    VertragsManagement_VertraegeGeschlossen = "VertragsManagement.VertraegeGeschlossen",
    VertragsManagement_BeauftragungVersendet = "VertragsManagement.BeauftragungVersendet",
    VertragsManagement_BeauftragungBestaetigt = "VertragsManagement.BeauftragungBestaetigt",
    VertragsManagement_BaustellenStartTerminFestgelegt = "VertragsManagement.BaustellenStartTerminFestgelegt",
    VertragsManagement_BaustelleGestartet = "VertragsManagement.BaustelleGestartet",
    VertragsManagement_PauschaleAbgerechnet = "VertragsManagement.PauschaleAbgerechnet",
    VertragsManagement_InMigration = "VertragsManagement.InMigration",

    Umsetzung_BaustelleGestartet = "Umsetzung.BaustelleGestartet",
    Umsetzung_LeistungenGebucht = "Umsetzung.LeistungenGebucht",
    Umsetzung_SchlussrechnungErstellt = "Umsetzung.SchlussrechnungErstellt",
    Umsetzung_SchlussrechnungExternErstellt = "Umsetzung.SchlussrechnungExternErstellt",
    Umsetzung_InMigration = "Umsetzung.InMigration",

    Koordination_Koordination = "Koordination.Koordination",
    InMigration = "InMigration",

    Abgebrochen = "Abgebrochen",
    Abgelehnt = "Abgelehnt"
}

export enum CraftsmanState {
    toBeChecked = "toBeChecked",
    active = "active",
    temporarilyDisabled = "temporarilyDisabled",
    disabled = "disabled"
}

export enum CategoryKey {
    bad = "BAD",
    raum = "RAUM",
    garten = "GARTEN",
    bauelemente = "BAUELEMENTE",
    sonstiges = "SONSTIGES"
}

export enum CountryCode {
    at = "AT",
    de = "DE"
}

export enum Anrede {
    Firma = "Firma",
    Frau = "Frau",
    Herr = "Herr",
    KeineAngabe = ""
}

export type PriceClusterNumber = string;

export enum Confidence {
    low = "low",
    medium = "medium",
    high = "high",
    invalid = "invalid",
    unknown = "unknown",
    pending = "pending"
}

export enum DocumentOrigin {
    LUCIFY = "LUCIFY",
    INNENDIENST = "INNENDIENST"
}

export enum BaustellenStartTerminTypes {
    UNBEKANNT = "UNBEKANNT",
    ERFORDERLICH = "ERFORDERLICH"
}

export enum LucifyEnvironment {
    LOCAL = "LOCAL",
    DEV = "DEV",
    TEST = "TEST",
    STAGE = "STAGE",
    PROD = "PROD"
}

export interface EmailTemplate {
    template: string;
    fullState: VorgangFullState[];
}
