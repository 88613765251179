import React, { PureComponent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import Proptypes from "prop-types";
import { authContext } from "@lucify/auth";
import Icon from "../../components/Icon/Icon";
import { renderGravatarSrcURL } from "../../utils/helpers/gravatar";
import { Avatar } from "../Avatar/Avatar";
import Can from "../Feature/Can";
import LinkIcon from "../LinkIcon/LinkIcon";
import styles from "./ContextMenu.css";

export default class ContextMenu extends PureComponent {
    static contextType = authContext;

    static defaultProps = {
        isOpen: false
    };

    static propTypes = {
        isOpen: Proptypes.bool,
        toggleMenu: Proptypes.func.isRequired
    };

    @autobind
    toggleContextMenu() {
        this.props.toggleMenu(!this.props.isOpen, "contextMenu");
    }

    componentWillUnmount() {
        this.props.toggleMenu(false, "contextMenu");
    }

    render() {
        const { isOpen } = this.props;

        const userToken = this.context.user || {};
        const { given_name: firstName, family_name: lastName, name, email } = userToken;

        const userData = {
            firstName,
            lastName,
            imageSrc: renderGravatarSrcURL(email),
            imageAlt: ""
        };

        return (
            <div className={classNames({ [styles.contextMenuOpen]: isOpen })}>
                <button onClick={this.toggleContextMenu} className={styles.toggleButton}>
                    <Icon className={styles.toggleIcon} name={"IconPerson"} />
                    <span className={styles.toggleText}>Mein Bereich</span>
                </button>
                <nav className={styles.navWrapper}>
                    <div className={styles.userContext}>
                        <div className={styles.userImage}>
                            <Avatar {...userData} />
                        </div>
                        <div className={styles.userInfo}>
                            <div className={styles.userName}>{name || ""}</div>
                            {userToken.country ? userToken.country.toUpperCase() : ""}
                        </div>
                    </div>

                    <Can perform={"projekt:erstellen"}>
                        <LinkIcon
                            component={"NavLink"}
                            className={styles.link}
                            to={"/projekt/anlegen"}
                            iconName={"IconAddCircleOutline"}
                        >
                            <span className={styles.linkText}>Projekt anlegen</span>
                        </LinkIcon>
                    </Can>
                    <LinkIcon
                        component={"NavLink"}
                        className={styles.link}
                        to={"/docs/shortcuts"}
                        iconName={"IconKeyboardOutline"}
                    >
                        <span className={styles.linkText}>Shortcuts</span>
                    </LinkIcon>
                    <Can perform={"reports:ansehen"}>
                        <LinkIcon
                            component={"NavLink"}
                            className={styles.link}
                            to={"/reports"}
                            iconName={"IconArchive"}
                        >
                            <span className={styles.linkText}>Reports</span>
                        </LinkIcon>
                    </Can>

                    <LinkIcon
                        component={"Button"}
                        onClick={this.context.logout}
                        iconName={"IconPowerOff"}
                        className={styles.link}
                    >
                        Abmelden
                    </LinkIcon>
                </nav>
            </div>
        );
    }
}
