import { LOGOUT } from "../actions/auth.actions";
import {
    GET_CRAFTSMEN_FOR_DIRTY_MATCHING_FULFILLED,
    GET_CRAFTSMEN_FOR_DIRTY_MATCHING_PENDING,
    GET_CRAFTSMEN_FOR_DIRTY_MATCHING_REJECTED,
    GET_CRAFTSMEN_FOR_MATCHING_FULFILLED,
    GET_CRAFTSMEN_FOR_MATCHING_PENDING,
    GET_CRAFTSMEN_FOR_MATCHING_REJECTED
} from "../actions/matching.actions";

const initialState = {
    pathname: null,
    dirty: {
        data: [],
        failed: false,
        isFetching: false
    },
    craftsmen: {
        data: [],
        failed: false,
        isFetching: false
    }
};

export function matching(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;
        case GET_CRAFTSMEN_FOR_DIRTY_MATCHING_PENDING:
            return {
                ...state,
                dirty: {
                    ...state.dirty,
                    isFetching: true
                }
            };
        case GET_CRAFTSMEN_FOR_DIRTY_MATCHING_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    dirty: {
                        ...state.dirty,
                        isFetching: false,
                        failed: true,
                        status: action.payload.response.status
                    }
                };
            }
        case GET_CRAFTSMEN_FOR_DIRTY_MATCHING_FULFILLED:
            return {
                ...state,
                dirty: {
                    data: action.payload.data,
                    isFetching: false,
                    failed: false,
                    status: action.payload.status
                }
            };
        case GET_CRAFTSMEN_FOR_MATCHING_PENDING:
            return {
                ...state,
                craftsmen: {
                    ...state.craftsmen,
                    isFetching: true
                }
            };
        case GET_CRAFTSMEN_FOR_MATCHING_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmen: {
                        ...state.craftsmen,
                        isFetching: false,
                        failed: true,
                        status: action.payload.response.status
                    }
                };
            }
        case GET_CRAFTSMEN_FOR_MATCHING_FULFILLED:
            return {
                ...state,
                craftsmen: {
                    data: action.payload.data,
                    isFetching: false,
                    failed: false,
                    status: action.payload.status
                }
            };
        // Entfernt, da beim Aufruf der selben Seite die Ergebnisse bestehen bleiben müssen
        // wieder einkommentiert, nachdem das Feature für verschiedene Vertragsmodelle eingeführt wurde.
        // Verhindert, dass alte Matches/DirtyMatches (der vorigen Vermittlung/Betruung) dargestellt werden, die ggf. für die aktuelle Vermittlung nicht vorgeschlagen werden dürften
        case "@@router/LOCATION_CHANGE":
            return initialState;
        default:
            return state;
    }
}
