// sort-imports-ignore
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/i18n";

// global imports for components, container, ...
import App from "./container/App/App";

// global styles#
import "./index.css";
import "./styles/typo.css";

// store
import { Provider, ReactReduxContext } from "react-redux";
import store, { history } from "./store/store";
import { ConnectedRouter } from "connected-react-router";
import Authentication from "./container/App/Authentication";

// breakpoints
import ReactBreakpoints from "react-breakpoints";
import { breakpoints } from "./utils/redux-breakpoint/breakpoints";
import LoadingIndicator from "./components/LoadingIndicator/LoadingIndicator";
import NotificationList from "./container/NotificationCenter/NotificationList";
import { registerServiceWorker } from "./utils/registerServiceWorker";

registerServiceWorker();

// Hard-reload on route switch when an update was detected by the service-worker
let lastHistoryHref;
history.listen(location => {
    const currentHistoryHref = history.createHref(location);

    if (lastHistoryHref !== currentHistoryHref && window.updateAvailable) {
        window.location.href = currentHistoryHref;
    }

    lastHistoryHref = currentHistoryHref;
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <Authentication>
            <ConnectedRouter history={history} context={ReactReduxContext}>
                <I18nextProvider i18n={i18n}>
                    <ReactBreakpoints breakpoints={breakpoints}>
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <App />
                            <NotificationList targetArea="topRight" />
                            <NotificationList maxEntries={1} targetArea="center" />
                            <NotificationList maxEntries={1} targetArea="bottom" />
                        </React.Suspense>
                    </ReactBreakpoints>
                </I18nextProvider>
            </ConnectedRouter>
        </Authentication>
    </Provider>
);
