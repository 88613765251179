import { craftsmenService } from "../../services/craftsmen";
import { macherService } from "../../services/macher/macher";

export const GET_CRAFTSMEN_FOR_DIRTY_MATCHING = "GET_CRAFTSMEN_FOR_DIRTY_MATCHING";
export const GET_CRAFTSMEN_FOR_DIRTY_MATCHING_PENDING = "GET_CRAFTSMEN_FOR_DIRTY_MATCHING_PENDING";
export const GET_CRAFTSMEN_FOR_DIRTY_MATCHING_FULFILLED = "GET_CRAFTSMEN_FOR_DIRTY_MATCHING_FULFILLED";
export const GET_CRAFTSMEN_FOR_DIRTY_MATCHING_REJECTED = "GET_CRAFTSMEN_FOR_DIRTY_MATCHING_REJECTED";

export const GET_CRAFTSMEN_FOR_MATCHING = "GET_CRAFTSMEN_FOR_MATCHING";
export const GET_CRAFTSMEN_FOR_MATCHING_PENDING = "GET_CRAFTSMEN_FOR_MATCHING_PENDING";
export const GET_CRAFTSMEN_FOR_MATCHING_FULFILLED = "GET_CRAFTSMEN_FOR_MATCHING_FULFILLED";
export const GET_CRAFTSMEN_FOR_MATCHING_REJECTED = "GET_CRAFTSMEN_FOR_MATCHING_REJECTED";

export const getCraftsmenForDirtyMatching = params => {
    return {
        type: GET_CRAFTSMEN_FOR_DIRTY_MATCHING,
        payload: craftsmenService.getList(params)
    };
};

export const getCraftsmenForMatching = (vermittlungId, params) => {
    return {
        type: GET_CRAFTSMEN_FOR_MATCHING,
        payload: macherService.getMatches(vermittlungId, params)
    };
};
