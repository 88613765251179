import React, { Component, useContext } from "react";
import { withBreakpoints } from "react-breakpoints";
import isEqual from "react-fast-compare";
// store & actions
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { autobind } from "core-decorators";
import { authContext } from "@lucify/auth";
import { LucifyEnvironment } from "@lucify/types";
import { classNames } from "@lucify/utils";
import NotificationList from "../../container/NotificationCenter/NotificationList";
import Sidebar from "../../container/Sidebar/Sidebar";
// components
import logo from "../../static/brand/brand-logo-machbar-macher.svg";
import atFlag from "../../static/images/at.png";
import deFlag from "../../static/images/de.png";
import { setHeaderHeights, toggleMenu } from "../../store/actions/ui.actions";
import { getUserCountry } from "../../utils/helpers/auth";
import { getEnvironment } from "../../utils/helpers/dev";
import { renderIf } from "../../utils/helpers/render";
import Cell from "../Grid/Cell";
import Grid from "../Grid/Grid";
import Icon from "../Icon/Icon";
import LinkIcon from "../LinkIcon/LinkIcon";
import Tooltip from "../Tooltip/Tooltip";
import ContextMenu from "./ContextMenu";
import styles from "./Header.css";
import NotificationBar from "./NotificationBar";
import Overlay from "./Overlay";

const Env = React.memo(props => {
    const { user } = useContext(authContext);
    const { country } = user;

    let env, hint;
    const flags = {
        DE: deFlag,
        AT: atFlag
    };

    if (process.env.LUCIFY_ENV === "dev" || getEnvironment() === LucifyEnvironment.DEV) {
        env = "DEV";
        hint = (
            <div>
                <strong>LUCIFY-LEGACY DEV</strong>{" "}
                <i>
                    {process.env.__BRANCH_NAME__} ({process.env.__COMMIT_HASH__})
                </i>
                <br />
                <ul style={{ paddingLeft: 20 }}>
                    <li>Es werden keine E&#8209;Mails verschickt</li>
                    <li>Bing API nicht verfügbar</li>
                </ul>
            </div>
        );
    }

    if (process.env.LUCIFY_ENV === "test" || getEnvironment() === LucifyEnvironment.TEST) {
        env = "TEST";
        hint = (
            <div>
                <strong>LUCIFY-LEGACY TEST</strong>{" "}
                <i>
                    {process.env.__BRANCH_NAME__} ({process.env.__COMMIT_HASH__})
                </i>
                <br />
                <ul style={{ paddingLeft: 20 }}>
                    <li>E&#8209;Mails werden nur an Verteiler verschickt</li>
                </ul>
            </div>
        );
    }

    if (process.env.LUCIFY_ENV === "stage" || getEnvironment() === LucifyEnvironment.STAGE) {
        env = "STAGE";
        hint = (
            <div>
                <strong>LUCIFY-LEGACY STAGE</strong>{" "}
                <i>
                    {process.env.__BRANCH_NAME__} ({process.env.__COMMIT_HASH__})
                </i>
                <br />
                <ul style={{ paddingLeft: 20 }}>
                    <li>E&#8209;Mails werden nur an Verteiler verschickt</li>
                </ul>
            </div>
        );
    }

    if (process.env.LUCIFY_ENV === "prod" && getEnvironment() === LucifyEnvironment.LOCAL) {
        env = "PROD";
        hint = (
            <div>
                <strong>LUCIFY-LEGACY LIVESYSTEM</strong>{" "}
                <i>
                    {process.env.__BRANCH_NAME__} ({process.env.__COMMIT_HASH__})
                </i>
                <br />
                <ul style={{ paddingLeft: 20 }}>
                    <li>Es werden E&#8209;Mails verschickt!</li>
                </ul>
            </div>
        );
    }

    return env && hint ? (
        <Grid axis={"x"} className={styles.env} customClasses={["alignMiddle"]}>
            <Cell auto shrink>
                <Icon name={"IconPolymer"} fill={"black"} />
            </Cell>
            <Cell auto>
                <Tooltip className={styles.tooltip} text={hint}>
                    {env}
                </Tooltip>
            </Cell>
            <Cell auto shrink>
                {props.isAuthenticated && country ? (
                    <img
                        style={{
                            height: 14,
                            marginLeft: 5,
                            boxShadow: "0 0 5px darkgrey"
                        }}
                        src={flags[getUserCountry()]}
                        alt={getUserCountry()}
                    />
                ) : null}
            </Cell>
        </Grid>
    ) : null;
});

export const HEADER_DEFAULT_HEIGHT = 44;
export const HEADER_DEFAULT_HEIGHT_DESKTOP = 88;
let timeout;
// don't use PureComponent for connect, prevent blocking render on NavLinks
// https://github.com/reduxjs/react-redux/blob/master/docs/troubleshooting.md#my-views-arent-updating-when-something-changes-outside-of-redux
@connect(
    ({ ui, breakpoint, notifications, user, router }) => ({
        ui,
        breakpoint,
        notifications,
        user,
        router
    }),
    {
        toggleMenu,
        push,
        setHeaderHeights
    }
)
@withBreakpoints
export default class Header extends Component {
    static contextType = authContext;
    notificationList = React.createRef();

    state = {
        fixed: false,
        headerHeight: this.props.breakpoint.device === "mobile" ? HEADER_DEFAULT_HEIGHT : HEADER_DEFAULT_HEIGHT_DESKTOP
    };

    shouldComponentUpdate(nextProps, nextState) {
        return !(
            isEqual(this.props.ui, nextProps.ui) &&
            isEqual(this.props.notifications, nextProps.notifications) &&
            isEqual(this.props.breakpoint, nextProps.breakpoint) &&
            isEqual(this.props.user, nextProps.user) &&
            isEqual(this.props.router, nextProps.router) &&
            isEqual(this.state, nextState)
        );
    }

    @autobind
    toggleSidebar() {
        this.props.toggleMenu(!this.props.ui.sidebar.isOpen, "sidebar");
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollListener);

        this.setUIHeaderHeights();
    }

    @autobind
    scrollListener() {
        const isFixed = (typeof window.scrollY === "undefined" ? window.pageYOffset : window.scrollY) >= 38;

        if (isFixed !== this.state.fixed) {
            this.setState({ fixed: isFixed });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevState.fixed !== this.state.fixed ||
            prevProps.breakpoint.device !== this.props.breakpoint.device ||
            !isEqual(this.props.notifications, prevProps.notifications)
        ) {
            timeout = setTimeout(() => this.setUIHeaderHeights());
        }
    }

    setUIHeaderHeights() {
        const isMobile = this.props.breakpoint.device === "mobile";
        const baseHeight = isMobile ? HEADER_DEFAULT_HEIGHT : HEADER_DEFAULT_HEIGHT_DESKTOP;
        const notificationsHeight = this.notificationList.current ? this.notificationList.current.clientHeight : 0;

        // maximum height of navigation including notifications
        const extendedBaseHeight = baseHeight + notificationsHeight;
        this.props.setHeaderHeights(extendedBaseHeight);

        const currentHeight =
            isMobile || (!isMobile && this.state.fixed) ? HEADER_DEFAULT_HEIGHT : HEADER_DEFAULT_HEIGHT_DESKTOP;

        this.setState({ headerHeight: currentHeight });
    }

    componentWillUnmount() {
        clearTimeout(timeout);
        window.removeEventListener("scroll", this.scrollListener);
    }

    render() {
        const auth = this.context;
        const { push } = this.props;
        const { toggleMenu, ui, network } = this.props;
        const { currentBreakpoint, breakpoints } = this.props;
        const { contextMenu } = ui;

        const showMobileNav = breakpoints[currentBreakpoint] < breakpoints.desktop;
        let burgerMenuIcon = null;

        if (ui.sidebar.isOpen) {
            burgerMenuIcon = <Icon className={styles.toggleIconPushIndex} name={"IconClose"} />;
        } else {
            burgerMenuIcon = <Icon className={styles.toggleIcon} name={"IconBurgerMenu"} />;
        }

        return (
            <header
                className={classNames(styles.header, {
                    [styles.headerFixed]: this.state.fixed,
                    [styles.headerAbsolute]: ui.sidebar.isOpen
                })}
            >
                <NotificationBar network={network} />
                <Overlay />
                {auth.authenticated ? <Env user={auth.user} isAuthenticated={auth.authenticated} /> : null}

                <div className={styles.headerInner} style={{ height: this.state.headerHeight }}>
                    <div className={styles.headerBrand}>
                        <img
                            src={logo}
                            className={styles.logo}
                            alt="Machbar Macher Logo"
                            onClick={() => (auth.authenticated ? this.props.push("/dashboard") : this.props.push("/"))}
                        />
                    </div>

                    {/* Mobile Burger Menu */}
                    <nav className={styles.headerMenu}>
                        {renderIf(auth.authenticated, () => (
                            <div>
                                {showMobileNav ? (
                                    this.props.showSidebarToggle ? (
                                        <button className={styles.toggleButton} onClick={this.toggleSidebar}>
                                            {burgerMenuIcon}
                                        </button>
                                    ) : null
                                ) : (
                                    <div className={styles.menuDesktop}>
                                        <LinkIcon
                                            component={"NavLink"}
                                            to={"/"}
                                            exact
                                            iconName={"IconDashboard"}
                                            variant={"dark"}
                                            iconClass={styles.toggleIcon}
                                            textClass={styles.toggleText}
                                        >
                                            Dashboard
                                        </LinkIcon>
                                    </div>
                                )}
                            </div>
                        ))}
                    </nav>

                    <div className={styles.headerContext}>
                        {renderIf(auth.authenticated, () => (
                            <ContextMenu push={push} isOpen={contextMenu.isOpen} toggleMenu={toggleMenu} />
                        ))}
                    </div>
                    {this.props.showSidebar ? <Sidebar position={"header"} /> : null}
                </div>

                <div ref={this.notificationList} className={styles.notificationList}>
                    <NotificationList maxEntries={1} targetArea="top" />
                </div>
            </header>
        );
    }
}
