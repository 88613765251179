import { SET_HEADER_HEIGHTS, TOGGLE_LIGHTBOX, TOGGLE_MENU } from "../actions/ui.actions";

const REPLACE = "REPLACE";

export const initialState = {
    sidebar: { isOpen: false },
    contextMenu: { isOpen: false },
    lightbox: { isOpen: false },
    header: {
        extendedBaseHeight: undefined
    }
};

export function ui(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_MENU:
            const { isOpen, menuId } = action.payload;
            return {
                ...state,
                [menuId]: { isOpen }
            };
        case TOGGLE_LIGHTBOX:
            return {
                ...state,
                lightbox: {
                    isOpen: action.payload
                }
            };
        case SET_HEADER_HEIGHTS:
            return {
                ...state,
                header: {
                    ...state.header,
                    ...action.payload
                }
            };

        case "@@router/LOCATION_CHANGE":
            return action.payload.action === REPLACE ? state : { ...initialState, header: state.header };
        default:
            return state;
    }
}
