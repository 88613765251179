import React, { Component, PureComponent, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { $TSFixMe, IVariantProps } from "@lucify/types";
import { renderVariantClasses } from "../../utils/helpers/render";
import Icon from "../Icon/Icon";
import styles from "./Typo.css";

interface ParagraphProps {
    children?: ReactNode;
    size: string;
    align: "center" | "left" | "right";
}

export class Paragraph extends Component<ParagraphProps> {
    static propTypes = {
        size: PropTypes.string,
        align: PropTypes.oneOf(["center", "left", "right"])
    };

    render() {
        const combinedClasses = classNames(styles.paragraph, {
            [styles[this.props.size]]: this.props.size,
            [styles[this.props.align]]: this.props.align
        });

        return <p className={combinedClasses}>{this.props.children}</p>;
    }
}

interface InlineLinkProps {
    children?: ReactNode;
    icon?: $TSFixMe; // todo implement IconProps from Icon.js component
    variant?: IVariantProps;
    className?: string;
    to: LinkProps["to"];
}

export class InlineLink extends PureComponent<InlineLinkProps> {
    render() {
        const { icon, variant, ...rest } = this.props;

        const classes = classNames(styles.inlineLink, renderVariantClasses(variant, styles), this.props.className, {
            [styles.withIcon]: icon
        });

        const iconProps = {
            ...icon,
            width: 18,
            height: 18
        };

        return (
            <Link {...rest} className={classes}>
                {icon ? <Icon {...iconProps} className={styles.icon} /> : null}
                {this.props.children}
            </Link>
        );
    }
}

interface TextProps {
    children?: ReactNode;
    className?: string;
    variant?: IVariantProps;
    component?: React.ElementType;
    title?: string;
    // todo make pattern where style is used
    style?: {
        [key: string]: any;
    };
}

export const Text: React.FC<TextProps> = ({
    children,
    className,
    component: Component = "span",
    variant,
    ...props
}) => {
    return (
        <Component {...props} className={classNames(className, renderVariantClasses(variant, styles))}>
            {children}
        </Component>
    );
};

interface InlineMailOrExtLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    mailto?: string;
    href?: string;
    external?: boolean;
}

export const InlineMailOrExtLink: React.FC<InlineMailOrExtLinkProps> = props => {
    const { external, href, mailto, ...rest } = props;

    function prepareUrl(href) {
        if (href.startsWith("http://") || href.startsWith("https://") || href.startsWith("//")) {
            return href;
        }

        return `http://${href}`;
    }

    return (
        <a
            className={styles.inlineMailTo}
            href={mailto ? `mailto:${mailto}` : href && external ? prepareUrl(href) : href}
            {...rest}
        >
            {props.children}
        </a>
    );
};
