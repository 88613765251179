//
// https://github.com/bala94/redux-breakpoint
//
import { debounce } from "@lucify/utils";
import breakpointReducer from "./breakPointReducer";

// Initial values
let storeRef = null;
let debounceDelay = 100;
let shouldDebounce = true;
let dispatchHeight = false;

// Default Resize listener
function onResize() {
    let state = storeRef.getState();
    let previousWidth = state.breakpoint.width;
    let currentWidth = window.innerWidth;

    if (currentWidth !== previousWidth || dispatchHeight) {
        storeRef.dispatch({
            type: "@@BREAKPOINT_CHANGE",
            height: dispatchHeight
        });
    }
}
function subscribe(callback) {
    if (shouldDebounce) {
        callback = debounce(callback, debounceDelay);
    }
    window.addEventListener("resize", callback);
}

function syncBreakpointWithStore(store, config) {
    storeRef = store;
    if (config) {
        if (config.debounce !== undefined) {
            shouldDebounce = config.debounce;
        }
        if (config.debounceDelay !== undefined) {
            debounceDelay = config.debounceDelay;
        }
        if (config.dispatchHeight !== undefined) {
            dispatchHeight = config.dispatchHeight;
        }
    }

    subscribe(onResize);
}

export default syncBreakpointWithStore;
export { breakpointReducer };
