interface Object {
    [key: string]: any;
}

// Inspired by:
// Deep-Merge JavaScript objects with ES6
// https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6
export function deepmerge<TargetType, SourceType>(target: object, source: object): TargetType & SourceType {
    const targetCopy: Object = { ...target };
    const sourceCopy: Object = { ...source };

    // Iterate through `sourceCopy` properties and if an `Object` set property to merge of `targetCopy` and `sourceCopy` properties
    for (let key of Object.keys(sourceCopy)) {
        if (sourceCopy[key] instanceof Object) {
            Object.assign(sourceCopy[key], deepmerge(targetCopy[key], sourceCopy[key]));
        }
    }

    // Join `targetCopy` and modified `sourceCopy`
    Object.assign(targetCopy || {}, sourceCopy);
    return targetCopy as TargetType & SourceType;
}
