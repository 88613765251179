import { $TSFixMe } from "@lucify/types/src/interfaces/Globals";

const errorMessage = "Access to Cache not allowed";

let errorHandler = (error: any, message: string = errorMessage) => {
    console.log("Message: ", message, "Response: ", error);

    if (process.env.NODE_ENV === "development" && (error instanceof TypeError || error instanceof ReferenceError)) {
        throw error;
    }
};

export const setCachesErrorHandler = (f: (error: any, message?: string) => void) => {
    errorHandler = f;
};

export const cachesWrapper = {
    async delete(cacheName: string): Promise<boolean | void> {
        try {
            return await caches.delete(cacheName);
        } catch (error) {
            errorHandler(error);
        }
    },
    async has(cacheName: string): Promise<boolean | undefined> {
        try {
            return await caches.has(cacheName);
        } catch (error) {
            errorHandler(error);
        }
    },
    async keys(): Promise<string[] | undefined> {
        try {
            return await caches.keys();
        } catch (error) {
            errorHandler(error);
        }
    },
    async match(request: RequestInfo, options?: $TSFixMe): Promise<Response | undefined> {
        try {
            return await caches.match(request, options);
        } catch (error) {
            errorHandler(error);
        }
    },
    async open(cacheName: string): Promise<Cache | undefined> {
        try {
            return await caches.open(cacheName);
        } catch (error) {
            errorHandler(error);
        }
    }
};
