import { craftsmenService } from "../../services/craftsmen";
import { macherService } from "../../services/macher/macher";

export const GET_CRAFTSMEN = "GET_CRAFTSMEN";
export const GET_CRAFTSMEN_PENDING = "GET_CRAFTSMEN_PENDING";
export const GET_CRAFTSMEN_FULFILLED = "GET_CRAFTSMEN_FULFILLED";
export const GET_CRAFTSMEN_REJECTED = "GET_CRAFTSMEN_REJECTED";

export const getCraftsmen = params => {
    return {
        type: GET_CRAFTSMEN,
        payload: craftsmenService.getList(params),
        meta: params
    };
};

export const GET_COORDINATION_CRAFTSMEN = "GET_COORDINATION_CRAFTSMEN";
export const GET_COORDINATION_CRAFTSMEN_PENDING = "GET_COORDINATION_CRAFTSMEN_PENDING";
export const GET_COORDINATION_CRAFTSMEN_FULFILLED = "GET_COORDINATION_CRAFTSMEN_FULFILLED";
export const GET_COORDINATION_CRAFTSMEN_REJECTED = "GET_COORDINATION_CRAFTSMEN_REJECTED";

export const getCoordinationCraftsmen = params => {
    return {
        type: GET_COORDINATION_CRAFTSMEN,
        payload: craftsmenService.getList(params),
        meta: params
    };
};

export const GET_CRAFTSMAN_STATS = "GET_CRAFTSMAN_STATS";
export const GET_CRAFTSMAN_STATS_PENDING = "GET_CRAFTSMAN_STATS_PENDING";
export const GET_CRAFTSMAN_STATS_FULFILLED = "GET_CRAFTSMAN_STATS_FULFILLED";
export const GET_CRAFTSMAN_STATS_REJECTED = "GET_CRAFTSMAN_STATS_REJECTED";

export const getCraftsmanStats = id => {
    return {
        type: GET_CRAFTSMAN_STATS,
        meta: {
            id
        },
        payload: macherService.getCraftsmanFacilityMetrics(id)
    };
};

export const GET_CRAFTSMAN_PROFILE = "GET_CRAFTSMAN_PROFILE";
export const GET_CRAFTSMAN_PROFILE_PENDING = "GET_CRAFTSMAN_PROFILE_PENDING";
export const GET_CRAFTSMAN_PROFILE_FULFILLED = "GET_CRAFTSMAN_PROFILE_FULFILLED";
export const GET_CRAFTSMAN_PROFILE_REJECTED = "GET_CRAFTSMAN_PROFILE_REJECTED";

export const getCraftsmanProfile = id => {
    return {
        type: GET_CRAFTSMAN_PROFILE,
        meta: {
            id
        },
        payload: craftsmenService.getProfile(id)
    };
};

export const UPDATE_CRAFTSMAN_PROFILE = "UPDATE_CRAFTSMAN_PROFILE";
export const UPDATE_CRAFTSMAN_PROFILE_PENDING = "UPDATE_CRAFTSMAN_PROFILE_PENDING";
export const UPDATE_CRAFTSMAN_PROFILE_FULFILLED = "UPDATE_CRAFTSMAN_PROFILE_FULFILLED";
export const UPDATE_CRAFTSMAN_PROFILE_REJECTED = "UPDATE_CRAFTSMAN_PROFILE_REJECTED";

export const updateCraftsmanProfile = (craftsmanId, data) => {
    return {
        type: UPDATE_CRAFTSMAN_PROFILE,
        meta: {
            craftsmanId
        },
        payload: craftsmenService.updateProfile(craftsmanId, data)
    };
};

export const REQUEST_CRAFTSMAN = "REQUEST_CRAFTSMAN";
export const REQUEST_CRAFTSMAN_PENDING = "REQUEST_CRAFTSMAN_PENDING";
export const REQUEST_CRAFTSMAN_FULFILLED = "REQUEST_CRAFTSMAN_FULFILLED";
export const REQUEST_CRAFTSMAN_REJECTED = "REQUEST_CRAFTSMAN_REJECTED";

export const request = (vermittlungId, craftsmanFacilityId, manuallyMatched) => {
    return {
        type: REQUEST_CRAFTSMAN,
        payload: macherService.request(vermittlungId, craftsmanFacilityId, manuallyMatched),
        meta: {
            vermittlungId,
            craftsmanFacilityId
        }
    };
};

export const RESPONSE_CRAFTSMAN = "RESPONSE_CRAFTSMAN";
export const RESPONSE_CRAFTSMAN_PENDING = "RESPONSE_CRAFTSMAN_PENDING";
export const RESPONSE_CRAFTSMAN_FULFILLED = "RESPONSE_CRAFTSMAN_FULFILLED";
export const RESPONSE_CRAFTSMAN_REJECTED = "RESPONSE_CRAFTSMAN_REJECTED";

export const response = (requestId, vermittlungId, action, data) => {
    return {
        type: RESPONSE_CRAFTSMAN,
        payload: macherService.response(requestId, vermittlungId, action, data),
        meta: {
            requestId,
            vermittlungId,
            action,
            data
        }
    };
};

export const GET_CRAFTSMAN_COMMENTS = "GET_CRAFTSMAN_COMMENTS";
export const GET_CRAFTSMAN_COMMENTS_PENDING = "GET_CRAFTSMAN_COMMENTS_PENDING";
export const GET_CRAFTSMAN_COMMENTS_FULFILLED = "GET_CRAFTSMAN_COMMENTS_FULFILLED";
export const GET_CRAFTSMAN_COMMENTS_REJECTED = "GET_CRAFTSMAN_COMMENTS_REJECTED";

export const getComments = craftsmanId => {
    return {
        type: GET_CRAFTSMAN_COMMENTS,
        payload: craftsmenService.getComments(craftsmanId),
        meta: {
            craftsmanId
        }
    };
};

export const ADD_CRAFTSMAN_COMMENT = "ADD_CRAFTSMAN_COMMENT";
export const ADD_CRAFTSMAN_COMMENT_PENDING = "ADD_CRAFTSMAN_COMMENT_PENDING";
export const ADD_CRAFTSMAN_COMMENT_FULFILLED = "ADD_CRAFTSMAN_COMMENT_FULFILLED";
export const ADD_CRAFTSMAN_COMMENT_REJECTED = "ADD_CRAFTSMAN_COMMENT_REJECTED";

export const addComment = (craftsmanId, data, country?) => {
    return {
        type: ADD_CRAFTSMAN_COMMENT,
        payload: craftsmenService.addComment(craftsmanId, data),
        meta: {
            craftsmanId
        }
    };
};

export const DELETE_CRAFTSMAN_COMMENT = "DELETE_CRAFTSMAN_COMMENT";
export const DELETE_CRAFTSMAN_COMMENT_PENDING = "DELETE_CRAFTSMAN_COMMENT_PENDING";
export const DELETE_CRAFTSMAN_COMMENT_FULFILLED = "DELETE_CRAFTSMAN_COMMENT_FULFILLED";
export const DELETE_CRAFTSMAN_COMMENT_REJECTED = "DELETE_CRAFTSMAN_COMMENT_REJECTED";

export const deleteComment = (craftsmanId, commentId) => {
    return {
        type: DELETE_CRAFTSMAN_COMMENT,
        payload: craftsmenService.deleteComment(craftsmanId, commentId),
        meta: {
            craftsmanId,
            commentId
        }
    };
};

export const GET_HKO_LIST = "GET_HKO_LIST";
export const GET_HKO_LIST_PENDING = "GET_HKO_LIST_PENDING";
export const GET_HKO_LIST_FULFILLED = "GET_HKO_LIST_FULFILLED";
export const GET_HKO_LIST_REJECTED = "GET_HKO_LIST_REJECTED";

export const getHKOList = () => ({
    type: GET_HKO_LIST,
    payload: craftsmenService.getHKOList()
});

export const GET_HANDWERKER_BEWERTUNGEN = "GET_HANDWERKER_BEWERTUNGEN";
export const GET_HANDWERKER_BEWERTUNGEN_PENDING = "GET_HANDWERKER_BEWERTUNGEN_PENDING";
export const GET_HANDWERKER_BEWERTUNGEN_FULFILLED = "GET_HANDWERKER_BEWERTUNGEN_FULFILLED";
export const GET_HANDWERKER_BEWERTUNGEN_REJECTED = "GET_HANDWERKER_BEWERTUNGEN_REJECTED";

export const getBewertungen = (craftsmanId, params) => ({
    type: GET_HANDWERKER_BEWERTUNGEN,
    payload: craftsmenService.getBewertungen(craftsmanId, params),
    meta: {
        craftsmanId
    }
});

export const GET_CRAFTSMAN_PRICECLUSTERS = "GET_CRAFTSMAN_PRICECLUSTERS";
export const GET_CRAFTSMAN_PRICECLUSTERS_PENDING = "GET_CRAFTSMAN_PRICECLUSTERS_PENDING";
export const GET_CRAFTSMAN_PRICECLUSTERS_FULFILLED = "GET_CRAFTSMAN_PRICECLUSTERS_FULFILLED";
export const GET_CRAFTSMAN_PRICECLUSTERS_REJECTED = "GET_CRAFTSMAN_PRICECLUSTERS_REJECTED";

export const getPriceclusters = function () {
    return {
        type: GET_CRAFTSMAN_PRICECLUSTERS,
        payload: craftsmenService.getPriceclusters()
    };
};
