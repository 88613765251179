import { LucifyKeycloakCountry } from "./constants";

/**
 * Checks if user has supported country
 * @param {LucifyKeycloakCountry[]} countrySupportMap
 * @param {string} userCountry
 * @returns {boolean}
 */
export function hasSupportedCountry(countrySupportMap: LucifyKeycloakCountry[], userCountry?: string): boolean {
    return !!userCountry && countrySupportMap.includes(userCountry as LucifyKeycloakCountry);
}
