export interface GenericEventListener {
    name: string | number;
    callback(...args: any): void;
    deleted?: boolean;
}

export class EventListener<SystemEvent extends GenericEventListener = GenericEventListener> {
    public name: SystemEvent["name"];
    public callback: SystemEvent["callback"];
    public deleted?: SystemEvent["deleted"];

    constructor(name: SystemEvent["name"], callback: SystemEvent["callback"]) {
        this.name = name;
        this.callback = callback;
    }

    unregister() {
        this.deleted = true;
    }
}

export class EventSystem<SystemEvent extends GenericEventListener = GenericEventListener> {
    private events: EventListener<SystemEvent>[] = [];

    public on(name: SystemEvent["name"], callback: SystemEvent["callback"]): EventListener<SystemEvent> {
        const event = new EventListener<SystemEvent>(name, callback);
        this.events.push(event);
        return event;
    }

    public off(eventToUnregister?: EventListener<SystemEvent>) {
        if (eventToUnregister) {
            const eventIndex = this.events.indexOf(eventToUnregister);
            if (eventIndex !== -1) {
                this.events.splice(eventIndex, 1);
            }
        }
    }

    public trigger(eventName: SystemEvent["name"], ...args: Parameters<SystemEvent["callback"]>) {
        this.events
            .filter(({ deleted }) => deleted)
            .forEach(event => {
                this.off(event);
            });
        this.events.filter(({ name }) => name === eventName).forEach(({ callback }) => callback(...args));
    }
}
