/**
 * Retries the given function until it succeeds given a number of retries and an interval between them. They are set
 * by default to retry 5 times with 1sec in between. There's also a flag to make the cooldown time exponential
 * https://gitlab.com/snippets/1775781
 * @author Daniel Iñigo <danielinigobanos@gmail.com>
 * @param {Function} fn - Returns a promise
 * @param {Number} retriesLeft - Number of retries. If -1 will keep retrying
 * @param {Number} interval - Millis between retries. If exponential set to true will be doubled each retry
 * @param {Boolean} exponential - Flag for exponential back-off mode
 * @return {Promise<*>}
 */
export async function retry(
    fn: () => Promise<any>,
    retriesLeft = 5,
    interval = 1000,
    exponential = false
): Promise<any> {
    try {
        return await fn();
    } catch (error) {
        if (retriesLeft) {
            await new Promise(r => setTimeout(r, interval));
            console.debug("Retries remaining", retriesLeft);
            return retry(fn, retriesLeft - 1, exponential ? interval * 2 : interval, exponential);
        } else {
            throw error;
        }
    }
}
