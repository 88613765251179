import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./Icon.css";
import * as Icons from "./Icons";

export default class Icon extends PureComponent {
    static defaultProps = {
        name: "IconBrokenImage",
        width: 24,
        height: 24,
        fill: "",
        intent: ""
    };

    static propTypes = {
        name: PropTypes.string.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        fill: PropTypes.string,
        intent: PropTypes.string
    };

    render() {
        const { width, height } = this.props;
        const bundleClasses = classNames(styles.wrapper, styles[this.props.intent], this.props.className);

        const IconName = this.props.children ? false : Icons[this.props.name];

        return (
            <div className={bundleClasses} style={{ width, height }}>
                {this.props.children ? (
                    this.props.children
                ) : this.props.name && Icons[this.props.name] ? (
                    <IconName width={width} height={height} fill={this.props.fill} />
                ) : null}
            </div>
        );
    }
}
