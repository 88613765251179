import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import { toggleMenu } from "../../store/actions/ui.actions";
import styles from "./Overlay.css";

const mapStateToProps = state => {
    const { ui, breakpoint } = state;
    return { ui, breakpoint };
};

const mapDispatchToProps = dispatch => bindActionCreators({ toggleMenu }, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
export default class Overlay extends Component {
    constructor() {
        super();
        this.navigationActive = this.navigationActive.bind(this);
        this.closeAll = this.closeAll.bind(this);
    }

    toggleMenu(menuId) {
        const { ui, toggleMenu } = this.props;
        const { isOpen } = ui[menuId];

        toggleMenu(!isOpen, menuId);
    }

    closeAll() {
        this.props.toggleMenu(false, "contextMenu");
        this.props.toggleMenu(false, "sidebar");
    }

    navigationActive() {
        const { ui } = this.props;
        let anyMenuActive = false;

        for (let key of Object.keys(ui)) {
            anyMenuActive = ui[key].isOpen;

            if (ui[key].isOpen) {
                break;
            }
        }

        return anyMenuActive;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakpoint.device !== this.props.breakpoint.device) {
            this.closeAll();
        }
    }

    render() {
        return (
            <div
                className={classNames(styles.overlay, {
                    [styles.active]: this.navigationActive()
                })}
                onClick={this.closeAll}
            >
                &nbsp;
            </div>
        );
    }
}
