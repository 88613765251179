import React from "react";
import { Link } from "react-router-dom";
import styles from "./Notification.css";

export interface NotificationLinkProps {
    to:
        | {
              pathname?: string;
              search?: string;
              hash?: string;
              protocolLink?: string;
          }
        | string;
    text: string;
    iconName?: string;
}

export const NotificationLink: React.FC<NotificationLinkProps> = React.memo(({ to, text, iconName }) => {
    return !(typeof to === "string") && to.protocolLink ? (
        <a href={to.protocolLink} className={styles.link}>
            {text}
        </a>
    ) : (
        <Link to={to} className={styles.link}>
            {text}
        </Link>
    );
});
