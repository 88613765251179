import { RestErrorResponse } from "@lucify/services";
import { isArray } from "./typeGuards";

type RequestData = Record<string, any> | Array<object | RequestData>;

// clean up keys starting with internal__ from server request preventing
export function cleanRequestData<Data extends RequestData>(data: Data): Data {
    if (isArray(data)) {
        return data.map(subtree => cleanRequestData(subtree)) as Data;
    } else if (data && data.constructor && data.constructor.name === "Object") {
        const res: RequestData = {};
        for (const subtreeKey in data) {
            if (data.hasOwnProperty(subtreeKey)) {
                if (subtreeKey.indexOf("internal__") !== 0) {
                    res[subtreeKey] = cleanRequestData(data[subtreeKey] as RequestData);
                }
            }
        }
        return res as Data;
    } else {
        return data as Data;
    }
}

type ErrorResponse = { field: string; defaultMessage: string };

export const createErrorObject = (err: RestErrorResponse) =>
    (err.response.data.errors as ErrorResponse[]).reduce<{ [key: string]: string }>(
        (acc, curr) => ({
            ...acc,
            [curr.field]: curr.defaultMessage
        }),
        {}
    );

/**
 * Filters an Object by an array of pathes (property1.property2)
 * or an array of objects with a property name which is a path
 * @param data
 * @param fields
 */
type Field = string | { name: string };

export const filterValuesByFieldsArray = (data: object, fields: Field[]) => {
    const values = {};

    const generateStructure = (fields: string[], obj: { [key: string]: any }, data: any) => {
        const field = fields.shift();

        if (field) {
            if (!data || !data.hasOwnProperty(field)) {
                return obj;
            }

            if (fields.length) {
                obj[field] = generateStructure(
                    fields,
                    obj.hasOwnProperty(field) && obj[field] ? obj[field] : {},
                    data[field]
                );
            } else {
                obj[field] = data[field];
            }
        }

        return obj;
    };

    fields.forEach(field => {
        const fieldName = typeof field === "string" ? field : field.name;
        const path = fieldName.split(".");
        generateStructure(path, values, data);
    });

    return values;
};

export const selectFilterByDataStr = ({ data }: { data: any }, searchstring: string) => {
    return !!~data.filterStr.toLowerCase().search(
        searchstring
            .replace(/[^a-zA-ZäÄöÖüÜß0-9\- ]/g, "")
            .toLowerCase()
            .split(" ")
            .map(str => `(?=.*${str})`)
            .join("")
    );
};

type Validator = (value: any) => string | undefined;

export const combineValidators =
    (...validators: Validator[]) =>
    (value: any) => {
        let error;
        for (const index in validators) {
            error = validators[index](value);
            if (error) {
                break;
            }
        }
        return error;
    };

export const required = (value: any) =>
    (typeof value === "string" && value.trim()) ||
    typeof value === "number" ||
    value instanceof File ||
    (Array.isArray(value) && value.length) ||
    (typeof value === "boolean" && value)
        ? undefined
        : "Required";
