import { Component } from "react";
import propTypes from "prop-types";
import { getUserCountry } from "../../utils/helpers/auth";

export const isFlagActive = flag => {
    try {
        const country = getUserCountry();
        const countryFeatureFlags = JSON.parse(process.env.FEATURE_FLAGS)[country];
        const featureFlag = countryFeatureFlags ? countryFeatureFlags[flag] : null;
        return featureFlag ? featureFlag.isActive : false;
    } catch (error) {
        return false;
    }
};

export default class Feature extends Component {
    static defaultProps = {
        off: false
    };

    static propTypes = {
        flags: propTypes.oneOfType([propTypes.string, propTypes.arrayOf(propTypes.string)]).isRequired,
        off: propTypes.bool
    };

    render() {
        const { flags, off, children } = this.props;
        const flagsNormalized = Array.isArray(flags) ? flags : [flags];
        const flagsOn = flagsNormalized.every(flag => isFlagActive(flag));

        return flagsOn ? (!off ? children : null) : off ? children : null;
    }
}
