import isPromise from "is-promise";
import { FluxStandardAction } from "redux-promise-middleware";

export default function errorMiddleware() {
    return next => (action: FluxStandardAction) => {
        // If not a promise, continue on
        if (!isPromise(action.payload)) {
            return next(action);
        }

        return next(action).catch(error => {
            return error?.name === "AbortError" ? error : Promise.reject(error);
        });
    };
}
