import React from "react";
import { Link } from "react-router-dom";
import { CountryCode } from "@lucify/types";
import { getContentCountry } from "../../utils/helpers/dev";
import styles from "./Footer.css";

interface FooterProps {
    match?: {
        params?: {
            country?: string;
        };
    };
}

const Footer = ({ match }: FooterProps) => {
    // todo fix workaround when router/switch to be localized / when clients were seperated
    const contentCountry = getContentCountry(match?.params?.country?.toUpperCase() as CountryCode);

    return (
        <div className={styles.footer}>
            <div className={styles.footerInner}>
                <nav className={styles.footerNav}>
                    <Link className={styles.footerNavLink} to={`/${contentCountry}/impressum`}>
                        Impressum
                    </Link>
                    <Link className={styles.footerNavLink} to={`/${contentCountry}/agb`}>
                        AGB
                    </Link>
                    <Link className={styles.footerNavLink} to={`/${contentCountry}/datenschutz`}>
                        Datenschutz
                    </Link>
                </nav>
            </div>

            {/* Warum hier? Wegen des Countrys und Kosten/Nutzen Faktor. TODO Lokalisierung der Routes/Pages o.ä. */}
            {/* Legal hat entschieden, dass wir zum aktuellen Zeitpunkt kein CookieBanner brauchen */}
            {/*<CookieBanner country={contentCountry} />*/}
        </div>
    );
};

export default Footer;
