import { LOGOUT } from "../actions/auth.actions";
import { GET_MARKETS_FULFILLED, GET_MARKETS_PENDING, GET_MARKETS_REJECTED } from "../actions/markets.actions";

const initialState = {
    list: [],
    failed: false,
    isFetching: false
};

export function markets(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;
        case GET_MARKETS_PENDING:
            return {
                ...state,
                failed: false,
                isFetching: true
            };
        case GET_MARKETS_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    isFetching: false,
                    failed: true,
                    status: action.payload.response.status
                };
            }
        case GET_MARKETS_FULFILLED:
            return {
                ...state,
                isFetching: false,
                failed: false,
                status: action.payload.status,
                list: action.payload.data
            };
        default:
            return state;
    }
}
