import { INotification } from "../../interfaces/Notification";
import { LOGOUT } from "../actions/auth.actions";
import {
    DELETE_ALL_NOTIFICATION,
    DELETE_NOTIFICATION,
    DISMISS_NOTIFICATION,
    DISMISS_TARGETAREA_NOTIFICATIONS,
    PUSH_NOTIFICATION,
    UPDATE_NOTIFICATION
} from "../actions/notifications.actions";

interface NotificationsState {
    list: INotification[];
}

export const initialState: NotificationsState = {
    list: []
};

export function notifications(state = initialState, action) {
    switch (action.type) {
        case DELETE_ALL_NOTIFICATION:
        case LOGOUT:
            return initialState;

        case PUSH_NOTIFICATION:
            return {
                list: [...state.list, action.payload]
            };

        case UPDATE_NOTIFICATION:
            return {
                list: [...state.list.filter(notification => notification.id !== action.meta.id), action.payload]
            };

        case DELETE_NOTIFICATION:
            return {
                list: [...state.list.filter(notification => notification.id !== action.meta.id)]
            };

        case DISMISS_NOTIFICATION:
            return {
                list: [
                    ...state.list.map(notification => ({
                        ...notification,
                        dismissed: notification.id === action.meta.id ? true : notification.dismissed
                    }))
                ]
            };

        case DISMISS_TARGETAREA_NOTIFICATIONS:
            return {
                list: [
                    ...state.list.map(notification => ({
                        ...notification,
                        dismissed: notification.targetArea === action.meta.targetArea ? true : notification.dismissed
                    }))
                ]
            };

        default:
            return state;
    }
}
