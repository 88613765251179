import React, { useEffect } from "react";

const IGNORE_THESE_TARGETS = ["INPUT", "TEXTAREA"];

type KeyMapFunction = (event: KeyboardEvent) => void;

type KeyMapObject = {
    fn?: KeyMapFunction;
    blocked?: boolean;
    // Array of CSS selectors. E.g. "input" or ".input"
    ignoreTargets: string[];
};

export interface KeyMap {
    [key: string]: { keys: string[]; ignoreTargets?: string[] };
}

const keyMap = new Map<string, Map<string, KeyMapObject>>();
let keyMapConfig: KeyMap | undefined;

(global as any)._keyMap = keyMap;

const setKeyMapConfig = (newKeyMapConfig: KeyMap) => {
    keyMapConfig = newKeyMapConfig;
};

const registerKeyMapping = (name: string, fn: KeyMapFunction) => {
    if (!keyMapConfig?.[name]) {
        console.warn(!keyMapConfig ? `KeyMap does not exist.` : `There is no ShortCut for "${name}" in the KeyMap.`);

        return;
    }

    const internalKey = keyMapConfig[name].keys.sort().toString();
    const ignoreTargets = keyMapConfig[name].ignoreTargets || IGNORE_THESE_TARGETS;

    if (!keyMap.has(internalKey)) {
        keyMap.set(internalKey, new Map());
    }

    keyMap.get(internalKey)!.set(name, { fn, ignoreTargets });
};

const unregisterKeyMapping = (name: string) => {
    if (!keyMapConfig?.[name]) {
        return;
    }

    const internalKey = keyMapConfig[name].keys.sort().toString();

    if (keyMap.has(internalKey) && keyMap.get(internalKey)?.has(name)) {
        keyMap.get(internalKey)?.delete(name);
    }
};

const blockKeyMapping = (name: string) => {
    if (!keyMapConfig?.[name]) {
        return;
    }

    const internalKey = keyMapConfig[name].keys.sort().toString();

    if (keyMap.has(internalKey) && keyMap.get(internalKey)!.has(name)) {
        keyMap.get(internalKey)!.get(name)!.blocked = true;
    }
};

const unblockKeyMapping = (name: string) => {
    if (!keyMapConfig?.[name]) {
        return;
    }

    const internalKey = keyMapConfig[name].keys.sort().toString();

    if (keyMap.has(internalKey) && keyMap.get(internalKey)!.has(name)) {
        delete keyMap.get(internalKey)!.get(name)!.blocked;
    }
};

let pressedKeys: string[] = [];

document.addEventListener("keydown", (e: KeyboardEvent) => {
    if (!pressedKeys.includes(e.key)) {
        pressedKeys.push(e.key);
    }

    const eventTarget = e.target as HTMLElement;

    const internalKey = pressedKeys.sort().toString();

    if (keyMap.has(internalKey)) {
        keyMap.get(internalKey)!.forEach((key: KeyMapObject) => {
            const fn = key.fn;
            const blocked = key.blocked;

            if (!key.ignoreTargets.some(ignoreTarget => eventTarget.matches(ignoreTarget))) {
                !blocked && !!fn && fn(e);
            }
        });
    }
});

document.addEventListener("keyup", () => {
    pressedKeys = [];
});

window.addEventListener("blur", () => {
    pressedKeys = [];
});

export const keymapManager = {
    registerKeyMapping,
    unregisterKeyMapping,
    setKeyMapConfig
};

interface ShortCutProps {
    name: string;
    fn: KeyMapFunction;
}

export const ShortCut: React.FC<ShortCutProps> = props => {
    const { name, fn } = props;

    useEffect(() => {
        registerKeyMapping(name, fn);

        return () => unregisterKeyMapping(name);
    }, [name, fn]);

    return null;
};

interface BlockShortCutProps {
    name: string;
}

export const BlockShortCut: React.FC<BlockShortCutProps> = props => {
    const { name } = props;

    useEffect(() => {
        blockKeyMapping(name);

        return () => unblockKeyMapping(name);
    }, [name]);

    return null;
};
