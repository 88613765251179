import * as React from "react";
import {
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    MergeProps,
    Options,
    connect as originalConnect
} from "react-redux";
import { IApplicationState } from "../interfaces/ApplicationState";

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/9951
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/9951
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/9951

export interface InferableComponentEnhancerWithProps<TInjectedProps, TNeedsProps> {
    <TComponent extends React.ComponentType<TInjectedProps & TNeedsProps>>(component: TComponent): TComponent;
}

interface TypedConnect {
    <TStateProps = {}, TDispatchProps = {}, TOwnProps = {}>(
        mapStateToProps?: MapStateToPropsParam<TStateProps, TOwnProps, IApplicationState>,
        mapDispatchToProps?: MapDispatchToPropsParam<TDispatchProps, TOwnProps>
    ): InferableComponentEnhancerWithProps<TStateProps & TDispatchProps, TOwnProps>;

    <TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, TMergedProps = {}>(
        mapStateToProps?: MapStateToPropsParam<TStateProps, TOwnProps, IApplicationState>,
        mapDispatchToProps?: MapDispatchToPropsParam<TDispatchProps, TOwnProps>,
        mergeProps?: MergeProps<TStateProps, TDispatchProps, TOwnProps, TMergedProps>,
        options?: Options<TStateProps, TOwnProps, TMergedProps>
    ): InferableComponentEnhancerWithProps<TMergedProps, TOwnProps>;
}

export const connect = originalConnect as TypedConnect;
