import React from "react";
import classNames from "classnames";
import { INotification } from "../../interfaces/Notification";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import styles from "./Notification.css";
import { NotificationLink } from "./NotificationLink";

export interface NotificationProps
    extends Pick<INotification, "id" | "title" | "message" | "iconName" | "type" | "link" | "persist"> {
    variant?: NotificationVariant;
    type?: NotificationType;
    onClose?: (SyntheticEvent) => void;
    inList?: boolean;
}

export enum NotificationVariant {
    hint = "hint",
    banner = "banner",
    modal = "modal"
}

export enum NotificationType {
    default = "default",
    special = "special",

    // legacy types, not removable for now
    neutral = "neutral",
    info = "info",
    success = "success",
    warning = "warning",
    error = "error"
}

export const Notification: React.FC<NotificationProps> = React.memo(
    ({ id, title, message, iconName, type, link, persist, variant, onClose, inList }) => {
        const isClosable = typeof onClose === "function";

        const handleOnClick = () => {
            if (typeof onClose === "function") {
                onClose(id);
            }
        };

        return (
            <div
                className={classNames(styles.notification, {
                    [styles[variant!]]: variant,
                    [styles[type!]]: type,
                    [styles.inList]: inList,
                    [styles.isClosable]: isClosable
                })}
            >
                <div className={styles.notificationInner}>
                    {iconName ? <Icon className={styles.icon} name={iconName} /> : null}

                    <div className={styles.content}>
                        {title ? <h2 className={styles.title}>{title}</h2> : null}
                        <p className={styles.message}>{message}</p>
                        {link ? <NotificationLink {...link} /> : null}
                    </div>

                    {isClosable ? (
                        <Button
                            square
                            variant={"none"}
                            onClick={handleOnClick}
                            className={styles.closeIcon}
                            icon={"IconClose"}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
);

Notification.defaultProps = {
    type: NotificationType.default
};
