const errorMessage = "Access to Cookies not allowed";

function setCookie(name: string, value: string | number, secure: boolean = true, days?: number) {
    const cookie = [`${name}=${value}`, "path=/"];
    const locationSecure = window.location.protocol === "https:";

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        cookie.push("expires=" + date.toUTCString());
    }

    if (secure && locationSecure) {
        cookie.push("Secure");
    }

    cookie.push("SameSite=Strict");

    document.cookie = cookie.join("; ");
}

function getCookie(name: string) {
    const cookies = document.cookie.split("; ");
    const [cookieValue] = cookies.find(cookie => cookie.startsWith(`${name}=`))?.split("; ") || [];

    return cookieValue;
}

function deleteCookie(name: string) {
    setCookie(name, "", true, -1);
}

let errorHandler = (error: any, message: string = errorMessage) => {
    console.log("Message: ", message, "Response: ", error);

    if (process.env.NODE_ENV === "development" && (error instanceof TypeError || error instanceof ReferenceError)) {
        throw error;
    }
};

export const setCookiesErrorHandler = (f: (error: any, message?: string) => void) => {
    errorHandler = f;
};

export const cookiesWrapper = {
    setCookie(name: string, value: string | number, secure: boolean = true, days?: number) {
        try {
            setCookie(name, value, secure, days);
        } catch (error) {
            errorHandler(error);
        }
    },
    getCookie(name: string) {
        try {
            return getCookie(name);
        } catch (error) {
            errorHandler(error);
        }
    },
    deleteCookie(name: string) {
        try {
            return deleteCookie(name);
        } catch (error) {
            errorHandler(error);
        }
    }
};
