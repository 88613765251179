import { FluxStandardAction } from "redux-promise-middleware";
import { RestSuccessResponse } from "@lucify/services";
import { IComment, IKoordinator } from "@lucify/types";
import { ICraftsmenState } from "../../interfaces/ApplicationState";
import { renderGravatarSrcURL } from "../../utils/helpers/gravatar";
import { getPagination } from "../../utils/helpers/list";
import { LOGOUT } from "../actions/auth.actions";
import {
    ADD_CRAFTSMAN_COMMENT_FULFILLED,
    ADD_CRAFTSMAN_COMMENT_PENDING,
    ADD_CRAFTSMAN_COMMENT_REJECTED,
    DELETE_CRAFTSMAN_COMMENT_FULFILLED,
    DELETE_CRAFTSMAN_COMMENT_PENDING,
    DELETE_CRAFTSMAN_COMMENT_REJECTED,
    GET_COORDINATION_CRAFTSMEN_FULFILLED,
    GET_CRAFTSMAN_COMMENTS_FULFILLED,
    GET_CRAFTSMAN_COMMENTS_PENDING,
    GET_CRAFTSMAN_COMMENTS_REJECTED,
    GET_CRAFTSMAN_PRICECLUSTERS_FULFILLED,
    GET_CRAFTSMAN_PRICECLUSTERS_PENDING,
    GET_CRAFTSMAN_PRICECLUSTERS_REJECTED,
    GET_CRAFTSMAN_PROFILE_FULFILLED,
    GET_CRAFTSMAN_PROFILE_PENDING,
    GET_CRAFTSMAN_PROFILE_REJECTED,
    GET_CRAFTSMAN_STATS_FULFILLED,
    GET_CRAFTSMAN_STATS_PENDING,
    GET_CRAFTSMAN_STATS_REJECTED,
    GET_CRAFTSMEN_FULFILLED,
    GET_CRAFTSMEN_PENDING,
    GET_CRAFTSMEN_REJECTED,
    GET_HANDWERKER_BEWERTUNGEN_FULFILLED,
    GET_HANDWERKER_BEWERTUNGEN_PENDING,
    GET_HANDWERKER_BEWERTUNGEN_REJECTED,
    GET_HKO_LIST_FULFILLED,
    GET_HKO_LIST_PENDING,
    GET_HKO_LIST_REJECTED,
    REQUEST_CRAFTSMAN_FULFILLED,
    REQUEST_CRAFTSMAN_PENDING,
    REQUEST_CRAFTSMAN_REJECTED,
    RESPONSE_CRAFTSMAN_FULFILLED,
    RESPONSE_CRAFTSMAN_PENDING,
    RESPONSE_CRAFTSMAN_REJECTED,
    UPDATE_CRAFTSMAN_PROFILE_FULFILLED,
    UPDATE_CRAFTSMAN_PROFILE_PENDING,
    UPDATE_CRAFTSMAN_PROFILE_REJECTED
} from "../actions/craftsmen.actions";
import { createCraftsmanProfileFromFacade, createCraftsmanStatsFromFacade } from "./facades/craftsman.facades";

const initialState: ICraftsmenState = {
    craftsmenList: {
        data: [],
        pagination: {
            totalCount: 0,
            totalPages: 1,
            pageNumber: 1,
            firstPage: true,
            lastPage: false
        },
        failed: false,
        isFetching: false
    },
    craftsmenInfo: {
        failed: false,
        isFetching: false,
        fetchingItems: []
    },
    craftsmenStats: {
        failed: false,
        isFetching: false,
        fetchingItems: []
    },
    craftsmenRequests: {
        failed: false,
        isFetching: false
    },
    craftsmenResponses: {
        failed: false,
        isFetching: false
    },
    craftsmenComments: {
        isFetching: false,
        failed: false
    },
    coordinationCraftsmenInfo: {
        isFetching: false,
        failed: false
    },
    craftsmenHKOs: {
        isFetching: false,
        failed: false,
        list: []
    },
    craftsmenBewertungen: {
        failed: false,
        isFetching: false,
        fetchingItems: []
    },
    craftsmenPriceclusters: {
        failed: false,
        isFetching: false,
        list: []
    }
};

export function craftsmen(state = initialState, action: FluxStandardAction) {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        // GET_CRAFTSMEN
        case GET_CRAFTSMEN_PENDING:
            return {
                ...state,
                craftsmenList: {
                    ...state.craftsmenList,
                    isFetching: true,
                    pagination: {
                        ...state.craftsmenList.pagination,
                        pageNumber: action.meta.page || 1
                    }
                }
            };

        case GET_CRAFTSMEN_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmenList: {
                        ...state.craftsmenList,
                        isFetching: false,
                        failed: true,
                        status: action.payload.response.status,
                        pagination: {
                            ...state.craftsmenList.pagination,
                            pageNumber: action.meta.page || 1
                        }
                    }
                };
            }

        case GET_CRAFTSMEN_FULFILLED:
            return {
                ...state,
                craftsmenList: {
                    data: action.payload.data,
                    pagination: getPagination(action),
                    isFetching: false,
                    failed: false,
                    status: action.payload.status
                }
            };

        // GET_CRAFTSMAN_STATS
        case GET_CRAFTSMAN_STATS_PENDING:
            return {
                ...state,
                craftsmenStats: {
                    ...state.craftsmenStats,
                    isFetching: true,
                    fetchingItems: [...state.craftsmenStats.fetchingItems, action.meta.id]
                }
            };

        case GET_CRAFTSMAN_STATS_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmenStats: {
                        ...state.craftsmenStats,
                        isFetching: false,
                        failed: true,
                        status: action.payload.response.status,
                        fetchingItems: [...state.craftsmenStats.fetchingItems.filter(item => item !== action.meta.id)]
                    }
                };
            }

        case GET_CRAFTSMAN_STATS_FULFILLED:
            return {
                ...state,
                craftsmenStats: {
                    ...state.craftsmenStats,
                    isFetching: false,
                    failed: false,
                    status: action.payload.status,
                    [action.meta.id]: createCraftsmanStatsFromFacade(action.payload.data),
                    fetchingItems: [...state.craftsmenStats.fetchingItems.filter(item => item !== action.meta.id)]
                }
            };

        // GET_CRAFTSMAN_PROFILE
        case GET_CRAFTSMAN_PROFILE_PENDING:
            return {
                ...state,
                craftsmenInfo: {
                    ...state.craftsmenInfo,
                    isFetching: true,
                    fetchingItems: [...state.craftsmenInfo.fetchingItems, action.meta.id]
                }
            };

        case GET_CRAFTSMAN_PROFILE_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmenInfo: {
                        ...state.craftsmenInfo,
                        isFetching: false,
                        failed: true,
                        status: action.payload.response.status,
                        fetchingItems: [...state.craftsmenInfo.fetchingItems.filter(item => item !== action.meta.id)]
                    }
                };
            }

        case GET_CRAFTSMAN_PROFILE_FULFILLED:
            return {
                ...state,
                craftsmenInfo: {
                    ...state.craftsmenInfo,
                    isFetching: false,
                    failed: false,
                    status: action.payload.status,
                    [action.meta.id]: createCraftsmanProfileFromFacade(action.payload.data),
                    fetchingItems: [...state.craftsmenInfo.fetchingItems.filter(item => item !== action.meta.id)]
                }
            };

        // REQUEST_CRAFTSMEN
        case REQUEST_CRAFTSMAN_PENDING:
            return {
                ...state,
                craftsmenRequests: {
                    ...state.craftsmenRequests,
                    isFetching: true
                }
            };

        case REQUEST_CRAFTSMAN_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmenRequests: {
                        isFetching: false,
                        failed: true,
                        status: action.payload.response.status
                    }
                };
            }

        case REQUEST_CRAFTSMAN_FULFILLED:
            return {
                ...state,
                craftsmenRequests: {
                    isFetching: false,
                    failed: false,
                    status: action.payload.status
                }
            };

        // RESPONSE_CRAFTSMEN
        case RESPONSE_CRAFTSMAN_PENDING:
            return {
                ...state,
                craftsmenResponses: {
                    ...state.craftsmenResponses,
                    isFetching: true
                }
            };

        case RESPONSE_CRAFTSMAN_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmenResponses: {
                        isFetching: false,
                        failed: true,
                        status: action.payload.response.status
                    }
                };
            }

        case RESPONSE_CRAFTSMAN_FULFILLED:
            return {
                ...state,
                craftsmenResponses: {
                    isFetching: false,
                    failed: false,
                    status: action.payload.status
                }
            };

        case UPDATE_CRAFTSMAN_PROFILE_PENDING:
            return {
                ...state,
                craftsmenInfo: {
                    ...state.craftsmenInfo,
                    isFetching: true
                }
            };

        case UPDATE_CRAFTSMAN_PROFILE_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmenInfo: {
                        ...state.craftsmenInfo,
                        status: action.payload.response.status,
                        isFetching: false,
                        failed: true
                    }
                };
            }

        case UPDATE_CRAFTSMAN_PROFILE_FULFILLED:
            return {
                ...state,
                craftsmenInfo: {
                    ...state.craftsmenInfo,
                    status: action.payload.status,
                    isFetching: false,
                    failed: false,
                    [action.meta.craftsmanId]: createCraftsmanProfileFromFacade(action.payload.data)
                }
            };

        case GET_CRAFTSMAN_COMMENTS_PENDING:
        case ADD_CRAFTSMAN_COMMENT_PENDING:
        case DELETE_CRAFTSMAN_COMMENT_PENDING:
            return {
                ...state,
                craftsmenComments: {
                    ...state.craftsmenComments,
                    isFetching: true,
                    failed: false
                }
            };

        case GET_CRAFTSMAN_COMMENTS_REJECTED:
        case ADD_CRAFTSMAN_COMMENT_REJECTED:
        case DELETE_CRAFTSMAN_COMMENT_REJECTED:
            return {
                ...state,
                craftsmenComments: {
                    ...state.craftsmenComments,
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true
                }
            };

        case GET_CRAFTSMAN_COMMENTS_FULFILLED:
            return {
                ...state,
                craftsmenComments: {
                    ...state.craftsmenComments,
                    status: action.payload.status,
                    isFetching: false,
                    failed: false,
                    [action.meta.craftsmanId]: action.payload.data
                }
            };

        case ADD_CRAFTSMAN_COMMENT_FULFILLED:
            return {
                ...state,
                craftsmenComments: {
                    ...state.craftsmenComments,
                    status: action.payload.status,
                    isFetching: false,
                    failed: false,
                    [action.meta.craftsmanId]: [
                        action.payload.data,
                        ...(state.craftsmenComments[action.meta.craftsmanId]
                            ? (state.craftsmenComments[action.meta.craftsmanId] as IComment[])
                            : [])
                    ]
                }
            };

        case DELETE_CRAFTSMAN_COMMENT_FULFILLED:
            const list = (state.craftsmenComments?.[action.meta.craftsmanId] as IComment[]) || [];
            const item = list.find(comment => comment.id === action.meta.commentId);
            if (item) {
                const index = list.indexOf(item);
                list.splice(index, 1);
            }

            return {
                ...state,
                craftsmenComments: {
                    ...state.craftsmenComments,
                    status: action.payload.status,
                    isFetching: false,
                    failed: false,
                    [action.meta.craftsmanId]: list
                }
            };

        case GET_COORDINATION_CRAFTSMEN_FULFILLED:
            return {
                ...state,
                coordinationCraftsmenInfo: {
                    ...state.coordinationCraftsmenInfo,
                    ...action.payload.data.reduce((acc, curr) => {
                        acc[curr.id] = curr;
                        return acc;
                    }, {})
                }
            };

        case GET_HKO_LIST_PENDING:
            return {
                ...state,
                craftsmenHKOs: {
                    ...state.craftsmenHKOs,
                    isFetching: true
                }
            };

        case GET_HKO_LIST_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmenHKOs: {
                        ...state.craftsmenHKOs,
                        status: action.payload.response.status,
                        isFetching: false,
                        failed: true
                    }
                };
            }

        case GET_HKO_LIST_FULFILLED:
            return {
                ...state,
                craftsmenHKOs: {
                    list: (action.payload as RestSuccessResponse<IKoordinator[]>).data.map(hko => ({
                        ...hko,
                        image: {
                            src: renderGravatarSrcURL(hko.email),
                            alt: ""
                        }
                    })),
                    status: action.payload.status,
                    isFetching: false,
                    failed: false
                }
            };

        case GET_HANDWERKER_BEWERTUNGEN_PENDING:
            return {
                ...state,
                craftsmenBewertungen: {
                    ...state.craftsmenBewertungen,
                    isFetching: true,
                    fetchingItems: [...state.craftsmenBewertungen.fetchingItems, action.meta.craftsmanId]
                }
            };

        case GET_HANDWERKER_BEWERTUNGEN_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmenBewertungen: {
                        ...state.craftsmenBewertungen,
                        isFetching: false,
                        failed: true,
                        status: action.payload.response.status,
                        fetchingItems: [
                            ...state.craftsmenBewertungen.fetchingItems.filter(item => item !== action.meta.craftsmanId)
                        ]
                    }
                };
            }

        case GET_HANDWERKER_BEWERTUNGEN_FULFILLED:
            return {
                ...state,
                craftsmenBewertungen: {
                    ...state.craftsmenBewertungen,
                    isFetching: false,
                    failed: false,
                    status: action.payload.status,
                    [action.meta.craftsmanId]: { pagination: getPagination(action), data: action.payload.data },
                    fetchingItems: [
                        ...state.craftsmenBewertungen.fetchingItems.filter(item => item !== action.meta.craftsmanId)
                    ]
                }
            };

        case GET_CRAFTSMAN_PRICECLUSTERS_PENDING:
            return {
                ...state,
                craftsmenPriceclusters: {
                    ...state.craftsmenPriceclusters,
                    isFetching: true
                }
            };

        case GET_CRAFTSMAN_PRICECLUSTERS_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    craftsmenPriceclusters: {
                        ...state.craftsmenPriceclusters,
                        status: action.payload.response.status,
                        isFetching: false,
                        failed: true
                    }
                };
            }

        case GET_CRAFTSMAN_PRICECLUSTERS_FULFILLED:
            return {
                ...state,
                craftsmenPriceclusters: {
                    list: action.payload.data,
                    status: action.payload.status,
                    isFetching: false,
                    failed: false
                }
            };

        default:
            return state;
    }
}
