export enum CustomerOfferState {
    Entwurf = "Entwurf",
    Festgeschrieben = "Festgeschrieben",
    Abgelehnt = "Abgelehnt",
    BeauftragungErstellt = "BeauftragungErstellt",
    Teilbeauftragt = "Teilbeauftragt",
    Beauftragt = "Beauftragt",
    Abrechenbar = "Abrechenbar",
    Abgerechnet = "Abgerechnet",
    Geloescht = "Geloescht",
    PauschalAbgerechnet = "PauschalAbgerechnet"
}

export enum CustomerOfferEvent {
    bearbeiten = "bearbeiten",
    leistungsHandwerkerBearbeiten = "leistungsHandwerkerBearbeiten",
    loeschen = "loeschen",
    festschreiben = "festschreiben",
    ablehnen = "ablehnen",
    reaktivieren = "reaktivieren",
    beauftragungErzeugen = "beauftragungErzeugen",
    beauftragungSenden = "beauftragungSenden",
    beauftragungLoeschen = "beauftragungLoeschen",
    beauftragungStornieren = "beauftragungStornieren",
    leistungsNachweisVeraendern = "leistungsNachweisVeraendern",
    abrechnen = "abrechnen",
    abrechnungStornieren = "abrechnungStornieren"
}
