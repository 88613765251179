import { uuid } from "@lucify/utils";
import { INotification, NotificationTargetArea } from "../../interfaces/Notification";

export const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";

export function pushNotification(partialNotification: Partial<INotification>) {
    const id = uuid();
    const createdAt = Date.now();
    const lastModified = createdAt;
    const notification = {
        id,
        createdAt,
        lastModified,
        targetArea: partialNotification.targetArea ? partialNotification.targetArea : NotificationTargetArea.topRight,
        ...partialNotification
    };
    return {
        type: PUSH_NOTIFICATION,
        payload: notification,
        meta: {
            id: notification.id
        }
    };
}

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export function updateNotification(id, notification: Notification) {
    const lastModified = Date.now();
    return {
        type: UPDATE_NOTIFICATION,
        payload: {
            ...notification,
            lastModified
        },
        meta: {
            id
        }
    };
}

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export function deleteNotification(id) {
    return {
        type: DELETE_NOTIFICATION,
        meta: {
            id
        }
    };
}

export const DELETE_ALL_NOTIFICATION = "DELETE_ALL_NOTIFICATION";

export function deleteAllNotification() {
    return {
        type: DELETE_ALL_NOTIFICATION
    };
}

export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export function dismissNotification(id) {
    return {
        type: DISMISS_NOTIFICATION,
        meta: {
            id
        }
    };
}

export const DISMISS_TARGETAREA_NOTIFICATIONS = "DISMISS_TARGETAREA_NOTIFICATIONS";

export function dismissTargetAreaNotifications(targetArea: NotificationTargetArea) {
    return {
        type: DISMISS_TARGETAREA_NOTIFICATIONS,
        meta: {
            targetArea
        }
    };
}
