import { createContext } from "react";
import { KeycloakPromise } from "keycloak-js";
import { IAuthContext } from "./types";

export const initalAuthContext: IAuthContext = {
    initialized: false,
    authenticated: false,
    isTokenExpired: true,
    isPending: false,

    user: {
        countrySupport: false,
        roles: []
    },

    login() {
        return {} as KeycloakPromise<void, void>;
    },

    logout() {
        return {} as KeycloakPromise<void, void>;
    }
};

export const authContext = createContext<IAuthContext>(initalAuthContext);

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
