import { CountryCode } from "@lucify/types";
import { LucifyEnvironment } from "@lucify/types";

export const getEnvironment = () => {
    switch (window.location.hostname) {
        case "localhost":
            return LucifyEnvironment.LOCAL;
        case "lucify-client-development.stage-next.obi-solution.net":
            return LucifyEnvironment.DEV;
        case "lucify-client-test.stage-next.obi-solution.net":
            return LucifyEnvironment.TEST;
        case "lucify-client.stage-next.obi-solution.net":
            return LucifyEnvironment.STAGE;
        default:
            return LucifyEnvironment.PROD;
    }
};

export const getTopLevelDomainCountry = (): CountryCode => {
    switch (window.location.hostname) {
        case "machbar-macher.at":
            return CountryCode.at;
        default:
            return CountryCode.de;
    }
};

export function getContentCountry(paramCountry?: CountryCode): CountryCode {
    let contentCountry = CountryCode.de;
    const domainCountry = getTopLevelDomainCountry();

    if (paramCountry) {
        contentCountry = paramCountry;
    } else if (domainCountry) {
        contentCountry = domainCountry;
    }

    return contentCountry;
}
