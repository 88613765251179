import React, { Component } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import getBreakPoints from "../../utils/redux-breakpoint/breakpointCalculator";
import styles from "./Tooltip.css";

const tooltipRoot = document.getElementById("tooltip-root");

class TooltipPortal extends Component {
    constructor(props) {
        super(props);
        this.element = document.createElement("div");
    }

    componentDidMount() {
        tooltipRoot.appendChild(this.element);
    }

    componentWillUnmount() {
        tooltipRoot.removeChild(this.element);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.element);
    }
}

export default class Tooltip extends Component {
    anchorRef = React.createRef();
    tooltipRef = React.createRef();

    state = {
        open: false,
        topBubble: 0,
        topArrow: 0,
        leftBubble: 0,
        leftArrow: 0
    };

    static defaultProps = {
        text: "",
        layout: "inlineBlockSub"
    };

    static propTypes = {
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.open && prevProps.text !== this.props.text) {
            this.openTooltip();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.closeTooltip);
    }

    @autobind
    openTooltip() {
        const anchorRect = this.anchorRef.current.getBoundingClientRect();
        const tooltipRect = this.tooltipRef.current.getBoundingClientRect();
        const padding = getBreakPoints().device === "mobile" ? 8 : 12;

        // Prevent Tooltip from opening when it's not inside the visible area
        if (anchorRect.left < padding || anchorRect.right > window.innerWidth - padding) {
            return;
        }

        const scrollY = typeof window.scrollY === "undefined" ? window.pageYOffset : window.scrollY;

        this.setState({
            topBubble: Math.round(anchorRect.top - tooltipRect.height + scrollY),
            topArrow: Math.round(anchorRect.top + scrollY),
            leftBubble: Math.min(
                Math.max(padding, Math.round(anchorRect.right - tooltipRect.width)),
                Math.round(window.innerWidth - tooltipRect.width - padding)
            ),
            leftArrow: Math.max(padding, Math.round(anchorRect.left + anchorRect.width / 2)),
            open: true
        });

        document.addEventListener("scroll", this.closeTooltip);
    }

    @autobind
    closeTooltip() {
        this.setState({
            open: false,
            topBubble: 0,
            topArrow: 0,
            leftBubble: 0,
            leftArrow: 0
        });

        document.removeEventListener("scroll", this.closeTooltip);
    }

    render() {
        const Portal = process.env.__CLIENT__ ? TooltipPortal : React.Fragment;

        return (
            <div
                ref={this.anchorRef}
                className={classNames(styles.anchor, this.props.className, {
                    [styles[this.props.layout]]: !!this.props.layout && !this.props.noLayout
                })}
                onMouseEnter={this.openTooltip}
                onMouseLeave={this.closeTooltip}
            >
                <Portal>
                    <div
                        ref={this.tooltipRef}
                        style={{
                            top: this.state.topBubble,
                            left: this.state.leftBubble
                        }}
                        className={classNames(styles.tooltip, {
                            [styles.open]: this.state.open
                        })}
                    >
                        {this.props.text}
                    </div>
                    <div
                        style={{
                            top: this.state.topArrow,
                            left: this.state.leftArrow
                        }}
                        className={classNames(styles.tooltipArrow, {
                            [styles.open]: this.state.open
                        })}
                    />
                </Portal>
                {this.props.children}
            </div>
        );
    }
}
