import originalClassNames from "classnames";

type ClassNameArgument =
    | string
    | undefined
    | null
    | {
          [key: string]: boolean | undefined | null;
      };

const blacklistedKeyNames = ["undefined"];

export function classNames(...classNameArguments: ClassNameArgument[]) {
    return originalClassNames(classNameArguments)
        .split(" ")
        .filter((className: string) => className && !blacklistedKeyNames.includes(className.toLowerCase()))
        .join(" ");
}
