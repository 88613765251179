import * as constants from "./constants.js";

export default function getBreakPoints() {
    let isExtraSmall = false;
    let isSmall = false;
    let isMedium = false;
    let isLarge = false;
    let isExtraLarge = false;
    let width = window.innerWidth;
    let device;

    if (width < constants.XS_UPPER) {
        isExtraSmall = true;
        device = constants.MOBILE;
    } else if (width < constants.SM_UPPER) {
        isSmall = true;
        device = constants.MOBILE;
    } else if (width < constants.MD_UPPER) {
        isMedium = true;
        device = constants.MOBILE;
    } else if (width < constants.LG_UPPER) {
        isLarge = true;
        device = constants.MOBILE;
    } else {
        isExtraLarge = true;
        device = constants.DESKTOP;
    }

    return {
        width,
        isExtraSmall,
        isSmall,
        isMedium,
        isLarge,
        isExtraLarge,
        device
    };
}
