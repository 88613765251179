import { UseMutationResult, UseQueryResult } from "react-query";
import { RestErrorResponse, RestSuccessResponse } from "@lucify/services";
import { isMutationResult, isQueryResult } from "./typeGuards";

export type StatusMessageType = "info" | "error" | "success" | "warning";

export interface StatusMessage {
    type: StatusMessageType;
    title: string;
    message?: string;
    correlationId?: string;
}

export type StatusMessageCreator = (response: Partial<RestSuccessResponse>) => StatusMessage;

export interface StatusCodeMapping {
    [key: string]: StatusMessage | StatusMessageCreator;
}

export const dynamicStatusMessage: StatusMessageCreator = response => ({
    type: response.data?.first || "error",
    title: (response.data?.first && response.data?.second) || "Es ist ein unerwarteter Fehler aufgetreten."
});

export const statusCodeMapping = {
    200: {
        type: "success",
        title: "Aktion wurde erfolgreich durchgeführt. (200)"
    },
    400: {
        type: "error",
        title: "Es ist ein Fehler aufgetreten. (400)"
    },
    401: {
        type: "error",
        title: "Es ist ein Fehler aufgetreten. (401)"
    },
    403: {
        type: "error",
        title: "Für diese Aktion liegen keine ausreichenden Berechtigungen vor. (403)"
    },
    404: {
        type: "error",
        title: "Der angefragte Inhalt konnte nicht gefunden werden. (404)"
    },
    409: {
        type: "error",
        title: "Aktion nicht zulässig (409)"
    },
    413: {
        type: "error",
        title: "Es können maximal 50 MB zeitgleich hochgeladen werden. (413)"
    },
    415: {
        type: "error",
        title: "Dateityp nicht zulässig (415)"
    },
    422: {
        type: "error",
        title: "Bitte überprüfen Sie Ihre Eingabe. (422)"
    },
    500: {
        type: "error",
        title: "Es ist ein Fehler aufgetreten. (500)"
    },
    503: {
        type: "error",
        title: "Dienst nicht erreichbar. (503)"
    },
    default: {
        type: "error",
        title: "Es ist ein unerwarteter Fehler aufgetreten."
    }
} as const;

export function getGenericMessage<T = any>(
    responseOrQuery?:
        | Partial<RestSuccessResponse<T>>
        | UseQueryResult<RestSuccessResponse, RestErrorResponse>
        | UseMutationResult<RestSuccessResponse, RestErrorResponse, any, any>,
    customMapping: StatusCodeMapping = {}
): StatusMessage | null {
    const mapping: StatusCodeMapping = {
        ...statusCodeMapping,
        ...customMapping
    };

    const response =
        isQueryResult(responseOrQuery) || isMutationResult(responseOrQuery)
            ? responseOrQuery.error?.response
            : responseOrQuery;

    const { status, headers } = response || {};
    const correlationId = headers?.get("x-correlation-id") || undefined;
    const messageOrCreator = response && status ? mapping[mapping.hasOwnProperty(status) ? status : "default"] : null;

    const statusMessage = response
        ? typeof messageOrCreator === "function"
            ? messageOrCreator(response)
            : messageOrCreator
        : null;

    if (response?.data?.error && statusMessage) {
        statusMessage.message = response.data.error;
    }

    return statusMessage ? { ...statusMessage, correlationId } : null;
}
