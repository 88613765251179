import { LOGOUT } from "../actions/auth.actions";
import {
    DELETE_PROJECT_CUSTOMEROFFER_PENDING,
    GET_PROJECT_CUSTOMEROFFERS_FULFILLED,
    GET_PROJECT_CUSTOMEROFFERS_PENDING,
    GET_PROJECT_CUSTOMEROFFERS_REJECTED,
    UPDATE_PROJECT_CUSTOMEROFFER_FULFILLED
} from "../actions/projects.actions";

const initialState = {
    failed: false,
    isFetching: false
};

export function projectsCustomerOffers(state = initialState, action) {
    let dataKey = "data";

    if (action.meta && action.meta.vermittlungId) {
        dataKey = [action.meta.vermittlungId];
    }

    switch (action.type) {
        case LOGOUT:
            return initialState;

        case GET_PROJECT_CUSTOMEROFFERS_PENDING:
            return {
                ...state,
                failed: false,
                isFetching: true
            };

        case GET_PROJECT_CUSTOMEROFFERS_REJECTED:
            return {
                ...state,
                failed: true,
                isFetching: false,
                status: action.payload.status
            };

        case GET_PROJECT_CUSTOMEROFFERS_FULFILLED:
            return {
                ...state,
                isFetching: false,
                failed: false,
                status: action.payload.status,
                [dataKey]: action.payload.data
            };

        case DELETE_PROJECT_CUSTOMEROFFER_PENDING:
            return {
                ...state,
                [dataKey]: state[dataKey].filter(offer => offer.id !== action.meta.customerOfferId)
            };

        case UPDATE_PROJECT_CUSTOMEROFFER_FULFILLED:
            return {
                ...state,
                [dataKey]: state[dataKey].map(offer =>
                    offer.id !== action.meta.customerOfferId ? offer : action.payload.data
                )
            };

        default:
            return state;
    }
}
