/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActionPayload {
    token: string;
    aktion: string;
}

export interface AdditionalAnswer {
    id: string;
    macherProjektTechnicalTaskId: string;
    /** @format int32 */
    listIndex: number;
    display: string;
    parameterName: string;
    values: Value[];
    type: DataType;
}

export interface AdditionalAnswerCreateDTO {
    display: string;
    parameterName: string;
    type: DataType;
    values: AnswerValueCreateDTO[];
}

export interface AdditionalAnswerCreationDTO {
    parameterName: string;
    display: string;
    values: AnswerValueCreationDTO[];
    type: DataType;
}

export interface AdditionalAnswerDTO {
    display: string;
    parameterName: string;
    type: DataType;
    values: AnswerValueDTO[];
}

export interface AdditionalAnswerPatchDTO {
    display: string;
    parameterName: string;
    type: DataType;
    values: AnswerValuePatchDTO[];
}

export interface AdditionalInformation {
    id: string;
    values: AdditionalInformationValue[];
    displayName: string;
    type: InputDataType;
}

export interface AdditionalInformationValue {
    value: string;
    label?: string;
}

export interface Address {
    country: string;
    state?: string;
    zip?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addition?: string;
    geoLocation: GeoLocation;
}

export interface AdressForCreationDTO {
    countryCode: string;
    plz: string;
    stadt: string;
    strasse: string;
    /**
     * @minLength 0
     * @maxLength 16
     */
    hausNummer?: string;
    adressZusatz?: string;
}

export interface AnswerValueCreateDTO {
    value: string;
    group?: string;
    label?: string;
}

export interface AnswerValueCreationDTO {
    value: string;
    group?: string;
    label?: string;
}

export interface AnswerValueDTO {
    value: string;
    group?: string;
    label?: string;
}

export interface AnswerValuePatchDTO {
    value: string;
    group?: string;
    label?: string;
}

export interface BedarfsermittlungDTO {
    tasks: TaskSelectionDTO[];
    projectAnswers?: AdditionalAnswerCreationDTO[];
}

export interface CommentForPostDTO {
    content?: string;
}

export interface Contact {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
}

export interface ContactDTO {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
}

export interface ContactDetails {
    phoneNumber: string;
}

export interface CraftsmanContact {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    mobileNumber?: string;
    faxNumber?: string;
}

export interface CraftsmanFacility {
    id: string;
    /** @format int32 */
    version: number;
    shortId: string;
    facilityName?: string;
    email?: string;
    contact?: CraftsmanContact;
    address: Address;
    status?: string;
    notes?: string;
    marketBlacklist: Market[];
    preferredCategories: ProjectCategory[];
    operationRoles: string[];
    /** @format int32 */
    radius?: number;
    ekPriceCluster?: string;
    /** @format date */
    zusammenarbeitSeit?: string;
    partnerTyp: string;
    handwerkerBewertungStatistik: HandwerkerBewertungStatistik;
    partnerApi?: string;
    tasks: Task[];
    automaticallyAssignLead: boolean;
    /** @format int32 */
    monthlyLeadCapacity?: number;
    companyId: string;
}

export interface CraftsmanFacilityMetricsDTO {
    /** @format date-time */
    lastRequestedDate?: string;
    /** @format int32 */
    acceptedRequestsCount?: number;
    /** @format int32 */
    activeAndAcceptedVorgangCount?: number;
    /** @format int32 */
    completedVorgangCount?: number;
    /** @format int32 */
    pendingRequestsCount?: number;
    /** @format int32 */
    declinedRequestsCount?: number;
    /** @format int32 */
    cancelledVorgangCount?: number;
    /** @format int32 */
    acceptedLeadsCount?: number;
    acceptedLeadsCountByTask: Record<string, number>;
}

export interface CraftsmanFacilityStatesDTO {
    state?: string;
    /** @format int32 */
    count?: number;
}

export interface CraftsmanFacilityWithStatsDTO {
    id: string;
    shortId: string;
    facilityName?: string;
    email?: string;
    /** @format int32 */
    version: number;
    contact?: CraftsmanContact;
    address?: Address;
    status?: string;
    notes?: string;
    /** @format int64 */
    acceptedRequestsCount?: number;
    /** @format date-time */
    lastRequestedDate?: string;
    internalRating?: string;
    marketBlacklist?: Market[];
    preferredCategories?: ProjectCategory[];
    operationRoles?: string[];
    /** @format int32 */
    radius?: number;
    /** @format date */
    zusammenarbeitSeit?: string;
    partnerTyp: string;
    handwerkerBewertungStatistik: HandwerkerBewertungStatistik;
    partnerApi?: string;
    tasks: Task[];
    /** @format int64 */
    acceptedLeadsCount?: number;
    /** @format int32 */
    monthlyLeadCapacity?: number;
    automaticallyAssignLead?: boolean;
    neu: boolean;
}

export interface CreatedObject {
    id: string;
}

export interface DSGVOAnonymousMassResponse {
    /** @format int32 */
    updatedProjects: number;
}

export interface DSGVOAnonymousResponse {
    projectId: string;
    message: string;
}

export enum DataType {
    SELECT = "SELECT",
    MULTI_SELECT = "MULTI_SELECT",
    INTEGER = "INTEGER",
    DECIMAL = "DECIMAL",
    STRING = "STRING",
    BOOLEAN = "BOOLEAN"
}

export enum DebitorStatus {
    DORMANT = "DORMANT",
    CREATED = "CREATED",
    UPDATED = "UPDATED",
    SYNCHRONIZED = "SYNCHRONIZED"
}

export interface DirectLeadProject {
    customerId: string;
    inquiryId: string;
    contactDetails: ContactDetails;
    constructionSiteAddress: Address;
    tasks: Task[];
    userDocumentsUploaded: boolean;
    additionalInformation?: AdditionalInformation[];
    userNote?: string;
    leadFunnelOrigin?: string;
}

export interface DocumentInfoDTO {
    displayName: string;
    documentTags: string[];
    origin: OriginType;
    vorgangId?: string;
}

export interface DocumentPatchRequest {
    displayName?: string;
    documentTags?: string[];
    deleted?: boolean;
}

export enum EigentumsVerhaeltnis {
    EIGENTUEMER = "EIGENTUEMER",
    MIETER_MIT_EINVERSTAENDNISERKLAERUNG = "MIETER_MIT_EINVERSTAENDNISERKLAERUNG",
    MIETER_OHNE_EINVERSTAENDNISERKLAERUNG = "MIETER_OHNE_EINVERSTAENDNISERKLAERUNG"
}

export interface ErrorDescription {
    error: string;
}

export interface ExternerKunde {
    baseCustomerId: string;
    kundenTyp: KundenTyp;
    ecomId?: string;
    heyOBIId?: string;
    heyOBICompanyId?: string;
    firmenname?: string;
}

export interface ExternerKundeDTO {
    baseCustomerId: string;
    ecomId?: string;
    heyOBIId?: string;
    kundenTyp: KundenTyp;
    heyOBICompanyId?: string;
    firmenname?: string;
}

export interface ExternerKundeForCreationDTO {
    baseCustomerId: string;
    heyOBIId?: string;
    ecomId?: string;
    kundenTyp: KundenTyp;
    heyOBICompanyId?: string;
    firmenname?: string;
}

export interface Feedback {
    id: string;
    feedbackChoices?: FeedbackChoice[];
    type?: string;
    /** @format date-time */
    createdAt: string;
}

export interface FeedbackChoice {
    id: string;
    question?: string;
    answer?: string;
}

export interface FeedbackChoiceDTO {
    id: string;
    question?: string;
    answer?: string;
}

export interface FeedbackDTO {
    id: string;
    /** @format date-time */
    createdAt: string;
    type?: string;
    feedbackChoices: FeedbackChoiceDTO[];
}

/** DTO for cancel */
export interface FeedbackResponseDTO {
    partnerRequestResponder?: ResponderDTO;
    /**
     * The feedbackReasons for the transition umsetzen
     * @example null
     */
    feedbackReasons?: Record<string, string>;
}

export interface FieldErrorDTO {
    errors: LucifyFieldError[];
}

/** DTO for completeWithGMV */
export interface GMVAndFeedbackResponseDTO {
    /**
     * The value of the gmv to be saved
     * @min 0
     * @exclusiveMin false
     * @example 500.75
     */
    gmv: number;
    /**
     * The feedbackReasons for the transition umsetzenMitGMV
     * @example null
     */
    feedbackReasons: Record<string, string>;
    partnerRequestResponder?: ResponderDTO;
    /** Optional DTO for onSiteDate */
    onSiteDate?: VorOrtTerminAbstimmenDTO;
}

export interface GeoLocation {
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    confidence: GeoLocationConfidence;
}

export enum GeoLocationConfidence {
    Pending = "pending",
    Unknown = "unknown",
    Invalid = "invalid",
    High = "high",
    Medium = "medium",
    Low = "low"
}

export interface GeoLocationDTO {
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    confidence: GeoLocationConfidence;
}

export interface GeoLocationListDTO {
    confidence: GeoLocationConfidence;
}

export interface HandwerkerAccessPayload {
    token: string;
    vorgangId: string;
    plz: string;
}

export interface HandwerkerAnfrageDTO {
    craftsmanFacilityId: string;
    manuallyMatched: boolean;
}

export interface HandwerkerBewertungStatistik {
    /** @format int32 */
    bewertungAnzahl: number;
    /** @format double */
    bewertungScore: number;
}

export interface HandwerkerDTO {
    facilityName?: string;
}

export interface HandwerkerRequestDTO {
    id: string;
    /** @format float */
    distanceToTargetInKm?: number;
    status?: PartnerRequestStatus;
    /** @format date-time */
    stateChangedAt?: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    lastModified?: string;
    /** @format int32 */
    version: number;
    manuallyMatched?: boolean;
    feedbacks: FeedbackDTO[];
    documentLinks: VorgangDocumentLinkDTO[];
    respondedBy?: Responder;
    craftsmanFacility: CraftsmanFacility;
}

export interface Id {
    countryCode: string;
    id: string;
}

export enum InputDataType {
    SELECT = "SELECT",
    MULTI_SELECT = "MULTI_SELECT",
    INTEGER = "INTEGER",
    DECIMAL = "DECIMAL",
    STRING = "STRING",
    BOOLEAN = "BOOLEAN"
}

export interface KundeListDTO {
    debitorId?: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
}

export enum KundenTyp {
    HeyOBI = "heyOBI",
    Markt = "Markt",
    HeyOBIB2B = "heyOBIB2B"
}

export interface LeadsByTaskAndMonth {
    taskId: string;
    displayName: string;
    /** @format int32 */
    year: number;
    /** @format int32 */
    month: number;
    /** @format int64 */
    count: number;
}

export interface LucifyFieldError {
    objectName: string;
    field: string;
    rejectedValue?: object;
    defaultMessage?: string;
}

export interface MacherKunde {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber: string;
    country?: string;
    zip?: string;
    city?: string;
    street?: string;
    /**
     * @minLength 0
     * @maxLength 16
     */
    houseNumber?: string;
    addressAddition?: string;
    debitorId?: string;
    debitorStatus: DebitorStatus;
}

export interface MacherKundeDTO {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    country?: string;
    zip?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addressAddition?: string;
    debitorId?: string;
    debitorStatus: string;
    phoneNumber: string;
}

export interface MacherKundeForCreationDTO {
    vorname: string;
    nachname: string;
    anrede: string;
    email: string;
    telefonNummer: string;
    adresse: AdressForCreationDTO;
}

export interface MacherKundePatchDTO {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    country?: string;
    zip?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addressAddition?: string;
}

export interface MacherProjekt {
    id: string;
    /** @format int32 */
    version: number;
    projectId: string;
    state?: MacherProjektFsmState;
    categoryKey: ProjectCategory;
    projectDescription?: string;
    /** @format float */
    totalDurationInHours?: number;
    /** @format float */
    totalCost?: number;
    customer?: MacherKunde;
    externerKunde?: ExternerKunde;
    street?: string;
    /**
     * @minLength 0
     * @maxLength 16
     */
    houseNumber?: string;
    zip?: string;
    country: string;
    city?: string;
    addressAddition?: string;
    origin: Origin;
    geoLocation: GeoLocation;
    /** @format date-time */
    earliestDeliveryDate?: string;
    /** @format date-time */
    projectStartDate?: string;
    /** @format date-time */
    projectEndDate?: string;
    /** @format date-time */
    projectCreatedAt: string;
    /** @format date-time */
    inquiryCreatedAt?: string;
    market: Market;
    referenceNumberB3?: string;
    projektKoordinator?: ProjektKoordinator;
    possibleDoubletProjectId?: string;
    notiz?: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    lastModified: string;
    /** @format int32 */
    budgetRahmen?: number;
    stockwerk?: string;
    fahrstuhl?: boolean;
    parkplatz?: boolean;
    entsorgungAltmaterial?: boolean;
    eigentumsVerhaeltnis?: EigentumsVerhaeltnis;
    warenAnlieferung?: WarenAnlieferung;
    issueType?: string;
    srtUserDocumentsUploaded?: boolean;
    marketExpert?: MarketExpert;
    documentLinks: MacherProjektDocumentLink[];
    /** @uniqueItems true */
    vorgaenge: Vorgang[];
    tasks: MacherProjektTask[];
    projectAnswers: MacherProjektProjectAnswer[];
    feedbackSent: boolean;
    assigneeId?: string;
    leadFunnelOrigin?: string;
    contact?: Contact;
}

export interface MacherProjektDTO {
    id: string;
    /** @format int32 */
    version: number;
    origin: Origin;
    projectId: string;
    state: MacherProjektFsmState;
    street?: string;
    houseNumber?: string;
    zip?: string;
    country: string;
    city?: string;
    addressAddition?: string;
    categoryKey: ProjectCategory;
    projectDescription?: string;
    /** @format float */
    totalDurationInHours?: number;
    /** @format float */
    totalCost?: number;
    /** @format date */
    earliestDeliveryDate?: string;
    /** @format date */
    projectStartDate?: string;
    /** @format date */
    projectEndDate?: string;
    /** @format date-time */
    projectCreatedAt: string;
    referenceNumberB3?: string;
    possibleDoubletProjectId?: string;
    notiz?: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    inquiryCreatedAt?: string;
    /** @format date-time */
    lastModified: string;
    /** @format int32 */
    budgetRahmen?: number;
    stockwerk?: string;
    fahrstuhl?: boolean;
    parkplatz?: boolean;
    entsorgungAltmaterial?: boolean;
    eigentumsVerhaeltnis?: EigentumsVerhaeltnis;
    warenAnlieferung?: WarenAnlieferung;
    issueType?: string;
    feedbackSent: boolean;
    marketExpert?: MarketExpertDTO;
    customer?: MacherKundeDTO;
    externerKunde?: ExternerKundeDTO;
    geoLocation: GeoLocationDTO;
    market: MarketDTO;
    projektKoordinator?: ProjektKoordinatorDTO;
    contact?: ContactDTO;
    documentLinks: MacherProjektDocumentLinkDTO[];
    vorgaenge: VorgangDTO[];
    tasks: MacherProjektTaskDTO[];
    projectAnswers: MacherProjektProjektAnswerDTO[];
    srtUserDocumentsUploaded?: boolean;
    assigneeId?: string;
}

export interface MacherProjektDocumentLink {
    id: string;
    origin: OriginType;
    displayName?: string;
    vorgangId?: string;
    contentType: string;
    deleted: boolean;
    tags: string[];
    /** @format date-time */
    createdAt: string;
}

export interface MacherProjektDocumentLinkDTO {
    id: string;
    origin: OriginType;
    displayName?: string;
    vorgangId?: string;
    contentType: string;
    deleted: boolean;
    tags: string[];
    /** @format date-time */
    createdAt: string;
}

export interface MacherProjektForPatchDTO {
    categoryKey?: string;
    projectDescription?: string;
    customer?: MacherKundePatchDTO;
    street?: string;
    houseNumber?: string;
    zip?: string;
    city?: string;
    addressAddition?: string;
    /** @format date */
    projectStartDate?: string;
    /** @format date */
    projectEndDate?: string;
    /** @format date-time */
    projectCreatedAt?: string;
    market?: Market;
    contact?: Contact;
    referenceNumberB3?: string;
    projektKoordinatorId?: string;
    notiz?: string;
    /** @format int32 */
    budgetRahmen?: number;
    stockwerk?: string;
    fahrstuhl?: boolean;
    parkplatz?: boolean;
    entsorgungAltmaterial?: boolean;
    eigentumsVerhaeltnis?: string;
    warenAnlieferung?: string;
}

export enum MacherProjektFsmState {
    Eingegangen = "Eingegangen",
    Akzeptiert = "Akzeptiert",
    InUmsetzung = "InUmsetzung",
    Umgesetzt = "Umgesetzt",
    Abgebrochen = "Abgebrochen",
    FeedbackEingeholt = "FeedbackEingeholt",
    Abgelehnt = "Abgelehnt",
    Unbekannt = "Unbekannt"
}

export interface MacherProjektListDTO {
    id: string;
    /** @format int32 */
    version: number;
    country: string;
    projectId: string;
    categoryKey: ProjectCategory;
    state?: MacherProjektFsmState;
    /** @format date-time */
    projectCreatedAt: string;
    projectDescription?: string;
    debitorId?: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    origin: Origin;
    zip?: string;
    marktId?: string;
    marktDescription?: string;
    marktTyp?: string;
    marktVertriebsRegion?: VertriebsRegion;
    projektKoordinatorVorname?: string;
    projektKoordinatorNachname?: string;
    geoLocationConfidence?: GeoLocationConfidence;
    srtUserDocumentsUploaded?: boolean;
    projektKoordinatorName: string;
    customer: KundeListDTO;
    market: MarktDTO;
    vorgaenge: VorgangListDTO[];
    geoLocation: GeoLocationListDTO;
}

export interface MacherProjektProjectAnswer {
    id: string;
    macherProjektId: string;
    parameterName: string;
    display: string;
    type: DataType;
    values: MacherProjektProjectAnswerValue[];
}

export interface MacherProjektProjectAnswerPutDTO {
    items: MacherProjektProjectAnswerPutItemDTO[];
}

export interface MacherProjektProjectAnswerPutItemDTO {
    parameterName: string;
    display: string;
    type: DataType;
    values: MacherProjektProjektAnswerValuePutDTO[];
}

export interface MacherProjektProjectAnswerValue {
    value: string;
    label: string;
}

export interface MacherProjektProjektAnswerDTO {
    id: string;
    parameterName: string;
    display: string;
    values: MacherProjektProjektAnswerValueDTO[];
}

export interface MacherProjektProjektAnswerValueDTO {
    value: string;
    label: string;
}

export interface MacherProjektProjektAnswerValuePutDTO {
    value: string;
    label: string;
}

export interface MacherProjektQueryParams {
    search?: string[];
    kategorie?: ProjectCategory[];
    projektId?: string[];
    projektKoordinatorId?: string[];
    geoLocationConfidence?: GeoLocationConfidence[];
    kundenName?: string[];
    kundenEmail?: string[];
    debitorId?: string[];
    plz?: string[];
    marktId?: string[];
    marktTyp?: string[];
    state?: MacherProjektFsmState[];
    wiedervorlage?: string[];
    handwerkerFirma?: string[];
    interneNotiz?: string[];
    reklamationsNotiz?: string[];
    vorgangWithLabel?: string[];
    vorgangWithoutLabel?: string[];
    vorgangStatus?: string[];
    vertriebsRegion?: VertriebsRegion[];
    page?: string;
    pageSize?: string;
    sortOrder?: string;
    sortBy?: string;
    origin?: Origin[];
    fullState?: string[];
    taskIds?: string[];
    processCreatedAt?: string[];
    processStateChangedAt?: string[];
    projektKoordinatorAssigned?: boolean;
    vorgangId?: string[];
    pageRequestParams: {
        all?: Record<string, string>;
        empty?: boolean;
        [key: string]: any;
    };
}

export interface MacherProjektStatesDTO {
    /** @format int64 */
    totalCount: number;
    stateCount: Record<string, number>;
}

export interface MacherProjektStatusMetaDTO {
    /** @format int32 */
    projektVersion: number;
    status?: MacherProjektFsmState;
    transitions: MacherProjektTransitionDTO[];
}

export interface MacherProjektTask {
    id: string;
    macherProjektId?: string;
    countryCode: string;
    taskId: string;
    displayName: string;
    category: string;
    subCategory: string;
    additionalAnswers: AdditionalAnswer[];
}

export interface MacherProjektTaskCreateDTO {
    taskId: string;
    displayName: string;
    category: string;
    subCategory: string;
    additionalAnswers?: AdditionalAnswerCreateDTO[];
}

export interface MacherProjektTaskDTO {
    countryCode: string;
    id: string;
    taskId: string;
    displayName: string;
    category: string;
    subCategory: string;
    additionalAnswers: AdditionalAnswerDTO[];
}

export interface MacherProjektTaskPatchDTO {
    displayName?: string;
    category?: string;
    subCategory?: string;
    additionalAnswers?: AdditionalAnswerPatchDTO[];
}

export interface MacherProjektTransitionDTO {
    trigger: string;
    payloadType?: string;
    flags?: string[];
}

export interface Market {
    id: string;
    /** @format int32 */
    version: number;
    country: string;
    type: string;
    vertriebsRegion: VertriebsRegion;
    description?: string;
    street?: string;
    zip?: string;
    city?: string;
    state?: string;
    phoneNumber?: string;
    active?: boolean;
}

export interface MarketDTO {
    id: string;
    /** @format int32 */
    version: number;
    country: string;
    type: string;
    vertriebsRegion: VertriebsRegion;
    description?: string;
    street?: string;
    zip?: string;
    city?: string;
    state?: string;
    phoneNumber?: string;
    active?: boolean;
}

export interface MarketExpert {
    id: string;
    vorname: string;
    nachname: string;
    email: string;
    marketId: string;
    telefon: string;
}

export interface MarketExpertDTO {
    id: string;
    vorname: string;
    nachname: string;
    email: string;
    marketId: string;
    telefon: string;
}

export interface MarktDTO {
    id: string;
    description?: string;
    type: string;
    vertriebsRegion: VertriebsRegion;
}

export interface MatchingCraftsmen {
    /** @format double */
    distanceToTargetInKm?: number;
    /** @format int32 */
    travelDurationInSeconds?: number;
    vermittlung?: Vorgang;
    acceptedLeadsCountByTask: LeadsByTaskAndMonth[];
    craftsman?: CraftsmanFacilityWithStatsDTO;
    id: string;
    shortId: string;
    facilityName?: string;
    email?: string;
    /** @format int32 */
    version: number;
    contact?: CraftsmanContact;
    address?: Address;
    status?: string;
    notes?: string;
    /** @format int64 */
    acceptedRequestsCount?: number;
    /** @format date-time */
    lastRequestedDate?: string;
    internalRating?: string;
    marketBlacklist?: Market[];
    preferredCategories?: ProjectCategory[];
    operationRoles?: string[];
    /** @format int32 */
    radius?: number;
    /** @format date */
    zusammenarbeitSeit?: string;
    partnerTyp: string;
    handwerkerBewertungStatistik: HandwerkerBewertungStatistik;
    partnerApi?: string;
    tasks: Task[];
    /** @format int64 */
    acceptedLeadsCount?: number;
    /** @format int32 */
    monthlyLeadCapacity?: number;
    automaticallyAssignLead?: boolean;
    /** @format int32 */
    durationInSeconds?: number;
    neu: boolean;
}

/** DTO for sending an offerAcceptValue */
export interface OfferAcceptedValueDTO {
    /**
     * The value of the offerAcceptedValue to be saved
     * @min 0
     * @exclusiveMin false
     * @example 500.75
     */
    offerAcceptedValue: number;
    /** Optional DTO for onSiteDate */
    onSiteDate?: VorOrtTerminAbstimmenDTO;
}

/** DTO for sending an offerValue and/or gmv */
export interface OfferAndGMVDTO {
    /**
     * The value of the offerSentValue to be saved
     * @min 0
     * @exclusiveMin false
     * @example 500.75
     */
    offerSentValue?: number;
    /**
     * The value of the offerAcceptedValue to be saved
     * @min 0
     * @exclusiveMin false
     * @example 500.75
     */
    offerAcceptedValue?: number;
    /**
     * The value of the gmv to be saved
     * @min 0
     * @exclusiveMin false
     * @example 500.75
     */
    gmv?: number;
}

/** DTO for sending an offerSentValue */
export interface OfferSentValueDTO {
    /**
     * The value of the offerSentValue to be saved
     * @min 0
     * @exclusiveMin false
     * @example 500.75
     */
    offerSentValue?: number;
    /** Optional DTO for onSiteDate */
    onSiteDate?: VorOrtTerminAbstimmenDTO;
}

export enum Origin {
    Projektmacher = "Projektmacher",
    Gartenplaner = "Gartenplaner",
    Poseidon = "Poseidon",
    LucifyClient = "LucifyClient",
    Beratungscenter = "Beratungscenter",
    DirectLead = "DirectLead"
}

export enum OriginType {
    INNENDIENST = "INNENDIENST",
    MARKTMITARBEITER = "MARKTMITARBEITER"
}

export interface PartnerProcessDTO {
    /**
     * The ISO3166 two letter country code of the partners company
     * @example "DE"
     */
    countryCode: string;
    /**
     * The unique partners company UUID
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    companyId: string;
    /**
     * The unique partners facility id
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    facilityId: string;
    /**
     * The unique partners facility short id
     * @example "LUCIFY"
     */
    facilityShortId: string;
    /**
     * The UUID of the project which owns the process
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    macherProjectId: string;
    /**
     * The UUID of the process
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    processId: string;
    /**
     * The version of the facility
     * @format int32
     * @example 1
     */
    version: number;
    /**
     * The date when the process was accepted by the facility
     * @format date
     */
    acceptedDate: string;
    /**
     * The date of the last state change
     * @format date-time
     * @example "2024-02-14T14:32:23Z"
     */
    stateChangedAt: string;
    /**
     * The first name of the customer
     * @example "Mark"
     */
    customerFirstName: string;
    /**
     * The last name of the customer
     * @example "Mustermann"
     */
    customerLastName: string;
    /**
     * The current full state of the process
     * @example "SolutionPartnering.angebotAnnehmen"
     */
    state: string;
    /**
     * The human readable name of the partners facility
     * @example "Craftsman Markus Mustermann"
     */
    facilityName: string;
    /**
     * The project title (aka as project description in other contexts)
     * @example "Badsanierung Kachel Rot"
     */
    projectTitle: string;
    /**
     * The full business id of the process {projectId}_{vorgangsNummer}
     * @example "DE98435_2"
     */
    processBusinessId: string;
    /**
     * The type of the on site date
     * @example "ERFORDERLICH"
     */
    onSiteType?: string;
    /**
     * The date of the on site date (can be null)
     * @format date
     */
    onSiteDate?: string;
    /**
     * The phone number of the customer
     * @example "09999-9999999"
     */
    customerPhoneNumber?: string;
    /**
     * The offerSentValue of the process
     * @example 525.75
     */
    offerSentValue?: number;
    /**
     * The offerAcceptedValue of the process
     * @example 525.75
     */
    offerAcceptedValue?: number;
    /**
     * The gmv of the process
     * @example 525.75
     */
    gmv?: number;
}

export interface PartnerProcessListDTO {
    /**
     * The ISO3166 two letter country code of the partners company
     * @example "DE"
     */
    countryCode: string;
    /**
     * The unique partners company UUID
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    companyId: string;
    /**
     * The unique partners facility id
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    facilityId: string;
    /**
     * The unique partners facility short id
     * @example "LUCIFY"
     */
    facilityShortId: string;
    /**
     * The UUID of the project which owns the process
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    macherProjectId: string;
    /**
     * The UUID of the process
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    processId: string;
    /**
     * The version of the facility
     * @format int32
     * @example 1
     */
    version: number;
    /**
     * The date when the process was accepted by the facility
     * @format date
     */
    acceptedDate: string;
    /**
     * The date of the last state change
     * @format date-time
     * @example "2024-02-14T14:32:23Z"
     */
    stateChangedAt: string;
    /**
     * The first name of the customer
     * @example "Mark"
     */
    customerFirstName: string;
    /**
     * The last name of the customer
     * @example "Mustermann"
     */
    customerLastName: string;
    /**
     * The current full state of the process
     * @example "SolutionPartnering.angebotAnnehmen"
     */
    state: string;
    /**
     * The human readable name of the partners facility
     * @example "Craftsman Markus Mustermann"
     */
    facilityName: string;
    /**
     * The project title (aka as project description in other contexts)
     * @example "Badsanierung Kachel Rot"
     */
    projectTitle: string;
    /**
     * The full business id of the process {projectId}_{vorgangsNummer}
     * @example "DE98435_2"
     */
    processBusinessId: string;
    /**
     * The type of the on site date
     * @example "ERFORDERLICH"
     */
    onSiteType?: string;
    /**
     * The date of the on site date (can be null)
     * @format date
     */
    onSiteDate?: string;
    /**
     * The phone number of the customer
     * @example "09999-9999999"
     */
    customerPhoneNumber?: string;
    /**
     * The offerSentValue of the process
     * @example 525.75
     */
    offerSentValue?: number;
    /**
     * The offerAcceptedValue of the process
     * @example 525.75
     */
    offerAcceptedValue?: number;
    /**
     * The gmv of the process
     * @example 525.75
     */
    gmv?: number;
    _link?: WebLinkDTO;
}

export interface PartnerRequest {
    id: string;
    craftsmanFacility: CraftsmanFacility;
    vorgang: Vorgang;
    /** @format float */
    distanceToTargetInKm?: number;
    status?: PartnerRequestStatus;
    /** @format date-time */
    stateChangedAt?: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    lastModified?: string;
    /** @format int32 */
    version: number;
    manuallyMatched?: boolean;
    feedbacks: Feedback[];
    vertragsPartnerDocumentLinks: VertragsPartnerDocumentLink[];
    respondedBy?: Responder;
}

export interface PartnerRequestDTO {
    partnerRequest: PartnerRequest;
    id: string;
    craftsmanFacility?: CraftsmanFacility;
    /** @format float */
    distanceToTargetInKm?: number;
    status?: PartnerRequestStatus;
    /** @format date-time */
    stateChangedAt?: string;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    lastModified?: string;
    /** @format int32 */
    version: number;
    manuallyMatched?: boolean;
    feedbacks?: Feedback[];
    documentLinks: VertragsPartnerDocumentLink[];
    respondedBy?: Responder;
}

export interface PartnerRequestStatus {
    status?: string;
}

export interface PriceCluster {
    clusterNumber: string;
    /** @format double */
    netSellingPrice: number;
    /** @format double */
    netPurchasePrice: number;
}

export interface PriceClusterDTO {
    clusterNumber: string;
    /** @format double */
    netSellingPrice: number;
    /** @format double */
    netPurchasePrice: number;
}

export interface ProcessFilterCriteriaDTO {
    /** @example null */
    search?: string[];
    /** @example null */
    stateTag?: string[];
}

export enum ProcessSortCriteriaDTO {
    FacilityShortId = "facilityShortId",
    FacilityName = "facilityName",
    ProcessBusinessId = "processBusinessId",
    AcceptedDate = "acceptedDate",
    StateChangedAt = "stateChangedAt"
}

export interface ProcessStatusMetaDTO {
    /**
     * The version of the process
     * @format int32
     * @example 1
     */
    processVersion: number;
    /**
     * The full state (phase and sub state) of the process
     * @example "SolutionPartnering.KundeKontaktiert"
     */
    state: string;
    /**
     * Is true for the service accepting trigger requests
     * @example true
     */
    active: boolean;
    /**
     * A list (set) of tags attached to the state/sub state
     * @example ["active","machbarMacher"]
     */
    tags: string[];
    /**
     * The list (set) of available transitions
     * @example null
     */
    transitions: ProcessTransitionMetaDTO[];
    /**
     * The version of the project owning the process
     * @format int32
     * @example 1
     */
    projectVersion?: number;
}

/**
 * The list (set) of available transitions
 * @example null
 */
export interface ProcessTransitionMetaDTO {
    /**
     * The name of the trigger
     * @example "angebotVersenden"
     */
    trigger: string;
    /**
     * A list (set) of tags attached to transition
     * @example null
     */
    tags: string[];
    /**
     * The list of possible destination states. Based on the execution only one will be the new state.
     * @example ["SolutionPartnering.KundeKontaktiert","SolutionPartnering.AngebotVersendet","SolutionPartnering.AngebotAngenommen"]
     */
    destinationStates: string[];
    /**
     * The type of the payload to be send with the transition, if required
     * @example "ProcessAbortReason"
     */
    payloadType?: string;
    /**
     * The available optional flags to send as query in the transition
     * @example ["withoutNotification"]
     */
    flags?: string[];
}

export interface Project {
    projectId: string;
    categoryKey?: string;
    projectDescription?: string;
    projectType?: string;
    /** @format float */
    totalDuration?: number;
    /** @format float */
    totalCost?: number;
    salutation?: string;
    givenName?: string;
    surname?: string;
    street?: string;
    houseNumber?: string;
    country: string;
    zipCode?: string;
    city?: string;
    addressAddition?: string;
    email?: string;
    phone: string;
    origin?: string;
    contactFirstName?: string;
    contactName?: string;
    contactEmail?: string;
    contactPhone?: string;
    /** @format date-time */
    earliestDeliveryDate?: string;
    /** @format date-time */
    executionFromDate?: string;
    /** @format date-time */
    executionUntilDate?: string;
    /** @format date-time */
    createdAt: string;
    basis3CustomerNumber?: string;
    ilpCustomerNumber?: string;
    comment?: string;
    store: string;
    workPackages?: ProjectWorkPackage[];
    siteCountry?: string;
    siteZipCode?: string;
    siteCity?: string;
    siteStreet?: string;
    siteHouseNumber?: string;
    siteAddressAddition?: string;
    debitorID?: string;
    referenceNumberB3?: string;
    isSiteAddressProvided: boolean;
}

export enum ProjectCategory {
    GARTEN = "GARTEN",
    BAD = "BAD",
    RAUM = "RAUM",
    BAUELEMENTE = "BAUELEMENTE",
    SONSTIGES = "SONSTIGES"
}

export interface ProjectWorkPackage {
    id?: string;
    workPackage: string;
    /** @format float */
    timeWorkPackage: number;
    /** @format float */
    costWorkPackage: number;
}

export interface ProjektKoordinator {
    id: string;
    /** @pattern [A-Z][A-Z] */
    countryCode: string;
    vertriebsRegion: VertriebsRegion;
    anrede: string;
    vorname: string;
    nachname: string;
    email: string;
    telefon: string;
    aktiv: boolean;
}

export interface ProjektKoordinatorCreateDTO {
    vertriebsRegion: VertriebsRegion;
    anrede: string;
    vorname: string;
    nachname: string;
    email: string;
    telefon: string;
    aktiv: boolean;
}

export interface ProjektKoordinatorDTO {
    id: string;
    countryCode: string;
    vertriebsRegion: VertriebsRegion;
    anrede: string;
    vorname: string;
    nachname: string;
    email: string;
    telefon: string;
    aktiv: boolean;
}

export interface ProjektKoordinatorPatchDTO {
    vertriebsRegion?: VertriebsRegion;
    anrede?: string;
    vorname?: string;
    nachname?: string;
    email?: string;
    telefon?: string;
    aktiv?: boolean;
}

export interface RequestPartnerDTO {
    craftsmanFacilityId: string;
    manuallyMatched: boolean;
}

export enum Responder {
    CRAFTSMAN = "CRAFTSMAN",
    OPERATOR = "OPERATOR",
    PARTNER_API = "PARTNER_API"
}

/** @example "null" */
export enum ResponderDTO {
    CRAFTSMAN = "CRAFTSMAN",
    OPERATOR = "OPERATOR",
    PARTNER_API = "PARTNER_API"
}

export interface SrtProjekt {
    inquiryId: string;
    marktId: string;
    baustellenAdresse: AdressForCreationDTO;
    /** @format date-time */
    inquiryCreatedAt: string;
    macherKunde: MacherKundeForCreationDTO;
    externerKunde: ExternerKundeForCreationDTO;
    notiz?: string;
    categoryKey?: ProjectCategory;
    /** @format date-time */
    projectStartDate?: string;
    /** @format date-time */
    projectEndDate?: string;
    projectDescription?: string;
    /** @format int32 */
    budgetRahmen?: number;
    stockwerk?: string;
    fahrstuhl?: boolean;
    parkplatz?: boolean;
    eigentumsVerhaeltnis?: EigentumsVerhaeltnis;
    warenAnlieferung?: WarenAnlieferung;
    issueType?: string;
    bedarfsErmittlung: BedarfsermittlungDTO;
    assigneeId?: string;
}

export type StreamingResponseBody = object;

export interface Substate {
    state: string;
    /** @format int64 */
    sequenceNumber: number;
}

export interface Task {
    id: Id;
    kafkaKey: string;
    displayName: string;
    category: string;
    subCategory: string;
    /** @format int32 */
    version: number;
    active: boolean;
}

export interface TaskSelectionDTO {
    taskId: string;
    additionalAnswers?: AdditionalAnswerCreationDTO[];
}

export type Unit = object;

export interface Value {
    value: string;
    group?: string;
    label?: string;
}

export interface VermittlungDTO {
    vorgang?: Vorgang;
    possibleDoubletVorgangId?: string;
    mapVorgaenge?: boolean;
    id: string;
    projectId: string;
    categoryKey: ProjectCategory;
    projectDescription?: string;
    /** @format float */
    totalDurationInHours?: number;
    /** @format float */
    totalCost?: number;
    customer?: MacherKunde;
    street?: string;
    houseNumber?: string;
    zip?: string;
    country: string;
    city?: string;
    addressAddition?: string;
    origin?: string;
    geoLocation: GeoLocation;
    /** @format date */
    earliestDeliveryDate?: string;
    /** @format date */
    projectStartDate?: string;
    /** @format date */
    projectEndDate?: string;
    /** @format date-time */
    projectCreatedAt?: string;
    market?: Market;
    contact?: Contact;
    referenceNumberB3?: string;
    projektKoordinator?: ProjektKoordinator;
    possibleDoublet?: string;
    /** @format date-time */
    createdAt?: string;
    status?: VermittlungStatus;
    state?: string;
    vorgangStatus?: string;
    substate?: Substate;
    vorOrtTermin?: VorOrtTermin;
    /** @format date */
    reminder?: string;
    comment?: string;
    currentRequest?: PartnerRequestDTO;
    feedbacks: Feedback[];
    /** @format date-time */
    stateChangedAt?: string;
    labels: string[];
    vermittlungWorkTasks: VorgangWorkTask[];
    documentLinks: VertragsPartnerDocumentLink[];
    type?: string;
    macherProjektId: string;
}

export interface VermittlungForPatchDTO {
    categoryKey?: string;
    projectDescription?: string;
    street?: string;
    houseNumber?: string;
    zip?: string;
    city?: string;
    addressAddition?: string;
    /** @format date */
    projectStartDate?: string;
    /** @format date */
    projectEndDate?: string;
    /** @format date-time */
    projectCreatedAt?: string;
    reminder?: string;
    market?: Market;
    comment?: string;
    customer?: MacherKunde;
    contact?: Contact;
    referenceNumberB3?: string;
    labels?: string[];
    vermittlungWorkTasks?: VorgangWorkTask[];
    projektKoordinatorId?: string;
}

export interface VermittlungStatesDTO {
    state?: string;
    /** @format int32 */
    count?: number;
}

export enum VermittlungStatus {
    Created = "created",
    OperatorAssigned = "operator_assigned",
    CraftsmanRequested = "craftsman_requested",
    InExecution = "in_execution",
    Completed = "completed",
    Rejected = "rejected",
    Cancelled = "cancelled",
    Coordination = "coordination"
}

export interface VermittlungenQueryParams {
    zip?: string;
    customerName?: string;
    craftsmanCompanyName?: string;
    state?: VermittlungStatus[];
    categoryKey?: ProjectCategory[];
    projectId?: string;
    reminder?: string;
    reminderTo?: string;
    createdAt?: string;
    createdAtTo?: string;
    stateChangedAtFrom?: string;
    stateChangedAtTo?: string;
    withLabel?: string[];
    withoutLabel?: string[];
    marketId?: string[];
    debitorId?: string[];
    projektKoordinatorId?: string;
    vorgangStatus?: string;
    vertriebsRegion?: VertriebsRegion[];
    interneNotiz?: string;
    reklamationsNotiz?: string;
    page?: string;
    pageSize?: string;
    sortOrder?: string;
    origin?: Origin[];
    fullState?: string[];
    sortBy?: string;
    /** @format date-time */
    stateChangedAtToZonedDateTime?: string;
    /** @format date-time */
    stateChangedAtFromZonedDateTime?: string;
    /** @format date-time */
    reminderToZonedDateTime?: string;
    /** @format date-time */
    reminderFromZonedDateTime?: string;
    /** @format date-time */
    createdAtToZonedDateTime?: string;
    /** @format date-time */
    createdAtFromZonedDateTime?: string;
    pageRequestParams: {
        all?: Record<string, string>;
        empty?: boolean;
        [key: string]: any;
    };
}

export interface VertragsPartnerDocumentLink {
    documentId: string;
    documentNumber?: string;
    partnerRequestId: string;
    craftsmanFacilityShortId: string;
    /** @format int32 */
    version: number;
    /** @format date-time */
    createdAt: string;
    gaebAvailable: boolean;
}

export enum VertriebsRegion {
    UNBEKANNT = "UNBEKANNT",
    DE_NORDOST = "DE_NORDOST",
    DE_NORDWEST = "DE_NORDWEST",
    DE_SUED = "DE_SUED",
    DE_SUEDWEST = "DE_SUEDWEST",
    DE_INTERN = "DE_INTERN",
    DE_FRANCHISEPARTNER = "DE_FRANCHISEPARTNER",
    AT = "AT",
    ATFM1 = "AT_FM_1",
    ATFM2 = "AT_FM_2",
    ATFM3 = "AT_FM_3",
    ATFM4 = "AT_FM_4",
    ATFM5 = "AT_FM_5",
    ATFM6 = "AT_FM_6",
    AT_FRANCHISEPARTNER = "AT_FRANCHISEPARTNER",
    AT_INTERN = "AT_INTERN"
}

export interface VorOrtTermin {
    typ: VorOrtTerminTyp;
    /** @format date */
    datum?: string;
}

/**
 * Optional DTO for onSiteDate
 * @example null
 */
export interface VorOrtTerminAbstimmenDTO {
    typ: VorOrtTerminTypDTO;
    /**
     * The onSiteDate
     * @format date
     */
    datum?: string;
}

export interface VorOrtTerminReadDTO {
    typ: VorOrtTerminTypDTO;
    /** @format date */
    datum?: string;
}

export enum VorOrtTerminTyp {
    UNBEKANNT = "UNBEKANNT",
    NICHT_NOTWENDIG = "NICHT_NOTWENDIG",
    ERFORDERLICH = "ERFORDERLICH"
}

/** @example "null" */
export enum VorOrtTerminTypDTO {
    UNBEKANNT = "UNBEKANNT",
    NICHT_NOTWENDIG = "NICHT_NOTWENDIG",
    ERFORDERLICH = "ERFORDERLICH"
}

export interface Vorgang {
    id: string;
    /** @format int32 */
    version: number;
    macherProjekt: MacherProjekt;
    /** @format int32 */
    vorgangsNummer: number;
    state?: VorgangState;
    substate?: Substate;
    status?: VermittlungStatus;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    lastModified: string;
    /** @format date-time */
    reminder?: string;
    currentRequest?: PartnerRequest;
    feedbacks: Feedback[];
    /** @format date-time */
    stateChangedAt?: string;
    labels: string[];
    workTasks: VorgangWorkTask[];
    vertragsPartnerDocumentLinks: VertragsPartnerDocumentLink[];
    vorOrtTermin?: VorOrtTermin;
    interneNotiz?: string;
    reklamationsNotiz?: string;
    abortFeedbackSent: boolean;
    offerSentValue?: number;
    offerAcceptedValue?: number;
    gmv?: number;
    /** @format int32 */
    documentLinksSizeForCraftsman: number;
    metadata: Record<string, Record<string, string>>;
}

export interface VorgangComment {
    id: string;
    comment?: string;
    /** @format date-time */
    createdAt: string;
}

export interface VorgangDTO {
    id: string;
    /** @format int32 */
    version: number;
    /** @format int32 */
    vorgangsNummer: number;
    state?: VorgangState;
    substate?: Substate;
    fullState: string;
    status?: VermittlungStatus;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    lastModified: string;
    /** @format date */
    reminder?: string;
    /** @format date-time */
    stateChangedAt?: string;
    labels: string[];
    vorOrtTermin?: VorOrtTerminReadDTO;
    interneNotiz?: string;
    reklamationsNotiz?: string;
    metadata: Record<string, Record<string, string>>;
    macherProjekt?: MacherProjektDTO;
    currentRequest?: HandwerkerRequestDTO;
    feedbacks: FeedbackDTO[];
    documentLinks: VorgangDocumentLinkDTO[];
    workTasks: VorgangWorkTaskDTO[];
    offerSentValue?: number;
    offerAcceptedValue?: number;
    gmv?: number;
}

export interface VorgangDocumentLinkDTO {
    documentId: string;
    documentNumber?: string;
    vermitllungRequestId: string;
    craftsmanFacilityShortId: string;
    /** @format int32 */
    version: number;
    /** @format date-time */
    createdAt: string;
    gaebAvailable: boolean;
}

export interface VorgangForPatchDTO {
    reminder?: string;
    comment?: string;
    labels?: string[];
    workTasks?: VorgangWorkTask[];
    interneNotiz?: string;
    reklamationsNotiz?: string;
}

export interface VorgangListDTO {
    id: string;
    /** @format int32 */
    version: number;
    phase?: VorgangState;
    statusAlt?: VermittlungStatus;
    status?: string;
    craftsmanFacilityName?: string;
    /** @format date-time */
    reminderAsZonedDateTime?: string;
    /** @format date-time */
    stateChangedAt?: string;
    macherProjektId: string;
    /** @format int32 */
    vorgangsNummer: number;
    craftsmanFacility: HandwerkerDTO;
    /** @format date */
    reminder?: string;
    fullState: string;
    labels: string[];
}

export enum VorgangState {
    HandwerkerAnfrage = "HandwerkerAnfrage",
    Koordination = "Koordination",
    MachbarMacher = "MachbarMacher",
    SolutionPartnering = "SolutionPartnering",
    VertragsManagement = "VertragsManagement",
    Umsetzung = "Umsetzung",
    InMigration = "InMigration",
    Abgebrochen = "Abgebrochen",
    Abgelehnt = "Abgelehnt"
}

export interface VorgangStatesDTO {
    fullState: string;
    /** @format int64 */
    countMacherProjekt: number;
    /** @format int64 */
    countVorgang: number;
}

export interface VorgangStatusMetaDTO {
    /** @format int32 */
    projektVersion: number;
    /** @format int32 */
    vorgangVersion: number;
    phase: string;
    status?: string;
    aktiv: boolean;
    transitions: VorgangTransitionDTO[];
    /** @uniqueItems true */
    tags: string[];
    fullState: string;
}

export interface VorgangTransitionDTO {
    trigger: string;
    /** @uniqueItems true */
    tags: string[];
    payloadType?: string;
    flags?: string[];
    /** @uniqueItems true */
    destinationStates: string[];
}

export interface VorgangWorkTask {
    clusterName: string;
    /** @format int32 */
    priority: number;
    workTask: WorkTask;
    /** @format double */
    amount: number;
    /** @format double */
    netPurchasePrice?: number;
    /** @format double */
    netSellingPrice: number;
    purchasePriceCluster?: string;
    optional: boolean;
    additionalDescription?: string;
    craftsmanFacilityId?: string;
}

export interface VorgangWorkTaskDTO {
    clusterName: string;
    /** @format int32 */
    priority: number;
    workTask: WorkTaskDTO;
    /** @format double */
    amount: number;
    /** @format double */
    netPurchasePrice?: number;
    /** @format double */
    netSellingPrice: number;
    purchasePriceCluster?: string;
    optional: boolean;
    additionalDescription?: string;
    craftsmanFacilityId?: string;
}

export enum WarenAnlieferung {
    KUNDE = "KUNDE",
    OBI_LIEFERDIENST = "OBI_LIEFERDIENST",
    HERSTELLER = "HERSTELLER"
}

/** @example null */
export interface WebLinkDTO {
    /**
     * The URI of the resource
     * @example "http//example.org/my-resource-path/12345"
     */
    href: string;
    /**
     * The optional relation of the link
     * @example "null"
     */
    rel: string;
    /**
     * The optional human readable title of the link
     * @example "null"
     */
    title?: string;
    /**
     * The operation id of the partner api
     * @example "getPartnerFacility"
     */
    apiOperation?: string;
}

export interface WorkTask {
    id?: string;
    /** @format int32 */
    version: number;
    articleNumber?: string;
    trade?: string;
    category: string;
    name?: string;
    description?: string;
    unit?: string;
    /** @format double */
    tax?: number;
    active?: boolean;
    netPurchasePriceEditable: boolean;
    netSellingPriceEditable: boolean;
    /** @format float */
    timeFactor?: number;
    priceClusters?: PriceCluster[];
}

export interface WorkTaskDTO {
    id?: string;
    /** @format int32 */
    version: number;
    articleNumber?: string;
    trade?: string;
    category: string;
    name?: string;
    description?: string;
    unit?: string;
    /** @format double */
    tax?: number;
    active?: boolean;
    netPurchasePriceEditable: boolean;
    netSellingPriceEditable: boolean;
    /** @format float */
    timeFactor?: number;
    priceClusters?: PriceClusterDTO[];
}
