import { PureComponent } from "react";
import PropTypes from "prop-types";
import keymapConfig from "../mappings/keymap";

const IGNORE_THESE_TARGETS = ["INPUT", "TEXTAREA"];

const keyMap = new Map();

const registerKeyMapping = ({ name, fn }) => {
    const internalKey = keymapConfig[name].sort().toString();

    if (!keyMap.has(internalKey)) {
        keyMap.set(internalKey, new Map());
    }

    keyMap.get(internalKey).set(name, { fn });
};

const unregisterKeyMapping = ({ name }) => {
    const internalKey = keymapConfig[name].sort().toString();

    if (keyMap.has(internalKey)) {
        keyMap.get(internalKey).delete(name);
    }
};

const blockKeyMapping = ({ name }) => {
    const internalKey = keymapConfig[name].sort().toString();

    if (keyMap.has(internalKey) && keyMap.get(internalKey).has(name)) {
        keyMap.get(internalKey).get(name).blocked = true;
    }
};

const unblockKeyMapping = ({ name }) => {
    const internalKey = keymapConfig[name].sort().toString();

    if (keyMap.has(internalKey) && keyMap.get(internalKey).has(name)) {
        delete keyMap.get(internalKey).get(name).blocked;
    }
};

let pressedKeys = [];

document.addEventListener("keydown", e => {
    if (!pressedKeys.includes(e.code)) {
        pressedKeys.push(e.code);
    }

    if (!IGNORE_THESE_TARGETS.includes(e.target.tagName)) {
        const internalKey = pressedKeys.sort().toString();

        if (keyMap.has(internalKey)) {
            keyMap.get(internalKey).forEach(({ fn, blocked }) => !blocked && fn());
        }
    }
});

document.addEventListener("keyup", () => {
    pressedKeys = [];
});

const keymapManager = {
    registerKeyMapping,
    unregisterKeyMapping
};

export default keymapManager;

export class ShortCut extends PureComponent {
    static propTypes = {
        name: PropTypes.string,
        fn: PropTypes.func
    };

    componentDidMount() {
        registerKeyMapping({
            name: this.props.name,
            fn: this.props.fn
        });
    }

    componentWillUnmount() {
        unregisterKeyMapping({
            name: this.props.name
        });
    }

    render() {
        return null;
    }
}

export class BlockShortCut extends PureComponent {
    static propTypes = {
        name: PropTypes.string
    };

    componentDidMount() {
        blockKeyMapping({
            name: this.props.name
        });
    }

    componentWillUnmount() {
        unblockKeyMapping({
            name: this.props.name
        });
    }

    render() {
        return null;
    }
}
