import { macherService } from "../../services/macher/macher";
import { realizationService } from "../../services/realization";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_PENDING = "GET_PROJECTS_PENDING";
export const GET_PROJECTS_FULFILLED = "GET_PROJECTS_FULFILLED";
export const GET_PROJECTS_REJECTED = "GET_PROJECTS_REJECTED";

export const getProjects = params => ({
    type: GET_PROJECTS,
    payload: macherService.getProjectsList(params),
    meta: params
});

export const GET_PROJECT_PROFILE = "GET_PROJECT_PROFILE";
export const GET_PROJECT_PROFILE_PENDING = "GET_PROJECT_PROFILE_PENDING";
export const GET_PROJECT_PROFILE_FULFILLED = "GET_PROJECT_PROFILE_FULFILLED";
export const GET_PROJECT_PROFILE_REJECTED = "GET_PROJECT_PROFILE_REJECTED";

export const getProjectProfile = projectId => ({
    type: GET_PROJECT_PROFILE,
    meta: {
        projectId
    },
    payload: macherService.getProfile(projectId)
});

export const UPDATE_PROJECT_PROFILE = "UPDATE_PROJECT_PROFILE";
export const UPDATE_PROJECT_PROFILE_PENDING = "UPDATE_PROJECT_PROFILE_PENDING";
export const UPDATE_PROJECT_PROFILE_FULFILLED = "UPDATE_PROJECT_PROFILE_FULFILLED";
export const UPDATE_PROJECT_PROFILE_REJECTED = "UPDATE_PROJECT_PROFILE_REJECTED";

export const updateProjectProfile = (projectId, data) => ({
    type: UPDATE_PROJECT_PROFILE,
    meta: {
        projectId
    },
    payload: macherService.updateProfile(projectId, data)
});

export const SET_PROJECT_OPERATOR = "SET_PROJECT_OPERATOR";
export const SET_PROJECT_OPERATOR_PENDING = "SET_PROJECT_OPERATOR_PENDING";
export const SET_PROJECT_OPERATOR_FULFILLED = "SET_PROJECT_OPERATOR_FULFILLED";
export const SET_PROJECT_OPERATOR_REJECTED = "SET_PROJECT_OPERATOR_REJECTED";

export const setProjectOperator = projectId => ({
    type: SET_PROJECT_OPERATOR,
    meta: {
        projectId
    },
    payload: macherService.assignOperator(projectId)
});

export const SET_PROJECT_COMPLETED = "SET_PROJECT_COMPLETED";
export const SET_PROJECT_COMPLETED_PENDING = "SET_PROJECT_COMPLETED_PENDING";
export const SET_PROJECT_COMPLETED_FULFILLED = "SET_PROJECT_COMPLETED_FULFILLED";
export const SET_PROJECT_COMPLETED_REJECTED = "SET_PROJECT_COMPLETED_REJECTED";

export const setProjectCompleted = (id, params) => ({
    type: SET_PROJECT_COMPLETED,
    payload: macherService.complete(id, params)
});

export const SET_PROJECT_REJECTED = "SET_PROJECT_REJECTED";
export const SET_PROJECT_REJECTED_PENDING = "SET_PROJECT_REJECTED_PENDING";
export const SET_PROJECT_REJECTED_FULFILLED = "SET_PROJECT_REJECTED_FULFILLED";
export const SET_PROJECT_REJECTED_REJECTED = "SET_PROJECT_REJECTED_REJECTED";

export const setProjectRejected = (id, params) => ({
    type: SET_PROJECT_REJECTED,
    payload: macherService.reject(id, params)
});

export const SET_PROJECT_REVOKED = "SET_PROJECT_REJECTED";
export const SET_PROJECT_REVOKED_PENDING = "SET_PROJECT_REJECTED_PENDING";
export const SET_PROJECT_REVOKED_FULFILLED = "SET_PROJECT_REJECTED_FULFILLED";
export const SET_PROJECT_REVOKED_REJECTED = "SET_PROJECT_REJECTED_REJECTED";

export const setProjectRevoked = (id, params) => ({
    type: SET_PROJECT_REVOKED,
    payload: macherService.revoke(id, params)
});

export const SET_PROJECT_CANCELLED = "SET_PROJECT_CANCELLED";
export const SET_PROJECT_CANCELLED_PENDING = "SET_PROJECT_CANCELLED_PENDING";
export const SET_PROJECT_CANCELLED_FULFILLED = "SET_PROJECT_CANCELLED_FULFILLED";
export const SET_PROJECT_CANCELLED_REJECTED = "SET_PROJECT_CANCELLED_REJECTED";

export const setProjectCancelled = (id, params) => ({
    type: SET_PROJECT_CANCELLED,
    payload: macherService.cancel(id, params)
});

export const SET_PROJECT_REOPEN = "SET_PROJECT_REOPEN";
export const SET_PROJECT_REOPEN_PENDING = "SET_PROJECT_REOPEN_PENDING";
export const SET_PROJECT_REOPEN_FULFILLED = "SET_PROJECT_REOPEN_FULFILLED";
export const SET_PROJECT_REOPEN_REJECTED = "SET_PROJECT_REOPEN_REJECTED";

export const setProjectReopen = id => ({
    type: SET_PROJECT_REOPEN,
    payload: macherService.reopen(id)
});

export const CREATE_SUCCESSOR = "CREATE_SUCCESSOR";
export const CREATE_SUCCESSOR_PENDING = "CREATE_SUCCESSOR_PENDING";
export const CREATE_SUCCESSOR_FULFILLED = "CREATE_SUCCESSOR_FULFILLED";
export const CREATE_SUCCESSOR_REJECTED = "CREATE_SUCCESSOR_REJECTED";

export const createSuccessor = id => ({
    type: CREATE_SUCCESSOR,
    payload: macherService.starteVorgangTrigger(id)
});

export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_PENDING = "CREATE_PROJECT_PENDING";
export const CREATE_PROJECT_FULFILLED = "CREATE_PROJECT_FULFILLED";
export const CREATE_PROJECT_REJECTED = "CREATE_PROJECT_REJECTED";

export const createProject = params => ({
    type: CREATE_PROJECT,
    payload: macherService.createProject(params)
});

export const GET_PROJECT_COMMENTS = "GET_PROJECT_COMMENTS";
export const GET_PROJECT_COMMENTS_PENDING = "GET_PROJECT_COMMENTS_PENDING";
export const GET_PROJECT_COMMENTS_FULFILLED = "GET_PROJECT_COMMENTS_FULFILLED";
export const GET_PROJECT_COMMENTS_REJECTED = "GET_PROJECT_COMMENTS_REJECTED";

export const getComments = projectId => ({
    type: GET_PROJECT_COMMENTS,
    payload: macherService.getVorgangKommentare(projectId),
    meta: {
        projectId
    }
});

export const ADD_PROJECT_COMMENT = "ADD_PROJECT_COMMENT";
export const ADD_PROJECT_COMMENT_PENDING = "ADD_PROJECT_COMMENT_PENDING";
export const ADD_PROJECT_COMMENT_FULFILLED = "ADD_PROJECT_COMMENT_FULFILLED";
export const ADD_PROJECT_COMMENT_REJECTED = "ADD_PROJECT_COMMENT_REJECTED";

export const addComment = (projectId, data) => ({
    type: ADD_PROJECT_COMMENT,
    payload: macherService.addVorgangKommentar(projectId, data),
    meta: {
        projectId
    }
});

export const DELETE_PROJECT_COMMENT = "DELETE_PROJECT_COMMENT";
export const DELETE_PROJECT_COMMENT_PENDING = "DELETE_PROJECT_COMMENT_PENDING";
export const DELETE_PROJECT_COMMENT_FULFILLED = "DELETE_PROJECT_COMMENT_FULFILLED";
export const DELETE_PROJECT_COMMENT_REJECTED = "DELETE_PROJECT_COMMENT_REJECTED";

export const deleteComment = (projectId, commentId) => ({
    type: DELETE_PROJECT_COMMENT,
    payload: macherService.deleteVorgangKommentar(projectId, commentId),
    meta: {
        projectId,
        commentId
    }
});

export const GET_PROJECT_HISTORY = "GET_PROJECT_HISTORY";
export const GET_PROJECT_HISTORY_PENDING = "GET_PROJECT_HISTORY_PENDING";
export const GET_PROJECT_HISTORY_FULFILLED = "GET_PROJECT_HISTORY_FULFILLED";
export const GET_PROJECT_HISTORY_REJECTED = "GET_PROJECT_HISTORY_REJECTED";

export const getHistory = projectId => ({
    type: GET_PROJECT_HISTORY,
    payload: macherService.getHistory(projectId),
    meta: {
        projectId
    }
});

export const CREATE_REQUEST_DOCUMENT = "CREATE_REQUEST_DOCUMENT";
export const CREATE_REQUEST_DOCUMENT_PENDING = "CREATE_REQUEST_DOCUMENT_PENDING";
export const CREATE_REQUEST_DOCUMENT_FULFILLED = "CREATE_REQUEST_DOCUMENT_FULFILLED";
export const CREATE_REQUEST_DOCUMENT_REJECTED = "CREATE_REQUEST_DOCUMENT_REJECTED";

export const createRequestDocument = projectId => ({
    type: CREATE_REQUEST_DOCUMENT,
    payload: macherService.createCraftsmanRequestDocument(projectId),
    meta: {
        projectId
    }
});

export const createRequestCoordinationDocument = (projectId, craftsmanFacilityId) => ({
    type: CREATE_REQUEST_DOCUMENT,
    payload: macherService.createCraftsmanRequestCoordinationDocument(projectId, craftsmanFacilityId),
    meta: {
        projectId,
        craftsmanFacilityId
    }
});

export const CREATE_CUSTOMEROFFER_DOCUMENT = "CREATE_CUSTOMEROFFER_DOCUMENT";
export const CREATE_CUSTOMEROFFER_DOCUMENT_PENDING = "CREATE_CUSTOMEROFFER_DOCUMENT_PENDING";
export const CREATE_CUSTOMEROFFER_DOCUMENT_FULFILLED = "CREATE_CUSTOMEROFFER_DOCUMENT_FULFILLED";
export const CREATE_CUSTOMEROFFER_DOCUMENT_REJECTED = "CREATE_CUSTOMEROFFER_DOCUMENT_REJECTED";

export const createCustomerOfferDocument = customerOfferId => ({
    type: CREATE_CUSTOMEROFFER_DOCUMENT,
    payload: realizationService.createCustomerOfferDocument(customerOfferId),
    meta: {
        customerOfferId
    }
});

export const GET_PROJECT_CUSTOMEROFFERS = "GET_PROJECT_CUSTOMEROFFERS";
export const GET_PROJECT_CUSTOMEROFFERS_PENDING = "GET_PROJECT_CUSTOMEROFFERS_PENDING";
export const GET_PROJECT_CUSTOMEROFFERS_FULFILLED = "GET_PROJECT_CUSTOMEROFFERS_FULFILLED";
export const GET_PROJECT_CUSTOMEROFFERS_REJECTED = "GET_PROJECT_CUSTOMEROFFERS_REJECTED";

export const getProjectCustomerOffers = params => ({
    type: GET_PROJECT_CUSTOMEROFFERS,
    meta: params,
    payload: realizationService.getCustomerOffers(params)
});

export const CREATE_PROJECT_CUSTOMEROFFER = "CREATE_PROJECT_CUSTOMEROFFER";
export const CREATE_PROJECT_CUSTOMEROFFER_PENDING = "CREATE_PROJECT_CUSTOMEROFFER_PENDING";
export const CREATE_PROJECT_CUSTOMEROFFER_FULFILLED = "CREATE_PROJECT_CUSTOMEROFFER_FULFILLED";
export const CREATE_PROJECT_CUSTOMEROFFER_REJECTED = "CREATE_PROJECT_CUSTOMEROFFER_REJECTED";

export const createCustomerOffer = data => ({
    type: CREATE_PROJECT_CUSTOMEROFFER,
    payload: realizationService.createCustomerOffer(data)
});

export const CREATE_PROJECT_CUSTOMEROFFER_NACHTRAG = "CREATE_PROJECT_CUSTOMEROFFER_NACHTRAG";
export const CREATE_PROJECT_CUSTOMEROFFER_NACHTRAG_PENDING = "CREATE_PROJECT_CUSTOMEROFFER_NACHTRAG_PENDING";
export const CREATE_PROJECT_CUSTOMEROFFER_NACHTRAG_FULFILLED = "CREATE_PROJECT_CUSTOMEROFFER_NACHTRAG_FULFILLED";
export const CREATE_PROJECT_CUSTOMEROFFER_NACHTRAG_REJECTED = "CREATE_PROJECT_CUSTOMEROFFER_NACHTRAG_REJECTED";

export const createCustomerOfferNachtrag = (macherProjektId, vermittlungId, data) => ({
    type: CREATE_PROJECT_CUSTOMEROFFER_NACHTRAG,
    payload: realizationService.nachtragErstellenTrigger(macherProjektId, vermittlungId, data)
});

export const DELETE_PROJECT_CUSTOMEROFFER = "DELETE_PROJECT_CUSTOMEROFFER";
export const DELETE_PROJECT_CUSTOMEROFFER_PENDING = "DELETE_PROJECT_CUSTOMEROFFER_PENDING";
export const DELETE_PROJECT_CUSTOMEROFFER_FULFILLED = "DELETE_PROJECT_CUSTOMEROFFER_FULFILLED";
export const DELETE_PROJECT_CUSTOMEROFFER_REJECTED = "DELETE_PROJECT_CUSTOMEROFFER_REJECTED";

export const deleteCustomerOffer = (customerOfferId, vermittlungId) => ({
    type: DELETE_PROJECT_CUSTOMEROFFER,
    meta: { customerOfferId, vermittlungId },
    payload: realizationService.deleteCustomerOffer(customerOfferId)
});

export const UPDATE_PROJECT_CUSTOMEROFFER = "UPDATE_PROJECT_CUSTOMEROFFER";
export const UPDATE_PROJECT_CUSTOMEROFFER_PENDING = "UPDATE_PROJECT_CUSTOMEROFFER_PENDING";
export const UPDATE_PROJECT_CUSTOMEROFFER_FULFILLED = "UPDATE_PROJECT_CUSTOMEROFFER_FULFILLED";
export const UPDATE_PROJECT_CUSTOMEROFFER_REJECTED = "UPDATE_PROJECT_CUSTOMEROFFER_REJECTED";

export const updateCustomerOffer = (customerOfferId, data, vermittlungId) => ({
    type: UPDATE_PROJECT_CUSTOMEROFFER,
    meta: { customerOfferId, vermittlungId },
    payload: realizationService.updateCustomerOffer(customerOfferId, data)
});

export const ADD_ASSIGNED_CRAFTSMEN = "ADD_ASSIGNED_CRAFTSMEN";
export const addAssignedCraftsmen = (vermittlungId, craftsmanFacilityIds) => ({
    type: ADD_ASSIGNED_CRAFTSMEN,
    meta: { vermittlungId },
    payload: craftsmanFacilityIds
});

export const DELETE_ASSIGNED_CRAFTSMAN = "DELETE_ASSIGNED_CRAFTSMAN";
export const deleteAssignedCraftsman = (vermittlungId, craftsmanFacilityId) => ({
    type: DELETE_ASSIGNED_CRAFTSMAN,
    meta: { vermittlungId },
    payload: craftsmanFacilityId
});

export const GET_PROJECT_SD_FORMS = "GET_PROJECT_SD_FORMS";
export const GET_PROJECT_SD_FORMS_PENDING = "GET_PROJECT_SD_FORMS_PENDING";
export const GET_PROJECT_SD_FORMS_FULFILLED = "GET_PROJECT_SD_FORMS_FULFILLED";
export const GET_PROJECT_SD_FORMS_REJECTED = "GET_PROJECT_SD_FORMS_REJECTED";

export const getProjectSDForms = projectId => ({
    type: GET_PROJECT_SD_FORMS,
    meta: { projectId },
    payload: realizationService.getSDForms(projectId)
});

export const GET_PKO_LIST = "GET_PKO_LIST";
export const GET_PKO_LIST_PENDING = "GET_PKO_LIST_PENDING";
export const GET_PKO_LIST_FULFILLED = "GET_PKO_LIST_FULFILLED";
export const GET_PKO_LIST_REJECTED = "GET_PKO_LIST_REJECTED";

export const getPKOList = () => ({
    type: GET_PKO_LIST,
    payload: macherService.getPKOList()
});
