import React from "react";
import LinkIcon from "../LinkIcon/LinkIcon";
import styles from "./Section.css";

const Section = props => {
    return (
        <section className={props.layout ? styles[props.layout] : styles.section}>
            {props.message ? props.message : null}
            <div className={styles.header}>
                {props.headline ? <h1 className={styles.headline}>{props.headline}</h1> : null}
                {props.showPrintButton ? (
                    <LinkIcon className={styles.printButton} iconName="IconArchive" onClick={e => window.print()}>
                        Drucken
                    </LinkIcon>
                ) : null}
            </div>
            <div>{props.children}</div>
        </section>
    );
};

export default Section;
