import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import XHR from "i18next-xhr-backend";

i18n.use(XHR)
    .use(initReactI18next)
    .init({
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        loadPath: "/static/locales/{{lng}}/{{ns}}.json",

        lng: "de",
        fallbackLng: "de",

        // have a common namespace used around the full app
        ns: ["translations", "messages", "feedbacks", "internals"],
        defaultNS: "translations",

        debug: process.env.NODE_ENV === "development",

        interpolation: {
            escapeValue: false // not needed for react!!
        },

        react: {
            wait: true
        }
    });

export default i18n;
