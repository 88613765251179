import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import foundation from "./Foundation.css";

export default class Cell extends Component {
    static propTypes = {
        small: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        medium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        large: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        smallOffset: PropTypes.number,
        mediumOffset: PropTypes.number,
        largeOffset: PropTypes.number,
        auto: PropTypes.bool,
        className: PropTypes.string
    };

    render() {
        return (
            <div
                className={classNames(foundation.cell, this.props.className, {
                    [foundation["small" + this.props.small]]: this.props.small,
                    [foundation["medium" + this.props.medium]]: this.props.medium,
                    [foundation["large" + this.props.large]]: this.props.large,
                    [foundation["smallOffset" + this.props.small]]: this.props.smallOffset,
                    [foundation["mediumOffset" + this.props.medium]]: this.props.mediumOffset,
                    [foundation["largeOffset" + this.props.large]]: this.props.largeOffset,
                    [foundation.auto]: this.props.auto,
                    [foundation.shrink]: this.props.shrink
                })}
            >
                {this.props.children}
            </div>
        );
    }
}
