import { IVorgangProfileState } from "../../interfaces/ApplicationState";
import { LOGOUT } from "../actions/auth.actions";
import {
    GET_MACHERPROJEKT_PROFILE_FULFILLED,
    GET_VORGANG_PROFILE_FULFILLED,
    GET_VORGANG_PROFILE_PENDING,
    GET_VORGANG_PROFILE_REJECTED,
    UPDATE_MACHERPROJEKT_PROFILE_FULFILLED,
    UPDATE_VORGANG_PROFILE_FULFILLED,
    UPDATE_VORGANG_PROFILE_PENDING,
    UPDATE_VORGANG_PROFILE_REJECTED
} from "../actions/macherProjekte.actions";

const initialState: IVorgangProfileState = {
    isFetching: false,
    failed: false
};

export function vorgangProfile(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        case GET_VORGANG_PROFILE_PENDING:
            return {
                ...state,
                isFetching: true
            };

        case GET_VORGANG_PROFILE_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true
                };
            }

        case GET_VORGANG_PROFILE_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                [action.meta.vorgangId]: action.payload.data
            };

        case GET_MACHERPROJEKT_PROFILE_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                ...action.payload.data.vorgaenge.reduce(
                    (acc, currVorgang) => ({
                        ...acc,
                        [currVorgang.id]: currVorgang
                    }),
                    {}
                )
            };

        case UPDATE_VORGANG_PROFILE_PENDING:
            return {
                ...state,
                isFetching: true
            };

        case UPDATE_VORGANG_PROFILE_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true
                };
            }

        case UPDATE_VORGANG_PROFILE_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                [action.meta.vorgangId]: action.payload.data
            };

        case UPDATE_MACHERPROJEKT_PROFILE_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                ...action.payload.data.vorgaenge.reduce(
                    (acc, currVorgang) => ({
                        ...acc,
                        [currVorgang.id]: currVorgang
                    }),
                    {}
                )
            };

        default:
            return state;
    }
}
