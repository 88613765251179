/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Address {
    country: string;
    state?: string;
    zip: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addition?: string;
    geoLocation?: GeoLocation;
}

export interface AddressDTO {
    country: string;
    state?: string;
    zip: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addition?: string;
    geoLocation?: GeoLocationDTO;
}

export interface AddressInCompanyDTO {
    countryCode: string;
    zip: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addition?: string;
    geoLocation?: GeoLocationInCompanyDTO;
}

export interface AddressPatchDTO {
    country?: string;
    state?: string;
    zip?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    addition?: string;
    geoLocation?: GeoLocation;
}

export interface AdresseListDTO {
    zip?: string;
    /** @format double */
    geoLocationLatitude?: number;
    /** @format double */
    geoLocationLongitude?: number;
    geoLocationConfidence?: Confidence;
    geoLocation: GeoLocationListDTO;
}

export enum AkquiseQuelle {
    KONTAKTFORMULAR = "KONTAKTFORMULAR",
    EIGENBEWERBUNG_HANDWERKER = "EIGENBEWERBUNG_HANDWERKER",
    HKO = "HKO",
    PKO = "PKO",
    INNENDIENST = "INNENDIENST",
    MARKT = "MARKT",
    KALTAKQUISE = "KALTAKQUISE",
    DIENSTLEISTER = "DIENSTLEISTER",
    JACANDO = "JACANDO",
    ORS = "ORS",
    EIGENBEWERBUNG_INNENDIENST = "EIGENBEWERBUNG_INNENDIENST",
    PARTNER_ACCOUNT_MANAGEMENT = "PARTNER_ACCOUNT_MANAGEMENT",
    PARTNER_MANAGEMENT = "PARTNER_MANAGEMENT"
}

export interface Bewertung {
    kriteriumName: string;
    kategorie: string;
    /** @format int32 */
    score: number;
}

export interface BewertungDTO {
    score: string;
    kategorie: string;
}

export interface Channel {
    /** @uniqueItems true */
    matching: Matching[];
    /** @uniqueItems true */
    statusResponse: StatusResponse[];
}

export interface ChannelDTO {
    /** @uniqueItems true */
    matching: Matching[];
    /** @uniqueItems true */
    statusResponse: StatusResponse[];
}

export interface ChannelPatchDTO {
    /** @uniqueItems true */
    matching?: Matching[];
    /** @uniqueItems true */
    statusResponse?: StatusResponse[];
}

export interface CompanyFacilityAddressCreateDTO {
    street?: string;
    houseNumber?: string;
    zip: string;
    city?: string;
    addition?: string;
}

export interface CompanyFacilityContactCreateDTO {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    phoneNumber?: string;
    faxNumber?: string;
}

export interface CompanyFacilityCreateDTO {
    facilityName: string;
    status: FacilityCreateStatus;
    partnerType: PartnerTyp;
    /** @format int32 */
    radius?: number;
    email: string;
    address: CompanyFacilityAddressCreateDTO;
    contact: CompanyFacilityContactCreateDTO;
    /** @uniqueItems true */
    acquisitionSources: AkquiseQuelle[];
    contractInformation?: string;
    operationArea?: OperationArea;
}

/**
 * The list of companyIds available for the tenant
 * @example null
 */
export interface CompanyIdLinkDTO {
    companyId: CountryCodeIdDTO;
    _link?: WebLinkDTO;
}

export interface CompanyProvisionCreateDTO {
    provisionType: ProvisionType;
    amount: number;
    leadType?: LeadType;
    /** @format date */
    validFrom?: string;
}

export interface CompanyProvisionDTO {
    craftsmanCompanyId: string;
    countryCode: string;
    provisionType: ProvisionType;
    amount: number;
    leadType?: LeadType;
    /** @format date */
    validFrom: string;
}

export enum Confidence {
    Unknown = "unknown",
    Invalid = "invalid",
    High = "high",
    Medium = "medium",
    Low = "low"
}

export interface Contact {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    mobileNumber?: string;
    faxNumber?: string;
}

export interface ContactDTO {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    mobileNumber?: string;
    faxNumber?: string;
}

export interface ContactInCompanyDTO {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    mobileNumber?: string;
}

export interface ContactListDTO {
    salutation?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    mobileNumber?: string;
}

/** @example null */
export interface CountryCodeIdDTO {
    /**
     * The ISO3166 two letter country code of object
     * @example "DE"
     */
    countryCode: string;
    /**
     * The unique ID of the object
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    value: string;
}

export interface CraftsmanCompany {
    id: string;
    countryCode: string;
    partnerName: string;
    website?: string;
    email?: string;
    /** @format int32 */
    version: number;
    heyOBIProfiData?: HeyObiProfiData;
}

export interface CraftsmanCompanyCreateDTO {
    partnerName: string;
    website?: string;
    email: string;
    craftsmanFacility: CompanyFacilityCreateDTO;
}

export interface CraftsmanCompanyDTO {
    id: string;
    countryCode: string;
    partnerName?: string;
    website?: string;
    email?: string;
    /** @format int32 */
    version: number;
    heyOBIProfiData?: HeyOBIProfiDataDTO;
    craftsmanFacilities: CraftsmanFacilityInCompanyDTO[];
}

export interface CraftsmanCompanyListDTO {
    id: string;
    countryCode: string;
    partnerName?: string;
    website?: string;
    email?: string;
    heyOBIProfiData?: HeyOBIProfiDataDTO;
}

export interface CraftsmanCompanyPatchDTO {
    partnerName?: string;
    website?: string;
    email?: string;
}

export interface CraftsmanCompanyWithoutFacilitiesDTO {
    id: string;
    countryCode: string;
    partnerName?: string;
    website?: string;
    email?: string;
    /** @format int32 */
    version: number;
    heyOBIProfiData?: HeyOBIProfiDataDTO;
}

export interface CraftsmanCoordinatorDTO {
    salutation: string;
    firstName: string;
    lastName: string;
    hkoRegions: string[];
    phoneNumber?: string;
    email?: string;
}

export interface CraftsmanFacility {
    id: string;
    shortId?: string;
    /** @format int32 */
    version: number;
    /** @format date-time */
    created: string;
    /** @format date-time */
    lastModified: string;
    status: FacilityStatus;
    facilityName: string;
    email: string;
    crefoNummer?: string;
    address: Address;
    contact?: Contact;
    /** @uniqueItems true */
    operationRoles: OperationRoles[];
    /** @format int32 */
    radius?: number;
    notes?: string;
    vertragsinfos?: string;
    /** @uniqueItems true */
    preferredCategories: PreferredCategories[];
    qualifications: Qualification[];
    marketBlacklist: Market[];
    documentLinks: CraftsmanFacilityDocumentLink[];
    jacandoId?: string;
    orsId?: string;
    ekPriceCluster?: string;
    newsletterChoice?: boolean;
    /** @format date */
    handwerkerKarteAusgestelltAm?: string;
    /** @format date */
    gewerbeAnmeldungAusgestelltAm?: string;
    /** @format date */
    betriebsHaftpflichtGueltigBis?: string;
    /** @format date */
    freistellungsBescheinigungGueltigBis?: string;
    erinnerungen: Erinnerung[];
    handwerkerKoordinatorRegion?: HandwerkerKoordinatorRegion;
    meisterBetrieb?: boolean;
    /** @uniqueItems true */
    akquiseQuellen: AkquiseQuelle[];
    /** @format date */
    zusammenarbeitSeit?: string;
    /** @format date */
    zusammenarbeitBis?: string;
    sapKreditorNr?: string;
    maerkte: Market[];
    /** @format date */
    abwesendVon?: string;
    /** @format date */
    abwesendBis?: string;
    channel: Channel;
    /** @format date */
    naechsteUmsetzungMoeglichAb?: string;
    umsatzsteuerId?: string;
    partnerTyp: PartnerTyp;
    handwerkerBewertungStatistik: HandwerkerBewertungStatistik;
    craftsmanCompany: CraftsmanCompany;
    partnerApi?: PartnerApi;
    /** @uniqueItems true */
    tasks: CraftsmanFacilityTask[];
    automaticallyAssignLead: boolean;
    operationArea?: OperationArea;
    /** @format int32 */
    monthlyLeadCapacity?: number;
    neu: boolean;
    /** @format int32 */
    operationZipRegionCount: number;
}

export interface CraftsmanFacilityAddTaskDTO {
    taskId: string;
}

export interface CraftsmanFacilityAddTasksDTO {
    tasks: CraftsmanFacilityAddTaskDTO[];
}

export interface CraftsmanFacilityComment {
    id: string;
    /** @format date-time */
    createdAt: string;
    createdBy: string;
    comment: string;
}

export interface CraftsmanFacilityDTO {
    id: string;
    orsId?: string;
    shortId?: string;
    /** @format int32 */
    version: number;
    status: FacilityStatus;
    facilityName: string;
    email: string;
    crefoNummer?: string;
    address: AddressDTO;
    contact?: ContactDTO;
    /** @uniqueItems true */
    operationRoles: OperationRoles[];
    /** @format int32 */
    radius?: number;
    notes?: string;
    vertragsinfos?: string;
    /** @uniqueItems true */
    preferredCategories: PreferredCategories[];
    qualifications: QualificationDTO[];
    marketBlacklist: MarketDTO[];
    ekPriceCluster?: string;
    newsletterChoice?: boolean;
    /** @format date */
    handwerkerKarteAusgestelltAm?: string;
    /** @format date */
    gewerbeAnmeldungAusgestelltAm?: string;
    /** @format date */
    betriebsHaftpflichtGueltigBis?: string;
    /** @format date */
    freistellungsBescheinigungGueltigBis?: string;
    erinnerungen: Erinnerung[];
    handwerkerKoordinatorRegion?: HandwerkerKoordinatorRegionDTO;
    meisterBetrieb?: boolean;
    /** @uniqueItems true */
    akquiseQuellen: AkquiseQuelle[];
    /** @format date */
    zusammenarbeitSeit?: string;
    /** @format date */
    zusammenarbeitBis?: string;
    sapKreditorNr?: string;
    maerkte: MarketDTO[];
    /** @format date */
    abwesendVon?: string;
    /** @format date */
    abwesendBis?: string;
    channel: ChannelDTO;
    /** @format date */
    naechsteUmsetzungMoeglichAb?: string;
    umsatzsteuerId?: string;
    partnerTyp: PartnerTyp;
    handwerkerBewertungStatistik: HandwerkerBewertungStatistikDTO;
    craftsmanCompany: CraftsmanCompanyWithoutFacilitiesDTO;
    partnerApi?: PartnerApi;
    tasks: TaskDTO[];
    /** @format int32 */
    operationZipRegionCount: number;
    automaticallyAssignLead: boolean;
    operationArea?: OperationArea;
    /** @format int32 */
    monthlyLeadCapacity?: number;
    documentLinks: CraftsmanFacilityDocumentLinkDTO[];
    neu: boolean;
}

export interface CraftsmanFacilityDocumentLink {
    documentId: string;
    craftsmanFacility: CraftsmanFacility;
    origin: OriginTypes;
    displayName?: string;
    contentType: string;
    deleted: boolean;
    tags: string[];
    /** @format date-time */
    createdAt: string;
}

export interface CraftsmanFacilityDocumentLinkDTO {
    documentId: string;
    origin: OriginTypes;
    displayName?: string;
    link: string;
    contentType: string;
    deleted: boolean;
    tags: string[];
    /** @format date-time */
    createdAt: string;
}

export interface CraftsmanFacilityInCompanyDTO {
    id: string;
    shortId: string;
    /** @format int32 */
    version: number;
    status: FacilityStatus;
    facilityName?: string;
    email: string;
    hkoRegion?: string;
    /** @format int32 */
    radius?: number;
    /** @format date */
    cooperationSince?: string;
    partnerType: PartnerTyp;
    address: AddressInCompanyDTO;
    contact: ContactInCompanyDTO;
    rating: RatingDTO;
    craftsmanFacilityCoordinator?: CraftsmanCoordinatorDTO;
    automaticallyAssignLead: boolean;
    /** @uniqueItems true */
    operationRoles: OperationRoles[];
    reminders: ReminderDTO[];
    /** @uniqueItems true */
    preferredCategories: PreferredCategories[];
    operationArea?: OperationArea;
    /** @uniqueItems true */
    acquisitionSources: AkquiseQuelle[];
    contractInformation?: string;
    newsletterChoice?: boolean;
    new: boolean;
}

export interface CraftsmanFacilityListDTO {
    id: string;
    shortId: string;
    status: FacilityStatus;
    facilityName?: string;
    email: string;
    addressZip?: string;
    /** @format double */
    addressGeoLocationLatitude?: number;
    /** @format double */
    addressGeoLocationLongitude?: number;
    addressGeoLocationConfidence?: Confidence;
    contactSalutation?: string;
    contactFirstName?: string;
    contactLastName?: string;
    contactPhoneNumber?: string;
    contactMobileNumber?: string;
    handwerkerKoordinatorAnrede?: string;
    handwerkerKoordinatorVorname?: string;
    handwerkerKoordinatorNachname?: string;
    handwerkerKoordinatorRegion?: string;
    /** @format int32 */
    radius?: number;
    /** @format int32 */
    handwerkerBewertungStatistikBewertungAnzahl?: number;
    /** @format double */
    handwerkerBewertungStatistikBewertungScore?: number;
    /** @format date */
    zusammenarbeitSeit?: string;
    partnerTyp: PartnerTyp;
    address: AdresseListDTO;
    contact: ContactListDTO;
    handwerkerBewertungStatistik: HandwerkerBewertungStatistikListDTO;
    handwerkerKoordinator?: HandwerkerKoordinatorListDTO;
    /** @uniqueItems true */
    operationRoles: OperationRoles[];
    erinnerungen: ErinnerungListDTO[];
    /** @uniqueItems true */
    preferredCategories: PreferredCategories[];
    neu: boolean;
}

export interface CraftsmanFacilityOperationZipRegionsDTO {
    method: "ADD" | "REPLACE" | "DELETE";
    zipRegions: string[];
}

export interface CraftsmanFacilityOperationZipRegionsResponseDTO {
    zipRegions: string[];
}

export interface CraftsmanFacilityPatchRequest {
    status?: FacilityStatus;
    facilityName?: string;
    email?: string;
    address?: AddressPatchDTO;
    contact?: Contact;
    /** @format int32 */
    radius?: number;
    notes?: string;
    vertragsinfos?: string;
    preferredCategories?: PreferredCategories[];
    operationRoles?: OperationRoles[];
    qualifications?: Qualification[];
    marketBlacklist?: MarketId[];
    crefoNummer?: string;
    orsId?: string;
    ekPriceCluster?: string;
    newsletterChoice?: boolean;
    handwerkerKarteAusgestelltAm?: string;
    gewerbeAnmeldungAusgestelltAm?: string;
    betriebsHaftpflichtGueltigBis?: string;
    freistellungsBescheinigungGueltigBis?: string;
    erinnerungen?: Erinnerung[];
    meisterBetrieb?: boolean;
    akquiseQuellen?: AkquiseQuelle[];
    zusammenarbeitSeit?: string;
    zusammenarbeitBis?: string;
    website?: string;
    sapKreditorNr?: string;
    maerkte?: MarketId[];
    abwesendVon?: string;
    abwesendBis?: string;
    channel?: ChannelPatchDTO;
    naechsteUmsetzungMoeglichAb?: string;
    umsatzsteuerId?: string;
    partnerTyp?: PartnerTyp;
    automaticallyAssignLead?: boolean;
    operationArea?: OperationArea;
    /** @format int32 */
    monthlyLeadCapacity?: number;
}

export interface CraftsmanFacilityTask {
    craftsmanFacility: CraftsmanFacility;
    task: Task;
}

export interface CraftsmanFacilityTaskDTO {
    id: string;
    displayName: string;
    category: string;
    subCategory: string;
    suggestion: boolean;
}

export interface DocumentInfoDTO {
    displayName: string;
    documentTags: string[];
}

export interface DocumentPatchRequest {
    displayName?: string;
    documentTags?: string[];
    deleted?: boolean;
}

export interface Erinnerung {
    /** @format date */
    datum: string;
    grund: string;
}

export interface ErinnerungListDTO {
    /** @format date */
    datum: string;
    grund: string;
}

export interface ErrorDescription {
    error: string;
}

export enum FacilityCreateStatus {
    Akquise = "akquise",
    ToBeChecked = "toBeChecked"
}

/**
 * The list of facilityIds available for the tenant
 * @example null
 */
export interface FacilityIdLinkDTO {
    companyId: CountryCodeIdDTO;
    facilityId: CountryCodeIdDTO;
    _link?: WebLinkDTO;
}

export enum FacilityStatus {
    Active = "active",
    Disabled = "disabled",
    TemporarilyDisabled = "temporarilyDisabled",
    ToBeChecked = "toBeChecked",
    Akquise = "akquise",
    Abgebrochen = "abgebrochen"
}

export interface FieldErrorDTO {
    errors: LucifyFieldError[];
}

export interface GeoLocation {
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    confidence: Confidence;
}

export interface GeoLocationDTO {
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    confidence: Confidence;
}

export interface GeoLocationInCompanyDTO {
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    confidence: Confidence;
}

export interface GeoLocationListDTO {
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
    confidence: Confidence;
}

export interface HandwerkerBewertung {
    id: string;
    /** @format date-time */
    createdAt: string;
    bewerter: User;
    projektBezug?: string;
    kommentar?: string;
    bewertungen: Bewertung[];
}

export interface HandwerkerBewertungDTO {
    projektBezug?: string;
    kommentar?: string;
    bewertungen: Record<string, BewertungDTO>;
}

export interface HandwerkerBewertungStatisticsDTO {
    /** @format double */
    scoreArbeitsqualitaet: number;
    /** @format int32 */
    anzahlPreisgestaltung: number;
    /** @format int32 */
    anzahlFreundlichkeit: number;
    /** @format double */
    scoreFreundlichkeit: number;
    /** @format int32 */
    anzahlErreichbarkeit: number;
    /** @format double */
    scoreErreichbarkeit: number;
    /** @format int32 */
    anzahlPuenktlichkeit: number;
    /** @format double */
    scorePuenktlichkeit: number;
    /** @format int32 */
    anzahlLoyalitaet: number;
    /** @format int32 */
    anzahlArbeitsqualitaet: number;
    /** @format double */
    scoreLoyalitaet: number;
    /** @format double */
    scorePreisgestaltung: number;
    /** @format int32 */
    anzahlProfessionellesAuftreten: number;
    /** @format double */
    scoreProfessionellesAuftreten: number;
    /** @format int32 */
    anzahlKundenzufriedenheit: number;
    /** @format double */
    scoreKundenzufriedenheit: number;
}

export interface HandwerkerBewertungStatistik {
    /** @format int32 */
    bewertungAnzahl: number;
    /** @format double */
    bewertungScore: number;
}

export interface HandwerkerBewertungStatistikDTO {
    /** @format int32 */
    bewertungAnzahl: number;
    /** @format double */
    bewertungScore: number;
}

export interface HandwerkerBewertungStatistikListDTO {
    /** @format int32 */
    bewertungAnzahl: number;
    /** @format double */
    bewertungScore: number;
}

export interface HandwerkerKoordinator {
    /** @pattern [A-Z][A-Z] */
    countryCode: string;
    anrede: string;
    vorname: string;
    nachname: string;
    email: string;
    telefon: string;
    regions: HandwerkerKoordinatorRegion[];
}

export interface HandwerkerKoordinatorCreateDTO {
    hkoRegions: string[];
    anrede: string;
    vorname: string;
    nachname: string;
    email: string;
    telefon: string;
}

export interface HandwerkerKoordinatorDTO {
    id: string;
    countryCode: string;
    hkoRegions: string[];
    anrede: string;
    vorname: string;
    nachname: string;
    email: string;
    telefon: string;
}

export interface HandwerkerKoordinatorListDTO {
    anrede: string;
    vorname: string;
    nachname: string;
}

export interface HandwerkerKoordinatorPatchDTO {
    hkoRegions?: string[];
    anrede?: string;
    vorname?: string;
    nachname?: string;
    email?: string;
    telefon?: string;
}

export interface HandwerkerKoordinatorRegion {
    handwerkerKoordinatorRegionId: HandwerkerKoordinatorRegionId;
    displayName: string;
    handwerkerKoordinator?: HandwerkerKoordinator;
}

export interface HandwerkerKoordinatorRegionDTO {
    name: string;
    countryCode: string;
    displayName: string;
    handwerkerKoordinator?: HandwerkerKoordinatorDTO;
}

export interface HandwerkerKoordinatorRegionId {
    name: string;
    countryCode: string;
}

export interface HeyOBIProfiDataDTO {
    id: string;
    /** @format date-time */
    eventTime: string;
    customerLevel: string;
}

export interface HeyObiProfiData {
    id: string;
    /** @format date-time */
    eventTime: string;
    customerLevel: string;
}

export interface KontaktFormularDTO {
    firmenname: string;
    radius: string;
    anrede?: string;
    vorname: string;
    nachname: string;
    strasse: string;
    hausnummer: string;
    plz: string;
    ort: string;
    telefon: string;
    datenschutzUndAgbZugestimmt: boolean;
    email: string;
    kommentar?: string;
}

export enum LeadType {
    PARTNER_BESTAETIGT = "PARTNER_BESTAETIGT",
    KUNDE_KONTAKTIERT = "KUNDE_KONTAKTIERT",
    ANGEBOT_GESENDET = "ANGEBOT_GESENDET",
    ANGEBOT_ANGENOMMEN = "ANGEBOT_ANGENOMMEN",
    UMGESETZT = "UMGESETZT"
}

export interface LucifyCountryValueDTO {
    countryCode: string;
    partValue: string;
}

export interface LucifyFieldError {
    objectName: string;
    field: string;
    rejectedValue?: object;
    defaultMessage?: string;
}

export interface LucifyTenantCreateDTO {
    parts: Record<string, LucifyCountryValueDTO>;
}

export interface LucifyTenantPatchDTO {
    parts: Record<string, LucifyCountryValueDTO>;
}

export interface LucifyTenantReadDTO {
    lucifyTenantGroup: string;
    parts: Record<string, LucifyCountryValueDTO>;
}

export interface LucifyUserCreateDTO {
    externalUserId: string;
    lucifyTenants?: LucifyTenantCreateDTO[];
}

export interface LucifyUserPatchDTO {
    lucifyTenants: LucifyTenantPatchDTO[];
    /** @format int32 */
    version: number;
}

export interface LucifyUserReadDTO {
    externalUserId: string;
    /** @format int32 */
    version: number;
    externalUserProvider: "KEYCLOAK";
    lucifyTenants?: LucifyTenantReadDTO[];
}

export interface Market {
    id: string;
    /** @format int32 */
    version: number;
    country: string;
    type: string;
    vertriebsRegion: VertriebsRegion;
    description: string;
    street: string;
    zip: string;
    city: string;
    state: string;
    geoLocation: GeoLocation;
    phoneNumber?: string;
    active: boolean;
}

export interface MarketDTO {
    id: string;
    country: string;
    type: string;
    vertriebsRegion: VertriebsRegion;
    description: string;
    street: string;
    zip: string;
    city: string;
    state: string;
    geoLocation: GeoLocationDTO;
    phoneNumber?: string;
    active: boolean;
}

export interface MarketId {
    id: string;
}

export enum Matching {
    EMAIL = "EMAIL",
    WHATSAPP = "WHATSAPP",
    MOBIL = "MOBIL",
    FESTNETZ = "FESTNETZ",
    API = "API"
}

export interface MultiValueMapStringString {
    all?: Record<string, string>;
    empty?: boolean;
    [key: string]: any;
}

export interface OneTimeToken {
    token: string;
    /** @format date-time */
    expireAt: string;
}

export enum OperationArea {
    National = "National",
    Regional = "Regional"
}

export enum OperationRoles {
    VERMITTLUNG = "VERMITTLUNG",
    BETREUUNG = "BETREUUNG"
}

export enum OriginTypes {
    LUCIFY = "LUCIFY",
    INNENDIENST = "INNENDIENST"
}

export enum PartnerApi {
    Installion = "Installion",
    Schueco = "Schueco",
    Schweng = "Schweng",
    Zolar = "Zolar",
    DZ4 = "DZ4",
    POS = "POS",
    Lichtblick = "Lichtblick",
    Aluterr = "Aluterr",
    VELUX = "VELUX",
    HubSpot = "HubSpot",
    RotoFrank = "RotoFrank",
    Vamo = "Vamo",
    Swimmondo = "Swimmondo",
    Soly = "Soly"
}

export interface PartnerCompanyDTO {
    /**
     * The unique partners company UUID
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    id: string;
    /**
     * The ISO3166 two letter country code of the partners company
     * @example "DE"
     */
    countryCode: string;
    /**
     * The human readable name of the partners company
     * @example "Mustermann GmbH"
     */
    partnerName: string;
    /**
     * The version of the partners company object
     * @format int32
     * @example 1
     */
    version: number;
    /**
     * The list of the partners company facilities
     * @example null
     */
    facilities: PartnerCompanyFacilityDTO[];
    /**
     * The website of the partners company
     * @example "www.example.com"
     */
    website?: string;
    /**
     * The email of the partners company
     * @example "info@example.com"
     */
    email?: string;
}

/**
 * The list of the partners company facilities
 * @example null
 */
export interface PartnerCompanyFacilityDTO {
    /**
     * The ISO3166 two letter country code of the partners company
     * @example "DE"
     */
    countryCode: string;
    /**
     * The unique partners facility UUID
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    id: string;
    /**
     * The unique partners facility short id
     * @example "LUCIFY"
     */
    shortId: string;
    /**
     * The version of the facility
     * @format int32
     * @example 1
     */
    version: number;
    /**
     * The current status of the partners facility
     * @example "active"
     */
    status: string;
    /**
     * The human readable name of the partners facility
     * @example "Craftsman Markus Mustermann"
     */
    facilityName: string;
    _link?: WebLinkDTO;
}

/** @example null */
export interface PartnerFacilityAddressDTO {
    /** @example "null" */
    countryCode: string;
    /** @example "null" */
    zip: string;
    /** @example "null" */
    city?: string;
    /** @example "null" */
    street?: string;
    /** @example "null" */
    houseNumber?: string;
    /** @example "null" */
    addition?: string;
    geoLocation?: PartnerFacilityGeoLocationDTO;
}

/** @example null */
export interface PartnerFacilityContactDTO {
    /** @example "null" */
    salutation?: string;
    /** @example "null" */
    firstName?: string;
    /** @example "null" */
    lastName?: string;
    /** @example "null" */
    phoneNumber?: string;
    /** @example "null" */
    mobileNumber?: string;
}

/** @example null */
export interface PartnerFacilityCoordinatorDTO {
    /** @example "null" */
    salutation: string;
    /** @example "null" */
    firstName: string;
    /** @example "null" */
    lastName: string;
}

export interface PartnerFacilityDTO {
    /** @example "null" */
    id: string;
    /** @example "null" */
    shortId: string;
    /** @example "null" */
    status: string;
    /** @example "null" */
    email: string;
    address: PartnerFacilityAddressDTO;
    contact: PartnerFacilityContactDTO;
    rating: PartnerFacilityRatingDTO;
    /** @format int32 */
    version?: number;
    /** @example "null" */
    facilityName?: string;
    /** @format int32 */
    radius?: number;
    /** @format date */
    cooperationSince?: string;
    craftsmanFacilityCoordinator?: PartnerFacilityCoordinatorDTO;
    /** @example false */
    automaticallyAssignLead?: boolean;
    /** @example "null" */
    operationArea?: string;
    /** @example false */
    newsletterChoice?: boolean;
}

/** @example null */
export interface PartnerFacilityGeoLocationDTO {
    /** @example "null" */
    confidence: string;
    /** @format double */
    latitude?: number;
    /** @format double */
    longitude?: number;
}

/** @example null */
export interface PartnerFacilityRatingDTO {
    /** @format int32 */
    amount?: number;
    /** @format double */
    score?: number;
}

export enum PartnerTyp {
    Beratungspartner = "Beratungspartner",
    Handwerker = "Handwerker",
    Lieferantenaufbauservice = "Lieferantenaufbauservice",
    Umsetzungspartner = "Umsetzungspartner",
    E2E = "E2E",
    Kuechenmonteur = "Kuechenmonteur"
}

export enum PreferredCategories {
    GARTEN = "GARTEN",
    BAD = "BAD",
    RAUM = "RAUM",
    BAUELEMENTE = "BAUELEMENTE",
    SONSTIGES = "SONSTIGES"
}

export interface PriceCluster {
    clusterNumber: string;
    /** @format double */
    netSellingPrice: number;
    /** @format double */
    netPurchasePrice: number;
}

export enum ProvisionType {
    GMV = "GMV",
    LEAD = "LEAD"
}

export interface Qualification {
    id: string;
    /** @format int32 */
    version: number;
    country?: string;
    name?: string;
    active: boolean;
}

export interface QualificationDTO {
    id: string;
    /** @format int32 */
    version: number;
    country?: string;
    name?: string;
    active: boolean;
}

export interface RatingDTO {
    /** @format int32 */
    amount: number;
    /** @format double */
    score: number;
}

export interface ReminderDTO {
    /** @format date */
    date: string;
    reason: string;
}

export enum StatusResponse {
    EXCEL = "EXCEL",
    EMAIL = "EMAIL",
    PKO = "PKO",
    FESTNETZ = "FESTNETZ",
    MOBIL = "MOBIL",
    API = "API"
}

export type StreamingResponseBody = object;

export interface Task {
    id: TaskId;
    displayName: string;
    kafkaKey: string;
    category: string;
    subCategory: string;
    /** @uniqueItems true */
    qualificationMappings: Qualification[];
    /** @format int32 */
    version: number;
    /** @format date-time */
    lastModified: string;
    active: boolean;
    questions: TaskQuestion[];
    /** @uniqueItems true */
    generalQuestionGroups: TaskGeneralQuestionGroup[];
}

export interface TaskDTO {
    id: TaskId;
    displayName: string;
    kafkaKey: string;
    category: string;
    subCategory: string;
    qualificationMappings: QualificationDTO[];
    /** @format int32 */
    version: number;
    /** @format date-time */
    lastModified: string;
    active: boolean;
}

export interface TaskGeneralQuestion {
    id: TaskGeneralQuestionId;
    active: boolean;
    question: string;
    display: string;
    type: "SELECT" | "MULTI_SELECT" | "INTEGER" | "DECIMAL" | "STRING" | "BOOLEAN";
    choices: TaskGeneralQuestionChoice[];
}

export interface TaskGeneralQuestionChoice {
    label: string;
    group?: string;
    value: string;
}

export interface TaskGeneralQuestionGroup {
    id: TaskGeneralQuestionGroupId;
    active: boolean;
    label: string;
    questions: TaskGeneralQuestion[];
}

export interface TaskGeneralQuestionGroupId {
    taskCountryCode: string;
    taskId: string;
    group: string;
}

export interface TaskGeneralQuestionId {
    taskCountryCode: string;
    taskId: string;
    group: string;
    parameterName: string;
}

export interface TaskId {
    countryCode: string;
    id: string;
}

export interface TaskQuestion {
    id: TaskQuestionId;
    active: boolean;
    question: string;
    display: string;
    type: "SELECT" | "MULTI_SELECT" | "INTEGER" | "DECIMAL" | "STRING" | "BOOLEAN";
    choices: TaskQuestionChoice[];
}

export interface TaskQuestionChoice {
    label: string;
    group?: string;
    value: string;
}

export interface TaskQuestionId {
    countryCode: string;
    taskId: string;
    parameterName: string;
}

export interface TenantEntryInformationDTO {
    userInformation: TenantEntryInformationUserInformationDTO;
    /**
     * The list of companyIds available for the tenant
     * @example null
     */
    companyTenants?: CompanyIdLinkDTO[];
    /**
     * The list of facilityIds available for the tenant
     * @example null
     */
    facilityTenants?: FacilityIdLinkDTO[];
}

/** @example null */
export interface TenantEntryInformationUserInformationDTO {
    /**
     * External user id as defined by the ID-provider
     * @example "123"
     */
    externalUserId: string;
    /**
     * The external ID-provider of the authentication
     * @example "KEYCLOAK"
     */
    externalIdProvider: string;
    /**
     * Internal user id
     * @example "3abed447-5c3a-421a-bcb4-3c4f5f9ec967"
     */
    id?: string;
}

export type Unit = object;

export interface User {
    email?: string;
    nachname: string;
    vorname: string;
    rolle: string;
}

export enum VertriebsRegion {
    UNBEKANNT = "UNBEKANNT",
    DE_NORDOST = "DE_NORDOST",
    DE_NORDWEST = "DE_NORDWEST",
    DE_SUED = "DE_SUED",
    DE_SUEDWEST = "DE_SUEDWEST",
    DE_FRANCHISEPARTNER = "DE_FRANCHISEPARTNER",
    DE_INTERN = "DE_INTERN",
    AT = "AT",
    ATFM1 = "AT_FM_1",
    ATFM2 = "AT_FM_2",
    ATFM3 = "AT_FM_3",
    ATFM4 = "AT_FM_4",
    ATFM5 = "AT_FM_5",
    ATFM6 = "AT_FM_6",
    AT_FRANCHISEPARTNER = "AT_FRANCHISEPARTNER",
    AT_INTERN = "AT_INTERN"
}

/** @example null */
export interface WebLinkDTO {
    /**
     * The URI of the resource
     * @example "http//example.org/my-resource-path/12345"
     */
    href: string;
    /**
     * The optional relation of the link
     * @example "null"
     */
    rel: string;
    /**
     * The optional human readable title of the link
     * @example "null"
     */
    title?: string;
    /**
     * The operation id of the partner api
     * @example "getPartnerFacility"
     */
    apiOperation?: string;
}

export interface WorkTask {
    id: string;
    /** @format int32 */
    version: number;
    articleNumber?: string;
    trade?: string;
    category: string;
    name?: string;
    description?: string;
    unit?: string;
    /** @format double */
    tax?: number;
    active?: boolean;
    /** @format float */
    timeFactor?: number;
    priceClusters?: PriceCluster[];
}

export interface ZipRegion {
    id: string;
    /** @pattern [A-Z][A-Z] */
    countryCode: string;
    zipCode: string;
    handwerkerKoordinatorRegion: HandwerkerKoordinatorRegion;
}

export interface ZipRegionPutDTO {
    zipRegion: string;
}
