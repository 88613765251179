import { connectRouter, routerMiddleware } from "connected-react-router";
// routing and store
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import promise from "redux-promise-middleware";
// vendor redux-breakpoint
import breakpointReducer from "../utils/redux-breakpoint/breakPointReducer";
import syncBreakpointWithStore from "../utils/redux-breakpoint/index";
import errorMiddleware from "./middlewares/errorMiddleware";
// reducers
import { auth } from "./reducers/auth.reducer";
import { craftsmen } from "./reducers/craftsmen.reducer";
import { kpis } from "./reducers/kpis.reducer";
import { extendedVorgangProfile } from "./reducers/macherProjekte.extendedVorgangProfile.reducer";
import { macherProjektProfile } from "./reducers/macherProjekte.profile.reducer";
import { vorgangProfile } from "./reducers/macherProjekte.vorgangProfile.reducer";
import { markets } from "./reducers/markets.reducer";
import { matching } from "./reducers/matching.reducer";
import { notifications } from "./reducers/notifications.reducer";
import { projectsAssignedCraftsmen } from "./reducers/projects.assignedCraftsmen.reducer";
import { projectsComments } from "./reducers/projects.comments.reducer";
import { projectsCustomerOffers } from "./reducers/projects.customerOffers.reducer";
import { projectsDocuments } from "./reducers/projects.documents.reducer";
import { projectsHistory } from "./reducers/projects.history.reducer";
import { projectsList } from "./reducers/projects.list.reducer";
import { projectsPKOs } from "./reducers/projects.pkos.reducer";
import { projectsProfile } from "./reducers/projects.profile.reducer";
import { projectsSDForms } from "./reducers/projects.sdforms.reducer";
import { qualifications } from "./reducers/qualifications.reducer";
import { states } from "./reducers/states.reducer";
import { ui } from "./reducers/ui.reducer";
import { workTasks } from "./reducers/worktasks.reducer";

export const history = createBrowserHistory();

let composeEnhancers = compose;

const initialState = {};

if (process.env.NODE_ENV === "development") {
    composeEnhancers = (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) || compose;
}

const rootReducer = combineReducers({
    router: connectRouter(history),
    breakpoint: breakpointReducer,
    auth,
    ui,
    craftsmen,
    macherProjekte: combineReducers({
        macherProjektProfile,
        vorgangProfile,
        extendedVorgangProfile
    }),
    projects: combineReducers({
        projectsList,
        projectsProfile,
        projectsComments,
        projectsHistory,
        projectsDocuments,
        projectsCustomerOffers,
        projectsAssignedCraftsmen,
        projectsSDForms,
        projectsPKOs
    }),
    states,
    matching,
    markets,
    qualifications,
    kpis,
    workTasks,
    notifications
});

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), errorMiddleware, promise))
);

syncBreakpointWithStore(store);

export default store;

export type RootState = ReturnType<typeof rootReducer>;
