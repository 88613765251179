import React, { PureComponent } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { HeadlineProps, possibleHeadings } from "../Typo/Headline";
import styles from "./Container.css";

interface UIContainerHeadline extends HeadlineProps {
    lightboxed?: boolean;
}

export default class ContainerHeadline extends PureComponent<UIContainerHeadline> {
    static defaultProps = {
        level: 2,
        count: null,
        underlined: false,
        icon: null,
        standalone: true,
        variant: null,
        lightboxed: false
    };

    static propTypes = {
        headline: PropType.string.isRequired,
        count: PropType.any,
        level: PropType.number,
        underlined: PropType.bool,
        icon: PropType.element,
        standalone: PropType.bool,
        variant: PropType.string,
        lightboxed: PropType.bool
    };

    render() {
        const Headline = `h${this.props.level}` as possibleHeadings;

        return (
            <Headline
                className={classNames(styles.headline, {
                    [styles.underlined]: this.props.underlined,
                    [styles.standalone]: this.props.standalone,
                    [styles.lightboxed]: this.props.lightboxed,
                    [styles[this.props.variant!]]: this.props.variant
                })}
            >
                {this.props.headline}
                {this.props.count ? <span className={styles.count}> ({this.props.count || 0})</span> : null}
                {this.props.icon ? <span className={styles.headlineIcon}>{this.props.icon}</span> : null}
            </Headline>
        );
    }
}
