import React, { FC, ReactNode } from "react";
import { checkPermission } from "@lucify/auth";
import { lucifyOIDCProvider } from "../../services/lucifyOIDCProvider";

interface CanProps {
    children?: ReactNode;
    perform?: string;
}

export const Can: FC<CanProps> = props => {
    return <>{!props.perform || checkPermission(lucifyOIDCProvider, props.perform) ? props.children : null}</>;
};

export default Can;
