import { LOGOUT } from "../actions/auth.actions";
import {
    GET_PROJECT_HISTORY_FULFILLED,
    GET_PROJECT_HISTORY_PENDING,
    GET_PROJECT_HISTORY_REJECTED
} from "../actions/projects.actions";

const initialState = {
    isFetching: false,
    failed: false
};

export function projectsHistory(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        case GET_PROJECT_HISTORY_PENDING:
            return {
                ...state,
                isFetching: true,
                failed: false
            };

        case GET_PROJECT_HISTORY_REJECTED:
            return {
                ...state,
                status: action.payload.response.status,
                isFetching: false,
                failed: true
            };

        case GET_PROJECT_HISTORY_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                [action.meta.projectId]: action.payload.data
            };

        default:
            return state;
    }
}
