export const TOGGLE_MENU = "TOGGLE_MENU";

export function toggleMenu(isOpen = false, menuId) {
    return {
        type: TOGGLE_MENU,
        payload: { isOpen, menuId }
    };
}

export const TOGGLE_LIGHTBOX = "TOGGLE_LIGHTBOX";

export function toggleLightbox(isOpen = false) {
    return {
        type: TOGGLE_LIGHTBOX,
        payload: isOpen
    };
}

export const SET_HEADER_HEIGHTS = "SET_HEADER_HEIGHTS";

export function setHeaderHeights(extendedBaseHeight) {
    return {
        type: SET_HEADER_HEIGHTS,
        payload: { extendedBaseHeight }
    };
}
