import { macherService } from "../../services/macher/macher";

export const GET_MARKETS = "GET_MARKETS";
export const GET_MARKETS_PENDING = "GET_MARKETS_PENDING";
export const GET_MARKETS_FULFILLED = "GET_MARKETS_FULFILLED";
export const GET_MARKETS_REJECTED = "GET_MARKETS_REJECTED";

export const getMarkets = country => {
    return {
        type: GET_MARKETS,
        payload: macherService.getMarkets()
    };
};
