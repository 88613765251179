import React, { ButtonHTMLAttributes, Component, SyntheticEvent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import Icon from "../Icon/Icon";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import styles from "./Button.css";

export type ButtonVariant = "primary" | "primaryInvert" | "secondary" | "blank" | "inlineLink" | "blankInvert" | "none";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant;
    disabled?: boolean;
    fullWidth?: boolean;
    mini?: boolean;
    className?: string;
    loading?: boolean;
    icon?: string;
    small?: boolean;
    square?: boolean;
    hasIndicator?: boolean;
    inputRef?: any;
}

interface ButtonState {
    hover: boolean;
}

export default class Button extends Component<ButtonProps, ButtonState> {
    static defaultProps = {
        variant: "primary",
        disabled: false,
        fullWidth: false,
        className: "",
        type: "button",
        loading: false,
        icon: ""
    };

    state = {
        hover: false
    };

    @autobind
    setHover(event: SyntheticEvent) {
        this.setState({
            ...this.state,
            hover: event.type === "mouseenter"
        });
    }

    render() {
        const {
            variant,
            fullWidth,
            mini,
            disabled,
            className,
            loading,
            type,
            children,
            icon,
            square,
            hasIndicator,
            small,
            inputRef,
            ...rest
        } = this.props;

        return (
            <button
                className={classNames(styles[variant!], className, {
                    [styles.expand]: fullWidth,
                    [styles.mini]: mini,
                    [styles.square]: square,
                    [styles.smallText]: small,
                    [styles.disabled]: disabled,
                    [styles.hover]: this.state.hover && !disabled,
                    [styles.buttonHasIndicator]: loading || icon || hasIndicator,
                    [styles.iconOnly]: icon && !children
                })}
                type={type}
                onMouseEnter={this.setHover}
                onMouseLeave={this.setHover}
                {...rest}
                ref={inputRef}
            >
                <span className={loading && (mini || square) ? styles.hidden : undefined}>{children}</span>

                {loading ? (
                    <LoadingIndicator
                        variant={
                            (this.state.hover && !(variant === "primaryInvert")) ||
                            (!this.state.hover && variant === "primaryInvert") ||
                            disabled
                                ? "onLight"
                                : "onDark"
                        }
                        className={classNames(styles.indicator)}
                    />
                ) : icon ? (
                    <Icon name={icon} className={styles.indicator} />
                ) : null}
            </button>
        );
    }
}
