import { IExtendedVorgangProfileState } from "../../interfaces/ApplicationState";
import { LOGOUT } from "../actions/auth.actions";
import {
    GET_EXTENDED_VORGANG_PROFILE_FULFILLED,
    GET_EXTENDED_VORGANG_PROFILE_PENDING,
    GET_EXTENDED_VORGANG_PROFILE_REJECTED
} from "../actions/macherProjekte.actions";

const initialState: IExtendedVorgangProfileState = {
    isFetching: false,
    failed: false
};

export function extendedVorgangProfile(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        case GET_EXTENDED_VORGANG_PROFILE_PENDING:
            return {
                ...state,
                isFetching: true
            };

        case GET_EXTENDED_VORGANG_PROFILE_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true
                };
            }

        case GET_EXTENDED_VORGANG_PROFILE_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                [action.meta.vorgangId]: action.payload.data
            };

        default:
            return state;
    }
}
