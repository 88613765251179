import React, { Fragment } from "react";
import { components } from "react-select";
import classNames from "classnames";
import { noop } from "../../../utils/helpers/render";
import BusinessCard from "../../BusinessCard/BusinessCard";
import Icon from "../../Icon/Icon";
import checkStyles from "../Checkbox.css";
import styles from "../Select.css";

export const DropdownIndicator = ({ innerProps, ...props }) => {
    const { name, field = {} } = props.selectProps;
    const inputName = name || field.name || "";

    const newProps = {
        ...props,
        innerProps: {
            ...innerProps,
            ...(props.selectProps
                ? {
                      "data-cy": "DropdownIndicator" + inputName
                  }
                : {})
        }
    };

    return (
        <components.DropdownIndicator {...newProps}>
            <Icon className={styles.selectIcon} name={"IconArrowDown"} />
        </components.DropdownIndicator>
    );
};

export const DropdownIndicatorSearch = () => {
    return (
        <div className={styles.dropdownIndicator}>
            <Icon className={styles.selectIcon} name={"IconSearch"} />
        </div>
    );
};

export const Option = ({ innerProps: { onMouseMove, onMouseOver, ...newInnerProps } = {}, ...props }) => {
    const attributes = {
        ...newInnerProps,
        "data-value": props.data.value
    };

    return <components.Option innerProps={attributes} {...props} />;
};

export const OptionCheckbox = ({ children, data, innerProps, ...props }) => {
    const input = React.createRef();
    const onClick = event => {
        event.preventDefault();
        input.current.checked = !input.current.checked;
        innerProps.onClick(input.current.checked);
    };

    return (
        <div className={"select__option withCheckbox"} {...innerProps}>
            <label
                data-value={data.value}
                className={classNames(checkStyles.wrapper, {
                    [checkStyles.disabled]: data.disabled
                })}
                onClick={onClick}
            >
                <input
                    ref={input}
                    type="checkbox"
                    className={checkStyles.checkbox}
                    disabled={data.disabled}
                    onChange={noop}
                    checked={props.isSelected}
                />
                <div className={checkStyles.fakeCheckbox} />
                <Icon className={checkStyles.iconCheck} name={"IconCheck"} />
                <div className={checkStyles.labelText}>{data.label}</div>
            </label>
        </div>
    );
};

export const OptionBusinessCard = ({ data, innerProps, ...props }) => {
    const { cardProps = { image: {} }, value } = data;
    cardProps.image.size = "smallSize";

    return !value ? (
        <components.Option innerProps={innerProps} {...props} data={data} />
    ) : (
        <div {...innerProps} className={"select__option"}>
            <BusinessCard withBorder={false} {...cardProps} />
        </div>
    );
};

export const SelectContainer = ({ innerProps, ...props }) => {
    const { name, field = {} } = props.selectProps;
    const inputName = name || field.name || "";

    const newProps = {
        ...props,
        innerProps: {
            ...innerProps,
            ...(inputName ? { "data-cy": inputName } : {}),
            className: classNames(props.selectProps.className, {
                menuIsActuallyClosed: !props.options.length && !props.selectProps.isLoading
            })
        }
    };

    return <components.SelectContainer {...newProps} />;
};

export const MultiValueRemove = props => {
    const newProps = {
        ...props,
        innerProps: {
            ...props.innerProps,
            "data-remove-value": props.data.value
        }
    };

    return (
        <components.MultiValueRemove {...newProps}>
            <Icon height={16} width={16} className={styles.crossIcon} name={"IconClose"} />
        </components.MultiValueRemove>
    );
};

export const ClearIndicator = props => (
    <components.ClearIndicator {...props}>
        <Icon height={18} width={18} className={styles.clearIndicator} name={"IconClose"} />
    </components.ClearIndicator>
);

export const ValueContainer = props => {
    return (
        <components.ValueContainer {...props}>
            {props.children[0] && props.children[0].length
                ? // prevent react issues with multiple keys "[object Object}", happens when isMulti && value is type object
                  props.children[0].map((label, i) => <Fragment key={i}>{label}</Fragment>)
                : props.children[0]}
            {props.children[1]}
        </components.ValueContainer>
    );
};

export const ValueContainerSummarize = props => (
    <div
        className={classNames("select__value-container", "select__value-container--summarize", {
            "select__value-container--summarizeOpen": props.selectProps.menuIsOpen
        })}
    >
        {/* children 1 is mandatory to keep click/focus events on select */}
        {props.children[1]}
        <span>{`${props.getValue().length} von ${props.options.length} gewählt`}</span>
    </div>
);
