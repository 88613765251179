import React, { Component, ReactNode } from "react";
import PropTypes from "prop-types";
import styles from "./Tile.css";

interface TileProps {
    children?: ReactNode;
    variant: boolean;
    headline: string;
    subline: string;
}

export default class Tile extends Component<TileProps> {
    static defaultProps = {
        variant: false,
        headline: "HeadlineText",
        subline: "SublineText",
        children: "ContentText or Element"
    };

    static propTypes = {
        variant: PropTypes.bool,
        headline: PropTypes.string.isRequired,
        subline: PropTypes.string,
        children: PropTypes.any
    };

    render() {
        return (
            <div className={styles.tile}>
                <div className={this.props.variant ? styles.tileHeaderVariant : styles.tileHeader}>
                    <h1 className={styles.headline}>{this.props.headline}</h1>
                    <p className={styles.subline}>{this.props.subline}</p>
                </div>

                <div className={styles.tileBody}>{this.props.children}</div>
            </div>
        );
    }
}
