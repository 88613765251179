import isEqual from "react-fast-compare";
import { Direction } from "@lucify/types";

export function unique(array: Array<any>) {
    return [...new Set(array)];
}

export function uniqueDeep<T>(array: T[]) {
    return array.reduce((acc, curr) => {
        if (!acc.some(item => isEqual(item, curr))) {
            acc.push(curr as T);
        }
        return acc;
    }, [] as T[]);
}

export function sortBy<T extends Array<{ [key: string]: any }>>(array: T, key: string, type: Direction = "asc"): T {
    return array.sort((a, b) => (type === "asc" ? (a[key] > b[key] ? 1 : -1) : a[key] > b[key] ? -1 : 1));
}

/**
 * Filter functions
 */

export function noFalsyValues<T>(item: T): item is Exclude<T, false | null | undefined | 0> {
    return Boolean(item);
}

export function sortByTranslation(
    array: string[],
    translationPrefix: string,
    tFunction: Function,
    direction: Direction = "asc"
): string[] {
    return array.sort((a, b) =>
        tFunction(`${translationPrefix}${a}`) < tFunction(`${translationPrefix}${b}`)
            ? direction === "asc"
                ? -1
                : 1
            : direction === "asc"
            ? 1
            : -1
    );
}
