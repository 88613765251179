import { LucifyOIDCProvider, OIDCEvent } from "@lucify/auth";
import { CountryCode } from "@lucify/types";
import { Fetch } from "./Fetch";

interface ServiceOptions {
    lucifyOIDCProvider?: LucifyOIDCProvider;
    host?: string;
    country?: CountryCode;
    customCancellation?: boolean;
    useUserCountry?: boolean;
}

interface ServiceEndpoints {
    [key: string]: string;
}

export abstract class Service<Endpoints extends ServiceEndpoints> {
    protected host: string = "";
    protected country?: CountryCode;
    protected readonly fetch: Fetch;

    abstract readonly endpoints: Endpoints;

    setHost(host: string) {
        this.host = host;
    }

    setCountry(country: CountryCode) {
        this.country = country;
    }

    protected get defaults() {
        if (!this.country) {
            throw Error(`${this.constructor.name}: No Country set`);
        }

        return {
            country: this.country
        };
    }

    protected endpoint(endpoint: keyof Endpoints): string {
        return this.host + this.endpoints[endpoint];
    }

    constructor({
        host,
        country,
        lucifyOIDCProvider,
        customCancellation = false,
        useUserCountry = true
    }: ServiceOptions) {
        this.host = host ?? this.host;
        this.country = country;
        this.fetch = new Fetch(lucifyOIDCProvider, customCancellation);

        if (useUserCountry) {
            lucifyOIDCProvider?.on(OIDCEvent.AuthSuccess, () => {
                try {
                    this.country = lucifyOIDCProvider?.getUserCountry();
                } catch (error) {
                    console.error(error);
                }
            });
        }
    }
}
