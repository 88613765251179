import React, { FC, useEffect, useState } from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { RestErrorResponse, RestResponse, RestSuccessResponse } from "@lucify/services";
import { CraftsmenServiceTypes } from "@lucify/services";
import { CountryCode, ICraftsmanRadiusOptions, LucifyEnvironment } from "@lucify/types";
import { getGenericMessage, isRestErrorResponse } from "@lucify/utils";
import Tile from "../../components/Container/Tile";
import { scrollToFirstFormMessageError } from "../../components/FormElements/FormMessage";
import { WithNotifications, withNotifications } from "../../hoc/withNotifications";
import { NotificationTargetArea } from "../../interfaces/Notification";
import { craftsmenService } from "../../services/craftsmen";
import { getEnvironment } from "../../utils/helpers/dev";
import ContactFormAT from "./ContactFormAT";
import ContactFormDE from "./ContactFormDE";

type ContactFormType = typeof ContactFormAT | typeof ContactFormDE;

interface LandingPageProps extends WithNotifications, WithTranslation, RouteComponentProps {
    country: string;
    contactForm: ContactFormType;
}

export interface ContactFormProps extends WithNotifications, WithTranslation {
    submitHandler: (
        values: CraftsmenServiceTypes.KontaktFormularDTO,
        setFieldError: (field: string, defaultMessage: string) => void
    ) => void;
    formMessage: null | { title: string; type: string };
    craftsmanRadiusOptions: ICraftsmanRadiusOptions;
}

const LandingPage: FC<LandingPageProps> = props => {
    const { country } = props;
    const [formMessage, setFormMessage] = useState<null | { type: string; title: string }>(null);
    const [showSuccessMessage, toggleSuccessMessage] = useState<boolean>(false);
    const altPortalDomainHost: string = `machbar-macher.${country === CountryCode.de ? "at" : "de"}`;
    const ContactForm: ContactFormType = props.contactForm;

    const craftsmanRadiusOptions: ICraftsmanRadiusOptions = [
        {
            label: "unbegrenzt",
            value: "-1"
        },
        {
            label: "10 km",
            value: "10"
        },
        {
            label: "25 km",
            value: "25"
        },
        {
            label: "50 km",
            value: "50"
        },
        {
            label: "75 km",
            value: "75"
        },
        {
            label: "100 km",
            value: "100"
        },
        {
            label: "150 km",
            value: "150"
        },
        {
            label: "200 km",
            value: "200"
        },
        {
            label: "250 km",
            value: "250"
        },
        {
            label: "300 km",
            value: "300"
        }
    ];

    const setErrorMessage = (error: RestErrorResponse) => {
        const { response }: { response: RestSuccessResponse } = error;

        setFormMessage(
            getGenericMessage(response, {
                410: {
                    type: "error",
                    title: "Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Angaben und versuchen es erneut."
                }
            })
        );
    };

    const submitHandler = async (
        values: CraftsmenServiceTypes.KontaktFormularDTO,
        setFieldError: (field: string, defaultMessage: string) => void
    ) => {
        try {
            const response: RestResponse = await craftsmenService.getToken(country.toUpperCase());
            let { token } = response.data || {};

            await craftsmenService.postContactFormData(token, values, country.toUpperCase());

            toggleSuccessMessage(true);
        } catch (err) {
            if (isRestErrorResponse(err)) {
                setErrorMessage(err);

                if (err.response.data.errors) {
                    err.response.data.errors.forEach(error => {
                        setFieldError(error.field, error.defaultMessage);
                    });
                }
                scrollToFirstFormMessageError();
            } else {
                console.error(err);
            }
        }
    };

    useEffect(() => {
        const notificationId: string = props.createNotification({
            message: `Aktuelles Land: ${
                country === CountryCode.de ? "Deutschland" : "Österreich"
            }, uns gibt es auch in ${country === CountryCode.de ? "Österreich" : "Deutschland"}.`,
            targetArea: NotificationTargetArea.top,
            persist: true,
            link: {
                to: {
                    protocolLink:
                        getEnvironment() === LucifyEnvironment.PROD
                            ? `https://${altPortalDomainHost}`
                            : `/${country === CountryCode.de ? "at" : "de"}`
                },
                text: `Zu ${country === CountryCode.at ? "Deutschland" : "Österreich"} wechseln`
            }
        });

        return () => {
            props.deleteNotification(notificationId);
        };
    }, [country, altPortalDomainHost, props]);

    return showSuccessMessage ? (
        <Tile subline={"Wir freuen uns über dein Interesse"} headline={"Vielen Dank"} variant={true}>
            <p>Vielen Dank, dass Sie ihre Kontaktdaten bei den MachbarMachern hinterlegt haben.</p>
            <p>Das Handwerkspartner-Team wird sich zeitnah bei dir melden.</p>
        </Tile>
    ) : (
        <ContactForm
            craftsmanRadiusOptions={craftsmanRadiusOptions}
            formMessage={formMessage}
            submitHandler={submitHandler}
            {...props}
        />
    );
};

export default withNotifications(LandingPage) as typeof LandingPage;
