export const combineValidators =
    (...validators) =>
    value => {
        let error;
        for (const index in validators) {
            error = validators[index](value);
            if (error) {
                break;
            }
        }
        return error;
    };

export const customMessage = (validator, error) => values => validator(values) ? error : undefined;

export const required = value =>
    (typeof value === "string" && value.trim()) ||
    typeof value === "number" ||
    value instanceof File ||
    value instanceof FileList ||
    (Array.isArray(value) && value.length) ||
    (typeof value === "boolean" && value)
        ? undefined
        : "Required";

export const market = value => (value || typeof value === "number" ? undefined : "NotAMarket");

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(value) ? "InvalidEmailAddress" : undefined;

export const number = value => (value && isNaN(Number(value)) ? "NotANumber" : undefined);

export const numberRange = (min, max) => value => value < min || value > max ? "InvalidRangeMax" : undefined;

export const positiveNumber = value => (value <= 0 ? "NotAPositiveNumber" : undefined);

export const dateBefore = (firstDateKey, secondDateKey) => (value, formValues) => {
    const firstDate = new Date(formValues[firstDateKey]);
    const secondDate = new Date(formValues[secondDateKey]);
    return formValues[firstDateKey] && formValues[secondDateKey] && firstDate > secondDate
        ? "InvalidDateRange"
        : undefined;
};

export const dateBeforeFormik = (firstDateKey, secondDateKey, values) => () => {
    const firstDate = new Date(values[firstDateKey]);
    const secondDate = new Date(values[secondDateKey]);
    return values[firstDateKey] && values[secondDateKey] && firstDate > secondDate ? "InvalidDateRange" : undefined;
};

export const date = value => {
    const match = value.match(/^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])?$/);
    const date = Date.parse(value);
    return !match || isNaN(date) ? "InvalidDate" : undefined;
};

export const pauschalAmount = value => (numberRange(0, 1)(value) ? "InvalidPauschalAmount" : undefined);

export const clusterNameExists = (clusters, id) => value => {
    const clusterExists = !!clusters.find(
        cluster => cluster.name.toLowerCase().trim() === value.toLowerCase().trim() && id !== cluster.internal__uuid
    );
    return clusterExists ? "DuplicateClusterName" : undefined;
};
