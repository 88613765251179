import {
    Contact,
    CraftsmanContact,
    MacherKundeDTO,
    ProjektKoordinator
} from "@lucify/services/src/generated/macher-service.types";
import { MarketDTO } from "@lucify/services/src/generated/worktask-service.types";

export type AnyPerson = Partial<MacherKundeDTO & Contact & ProjektKoordinator & CraftsmanContact>;

export interface MailKontakt {
    anrede?: string;
    vorname?: string;
    nachname?: string;
    telefon?: string;
    email?: string;
}

interface AddressLike {
    street?: string;
    houseNumber?: string;
    addressAddition?: string;
    addition?: string;
    zip?: string;
    city?: string;
}

export function convertToKontakt(anyPerson?: AnyPerson): MailKontakt {
    const anrede = anyPerson?.anrede || anyPerson?.salutation;
    const vorname = anyPerson?.vorname || anyPerson?.firstName;
    const nachname = anyPerson?.nachname || anyPerson?.lastName;
    const telefon = anyPerson?.telefon || anyPerson?.phoneNumber;
    const email = anyPerson?.email;

    return {
        anrede,
        vorname,
        nachname,
        telefon,
        email
    };
}

export function convertToAddress(anyAddressLike: AddressLike & { address?: AddressLike }) {
    const strasse = anyAddressLike?.street || (anyAddressLike?.address && anyAddressLike?.address?.street);
    const hausnummer = anyAddressLike?.houseNumber || (anyAddressLike?.address && anyAddressLike?.address?.houseNumber);
    const adresszusatz =
        anyAddressLike?.addressAddition || (anyAddressLike?.address && anyAddressLike.address.addition);
    const plz = anyAddressLike?.zip || (anyAddressLike?.address && anyAddressLike.address.zip);
    const stadt = anyAddressLike?.city || (anyAddressLike?.address && anyAddressLike.address.city);

    return {
        strasse,
        hausnummer,
        adresszusatz,
        plz,
        stadt
    };
}

export function anrede(person: MailKontakt, foermlich: boolean): string {
    const HERR = "Herr";
    const FRAU = "Frau";
    const FIRMA = "Firma";
    let anrede;
    let grussformel = "Hallo";

    if (person.anrede) {
        anrede =
            person.anrede.toLowerCase() === "herr"
                ? HERR
                : person.anrede.toLowerCase() === "frau"
                ? FRAU
                : person.anrede.toLowerCase() === "firma"
                ? FIRMA
                : person.anrede;
    }

    if (foermlich) {
        grussformel =
            anrede === HERR
                ? "Sehr geehrter"
                : anrede === FRAU || anrede === FIRMA
                ? "Sehr geehrte"
                : person.nachname
                ? "Sehr geehrte/r"
                : "Sehr geehrte";
    }

    let anredePerson =
        anrede && anrede !== FIRMA && person.nachname
            ? anrede
            : person.nachname && anrede !== FIRMA
            ? "Frau/Herr"
            : foermlich
            ? "Damen und Herren"
            : "";

    return `${grussformel} ${anredePerson} ${(anrede !== FIRMA && person.nachname) || ""}`.trim();
}

export function greeting(person: MailKontakt, isCustomer?: boolean) {
    return `${isCustomer ? "Hey" : "Hallo"} ${person.vorname || ""}`.trim();
}

export const handwerkerAnrede = (handwerkerKontakt: MailKontakt) => anrede(handwerkerKontakt, false);
export const kundeAnrede = (kunde: MailKontakt) => anrede(kunde, true);
export const marktAnrede = (marktKontakt: MailKontakt, markt: MarketDTO) => anrede(marktKontakt, false);

export interface EmailData {
    body?: string;
    to?: string;
    cc?: string;
    subject?: string;
}

export function generateMailtoHref(values: EmailData) {
    const fields = {
        cc: values.cc && encodeURIComponent(values.cc),
        subject: values.subject && encodeURIComponent(values.subject),
        body: values.body && encodeURIComponent(values.body)
    };

    return `mailto:${encodeURIComponent(values.to ?? "")}?${Object.entries(fields)
        .filter(([, value]) => !!value)
        .map(([key, value]) => key + "=" + value)
        .join("&")}`;
}

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i;
