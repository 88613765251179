import React from "react";
import { Helmet } from "react-helmet";
import SimpleLayout from "../../components/Layout/SimpleLayout";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { retry } from "../../utils/helpers/retry";

const LazyLegalCustomerAGB = React.lazy(() => retry(() => import("../../container/Legal/CustomerAGB")));

const LegalCustomerAGBPage = ({ title, match, network, ...rest }) => (
    <SimpleLayout network={network} match={match}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <React.Suspense fallback={<LoadingIndicator />}>
            <LazyLegalCustomerAGB match={match} {...rest} />
        </React.Suspense>
    </SimpleLayout>
);

export default LegalCustomerAGBPage;
