import React, { PureComponent } from "react";
import classNames from "classnames";
import { autobind } from "core-decorators";
import { getIn } from "formik";
import PropTypes from "prop-types";
import { FormGroup, FormLabel } from "../../FormElements/FormElements";
import styles from "../Textarea.css";
import FormMessageValidation from "./FormMessageValidation";

export default class Textarea extends PureComponent {
    static defaultProps = {
        label: "",
        labelHint: "",
        content: "",
        placeholder: "",
        required: false,
        readOnly: false,
        resize: "none",
        layout: "",
        showInlineError: false
    };

    static propTypes = {
        label: PropTypes.string,
        labelHint: PropTypes.string,
        title: PropTypes.string,
        name: PropTypes.string,
        readOnly: PropTypes.bool,
        onChange: PropTypes.func,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        resize: PropTypes.oneOf(["none", "both", "horizontal", "vertical"]),
        layout: PropTypes.string,
        maxLength: PropTypes.number,
        showInlineError: PropTypes.bool,
        rows: PropTypes.number
    };

    componentDidUpdate() {
        if (this.props.clearField) {
            this.props.clearField();
        }
    }

    @autobind
    handleChange(value) {
        this.props.field.onChange(value);

        if (this.props.touchOnChange) {
            this.props.form.setFieldTouched(this.props.field.name, true, false);
        }
    }

    render() {
        const { form, field, layout, showInlineError, onFocus } = this.props;

        const error = getIn(form.errors, this.props.field.name);
        const hasError = !!error && !this.props.disabled;

        const inputProps = {
            className: classNames(styles.input, this.props.className, {
                [styles.disabled]: this.props.disabled,
                [styles[this.props.resize]]: this.props.resize,
                [styles.hasError]: hasError
            }),
            disabled: this.props.disabled,
            placeholder: this.props.placeholder,
            readOnly: this.props.readOnly,
            maxLength: this.props.maxLength,
            onBlur: field.onBlur,
            rows: this.props.rows
        };

        const value = field.value || "";

        return (
            <FormGroup
                hasLabel={!!this.props.label}
                className={classNames({
                    [styles.expand]: this.props.expand,
                    [styles[layout]]: layout
                })}
            >
                <FormMessageValidation field={field} form={form} disabled={!(hasError && showInlineError)} />
                <FormLabel labelText={this.props.label} labelHint={this.props.labelHint} />

                <textarea
                    {...inputProps}
                    title={this.props.title}
                    value={value}
                    name={this.props.field.name}
                    data-cy={this.props.field.name}
                    onChange={this.handleChange}
                    onFocus={onFocus}
                    required={this.props.required}
                />
            </FormGroup>
        );
    }
}
