import React from "react";
import { Helmet } from "react-helmet";
import MainLayoutExpanded from "../components/Layout/MainLayoutExpanded";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import { retry } from "../utils/helpers/retry";

const LazyProjectDetailview = React.lazy(() => retry(() => import("../container/Project/Detailansicht")));

const ProjectDetailPage = ({ title, match, t, network, ...rest }) => (
    <MainLayoutExpanded network={network} match={match}>
        <Helmet>
            <title>
                {match && match.params && match.params.state ? `${title}: ${t("states." + match.params.state)}` : title}
            </title>
        </Helmet>
        <React.Suspense fallback={<LoadingIndicator />}>
            <LazyProjectDetailview
                match={match}
                macherProjektId={match.params.macherProjektId}
                id={match.params.id}
                {...rest}
            />
        </React.Suspense>
    </MainLayoutExpanded>
);

export default ProjectDetailPage;
