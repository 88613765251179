import React, { FC } from "react";
import { Field, Form, Formik } from "formik";
import { CraftsmenServiceTypes } from "@lucify/services";
import Button from "../../components/Button/Button";
import Container from "../../components/Container/Container";
import FormMessage, { scrollToFirstFormMessageError } from "../../components/FormElements/FormMessage";
import FormSection, {
    FormSectionBody,
    FormSectionHeadline,
    FormSectionSubmitGroup
} from "../../components/FormElements/FormSection";
import Checkbox from "../../components/FormInput/Formik/Checkbox";
import Select from "../../components/FormInput/Formik/Select";
import SingleInput from "../../components/FormInput/Formik/SingleInput";
import SyncErrors from "../../components/FormInput/Formik/SyncErrors";
import Textarea from "../../components/FormInput/Formik/Textarea";
import Cell from "../../components/Grid/Cell";
import Grid from "../../components/Grid/Grid";
import Section from "../../components/Section/Section";
import { Text } from "../../components/Typo/Typo";
import { withNotifications } from "../../hoc/withNotifications";
import { combineValidators, email, required } from "../../utils/validation/validators";
import { ContactFormProps } from "./LandingPage";

const ContactFormAT: FC<ContactFormProps> = ({ submitHandler, formMessage, craftsmanRadiusOptions, ...rest }) => {
    return (
        <Section layout="simple">
            <Container boxed={true} headline={"Jetzt MachbarMacher werden!"}>
                <FormSection divided={false}>
                    <FormSectionBody>
                        <Text component={"p"} variant={"markdownWrapper"}>
                            MachbarMacher<sup>1</sup> sind selbstständige und von OBI geprüfte
                            <sup>2</sup> Handwerksbetriebe, die individuelle Projekte unserer Kunden verwirklichen.
                            Werde auch du Teil des OBI Handwerker-Netzwerks MachbarMacher.
                        </Text>
                        <Text component={"p"} variant={"markdownWrapper"}>
                            Deine Vorteile im Überblick:
                        </Text>
                        <Text component={"ul"} variant={"markdownWrapper"}>
                            <li>Regelmäßig kostenlose Anfragen aus deiner Region</li>
                            <li>Volle Flexibilität bei jeder Kundenanfrage</li>
                            <li>Zeitersparnis durch Erstkontakt im OBI Markt inklusive Bedarfsermittlung</li>
                            <li>Du bleibst unabhängig und trittst immer unter deinem Namen beim Kunden auf</li>
                            <li>Profitiere vom MachbarMacher Handwerker-Netzwerk</li>
                        </Text>
                        <Text component={"p"} variant={"markdownWrapper"}>
                            Fülle einfach dieses Formular aus und wir melden uns anschließend bei dir. Infos zum
                            Handwerkerservice von OBI findest du unter{" "}
                            <a href="https://www.obi.at/handwerkspartner" title={"Link zu www.obi.at/handwerkspartner"}>
                                www.obi.at/handwerkspartner
                            </a>
                        </Text>
                    </FormSectionBody>
                </FormSection>

                <Formik
                    onSubmit={async (
                        values: CraftsmenServiceTypes.KontaktFormularDTO,
                        { setSubmitting, setFieldError }
                    ) => {
                        try {
                            await submitHandler(values, setFieldError);
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                    initialValues={
                        {
                            radius: "-1",
                            datenschutzUndAgbZugestimmt: false
                        } as CraftsmenServiceTypes.KontaktFormularDTO
                    }
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={scrollToFirstFormMessageError}
                >
                    {({ errors, isSubmitting }) => {
                        return (
                            <Form>
                                <FormSection>
                                    <FormSectionHeadline headline={"Allgemeine Angaben"} />
                                    <SyncErrors
                                        errors={errors}
                                        fieldsEntity={"craftsman"}
                                        whitelist={["firmenname", "radius", "anrede", "vorname", "nachname"]}
                                    />
                                    <FormSectionBody>
                                        <Grid marginX>
                                            <Cell small={12} medium={7}>
                                                <Field
                                                    name={"firmenname"}
                                                    component={SingleInput}
                                                    label={rest.t("internals:fields.craftsman.facilityName")}
                                                    validate={required}
                                                />
                                            </Cell>
                                            <Cell small={12} medium={5}>
                                                <Field
                                                    name={"radius"}
                                                    component={Select}
                                                    label="Gewünschter Tätigkeitsumkreis"
                                                    options={craftsmanRadiusOptions}
                                                    validate={required}
                                                />
                                            </Cell>
                                            <Cell small={12} medium={4}>
                                                <Field
                                                    name={"anrede"}
                                                    label={"Anrede"}
                                                    component={Select}
                                                    placeholder={"Auswahl"}
                                                    options={[
                                                        {
                                                            label: "keine Angabe",
                                                            value: ""
                                                        },
                                                        {
                                                            label: "Herr",
                                                            value: "Herr"
                                                        },
                                                        {
                                                            label: "Frau",
                                                            value: "Frau"
                                                        }
                                                    ]}
                                                />
                                            </Cell>
                                            <Cell small={12} medium={4}>
                                                <Field
                                                    name={"vorname"}
                                                    component={SingleInput}
                                                    label={"Vorname Ansprechpartner"}
                                                    validate={required}
                                                />
                                            </Cell>
                                            <Cell small={12} medium={4}>
                                                <Field
                                                    name={"nachname"}
                                                    component={SingleInput}
                                                    label={"Nachname Ansprechpartner"}
                                                    validate={required}
                                                />
                                            </Cell>
                                        </Grid>
                                    </FormSectionBody>
                                </FormSection>
                                <FormSection>
                                    <FormSectionHeadline headline={"Kontaktangaben"} />
                                    <SyncErrors
                                        errors={errors}
                                        fieldsEntity={"craftsman"}
                                        whitelist={["strasse", "hausnummer", "plz", "ort", "telefon", "email"]}
                                    />
                                    <FormSectionBody>
                                        <Grid marginX>
                                            <Cell small={12} medium={9}>
                                                <Field
                                                    component={SingleInput}
                                                    name={"strasse"}
                                                    label={"Straße"}
                                                    validate={required}
                                                />
                                            </Cell>
                                            <Cell small={12} medium={3}>
                                                <Field
                                                    name={"hausnummer"}
                                                    component={SingleInput}
                                                    label={"Hausnr."}
                                                    validate={required}
                                                />
                                            </Cell>
                                            <Cell small={12} medium={4}>
                                                <Field
                                                    component={SingleInput}
                                                    name={"plz"}
                                                    label={"Postleitzahl"}
                                                    validate={required}
                                                />
                                            </Cell>
                                            <Cell small={12} medium={8}>
                                                <Field
                                                    component={SingleInput}
                                                    name={"ort"}
                                                    label={"Stadt"}
                                                    validate={required}
                                                />
                                            </Cell>
                                            <Cell small={12} medium={6}>
                                                <Field
                                                    component={SingleInput}
                                                    name={"telefon"}
                                                    label={"Telefon"}
                                                    validate={required}
                                                />
                                            </Cell>
                                            <Cell small={12} medium={6}>
                                                <Field
                                                    component={SingleInput}
                                                    name={"email"}
                                                    label={"E-Mail"}
                                                    validate={combineValidators(required, email)}
                                                />
                                            </Cell>
                                        </Grid>
                                    </FormSectionBody>
                                </FormSection>
                                <FormSection>
                                    <FormSectionHeadline headline={"Kommentare (optional)"} />
                                    <p>Stelle dich beispielsweise kurz vor oder nenne uns deine Gewerke</p>
                                    <FormSectionBody>
                                        <Field name={"kommentar"} label={""} resize={"vertical"} component={Textarea} />
                                        <Field
                                            name={"datenschutzUndAgbZugestimmt"}
                                            component={Checkbox}
                                            validate={required}
                                            label={() => (
                                                <>
                                                    Ich bin damit einverstanden, dass das MachbarMacher Team einmalig
                                                    mit mir Kontakt aufnimmt zum Zwecke der möglichen Registrierung
                                                    <sup>3</sup>
                                                </>
                                            )}
                                        />
                                    </FormSectionBody>
                                </FormSection>
                                <FormSection divided={false}>
                                    <FormSectionBody>
                                        <Text component={"p"}>
                                            Mit Absenden des Formulars akzeptiere ich die Datenschutzhinweise und die
                                            AGB.
                                        </Text>
                                        <Text component={"p"} variant={["bold"]}>
                                            Nach dem Absenden des Formulars werden wir uns zeitnah telefonisch oder per
                                            E-Mail bei dir melden. Dann erhältst du auch alle erforderlichen
                                            Informationen für die nächsten Registrierungsschritte.
                                        </Text>
                                    </FormSectionBody>
                                </FormSection>
                                <FormSection divided={false}>
                                    {formMessage ? <FormMessage {...formMessage} /> : null}

                                    <FormSectionSubmitGroup>
                                        <Button disabled={isSubmitting} type={"submit"} fullWidth={true}>
                                            Formular abschicken
                                        </Button>
                                    </FormSectionSubmitGroup>
                                </FormSection>
                            </Form>
                        );
                    }}
                </Formik>

                <FormSection>
                    <Text component={"p"} variant={["mini", "cBrandGrey"]}>
                        <sup>1</sup> MachbarMacher ist ein Handwerker-Vermittlungsservice. MachbarMacher werden nicht
                        für oder im Auftrag von OBI tätig, sondern handeln im eigenen Namen und auf eigene Rechnung.
                        Wenn der MachbarMacher daran interessiert ist, für Sie tätig zu werden, erstellt er Ihnen anhand
                        Ihrer Auftragsanfrage selbstständig ein Angebot. Weitere Infos erhalten Sie in Ihrem OBI Markt.
                        Vermittlungsservice nur in teilnehmenden Märkten. <br />
                        <sup>2</sup> Handwerker bzw. Dienstleister, welche als MachbarMacher tätig werden, müssen ihre
                        Qualifikation und Zuverlässigkeit vorab gegenüber OBI dokumentieren, namentlich durch Vorlage
                        von Gewerbeberechtigung und Versicherungsnachweis. Zudem werden Kundenfeedbacks ausgewertet, um
                        eine fortlaufende Überprüfung der Arbeitsqualität der einzelnen MachbarMacher zu gewährleisten.{" "}
                        <br />
                        <sup>3</sup> Solltest du eine Löschung deiner Daten wünschen, nimm bitte Kontakt auf mit: OBI
                        Bau- und Heimwerkermärkte Systemzentrale GmbH, Litfaßstraße 8, 1030 Wien, Tel. 0800 201156,
                        E-Mail: machbarmacher@obi.at
                    </Text>
                </FormSection>
            </Container>
        </Section>
    );
};

export default withNotifications(ContactFormAT);
