import React from "react";
import { Helmet } from "react-helmet";
import SimpleLayout from "../../components/Layout/SimpleLayout";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { retry } from "../../utils/helpers/retry";

const LazyNotFound = React.lazy(() => retry(() => import("../../components/RoutingErrorHandler/NotFound")));

const NotFoundPage = ({ title, network, ...rest }) => (
    <SimpleLayout network={network} match={rest.match}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <React.Suspense fallback={<LoadingIndicator />}>
            <LazyNotFound {...rest} />
        </React.Suspense>
    </SimpleLayout>
);

export default NotFoundPage;
