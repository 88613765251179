import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { filterValuesByFieldsArray } from "@lucify/utils";
import FormMessage from "../../FormElements/FormMessage";

function getErrors(syncErrors, parentKey, allErrors) {
    const keys = Object.keys(syncErrors);
    const errors = allErrors || {};

    if (keys.length) {
        keys.forEach((key, index) => {
            if (typeof syncErrors[key] === "string") {
                const errorType = syncErrors[key];
                const fieldName = parentKey ? `${parentKey}.${key}` : key;

                if (errors.hasOwnProperty(errorType)) {
                    errors[errorType].push(fieldName);
                } else {
                    errors[errorType] = [fieldName];
                }
            } else if (typeof syncErrors[key] === "object") {
                getErrors(syncErrors[key], key, errors);
            }
        });
    }

    return errors;
}

@withTranslation("internals", "messages")
export default class SyncErrors extends PureComponent {
    static defaultProps = {
        whitelist: []
    };

    static propTypes = {
        whitelist: PropTypes.array,
        fieldsEntity: PropTypes.string
    };

    render() {
        const { whitelist, errors, t, fieldsEntity, ...restProps } = this.props;
        const filteredErrors = this.props.whitelist.length
            ? filterValuesByFieldsArray(this.props.errors, this.props.whitelist)
            : this.props.errors;
        const allErrors = getErrors(filteredErrors);

        return Object.keys(allErrors).map((errorGroup, index) => {
            let groupValidationMessage = this.props.t("messages:validation.syncError." + errorGroup, {
                allErrors: allErrors[errorGroup],
                count: allErrors[errorGroup].length,
                fieldsEntity: this.props.fieldsEntity,
                returnObjects: true
            });

            if (errorGroup !== "Required") {
                const fieldNames = allErrors[errorGroup]
                    .map(fieldName => this.props.t(`internals:fields.${this.props.fieldsEntity}.${fieldName}`))
                    .join(", ");

                groupValidationMessage = this.props.t(["messages:validation.syncError." + errorGroup, errorGroup], {
                    fieldNames,
                    allErrors: allErrors[errorGroup],
                    count: allErrors[errorGroup].length,
                    fieldsEntity: this.props.fieldsEntity,
                    returnObjects: true
                });
            }

            return (
                <FormMessage key={errorGroup + index} message={groupValidationMessage} type={"error"} {...restProps} />
            );
        });
    }
}
