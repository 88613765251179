export enum LucifyKeycloakRoles {
    omt = "omt",
    hko = "hko",
    insight = "insight",
    dsgvo_de = "dsgvo_de",
    dsgvo_at = "dsgvo_at",
    lucify_admin = "lucify_admin",
    pko = "pko",
    hpm = "hpm"
}

export enum LucifyRuleType {
    static = "static",
    dynamic = "dynamic"
}

export enum LucifyKeycloakCountry {
    de = "de",
    at = "at"
}
