import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { autobind } from "core-decorators";
import { AuthProvider, OIDCEvent, initalAuthContext as initalAuthenticationState } from "@lucify/auth";
import { LucifyEnvironment } from "@lucify/types";
import { cachesWrapper } from "@lucify/utils";
import { localStorageWrapper } from "@lucify/utils";
import { withNotifications } from "../../hoc/withNotifications";
import { NotificationTargetArea } from "../../interfaces/Notification";
import { userCountryPortalMapping } from "../../mappings/userCountryPortalMapping";
import { lucifyOIDCProvider } from "../../services/lucifyOIDCProvider";
import { logout, setAuthenticationStatus } from "../../store/actions/auth.actions";
import { getContentCountry, getEnvironment } from "../../utils/helpers/dev";

const INTERNALS_CACHE = "lucify-client-internals";
let notificationHasBeenShown = false;

@connect(null, { setAuthenticationStatus, logout })
@withNotifications
export default class Authentication extends PureComponent {
    state = initalAuthenticationState;

    static persistTokens(saveInLocalStorage) {
        if (saveInLocalStorage && lucifyOIDCProvider.token && lucifyOIDCProvider.refreshToken) {
            localStorageWrapper.setItem("kc_token", lucifyOIDCProvider.token);
            localStorageWrapper.setItem("kc_refreshToken", lucifyOIDCProvider.refreshToken);
        } else {
            localStorageWrapper.removeItem("kc_token");
            localStorageWrapper.removeItem("kc_refreshToken");
        }
    }

    static getPersistedTokens() {
        return {
            token: localStorageWrapper.getItem("kc_token"),
            refreshToken: localStorageWrapper.getItem("kc_refreshToken")
        };
    }

    componentDidMount() {
        const { token, refreshToken } = Authentication.getPersistedTokens();
        this.setState({ isPending: true });

        lucifyOIDCProvider.on(OIDCEvent.AuthSuccess, this.setLoggedIn);
        lucifyOIDCProvider.on(OIDCEvent.AuthRefreshSuccess, this.setLoggedIn);
        lucifyOIDCProvider.on(OIDCEvent.AuthError, this.setLoggedOut);
        lucifyOIDCProvider.on(OIDCEvent.AuthLogout, this.setLoggedOut);
        lucifyOIDCProvider.on(OIDCEvent.TokenExpired, async () => {
            this.setState({ isTokenExpired: true });

            try {
                const refreshed = await lucifyOIDCProvider.updateToken(5);
                console.log(refreshed ? "Token was successfully refreshed" : "Token is still valid");
            } catch (e) {
                console.error("Failed to refresh the token, or the session has expired", e);
                this.setLoggedOut();
            }
        });

        // Keycloaks native Promises are buggy
        // https://issues.jboss.org/browse/KEYCLOAK-8938
        // TODO: Switch to native promises when this issue is resolved
        lucifyOIDCProvider
            .init({
                token,
                refreshToken,
                checkLoginIframe: false
                // onLoad: "check-sso"
            })
            .then(authenticated => {
                if (authenticated) {
                    lucifyOIDCProvider.updateToken(180).then(this.setLoggedIn);
                } else {
                    this.setLoggedOut();
                }
            })
            .catch(err => {
                console.error(err);
                this.setLoggedOut();
            });

        if (process.env.NODE_ENV === "development") {
            window._lucifyOIDCProvider = lucifyOIDCProvider;
        }
    }

    @autobind
    setLoggedIn() {
        if (lucifyOIDCProvider.token && lucifyOIDCProvider.refreshToken) {
            try {
                const user = lucifyOIDCProvider.getUser();

                const userPortal = userCountryPortalMapping[user.country];

                const showNotificationInDev =
                    getEnvironment() !== LucifyEnvironment.PROD &&
                    user.country &&
                    user.country.toUpperCase() !== getContentCountry() &&
                    !notificationHasBeenShown;

                const showNotificationInProd =
                    getEnvironment() === LucifyEnvironment.PROD &&
                    !notificationHasBeenShown &&
                    user.country &&
                    userPortal &&
                    userPortal.hostname !== window.location.hostname;

                if (showNotificationInDev || showNotificationInProd) {
                    this.props.createNotification({
                        message: `Möchten Sie in Ihr MachbarMacher Portal (${userPortal.countryName}) wechseln, folgen Sie bitte folgendem Link:`,
                        targetArea: NotificationTargetArea.top,
                        persist: true,
                        iconName: "IconPublic",
                        link: {
                            to: {
                                protocolLink: getEnvironment() === LucifyEnvironment.PROD ? userPortal.url : "/"
                            },
                            text: userPortal.hostname
                        }
                    });

                    notificationHasBeenShown = true;
                }

                this.props.setAuthenticationStatus(true);

                setTimeout(() =>
                    this.setState({
                        authenticated: true,
                        isTokenExpired: false,
                        isPending: false,
                        user: {
                            ...this.state.user,
                            ...user
                        }
                    })
                );

                Authentication.persistTokens(true);
            } catch (e) {
                console.error(e);
            }
        }
    }

    @autobind
    setLoggedOut() {
        this.setState(initalAuthenticationState);
        this.props.logout();
        Authentication.persistTokens(false);

        cachesWrapper.delete(INTERNALS_CACHE);
    }

    logout() {
        Authentication.persistTokens(false);
        lucifyOIDCProvider.logout();
    }

    render() {
        const authProviderValue = {
            ...this.state,
            login: lucifyOIDCProvider.login,
            logout: this.logout
        };

        return <AuthProvider value={authProviderValue}>{this.props.children}</AuthProvider>;
    }
}
