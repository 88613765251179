export const debounce = function <Callback extends (...params: any[]) => void>(func: Callback, delay = 0) {
    let inDebounce: NodeJS.Timeout;

    return function (this: any, ...args: any[]) {
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => {
            return func.apply(this, args);
        }, delay);
    } as Callback;
};

type ResolveFn = <T>(value?: T | PromiseLike<T>) => void;

export const asyncDebounce = function <Callback extends (...params: any[]) => Promise<any>>(func: Callback, delay = 0) {
    let inDebounce: NodeJS.Timeout;
    let resolves: ResolveFn[] = [];

    return function (this: any, ...args: any[]) {
        clearTimeout(inDebounce);
        return new Promise(resolve => {
            inDebounce = setTimeout(() => {
                const result = func.apply(this, args);
                resolves.forEach(resolve => resolve(result));
                resolves = [];
            }, delay);

            resolves.push(resolve);
        });
    } as Callback;
};
