import React, { FC, useContext } from "react";
import { Route, RouteChildrenProps } from "react-router-dom";
import { IAuthContext, authContext, checkPermission } from "@lucify/auth";
import { INetworkStatus } from "../interfaces/Globals";
import AuthHandlerPage from "../pages/Handler/AuthHandler";
import { lucifyOIDCProvider } from "../services/lucifyOIDCProvider";

interface ProtectedRouteProps extends RouteChildrenProps {
    needs: string;
    render(props: RouteChildrenProps<any>): React.ReactNode;
    network: INetworkStatus;
    title: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ render, needs, ...rest }) => {
    const auth: IAuthContext = useContext(authContext);

    if (!auth.isPending && !auth.authenticated) {
        auth.login({
            redirectUri: window.location.href
        });
    }

    return (
        <Route
            {...rest}
            render={props =>
                auth.isPending || !auth.authenticated ? (
                    <AuthHandlerPage loading {...rest} {...props} />
                ) : checkPermission(lucifyOIDCProvider, needs) && auth.user.countrySupport ? (
                    render(props)
                ) : (
                    <AuthHandlerPage accessGranted={checkPermission(lucifyOIDCProvider, needs)} {...rest} {...props} />
                )
            }
        />
    );
};

export default ProtectedRoute;
