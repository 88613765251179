import Keycloak, { KeycloakError, KeycloakInitOptions } from "keycloak-js";
import { CountryCode } from "@lucify/types";
import { EventSystem } from "./EventSystem";
import { hasSupportedCountry } from "./auth";
import { LucifyKeycloakCountry, LucifyKeycloakRoles } from "./constants";
import { IAuthUser, ILucifyKeycloakInstance } from "./types";

interface LucifyOIDCProviderEvent {
    name:
        | OIDCEvent.AuthSuccess
        | OIDCEvent.AuthLogout
        | OIDCEvent.AuthRefreshError
        | OIDCEvent.AuthRefreshSuccess
        | OIDCEvent.TokenExpired;
    callback(): void;
    namespace?: string;
}

interface LucifyOIDCProviderReadyEvent {
    name: OIDCEvent.Ready;
    callback(authenticated?: boolean): void;
    namespace?: string;
}

interface LucifyOIDCProviderErrorEvent {
    name: OIDCEvent.AuthError;
    callback(error: KeycloakError): void;
    namespace?: string;
}

interface LucifyOIDCProviderClientConfig {
    /**
     * @deprecated
     */
    supportedCountries?: LucifyKeycloakCountry[];
    country?: CountryCode;
}

export enum OIDCEvent {
    AuthSuccess,
    AuthError,
    AuthLogout,
    AuthRefreshError,
    AuthRefreshSuccess,
    Ready,
    TokenExpired
}

export type LucifyOIDCEvent = LucifyOIDCProviderEvent | LucifyOIDCProviderReadyEvent | LucifyOIDCProviderErrorEvent;

/**
 * **Lucify OIDC Provider**
 *
 * Wraps Keycloak JS Adapter to:
 *  - Attach a custom EventSystem. Adds the possibility to listen on events from multiple
 * occurencies.
 *  - Wrap KeycloakPromises in native Promises
 *  - Expose only relevant properties
 *  - Provide custom getUser() and getUserCountry() Methods
 */
export class LucifyOIDCProvider<KeycloakInstance extends ILucifyKeycloakInstance = ILucifyKeycloakInstance> {
    private keycloakInstance: KeycloakInstance;
    private eventSystem = new EventSystem<LucifyOIDCEvent>();
    private clientConfig: LucifyOIDCProviderClientConfig;
    private initialized = false;
    public readonly legacyMode: boolean;

    constructor(config: string | Keycloak.KeycloakConfig, clientConfig: LucifyOIDCProviderClientConfig) {
        this.keycloakInstance = new Keycloak(config) as KeycloakInstance;
        this.clientConfig = clientConfig;
        this.legacyMode = !!clientConfig.supportedCountries;
        this.initEventListeners();
    }

    private initEventListeners() {
        this.keycloakInstance.onTokenExpired = () => this.eventSystem.trigger(OIDCEvent.TokenExpired);
        this.keycloakInstance.onAuthError = errorData => this.eventSystem.trigger(OIDCEvent.AuthError, errorData);
        this.keycloakInstance.onAuthLogout = () => this.eventSystem.trigger(OIDCEvent.AuthLogout);
        this.keycloakInstance.onAuthRefreshError = () => this.eventSystem.trigger(OIDCEvent.AuthRefreshError);
        this.keycloakInstance.onAuthRefreshSuccess = () => this.eventSystem.trigger(OIDCEvent.AuthRefreshSuccess);
        this.keycloakInstance.onAuthSuccess = () => this.eventSystem.trigger(OIDCEvent.AuthSuccess);
        this.keycloakInstance.onReady = authenticated => this.eventSystem.trigger(OIDCEvent.Ready, authenticated);
    }

    public getCountry() {
        return this.clientConfig.country;
    }

    /**
     * @deprecated
     */
    public getUserCountry() {
        if (!this.tokenParsed) {
            throw Error("User is not authenticated");
        }

        if (!this.tokenParsed?.country) {
            throw Error("User country mapping failed. Please check if user has needed authorities");
        }

        return this.tokenParsed.country.toUpperCase() as CountryCode;
    }

    public getUser(): IAuthUser {
        if (!this.tokenParsed) {
            throw Error("User is not authenticated");
        }

        const { country: tokenCountry, name, given_name, family_name, title, email } = this.tokenParsed;
        const roles = Object.values(LucifyKeycloakRoles).filter(roleName => this.hasRealmRole(roleName));

        const country = tokenCountry ? tokenCountry.toLowerCase() : undefined;
        const countrySupport = hasSupportedCountry(this.clientConfig.supportedCountries || [], country);

        return {
            roles,
            country,
            name,
            given_name,
            family_name,
            title,
            email,
            countrySupport
        };
    }

    public init(initOptions: KeycloakInitOptions) {
        this.initialized = true;
        return this.keycloakInstance.init(initOptions);
    }

    public get isInitialized() {
        return this.initialized;
    }

    /**
     * Expose on/off methods of the EventSystem
     */
    public get on() {
        return this.eventSystem.on.bind(this.eventSystem);
    }

    public get off() {
        return this.eventSystem.off.bind(this.eventSystem);
    }

    /**
     * Expose keycloak internals
     */
    public get updateToken() {
        return this.keycloakInstance.updateToken;
    }

    public get token() {
        return this.keycloakInstance.token;
    }

    public get refreshToken() {
        return this.keycloakInstance.refreshToken;
    }

    public get tokenParsed() {
        return this.keycloakInstance.tokenParsed;
    }

    public get authenticated() {
        return this.keycloakInstance.authenticated;
    }

    public get isTokenExpired() {
        return this.keycloakInstance.isTokenExpired;
    }

    public get hasResourceRole() {
        return this.keycloakInstance.hasResourceRole;
    }

    public get hasRealmRole() {
        return this.keycloakInstance.hasRealmRole;
    }

    public get login() {
        return this.keycloakInstance.login;
    }

    public get logout() {
        return this.keycloakInstance.logout;
    }
}
