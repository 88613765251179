import { getPagination } from "../../utils/helpers/list";
import { LOGOUT } from "../actions/auth.actions";
import { GET_PROJECTS_FULFILLED, GET_PROJECTS_PENDING, GET_PROJECTS_REJECTED } from "../actions/projects.actions";

const initialState = {
    isFetching: false,
    failed: false,
    data: [],
    pagination: {
        totalCount: null,
        totalPages: null,
        pageNumber: 1,
        firstPage: true,
        lastPage: false
    }
};

export function projectsList(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        case GET_PROJECTS_PENDING:
            return {
                ...state,
                isFetching: true,
                pagination: {
                    ...state.pagination,
                    pageNumber: action.meta.page || 1
                }
            };

        case GET_PROJECTS_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true,
                    pagination: {
                        ...state.pagination,
                        pageNumber: action.meta.page || 1
                    }
                };
            }

        case GET_PROJECTS_FULFILLED:
            return {
                data: action.payload.data,
                status: action.payload.status,
                pagination: getPagination(action),
                isFetching: false,
                failed: false
            };

        default:
            return state;
    }
}
