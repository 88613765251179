import { LOGOUT } from "../actions/auth.actions";
import {
    GET_WORK_TASKS_FULFILLED,
    GET_WORK_TASKS_PENDING,
    GET_WORK_TASKS_REJECTED
} from "../actions/worktasks.actions";

const initialState = {
    list: [],
    failed: false,
    isFetching: false
};

export function workTasks(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;
        case GET_WORK_TASKS_PENDING:
            return {
                ...state,
                failed: false,
                isFetching: true
            };
        case GET_WORK_TASKS_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    isFetching: false,
                    failed: true,
                    status: action.payload.response.status
                };
            }
        case GET_WORK_TASKS_FULFILLED:
            return {
                ...state,
                isFetching: false,
                failed: false,
                status: action.payload.status,
                list: action.payload.data
                    .filter(({ active, articleNumber }) => active && articleNumber)
                    .map(
                        ({
                            id,
                            articleNumber,
                            trade,
                            category,
                            name,
                            description,
                            unit,
                            tax,
                            priceClusters,
                            netPurchasePriceEditable,
                            netSellingPriceEditable
                        }) => ({
                            id,
                            articleNumber,
                            trade,
                            category,
                            name,
                            description,
                            unit,
                            tax,
                            priceClusters,
                            netPurchasePriceEditable,
                            netSellingPriceEditable
                        })
                    )
            };
        default:
            return state;
    }
}
