import React, { FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { match } from "react-router";
import { classNames } from "@lucify/utils";
import { IApplicationState, IUIState } from "../../interfaces/ApplicationState";
import { INetworkStatus } from "../../interfaces/Globals";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import styles from "./SimpleLayout.css";

interface SimpleLayoutProps {
    children?: ReactNode;
    backgroundImage?: string;
    network: INetworkStatus;
    match: match;
}

const SimpleLayout: FC<SimpleLayoutProps> = ({ backgroundImage, network, match, children }) => {
    const header = useSelector<IApplicationState, IUIState["header"]>(({ ui }) => ui.header);

    return (
        <div className={styles.layoutDefault}>
            <Header network={network} />

            <div className={styles.main}>
                <main
                    className={classNames(styles.container, {
                        [styles.withBackgroundImage]: !!backgroundImage
                    })}
                    style={{
                        backgroundImage: backgroundImage ? `url(${backgroundImage})` : "",
                        paddingTop: header.extendedBaseHeight
                    }}
                >
                    {children}
                </main>
            </div>

            <Footer match={match} />
        </div>
    );
};

export default SimpleLayout;
