//
// SET KEYCLOAK AUTHENTICATION STATUS
//
export const SET_AUTHENTICATION_STATUS = "SET_AUTHENTICATION_STATUS";

export const setAuthenticationStatus = authenticated => {
    return {
        type: SET_AUTHENTICATION_STATUS,
        payload: authenticated
    };
};

//
// LOGOUT ACTIONS/CREATORS
//
export const LOGOUT = "LOGOUT";

export const logout = () => {
    return {
        type: LOGOUT
    };
};
