import { LOGOUT } from "../actions/auth.actions";
import {
    ADD_PROJECT_COMMENT_FULFILLED,
    ADD_PROJECT_COMMENT_PENDING,
    ADD_PROJECT_COMMENT_REJECTED,
    DELETE_PROJECT_COMMENT_FULFILLED,
    DELETE_PROJECT_COMMENT_PENDING,
    DELETE_PROJECT_COMMENT_REJECTED,
    GET_PROJECT_COMMENTS_FULFILLED,
    GET_PROJECT_COMMENTS_PENDING,
    GET_PROJECT_COMMENTS_REJECTED
} from "../actions/projects.actions";

const initialState = {
    isFetching: false,
    failed: false
};

export function projectsComments(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        case GET_PROJECT_COMMENTS_PENDING:
        case ADD_PROJECT_COMMENT_PENDING:
        case DELETE_PROJECT_COMMENT_PENDING:
            return {
                ...state,
                isFetching: true,
                failed: false
            };

        case GET_PROJECT_COMMENTS_REJECTED:
        case ADD_PROJECT_COMMENT_REJECTED:
        case DELETE_PROJECT_COMMENT_REJECTED:
            return {
                ...state,
                status: action.payload.response.status,
                isFetching: false,
                failed: true
            };

        case GET_PROJECT_COMMENTS_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                [action.meta.projectId]: action.payload.data
            };

        case ADD_PROJECT_COMMENT_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                [action.meta.projectId]: [
                    action.payload.data,
                    ...(state[action.meta.projectId] ? state[action.meta.projectId] : [])
                ]
            };

        case DELETE_PROJECT_COMMENT_FULFILLED:
            const list = state[action.meta.projectId] ? state[action.meta.projectId] : [];
            const item = list.find(comment => comment.id === action.meta.commentId);
            const index = list.indexOf(item);
            list.splice(index, 1);

            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                [action.meta.projectId]: list
            };

        default:
            return state;
    }
}
