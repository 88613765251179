import React from "react";
import { Helmet } from "react-helmet";
import SimpleLayout from "../components/Layout/SimpleLayout";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import { retry } from "../utils/helpers/retry";

const LazyHandwerkerDokumente = React.lazy(() => retry(() => import("../container/Craftsman/CraftsmanDokumente")));

const HandwerkerDokumentePage = ({ title, network, match, location, history, ...rest }) => (
    <SimpleLayout network={network} match={match}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <React.Suspense fallback={<LoadingIndicator />}>
            <LazyHandwerkerDokumente match={match} location={location} history={history} {...rest} />
        </React.Suspense>
    </SimpleLayout>
);

export default HandwerkerDokumentePage;
