import React, { Component } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import getBreakPoints from "../../utils/redux-breakpoint/breakpointCalculator";
import styles from "./Popover.css";

const popoverRoot = document.getElementById("popover-root");

class PopoverPortal extends Component {
    constructor(props) {
        super(props);
        this.element = document.createElement("div");
    }

    componentDidMount() {
        popoverRoot.appendChild(this.element);
    }

    componentWillUnmount() {
        popoverRoot.removeChild(this.element);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.element);
    }
}

export default class Popover extends Component {
    anchorRef = React.createRef();
    popoverRef = React.createRef();
    headerRef = React.createRef();

    timeout = null;

    state = {
        open: false,
        topBubble: 0,
        topArrow: 0,
        leftBubble: 0,
        leftArrow: 0,
        openOnTheRightSide: true
    };

    static defaultProps = {
        text: "",
        headline: ""
    };

    static propTypes = {
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        headline: PropTypes.string
    };

    @autobind
    openPopover() {
        clearTimeout(this.timeout);
        const isExtraSmall = getBreakPoints().isExtraSmall;
        const isSmall = getBreakPoints().isSmall;
        const arrowWidth = 10;
        const anchorRect = this.anchorRef.current.getBoundingClientRect();
        const popoverRect = this.popoverRef.current.getBoundingClientRect();
        const headerRect = this.headerRef.current.getBoundingClientRect();
        const padding = isSmall ? 8 : 12;
        const openOnTheRightSide = arrowWidth + popoverRect.width + padding < window.innerWidth - anchorRect.right;

        // Prevent Popover from opening when it's not inside the visible area
        if (anchorRect.left < padding || anchorRect.right > window.innerWidth - padding) {
            return;
        }

        const scrollY = typeof window.scrollY === "undefined" ? window.pageYOffset : window.scrollY;

        this.setState({
            topBubble:
                (isExtraSmall || isSmall ? anchorRect.bottom + arrowWidth : anchorRect.top - headerRect.height) +
                scrollY,

            topArrow: (isExtraSmall || isSmall ? anchorRect.bottom : anchorRect.top) + scrollY,

            leftBubble: isExtraSmall
                ? 0
                : isSmall
                ? Math.max(
                      padding * 2,
                      Math.min(
                          anchorRect.right - (popoverRect.width - anchorRect.width / 2) / 2,
                          window.innerWidth - popoverRect.width - padding * 2
                      )
                  )
                : openOnTheRightSide
                ? anchorRect.left + anchorRect.width + arrowWidth
                : anchorRect.left - arrowWidth - popoverRect.width,

            leftArrow:
                isExtraSmall || isSmall
                    ? anchorRect.left + anchorRect.width / 2
                    : openOnTheRightSide
                    ? anchorRect.right
                    : anchorRect.left - arrowWidth,

            open: true,
            openOnTheRightSide
        });
    }

    @autobind
    closePopover() {
        this.timeout = setTimeout(
            () =>
                this.setState({
                    open: false,
                    topBubble: 0,
                    topArrow: 0,
                    leftBubble: 0,
                    leftArrow: 0,
                    openOnTheRightSide: true
                }),
            50
        );
    }

    render() {
        const Portal = process.env.__CLIENT__ ? PopoverPortal : React.Fragment;

        return (
            <div
                ref={this.anchorRef}
                className={classNames(styles.anchor, this.props.className)}
                onMouseEnter={this.openPopover}
                onMouseLeave={this.closePopover}
            >
                <Portal>
                    <div
                        ref={this.popoverRef}
                        style={{
                            top: this.state.topBubble,
                            left: this.state.leftBubble
                        }}
                        className={classNames(styles.popover, {
                            [styles.open]: this.state.open
                        })}
                    >
                        <div className={styles.header} ref={this.headerRef}>
                            {this.props.headline}
                        </div>
                        <div className={styles.body}>{this.props.text}</div>
                    </div>
                    <div
                        style={{
                            top: this.state.topArrow,
                            left: this.state.leftArrow
                        }}
                        className={classNames(styles.popoverArrow, {
                            [styles.open]: this.state.open,
                            [styles.popoverArrowRight]: this.state.openOnTheRightSide
                        })}
                    />
                </Portal>
                {this.props.children}
            </div>
        );
    }
}
