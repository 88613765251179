import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Sidebar from "../../container/Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import styles from "./MainLayout.css";

export default function MainLayout(props) {
    const { children, network, match } = props;
    const ui = useSelector(({ ui }) => ui);
    const { header, sidebar } = ui;

    return (
        <div
            className={classNames(styles.container, {
                [styles.displayNav]: sidebar.isOpen
            })}
        >
            <div className={styles.canvas}>
                <Header showSidebarToggle showSidebar network={network} />

                <div className={styles.layoutMain} style={{ paddingTop: header.extendedBaseHeight }}>
                    <Sidebar position={"layout"} fetchData={true} />

                    <div className={styles.content}>{children}</div>
                </div>

                <Footer match={match} />
            </div>
        </div>
    );
}
