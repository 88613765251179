import { unique } from "@lucify/utils";
import { LOGOUT } from "../actions/auth.actions";
import { GET_MACHERPROJEKT_PROFILE_FULFILLED } from "../actions/macherProjekte.actions";
import {
    ADD_ASSIGNED_CRAFTSMEN,
    DELETE_ASSIGNED_CRAFTSMAN,
    GET_PROJECT_CUSTOMEROFFERS_FULFILLED
} from "../actions/projects.actions";

const initialState = {
    isFetching: false,
    failed: false
};

function getCraftmenFacilityIdsFromCustomerOffers(customerOffers = []) {
    return unique(
        customerOffers
            .map(customerOffer => customerOffer.offerWorkTasks)
            .flat()
            .map(workTask => workTask.craftsmanFacilityId)
            .filter(craftsmanFacilityId => craftsmanFacilityId)
    );
}

function getCraftmenFacilityIdsFromWorkTasks(workTasks) {
    return unique(
        workTasks.map(workTask => workTask.craftsmanFacilityId).filter(craftsmanFacilityId => craftsmanFacilityId)
    );
}

export function projectsAssignedCraftsmen(state = initialState, action) {
    function getStateByVermittlungId(vermittlungId) {
        return state[vermittlungId] || [];
    }

    switch (action.type) {
        case LOGOUT:
            return initialState;

        case ADD_ASSIGNED_CRAFTSMEN:
            return {
                ...state,
                [action.meta.vermittlungId]: unique([
                    ...getStateByVermittlungId(action.meta.vermittlungId),
                    ...action.payload
                ])
            };

        case DELETE_ASSIGNED_CRAFTSMAN:
            return {
                ...state,
                [action.meta.vermittlungId]: getStateByVermittlungId(action.meta.vermittlungId).filter(
                    craftsmanFacilityId => craftsmanFacilityId !== action.payload
                )
            };

        case GET_PROJECT_CUSTOMEROFFERS_FULFILLED:
            return {
                ...state,
                [action.meta.vermittlungId]: unique([
                    ...getStateByVermittlungId(action.meta.vermittlungId),
                    ...getCraftmenFacilityIdsFromCustomerOffers(action.payload.data)
                ])
            };

        case GET_MACHERPROJEKT_PROFILE_FULFILLED:
            return action.meta.vorgangId
                ? {
                      ...state,
                      [action.meta.vorgangId]: unique([
                          ...getStateByVermittlungId(action.meta.projectId),
                          ...getCraftmenFacilityIdsFromWorkTasks(
                              action.payload.data.vorgaenge.find(vorgang => vorgang.id === action.meta.vorgangId)
                                  .workTasks
                          )
                      ])
                  }
                : state;

        default:
            return state;
    }
}
