import { MacherProjektDTO } from "@lucify/services/src/generated/macher-service.types";
import { IMacherProjektProfileState } from "../../interfaces/ApplicationState";
import { LOGOUT } from "../actions/auth.actions";
import {
    GET_MACHERPROJEKT_PROFILE_FULFILLED,
    GET_MACHERPROJEKT_PROFILE_PENDING,
    GET_MACHERPROJEKT_PROFILE_REJECTED,
    UPDATE_MACHERPROJEKT_PROFILE_FULFILLED,
    UPDATE_MACHERPROJEKT_PROFILE_PENDING,
    UPDATE_MACHERPROJEKT_PROFILE_REJECTED,
    UPDATE_VORGANG_PROFILE_FULFILLED
} from "../actions/macherProjekte.actions";

const initialState: IMacherProjektProfileState = {
    isFetching: false,
    failed: false
};

export function macherProjektProfile(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        case GET_MACHERPROJEKT_PROFILE_PENDING:
            return {
                ...state,
                isFetching: true
            };

        case GET_MACHERPROJEKT_PROFILE_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true
                };
            }

        case GET_MACHERPROJEKT_PROFILE_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                [action.meta.macherProjektId]: action.payload.data
            };

        case UPDATE_MACHERPROJEKT_PROFILE_PENDING:
            return {
                ...state,
                isFetching: true
            };

        case UPDATE_MACHERPROJEKT_PROFILE_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true
                };
            }

        case UPDATE_MACHERPROJEKT_PROFILE_FULFILLED:
            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,
                [action.meta.macherProjektId]: action.payload.data
            };

        case UPDATE_VORGANG_PROFILE_FULFILLED:
            const shouldUpdateExistingMacherProjekt = action.meta.macherProjektId && state[action.meta.macherProjektId];

            return {
                ...state,
                status: action.payload.status,
                isFetching: false,
                failed: false,

                ...(shouldUpdateExistingMacherProjekt
                    ? {
                          // update only updated vorgang in macherProjekt when macherProjekt is defined
                          [action.meta.macherProjektId]: {
                              ...(state[action.meta.macherProjektId] as MacherProjektDTO),
                              vorgaenge: (state[action.meta.macherProjektId] as MacherProjektDTO).vorgaenge.map(
                                  vorgang => (vorgang.id === action.meta.vorgangId ? action.payload.data : vorgang)
                              )
                          }
                      }
                    : {})
            };

        default:
            return state;
    }
}
