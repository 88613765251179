import { checkForUpdate, register } from "../serviceWorker";

const THIRTY_SECONDS = 30000;

let interval;

export function registerServiceWorker() {
    register({
        onUpdate() {
            clearInterval(interval);
            window.updateAvailable = true;
        }
    });

    clearInterval(interval);
    interval = setInterval(checkForUpdate, THIRTY_SECONDS);
}
