import React, { ReactNode } from "react";
import { classNames } from "@lucify/utils";
import { displayName } from "../../utils/helpers/render";
import { Avatar, AvatarImagesSizes } from "../Avatar/Avatar";
import styles from "./BusinessCard.css";

export interface UIBusinessCardProps {
    children?: ReactNode;
    name?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
    description?: string;
    image?: {
        src: string;
        alt: string;
        size?: AvatarImagesSizes;
    };

    withBorder?: boolean;
}

const BusinessCard: React.FC<UIBusinessCardProps> = ({
    children,
    withBorder = true,
    name,
    firstName,
    lastName,
    role,
    description,
    image
}) => {
    const { src: imageSrc, alt: imageAlt, size: imageSize } = image || {};
    const avatarProps = {
        firstName,
        lastName,
        imageSrc,
        imageAlt,
        imageSize
    };

    return (
        <div className={classNames(styles.card, { [styles.bordered]: withBorder })}>
            <div className={styles.content}>
                <div className={classNames(styles.image)}>
                    <Avatar {...avatarProps} />
                </div>

                <div className={styles.info}>
                    <span className={styles.role}>{role}</span>
                    <span className={styles.name}>{name ? name : displayName(firstName, lastName)}</span>
                    <span className={styles.description}>{description}</span>
                </div>
            </div>

            {children}
        </div>
    );
};

export default BusinessCard;
