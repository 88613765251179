import { getPagination } from "../../utils/helpers/list";
import { LOGOUT } from "../actions/auth.actions";
import {
    GET_PROJECTS_KPIS_FULFILLED,
    GET_PROJECTS_KPIS_PENDING,
    GET_PROJECTS_KPIS_REJECTED
} from "../actions/kpis.actions";

const initialState = {
    projects: {
        isFetching: false,
        failed: false
    }
};

export function kpis(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        case GET_PROJECTS_KPIS_PENDING:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    isFetching: true
                }
            };

        case GET_PROJECTS_KPIS_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    projects: {
                        ...state.projects,
                        status: action.payload.response.status,
                        isFetching: false,
                        failed: true
                    }
                };
            }

        case GET_PROJECTS_KPIS_FULFILLED:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    status: action.payload.status,
                    isFetching: false,
                    failed: false,
                    [action.meta.name]: getPagination(action).totalCount
                }
            };

        default:
            return state;
    }
}
