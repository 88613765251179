import { NotificationType } from "../components/NotificationCenter/Notification";
import { NotificationLinkProps } from "../components/NotificationCenter/NotificationLink";

export enum NotificationTargetArea {
    topRight = "topRight",
    top = "top",
    bottom = "bottom",
    center = "center"
}

export interface INotification {
    id: string;
    title?: string;
    message: string;
    iconName?: string;
    targetArea: NotificationTargetArea;
    type?: NotificationType;
    link?: NotificationLinkProps;
    persist?: boolean;
    dismissed?: boolean;
    createdAt: number;
    lastModified: number;
}
