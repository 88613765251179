import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styles from "./NotificationBar.css";

export default class NotificationBar extends PureComponent {
    static propTypes = {
        network: PropTypes.shape({
            networkError: PropTypes.bool,
            vpnError: PropTypes.bool
        })
    };

    render() {
        const { network } = this.props;

        return network && network.networkError ? (
            <div className={styles.notification}>
                {network.vpnError
                    ? "Die Server sind derzeit nicht erreichbar, möglicherweise wurde die VPN Verbindung unterbrochen."
                    : "Die Netzwerkverbindung wurde unterbrochen."}
            </div>
        ) : null;
    }
}
