import { LucifyKeycloakCountry } from "@lucify/auth";

interface UserCountryPortal {
    hostname: string;
    url: string;
    countryName: string;
}

type UserCountryPortalMapping = {
    [country in LucifyKeycloakCountry]?: UserCountryPortal;
};

export const userCountryPortalMapping: UserCountryPortalMapping = {
    [LucifyKeycloakCountry.de]: {
        hostname: "machbar-macher.de",
        url: "https://machbar-macher.de",
        countryName: "Deutschland"
    },
    [LucifyKeycloakCountry.at]: {
        hostname: "machbar-macher.at",
        url: "https://machbar-macher.at",
        countryName: "Österreich"
    }
};
