export function gapInPercentage(target: number, base: number): number {
    return Math.round((target / base - 1) * 100);
}

export function formatNumber(number: number, fractionDigits = 2) {
    return new Intl.NumberFormat("de-DE", {
        style: "decimal",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }).format(number);
}

export function formatCurrency(number: number | null | undefined, fallbackString: string = "-"): string {
    if (number !== null && number !== undefined) {
        return new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR"
        }).format(number);
    }

    return fallbackString;
}

export function roundTo(value: number, places: number): number {
    const power = Math.pow(10, places);
    return Number.isNaN(value) ? 0 : Math.round(value * power) / power;
}

export function isEven(number: number): boolean {
    return number % 2 === 0;
}

export function removePhoneNumberFormat(phoneNumber: string) {
    return phoneNumber.replace(/[^0-9+]/g, "");
}

export function localStringToNumber(value: string): number {
    return Number(String(value).replace(",", "."));
}

export function convertSecondsToMinutes(seconds: number) {
    return seconds / 60;
}
