const errorMessage = "Access to LocalStorage not allowed";

let errorHandler = (error: any, message: string = errorMessage) => {
    console.log("Message: ", message, "Response: ", error);

    if (process.env.NODE_ENV === "development" && (error instanceof TypeError || error instanceof ReferenceError)) {
        throw error;
    }
};

export const setLocalStorageErrorHandler = (f: (error: any, message?: string) => void) => {
    errorHandler = f;
};

export const localStorageWrapper = {
    setItem(key: string, value: string): void {
        try {
            return window.localStorage.setItem(key, value);
        } catch (error) {
            errorHandler(error);
        }
    },
    getItem(key: string): string | null {
        try {
            return window.localStorage.getItem(key);
        } catch (error) {
            errorHandler(error);
            return null;
        }
    },
    removeItem(key: string): void {
        try {
            return window.localStorage.removeItem(key);
        } catch (error) {
            errorHandler(error);
        }
    },
    clear() {
        try {
            return window.localStorage.clear();
        } catch (error) {
            errorHandler(error);
        }
    },
    key(index: number): string | null {
        try {
            return window.localStorage.key(index);
        } catch (error) {
            errorHandler(error);
            return null;
        }
    },
    setStringifiedItem(key: string, value: any) {
        return this.setItem(key, JSON.stringify(value));
    },
    getParsedItem<ReturnType>(key: string): ReturnType | null {
        const item = this.getItem(key);
        if (item != null) {
            try {
                return JSON.parse(item);
            } catch (e) {
                this.removeItem(key);
                console.debug(
                    `Parsen des LocalStorage mit dem key ${key} ist fehlgeschlagen und wurde zurückgesetzt`,
                    e
                );
                return null;
            }
        }
        return null;
    }
};
