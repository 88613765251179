import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
// store & routing
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { push } from "connected-react-router";
import { autobind } from "core-decorators";
// components
import ProtectedRoute from "../../hoc/ProtectedRoute";
import { endTick, startTick, triggerTick } from "../../hoc/withTick";
import ErrorHandlerPage from "../../pages/Handler/ErrorHandler";
import routes from "./routes";
import { registerAppShortcuts, unregisterAppShortcuts } from "./shortcuts";

const mapStateToProps = ({ auth, router }) => ({ auth, router });

@connect(mapStateToProps, { push })
@withTranslation("internals")
export default class App extends PureComponent {
    static defaultProps = {
        tickInterval: process.env.NODE_ENV === "development" ? 600000 : 30000
    };

    state = {
        networkError: false,
        vpnError: false,
        error: null,
        errorInfo: null,
        eventId: null
    };

    componentDidMount() {
        startTick(this.props.tickInterval);
        registerAppShortcuts(this.props.push);

        document.addEventListener("visibilitychange", this.onVisibilityChange);
        window.addEventListener("offline", this.onOffline);
        window.addEventListener("online", this.onOnline);
    }

    componentDidUpdate(prevProps) {
        if (this.props.tickInterval !== prevProps.tickInterval) {
            endTick();
            startTick(this.props.tickInterval);
        }

        if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo
        });
    }

    componentWillUnmount() {
        endTick();
        unregisterAppShortcuts();

        document.removeEventListener("visibilitychange", this.onVisibilityChange);
        window.removeEventListener("offline", this.onOffline);
        window.removeEventListener("online", this.onOnline);
    }

    @autobind
    onVisibilityChange() {
        if (document.hidden) {
            endTick();
        } else {
            triggerTick();
            startTick(this.props.tickInterval);
        }
    }

    @autobind
    onOnline() {
        this.setState({
            networkError: false
        });
    }

    @autobind
    onOffline() {
        this.setState({
            networkError: true
        });
    }

    render() {
        const { auth: authStore, t } = this.props;
        const { error, errorInfo, eventId, networkError, vpnError } = this.state;
        const network = {
            networkError: networkError,
            vpnError: vpnError
        };

        return error ? (
            <ErrorHandlerPage error={error} errorInfo={errorInfo} eventId={eventId} />
        ) : (
            <Switch>
                {routes.map(({ auth, page: Page, ...rest }, index) =>
                    auth ? (
                        <ProtectedRoute
                            key={index}
                            auth={authStore}
                            render={props => <Page t={t} network={network} {...props} {...rest} />}
                            {...rest}
                        />
                    ) : (
                        <Route
                            key={index}
                            render={props => <Page t={t} network={network} {...props} {...rest} />}
                            {...rest}
                        />
                    )
                )}
            </Switch>
        );
    }
}
