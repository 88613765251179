import uriTemplates from "uri-templates";
import { $TSFixMe, IHandwerkerBewertungenQueryParams, PriceClusterNumber } from "@lucify/types";
import { Methods } from "./Fetch";
import { Service } from "./Service";
import {
    CompanyFacilityCreateDTO,
    CompanyProvisionCreateDTO,
    CompanyProvisionDTO,
    CraftsmanCompanyCreateDTO,
    CraftsmanCompanyDTO,
    CraftsmanFacilityAddTaskDTO,
    CraftsmanFacilityComment,
    CraftsmanFacilityDTO,
    CraftsmanFacilityDocumentLink,
    CraftsmanFacilityListDTO,
    CraftsmanFacilityOperationZipRegionsResponseDTO,
    CraftsmanFacilityPatchRequest,
    CraftsmanFacilityTaskDTO,
    DocumentPatchRequest,
    HandwerkerBewertung,
    HandwerkerBewertungDTO,
    HandwerkerBewertungStatisticsDTO,
    HandwerkerKoordinatorCreateDTO,
    HandwerkerKoordinatorDTO,
    HandwerkerKoordinatorPatchDTO,
    HandwerkerKoordinatorRegionDTO,
    KontaktFormularDTO,
    OneTimeToken,
    Qualification
} from "./generated/craftsmen-service.types";

const endpoints = {
    list: "/craftsmen-service/api/{country}/craftsman-facilities",
    companyProfile: "/craftsmen-service/api/{country}/craftsman-companies/{id}",
    profile: "/craftsmen-service/api/{country}/craftsman-facilities/{id}",
    update: "/craftsmen-service/api/{country}/craftsman-facilities/{id}",
    companyUpdate: "/craftsmen-service/api/{country}/craftsman-companies/{id}",
    createCompany: "/craftsmen-service/api/{country}/craftsman-companies",
    createFacility: "/craftsmen-service/api/{country}/craftsman-companies/{id}/craftsman-facilities",
    comments: "/craftsmen-service/api/{country}/craftsman-facilities/{id}/comments",
    deleteComment: "/craftsmen-service/api/{country}/craftsman-facilities/{id}/comments/{commentId}",
    onetimetoken: "/craftsmen-service/api/{country}/onetimetoken",
    kontaktformular: "/craftsmen-service/api/{country}/kontaktformular/{token}",

    // HKOs
    handwerkerkoordinatoren: "/craftsmen-service/api/{country}/handwerkerkoordinatoren",
    handwerkerkoordinator: "/craftsmen-service/api/{country}/handwerkerkoordinatoren/{id}",
    handwerkerkoordinatorRegions: "/craftsmen-service/api/{country}/handwerker-koordinator-regions",

    // Qualifikationen & Tätigkeiten
    qualifications: "/craftsmen-service/api/{country}/qualifications",
    tasks: "/craftsmen-service/api/{country}/craftsman-facilities/{id}/tasks/suggestion",
    updateTasks: "/craftsmen-service/api/{country}/craftsman-facilities/{id}/tasks",

    // Craftsman Contracts Documents
    craftsmanContractDocumentDraftUri:
        "/craftsmen-service/api/{country}/craftsman-facilities/{id}/documents/contract-draft.pdf?access_token={token}",
    craftsmanContractDocument: "/craftsmen-service/api/{country}/craftsman-facilities/{id}/documents/contract",

    // Craftsman Documents
    craftsmanDocuments: "/craftsmen-service/api/{country}/craftsman-facilities/{id}/documents",
    craftsmanDocument:
        "/craftsmen-service/api/{country}/craftsman-facilities/{id}/documents/{documentId}?download={download}",
    craftsmanDocumentUri:
        "/craftsmen-service/api/{country}/craftsman-facilities/{id}/documents/{documentId}?download={download}&access_token={token}",
    uploadCraftsmanDocument: "/craftsmen-service/api/{country}/craftsman-facilities/{id}/documents",
    updateCraftsmanDocument: "/craftsmen-service/api/{country}/craftsman-facilities/{id}/documents/{documentId}",

    // Bewertungen
    bewertungen: "/craftsmen-service/api/{country}/craftsman-facilities/{craftsmanId}/bewertungen",
    deleteBewertung: "/craftsmen-service/api/{country}/craftsman-facilities/{craftsmanId}/bewertungen/{bewertungId}",
    bewertungenStats: "/craftsmen-service/api/{country}/craftsman-facilities/{craftsmanId}/bewertungen/stats",

    // Price clusters
    priceclusters: "/craftsmen-service/api/{country}/pricecluster",

    // ZipCodes
    craftsmanZipCodes: "/craftsmen-service/api/{country}/craftsman-facilities/{craftsmanId}/operation-zip-regions",
    allZipCodes: "/craftsmen-service/api/{country}/zip-regions/zip-codes",

    // Provisions
    createCompanyProvision: "/craftsmen-service/api/{country}/provision/craftsman-companies/{companyId}",
    getCurrentCompanyProvision: "/craftsmen-service/api/{country}/provision/craftsman-companies/{companyId}/current"
};

export class CraftsmenService extends Service<typeof endpoints> {
    endpoints = endpoints;

    getList(params: $TSFixMe) {
        return this.fetch.request<CraftsmanFacilityListDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("list"),
                data: {
                    ...this.defaults
                }
            },
            params
        });
    }

    getProfile(id: string) {
        return this.fetch.request<CraftsmanFacilityDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("profile"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    getCompanyProfile(id: string) {
        return this.fetch.request<CraftsmanCompanyDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("companyProfile"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    updateProfile(id: string, data: CraftsmanFacilityPatchRequest) {
        return this.fetch.request<CraftsmanFacilityDTO>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("update"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    updateCompanyProfile(id: string, data: CraftsmanCompanyDTO) {
        return this.fetch.request<CraftsmanCompanyDTO>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("companyUpdate"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    createCompany(data: CraftsmanCompanyCreateDTO) {
        return this.fetch.request<CraftsmanCompanyDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("createCompany"),
                data: {
                    ...this.defaults
                }
            },
            data
        });
    }

    createFacility(data: CompanyFacilityCreateDTO, companyId: string) {
        return this.fetch.request<CraftsmanFacilityDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("createFacility"),
                data: {
                    ...this.defaults,
                    id: companyId
                }
            },
            data
        });
    }

    getComments(id: string) {
        return this.fetch.request<CraftsmanFacilityComment[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("comments"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    addComment(id: string, data: $TSFixMe) {
        return this.fetch.request<CraftsmanFacilityComment>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("comments"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    deleteComment(id: string, commentId: string) {
        return this.fetch.request({
            method: Methods.DELETE,
            rest: {
                url: this.endpoint("deleteComment"),
                data: {
                    ...this.defaults,
                    id,
                    commentId
                }
            }
        });
    }

    // Handwerkerkoordinatoren (HKOs)
    getHKOList() {
        return this.fetch.request<HandwerkerKoordinatorDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("handwerkerkoordinatoren"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    getHKO(id: string) {
        return this.fetch.request<HandwerkerKoordinatorDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("handwerkerkoordinator"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    updateHKO(id: string, data: HandwerkerKoordinatorPatchDTO) {
        return this.fetch.request<HandwerkerKoordinatorDTO>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("handwerkerkoordinator"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data
        });
    }

    addHKO(data: HandwerkerKoordinatorCreateDTO) {
        return this.fetch.request<HandwerkerKoordinatorDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("handwerkerkoordinatoren"),
                data: {
                    ...this.defaults
                }
            },
            data
        });
    }

    postContactFormData(token: string, data: KontaktFormularDTO, country: string) {
        return this.fetch.request<{}>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("kontaktformular"),
                data: { country, token }
            },
            data
        });
    }

    getToken(country: string) {
        return this.fetch.request<OneTimeToken>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("onetimetoken"),
                data: {
                    country
                }
            }
        });
    }

    /**
     * @deprecated
     * Use method provided in WorktaskService
     */
    getQualifications() {
        return this.fetch.request<Qualification[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("qualifications"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    getTasks(id: string) {
        return this.fetch.request<CraftsmanFacilityTaskDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("tasks"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    updateTasks(id: string, tasks: CraftsmanFacilityAddTaskDTO[]) {
        return this.fetch.request<void>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("updateTasks"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            data: {
                tasks
            }
        });
    }

    // Document URIs
    getCraftsmanContractDocumentDraftUri(id: string) {
        return uriTemplates(this.endpoints.craftsmanContractDocumentDraftUri).fill({
            id,
            country: this.country as string,
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    getCraftsmanDocumentUri(id: string, documentId: string, download = false) {
        return uriTemplates(this.endpoints.craftsmanDocumentUri).fill({
            id,
            documentId,
            country: this.country as string,
            download: download.toString(),
            token: this.fetch.lucifyOIDCProvider?.token!
        });
    }

    // Documents
    getCraftsmanDocument(id: string, documentId: string) {
        return this.fetch.request<Blob>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("craftsmanDocument"),
                data: {
                    ...this.defaults,
                    id,
                    documentId,
                    download: "true"
                }
            },
            blob: true
        });
    }

    getCraftsmanDocuments(id: string) {
        return this.fetch.request<CraftsmanFacilityDocumentLink[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("craftsmanDocuments"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    uploadCraftsmanDocument(id: string, formData: FormData) {
        return this.fetch.request<CraftsmanFacilityDocumentLink>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("uploadCraftsmanDocument"),
                data: {
                    ...this.defaults,
                    id
                }
            },
            formData
        });
    }

    updateCraftsmanDocument(id: string, documentId: string, data: DocumentPatchRequest) {
        return this.fetch.request<CraftsmanFacilityDocumentLink>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("updateCraftsmanDocument"),
                data: {
                    ...this.defaults,
                    id,
                    documentId
                }
            },
            data
        });
    }

    // Craftsman Contracts
    createCraftsmanContractDocument(id: string) {
        return this.fetch.request<CraftsmanFacilityDocumentLink>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("craftsmanContractDocument"),
                data: {
                    ...this.defaults,
                    id
                }
            }
        });
    }

    addBewertung(craftsmanId: string, data: HandwerkerBewertungDTO) {
        return this.fetch.request<never>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("bewertungen"),
                data: {
                    ...this.defaults,
                    craftsmanId
                }
            },
            data
        });
    }

    getBewertungen(craftsmanId: string, params: IHandwerkerBewertungenQueryParams) {
        return this.fetch.request<HandwerkerBewertung[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("bewertungen"),
                data: {
                    ...this.defaults,
                    craftsmanId
                }
            },
            params
        });
    }

    getBewertungenStats(craftsmanId: string) {
        return this.fetch.request<HandwerkerBewertungStatisticsDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("bewertungenStats"),
                data: {
                    ...this.defaults,
                    craftsmanId
                }
            }
        });
    }

    deleteBewertung(craftsmanId: string, bewertungId: string) {
        return this.fetch.request({
            method: Methods.DELETE,
            rest: {
                url: this.endpoint("deleteBewertung"),
                data: {
                    ...this.defaults,
                    craftsmanId,
                    bewertungId
                }
            }
        });
    }

    getPriceclusters() {
        return this.fetch.request<PriceClusterNumber[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("priceclusters"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    getCraftsmanZipCodesList(craftsmanId: string) {
        return this.fetch.request<CraftsmanFacilityOperationZipRegionsResponseDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("craftsmanZipCodes"),
                data: {
                    ...this.defaults,
                    craftsmanId
                }
            }
        });
    }

    addCraftsmanZipCodes(craftsmanId: string, zipCodes: string[]) {
        return this.fetch.request<void>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("craftsmanZipCodes"),
                data: {
                    ...this.defaults,
                    craftsmanId
                }
            },
            data: {
                method: "ADD",
                zipRegions: zipCodes
            }
        });
    }

    deleteCraftsmanZipCodes(craftsmanId: string, zipCodes: string[]) {
        return this.fetch.request<void>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("craftsmanZipCodes"),
                data: {
                    ...this.defaults,
                    craftsmanId
                }
            },
            data: {
                method: "DELETE",
                zipRegions: zipCodes
            }
        });
    }

    replaceCraftsmanZipCodes(craftsmanId: string, zipCodes: string[]) {
        return this.fetch.request<void>({
            method: Methods.PATCH,
            rest: {
                url: this.endpoint("craftsmanZipCodes"),
                data: {
                    ...this.defaults,
                    craftsmanId
                }
            },
            data: {
                method: "REPLACE",
                zipRegions: zipCodes
            }
        });
    }

    getAllZipCodes() {
        return this.fetch.request<string[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("allZipCodes"),
                data: {
                    ...this.defaults
                }
            }
        });
    }

    createCompanyProvision(companyId: string, data: CompanyProvisionCreateDTO) {
        return this.fetch.request<CompanyProvisionDTO>({
            method: Methods.POST,
            rest: {
                url: this.endpoint("createCompanyProvision"),
                data: {
                    ...this.defaults,
                    companyId
                }
            },
            data
        });
    }
    getCurrentCompanyProvision(companyId: string) {
        return this.fetch.request<CompanyProvisionDTO>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("getCurrentCompanyProvision"),
                data: {
                    ...this.defaults,
                    companyId
                }
            }
        });
    }

    getHKORegions() {
        return this.fetch.request<HandwerkerKoordinatorRegionDTO[]>({
            method: Methods.GET,
            rest: {
                url: this.endpoint("handwerkerkoordinatorRegions"),
                data: {
                    ...this.defaults
                }
            }
        });
    }
}
