import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router";

const HomePage = ({ title }) => (
    <React.Fragment>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <Redirect to={"/dashboard"} />
    </React.Fragment>
);

export default HomePage;
