import React, { ReactNode, SyntheticEvent } from "react";
import { FieldProps } from "formik";
import { $TSFixMe } from "@lucify/types";
import { classNames } from "@lucify/utils";
import styles from "./FormElements.css";

interface FormWrapperProps {
    children?: ReactNode;
}

export const FormWrapper: React.FC<FormWrapperProps> = ({ children }) => (
    <div className={styles.formWrapper}>{children}</div>
);

interface FormGroupProps {
    className?: string;
    hasLabel?: boolean;
    children: any;
}

export const FormGroup: React.FC<FormGroupProps> = React.memo(({ className, hasLabel, children }) => (
    <div
        className={classNames(styles.formGroup, className, {
            [styles.formGroupHasLabel]: hasLabel
        })}
    >
        {children}
    </div>
));

interface FormLabelProps {
    labelText?: string;
    labelHint?: string;
    for?: any;
    disabled?: boolean;
}

export const FormLabel: React.FC<FormLabelProps> = React.memo(({ labelText, labelHint, disabled, for: htmlFor }) =>
    labelText ? (
        <label
            className={classNames(styles.formLabel, {
                [styles.formLabelDisabled]: disabled
            })}
            htmlFor={htmlFor}
        >
            {labelText}
            {labelHint ? <span className={styles.formLabelHint}> {labelHint}</span> : null}
        </label>
    ) : null
);

interface FormFakeInputProps {
    label?: string;
    labelHint?: string;
    value: string;
    hint?: string;
    fontSize?: number | string;
    fakeInputBorder?: boolean;
    disabled?: boolean;
    layout?: string;
    hasError?: boolean;
    labelEmptyButNeeded?: boolean;
    title?: string;
    onClick?: (event: SyntheticEvent<HTMLDivElement>) => void;
}

export const FormFakeInput: React.FC<FormFakeInputProps & Partial<FieldProps>> = props => {
    const { label, labelHint, hint, labelEmptyButNeeded, layout } = props;
    const value = props.field ? props.field.value : props.value;

    return (
        <FormGroup
            hasLabel={!!label || labelEmptyButNeeded}
            className={classNames({
                [styles[layout!]]: !!layout
            })}
        >
            {label ? <FormLabel labelText={label} labelHint={labelHint} /> : null}

            <div
                className={classNames(styles.fakeInputWrapper, {
                    [styles.fakeLabel]: labelEmptyButNeeded,
                    [styles.isClickable]: props.onClick && !props.disabled,
                    [styles.hasError]: props.hasError,
                    [styles.fakeInputBordered]: props.fakeInputBorder,
                    [styles.fakeInputDisabled]: props.disabled
                })}
                onClick={props.onClick}
            >
                <div title={props.title} className={styles.fakeInput} style={{ fontSize: props.fontSize }}>
                    {value}
                </div>
                <FormFieldHint hint={hint} />
            </div>
        </FormGroup>
    );
};

FormFakeInput.defaultProps = {
    fontSize: "18px",
    fakeInputBorder: true
};

interface SplitSelectInputProps {
    className: any;
    children: any;
    left: any;
    right: any;
}

export const SplitSelectInput: React.FC<SplitSelectInputProps> = React.memo(({ className, children }) => {
    return (
        <div className={classNames(styles.splitSelectInput, className)}>
            <div className={styles.splitLeft}>{children[0]}</div>
            <div className={styles.splitRight}>{children[1]}</div>
        </div>
    );
});

interface FormFieldHintProps {
    hint?: string; // PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    className?: string;
    component?: $TSFixMe; // PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string])
}

export const FormFieldHint: React.FC<FormFieldHintProps> = React.memo(
    ({ hint, className, component: Component, ...rest }) => {
        return hint ? (
            <Component className={classNames(styles.fieldHint, className)} {...rest}>
                {hint}
            </Component>
        ) : null;
    }
);

FormFieldHint.defaultProps = {
    hint: "",
    className: "",
    component: "span"
};

interface FormRatingLegendProps {
    labelFrom: string;
    labelTo: string;
}

export const FormRatingLegend: React.FC<FormRatingLegendProps> = React.memo(({ labelFrom, labelTo }) => (
    <div className={styles.ratingBar}>
        <div className={styles.bar} />
        <span className={styles.text}>
            {labelFrom} / {labelTo}
        </span>
    </div>
));

FormRatingLegend.defaultProps = {
    labelFrom: "1 = unwahrscheinlich",
    labelTo: "5 = sehr wahrscheinlich"
};
