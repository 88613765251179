import { IAuthState } from "../../interfaces/ApplicationState";
import { lucifyOIDCProvider } from "../../services/lucifyOIDCProvider";
import { LOGOUT, SET_AUTHENTICATION_STATUS } from "../actions/auth.actions";

export const initialState: IAuthState = {
    isFetching: true,
    isAuthenticated: lucifyOIDCProvider.authenticated || false,
    failed: false
};

export function auth(state = initialState, action) {
    switch (action.type) {
        case SET_AUTHENTICATION_STATUS:
            return {
                ...state,
                isFetching: false,
                failed: false,
                isAuthenticated: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                isFetching: false,
                failed: false,
                isAuthenticated: false
            };
        default:
            return state;
    }
}
