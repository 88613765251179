import { FluxStandardAction } from "redux-promise-middleware";
import { RestSuccessResponse } from "@lucify/services";
import { IKoordinator } from "@lucify/types";
import { IProjectsPKOsState } from "../../interfaces/ApplicationState";
import { renderGravatarSrcURL } from "../../utils/helpers/gravatar";
import { LOGOUT } from "../actions/auth.actions";
import { GET_PKO_LIST_FULFILLED, GET_PKO_LIST_PENDING, GET_PKO_LIST_REJECTED } from "../actions/projects.actions";

const initialState: IProjectsPKOsState = {
    isFetching: false,
    failed: false,
    list: []
};

export function projectsPKOs(state = initialState, action: FluxStandardAction): IProjectsPKOsState {
    switch (action.type) {
        case LOGOUT:
            return initialState;

        case GET_PKO_LIST_PENDING:
            return {
                ...state,
                isFetching: true
            };

        case GET_PKO_LIST_REJECTED:
            if (action.payload.name === "AbortError" || !action.payload.response) {
                return state;
            } else {
                return {
                    ...state,
                    status: action.payload.response.status,
                    isFetching: false,
                    failed: true
                };
            }

        case GET_PKO_LIST_FULFILLED:
            return {
                list: (action.payload as RestSuccessResponse<IKoordinator[]>).data.map(pko => ({
                    ...pko,
                    image: {
                        src: renderGravatarSrcURL(pko.email),
                        alt: ""
                    }
                })),
                status: action.payload.status,
                isFetching: false,
                failed: false
            };

        default:
            return state;
    }
}
