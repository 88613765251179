import { macherService } from "../../services/macher/macher";

export const GET_WORK_TASKS = "GET_WORK_TASKS";
export const GET_WORK_TASKS_PENDING = "GET_WORK_TASKS_PENDING";
export const GET_WORK_TASKS_FULFILLED = "GET_WORK_TASKS_FULFILLED";
export const GET_WORK_TASKS_REJECTED = "GET_WORK_TASKS_REJECTED";

export const getWorkTasks = () => {
    return {
        type: GET_WORK_TASKS,
        payload: macherService.getWorktasks()
    };
};
