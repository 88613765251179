import React, { Component, PureComponent } from "react";
import classNames from "classnames";
import { FormGroupRow } from "./FormGroupRow";
import styles from "./FormSection.css";

export class FormSectionBody extends Component {
    render() {
        return (
            <div
                className={classNames(styles.formSectionBody, {
                    [styles.formSectionBodyResetPadding]: this.props.resetPadding
                })}
            >
                {this.props.children}
            </div>
        );
    }
}

export class FormSectionHeader extends PureComponent {
    render() {
        return (
            <div
                className={classNames(styles.formSectionHeader, {
                    [styles.withAction]: this.props.withAction
                })}
            >
                {this.props.children.map((child, i) => (
                    <div key={i} className={styles.formSectionHeaderChild}>
                        {child}
                    </div>
                ))}
            </div>
        );
    }
}

export class FormSectionHeadline extends PureComponent {
    render() {
        return this.props.headline ? <h3 className={styles.formSectionHeadline}>{this.props.headline}</h3> : null;
    }
}

export class FormSectionSubmitGroup extends Component {
    static defaultProps = {
        columns: ["shrink", "shrink"]
    };

    render() {
        return (
            <FormGroupRow layout={"reverseOnUnstack"} stackedColumnsSpace={true} {...this.props}>
                {this.props.children}
            </FormGroupRow>
        );
    }
}

export default class FormSection extends Component {
    static defaultProps = {
        divided: true,
        layout: "",
        customHeader: false
    };

    render() {
        return (
            <div
                className={classNames(styles.formSection, styles[this.props.layout], {
                    [styles.divided]: this.props.divided
                })}
            >
                {this.props.customHeader ? null : <FormSectionHeadline headline={this.props.headline} />}

                <div className={classNames(styles.formSectionBody)}>{this.props.children}</div>
            </div>
        );
    }
}
