import React from "react";
import { Helmet } from "react-helmet";
import MainLayout from "../components/Layout/MainLayout";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";
import { retry } from "../utils/helpers/retry";

const LazyProjectsList = React.lazy(() => retry(() => import("../container/Project/Liste")));

const ProjectsPage = ({ title, network, ...rest }) => (
    <MainLayout network={network} match={rest.match}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <React.Suspense fallback={<LoadingIndicator />}>
            <LazyProjectsList {...rest} />
        </React.Suspense>
    </MainLayout>
);

export default ProjectsPage;
